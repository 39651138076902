import { useQuery } from 'react-query';

import {
  itemizedAddonRequest1,
  itemizedAddonRequest2,
  itemizedAddonRequest3,
} from './ScreeningPayloadsByType';
import { useStore } from '../../../../hooks/useStore';
import { createClient } from '../../../../services/http';

export function usePackagePrice(cacheKey, pkg, enabled = true) {
  const store = useStore();
  const auth = store && store?.auth;

  return useQuery(
    cacheKey,
    async () => {
      if (pkg.screenings.length === 0) {
        return {
          bundled_price: 0,
          fixed_price: 0,
          passthrough_fee_range: [0, 0],
          service_fee_range: [0, 0],
          items: [],
        };
      }
      const path = `accounts/${auth.getAccountId()}/packages/calculate_prices`;
      const http = createClient(auth?.token);
      const resp = await http.put(path, pkg);
      return resp;
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    },
  );
}

// query for the price of the base package plus all possible add ons
// this data populates each individual screening price in the list section
export function useAllAddOnPrices(packageName) {
  const store = useStore();
  const auth = store.auth;

  return useQuery(
    ['base package prices', packageName],
    async () => {
      const path = `accounts/${auth.getAccountId()}/packages/calculate_prices`;
      const http = createClient(auth?.token);

      const itemizedAddon1 = await http.put(path, itemizedAddonRequest1);
      const itemizedAddon2 = await http.put(path, itemizedAddonRequest2);
      const itemizedAddon3 = await http.put(path, itemizedAddonRequest3);

      const facis1 = itemizedAddon1.data.items.find(
        item => item.product_type === 'facis_search',
      );
      const employmentVerification1 = itemizedAddon1.data.items.find(
        item => item.product_type === 'employment_verification',
      );
      const personalReferenceVerification1 = itemizedAddon1.data.items.find(
        item => item.product_type === 'personal_reference_verification',
      );
      const professionalReferenceVerification1 = itemizedAddon1.data.items.find(
        item => item.product_type === 'professional_reference_verification',
      );

      facis1.subtype = '1';
      employmentVerification1.maximum_allowed_employers = '1';
      personalReferenceVerification1.required_quantity = [true, '1'];
      professionalReferenceVerification1.required_quantity = [true, '1'];

      const facis3 = itemizedAddon2.data.items.find(
        item => item.product_type === 'facis_search',
      );
      const employmentVerification2 = itemizedAddon2.data.items.find(
        item => item.product_type === 'employment_verification',
      );
      const personalReferenceVerification2 = itemizedAddon2.data.items.find(
        item => item.product_type === 'personal_reference_verification',
      );
      const professionalReferenceVerification2 = itemizedAddon2.data.items.find(
        item => item.product_type === 'professional_reference_verification',
      );

      const employmentVerification3 = itemizedAddon3.data.items.find(
        item => item.product_type === 'employment_verification',
      );
      const personalReferenceVerification3 = itemizedAddon3.data.items.find(
        item => item.product_type === 'personal_reference_verification',
      );
      const professionalReferenceVerification3 = itemizedAddon3.data.items.find(
        item => item.product_type === 'professional_reference_verification',
      );

      itemizedAddon1.data.items.push({ ...facis3, subtype: '3' });
      itemizedAddon1.data.items.push({
        ...employmentVerification2,
        maximum_allowed_employers: '2',
      });
      itemizedAddon1.data.items.push({
        ...employmentVerification3,
        maximum_allowed_employers: '3',
      });

      itemizedAddon1.data.items.push({
        ...personalReferenceVerification2,
        required_quantity: [true, '2'],
      });
      itemizedAddon1.data.items.push({
        ...professionalReferenceVerification2,
        required_quantity: [true, '2'],
      });
      itemizedAddon1.data.items.push({
        ...personalReferenceVerification3,
        required_quantity: [true, '3'],
      });
      itemizedAddon1.data.items.push({
        ...professionalReferenceVerification3,
        required_quantity: [true, '3'],
      });

      return itemizedAddon1;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}
