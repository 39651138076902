import React from 'react';
import PropTypes from 'prop-types';
import { orderBy, startCase, includes } from 'lodash';

import Tooltip from '../../form/Tooltip';
import {
  SCREENING_NAMES,
  SCREENINGS_WITH_SUBTYPES,
  CONTACT_FORM_URL,
} from '../../helpers/Constants';
import { usePluralize } from '../../../hooks/usePluralize';
import getInternationalPricing from '../../helpers/internationalPackagePricing';
import { LEGACY_PARTNER_PACKAGES } from '../../../constants/identifiers';

const PricingV3 = ({
  application,
  customDescription,
  customClassName,
  customHeader,
  excludeHeader,
  isCheckrDirect = false,
}) => {
  if (!application.packages) {
    return null;
  }

  const domesticPackages = application.packages.filter(
    p => !p.international_only,
  );
  const internationalPackages = application.packages.filter(
    p => p.international_only,
  );
  const displayCheckDirectIntlDisclaimer =
    !!isCheckrDirect && !!internationalPackages.length;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pluralize = usePluralize(internationalPackages?.length);
  const displayNonCheckrDirectIntlDisclaimer =
    !isCheckrDirect && !!internationalPackages.length;

  return (
    <div>
      <div className='card card-packages'>
        {!excludeHeader && (
          <div className='card-header v3'>
            <strong className={customClassName || ''}>
              {customHeader || 'Included Checkr background check packages'}
            </strong>
            <Tooltip
              tooltip='These are the default packages and <br />
              prices available to your account.'
            />
            <div className='packages-description'>
              {displayCheckDirectIntlDisclaimer && (
                <div className='mt-2 mb-2'>
                  <i>
                    For candidates <b>living and working in</b> the United
                    States
                  </i>
                  <br />
                </div>
              )}
              {customDescription || (
                <span>
                  Great news! {application.name} has already negotiated great
                  pricing on the most popular background check packages and they
                  will be automatically be added to your account. If you need to
                  add, remove or customize your packages please{' '}
                  <a
                    href={CONTACT_FORM_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    contact us
                  </a>{' '}
                  once you have completed the sign-up process.
                </span>
              )}
            </div>
          </div>
        )}
        <PackageItems
          packages={isCheckrDirect ? domesticPackages : application.packages}
          isBillable={!application.partner_billing}
        />
      </div>
      {displayNonCheckrDirectIntlDisclaimer && (
        <p className='my-4 cd-footer font-italic'>
          **For international packages, please note that pricing, candidate
          requirements and turnaround times will vary by country.
          <a
            href='https://checkr.com/pricing/international'
            target='_blank'
            rel='noopener noreferrer'
          >
            {' '}
            learn more
          </a>
        </p>
      )}
      {displayCheckDirectIntlDisclaimer && (
        <div className='card card-packages'>
          <div className='card-header v3'>
            <strong
              className={customClassName || ''}
            >{`International Checkr Background Check ${pluralize(
              'Package',
            )}`}</strong>
            <Tooltip
              tooltip={`${pluralize('This', 'These')} ${pluralize('is', 'are')}
              the default international ${pluralize('package')} <br />
              and average ${pluralize('price')} available to your account.`}
            />
            <div className='packages-description mt-2 mb-2'>
              <i>
                For candidates <b>living and working outside</b> of the United
                States
              </i>
            </div>
          </div>
          <PackageItems
            packages={internationalPackages}
            isBillable={!application.partner_billing}
            isCheckrDirect={true}
          />
        </div>
      )}
    </div>
  );
};

PricingV3.propTypes = {
  application: PropTypes.object.isRequired,
  customClassName: PropTypes.string,
  customDescription: PropTypes.string,
  customHeader: PropTypes.string,
  excludeHeader: PropTypes.bool,
  isCheckrDirect: PropTypes.bool,
};

export default PricingV3;

export function PackageItems({ packages, isBillable }) {
  function packagePrice(p) {
    let price = p.international_only
      ? getInternationalPricing(p.slug)
      : p.price;
    if (p.drug_screening_price) {
      price += p.drug_screening_price;
    }

    return price;
  }

  const screeningsList = p => {
    const screenings = p.screenings.map(s => {
      let screeningName = SCREENING_NAMES[s.type] || startCase(s.type);
      if (s.subtype && includes(SCREENINGS_WITH_SUBTYPES, s.type)) {
        screeningName += ` (${s.subtype.replace(
          /(\d+)([a-zA-Z]+)/g,
          '$1 $2',
        )})`;
      }

      return (
        <li key={s.type} className='screenings-list'>
          {screeningName}
        </li>
      );
    });

    const halfway = Math.ceil(screenings.length / 2);

    return (
      <div className='col-sm-9 pl-2 pr-0'>
        <div className='screenings-list'>
          <i>Includes:</i>
        </div>
        <div className='d-flex justify-content-between'>
          <ul className='pr-3'>{screenings.slice(0, halfway)}</ul>
          <ul>{screenings.slice(halfway)}</ul>
        </div>
      </div>
    );
  };

  const sortedPackages = packages => {
    const packagesWithPrices = packages.map(p => {
      p.calculated_price = packagePrice(p);
      return p;
    });

    return orderBy(packagesWithPrices, ['calculated_price'], ['desc']);
  };

  const displayPricing = p => {
    if (p.international_only && p.calculated_price === 0) return '$TBD';
    return `${p.international_only ? '~ $' : '$'}${centsToDollars(
      p?.calculated_price,
    )}`;
  };

  return (
    <div className='card-body p-0'>
      <ul
        data-testid={LEGACY_PARTNER_PACKAGES.packageCardList}
        className='list-unstyled list-packages mb-0'
      >
        {sortedPackages(packages).map(p => (
          <li
            key={p.slug}
            data-testid={`${LEGACY_PARTNER_PACKAGES.packageCard}-${p.slug}`}
            className='list-item-package-v3'
          >
            <div className='col-sm-3 mr-2 p-0'>
              <strong>
                {p.name}
                {p.international_only ? '**' : ''}
              </strong>
              {isBillable && (
                <div className='package-price'>{displayPricing(p)}</div>
              )}
            </div>
            {screeningsList(p)}
          </li>
        ))}
      </ul>
    </div>
  );
}

PackageItems.propTypes = {
  packages: PropTypes.array,
  isBillable: PropTypes.bool.isRequired,
  isCheckrDirect: PropTypes.bool,
};

export const centsToDollars = cents =>
  Math.floor(cents / 100) +
  (cents % 100 > 0 ? '.' + ('00' + (cents % 100)).slice(-2) : '');
