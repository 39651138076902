import React from 'react';
import { CardHeader, Box, Divider } from '@mui/material/';
import { capitalize } from 'lodash';
import { PARTNER_PACKAGES } from '../../../constants/identifiers';
import LoadingBlock from './PackageScreenings/shared/LoadingBlock';

const PackageCardHeader = ({
  testId,
  title,
  isSelected,
  showPrice,
  price,
  priceLoading,
}) => (
  <CardHeader
    sx={{
      width: '100%',
    }}
    titleTypographyProps={{
      fontSize: 18,
      marginTop: 2,
      fontWeight: 'bold',
      color: isSelected ? '#FFF' : '#001730',
      sx: {
        typography: {
          xs: { fontSize: '16px' },
          lg: { fontSize: '18px' },
        },
      },
    }}
    subheaderTypographyProps={{
      color: isSelected ? '#FFF' : '#001730',
      sx: {
        typography: {
          xs: { fontSize: '12px' },
          lg: { fontSize: '14px' },
        },
      },
    }}
    title={capitalize(title)}
    data-testid={`${PARTNER_PACKAGES.cardHeader}-${testId}`}
    subheader={
      <>
        {showPrice && (
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              minHeight: '27px',
            }}
            data-testid={`${PARTNER_PACKAGES.subheadPrice}-${testId}`}
          >
            {price !== 'Price varies' && (
              <span
                style={{
                  marginRight: '4px',
                  alignSelf: 'center',
                  marginTop: '3px',
                }}
              >
                starting at
              </span>
            )}
            {priceLoading ? (
              <LoadingBlock
                alignSelf='center'
                dataTestId={`${PARTNER_PACKAGES.startingAtLoadingBlock}-${testId}`}
              />
            ) : (
              <Box
                component='span'
                sx={{
                  color: isSelected ? '#FFF' : '#117091',
                  fontWeight: 'bold',
                  typography: {
                    xs: { fontSize: '16px' },
                    lg: { fontSize: '18px' },
                  },
                }}
              >
                {price}
              </Box>
            )}
          </div>
        )}
        <Divider
          style={{
            paddingTop: '16px',
            borderColor: '#CBD2DB',
          }}
        />
      </>
    }
  />
);

export default PackageCardHeader;
