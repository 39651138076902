import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import '../../components/views/CheckrDirect/fonts/Cambon-Bold.ttf';
import '../../components/views/CheckrDirect/fonts/OpenSans-Bold.ttf';
import '../../components/views/CheckrDirect/fonts/OpenSans-Regular.ttf';
import { StoreProvider } from '../../hooks/useStore';

export default function checkrDirect(
  WrapperComponent,
  numOfColumnsForLgScreens = 12,
) {
  @inject('store')
  @observer
  class Wrapper extends Component {
    render() {
      const { store } = this.props;
      return (
        <div className='cd-body'>
          <div className='container'>
            <div className='row justify-content-lg-center'>
              <div className={`col-lg-${numOfColumnsForLgScreens} col-md-12`}>
                <div className='row mt-3 mb-4'>
                  <div className='col-md-12 container-inner'>
                    <StoreProvider value={store}>
                      <WrapperComponent {...this.props} />
                    </StoreProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  Wrapper.wrappedComponent.propTypes = {
    store: PropTypes.object,
    numOfColumnsForLgScreens: PropTypes.number,
  };
  return Wrapper;
}
