import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const SetupModal = ({ isOpen, toggle, partnerName, getStep }) => (
  <Modal isOpen={isOpen} toggle={toggle} autoFocus={false} size='lg'>
    <ModalHeader toggle={toggle} className='modal-header'>
      <div className='h5 m-0'>Set up a Checkr account</div>
    </ModalHeader>
    <ModalBody>
      <div className='modal-body-text container'>
        <div className='row mb-3'>
          Let&apos;s get started! To set up your account, we&apos;ll need the
          following information:
        </div>
        <div className='row mb-3'>
          <div className='col-sm pl-0'>
            <strong>Contact information</strong>
            <ul className='modal-list-style'>
              <li>Full name</li>
              <li>Email address</li>
            </ul>
          </div>
          <div className='col-sm'>
            <strong>Business information</strong>
            <ul className='modal-list-style'>
              <li>Legal business name</li>
              <li>Phone number</li>
              <li>Company address</li>
              <li>Company website</li>
              <li>Tax ID / EIN</li>
            </ul>
          </div>
          <div className='col-sm'>
            <strong>Payment information</strong>
            <ul className='modal-list-style'>
              <li>Credit/Debit card or bank account</li>
            </ul>
          </div>
        </div>
        <div className='row mb-3'>
          Once you&apos;ve completed the sign-up process, Checkr will review and
          validate your business information. Once complete, we&apos;ll
          credential your account and send you an email letting you know
          you&apos;re ready to run background checks through {partnerName}.
        </div>
      </div>
      <div>
        <div className='d-flex justify-content-end'>
          <button
            onClick={toggle}
            className='btn close-btn mr-2'
            data-testid='modal-close'
          >
            Close
          </button>
          <button
            onClick={getStep}
            className='btn continue-btn'
            data-testid='modal-continue'
          >
            Continue
          </button>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

SetupModal.propTypes = {
  getStep: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  partnerName: PropTypes.string.isRequired,
};

export default SetupModal;
