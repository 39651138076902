import { useQuery } from 'react-query';
import { createClient } from '../services/http';
import { useStore } from './useStore';
import { useUser } from './useUser';

class PackageError extends Error {}

export function usePackages({ throwIfEmpty }, useQueryOptions) {
  const store = useStore();
  const auth = store.auth;
  const user = useUser();
  const { data: packages } = useQuery(
    [user, 'packages'],
    async () => {
      const http = createClient(auth?.token);
      const { data } = await http.get(
        `/accounts/${auth.getAccountId()}/packages`,
      );
      if (throwIfEmpty && data.length < 1) {
        throw new PackageError('No packages were returned');
      }
      return data;
    },
    useQueryOptions,
  );

  return packages?.sort((a, b) => a.price - b.price) || [];
}
