import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const GlobalWatchlistSearch = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo = `Search of various government and regulatory databases that list individuals who are 
    either prohibited from certain industries, 
    such as healthcare and finance, or who are on “most wanted” criminal lists.`;

  const advantages = [
    `Data sources included, for example: The Office of Inspector General for the U.S. Department of 
    Health and Human Services (OIG-HHS), System for Award Management (SAM), FBI Most Wanted Lists, 
    Interpol's Most Wanted Lists, Office of Foreign Asset Control Sanction Lists, Denied Persons Lists, 
    Department of State Sanction Lists, Specially Designated Nationals Lists; as well as various US Drug Enforcement 
    Administration Wanted Fugitive Lists.`,
    `Can identify suspected terrorists, money launderers, and drug traffickers 
    from federal databases that are unrelated to court records.`,
  ];

  const considerations = [
    'Federal, state, and county searches rarely return results from the global watchlist search sources.',
  ];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>Global Watchlist Search</ScreeningTitle>
        <ScreeningPrice priceByType='global_watchlist_search' />
        <AddScreeningsAction
          screeningType='global_watchlist_search'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default GlobalWatchlistSearch;
