/* eslint-disable camelcase */
import {
  ScreeningType,
  BasePackage,
  AdditionalPropertiesType,
  PackageFieldsType,
  ScreeningPrice,
  FACIS_SEARCH,
  PERSONAL_REFERENCE_VERIFICATION,
  PROFESSIONAL_REFERENCE_VERIFICATION,
  EMPLOYMENT_VERIFICATION,
} from './packageTypes';
import {
  screeningPayloadsByType,
  ScreeningPayloads,
} from './ScreeningPayloadsByType';

type ParamsType = {
  basePackage: BasePackage;
  addedScreeningTypes: ScreeningType[];
  additionalProperties?: AdditionalPropertiesType;
  packageFields?: PackageFieldsType;
};

export const buildPostBodyWithAddOns = ({
  basePackage,
  addedScreeningTypes = [],
  additionalProperties = {},
  packageFields = {},
}: ParamsType) => {
  // create an object of screening payloads keyed by screening type
  const screeningsMap: ScreeningPayloads = addedScreeningTypes.reduce(
    (acc: ScreeningPayloads, curr: ScreeningType) => {
      return {
        ...acc,
        [curr]: screeningPayloadsByType[curr],
      };
    },
    {} as ScreeningPayloads,
  );

  if (
    screeningsMap.international_criminal_search_v2 &&
    !basePackage.name.includes('International')
  ) {
    if (!screeningsMap.international_adverse_media_search) {
      screeningsMap.international_adverse_media_search = {
        type: 'international_adverse_media_search',
        out_of_country_history: true,
      };
      addedScreeningTypes.push('international_adverse_media_search');
    }
  }
  // for screenings with additional properties, add those properties to the corresponding payload objects
  if (screeningsMap.facis_search && additionalProperties.facis_search) {
    screeningsMap.facis_search = {
      ...screeningsMap.facis_search,
      ...additionalProperties.facis_search,
    };
  }

  if (
    screeningsMap.education_verification &&
    additionalProperties.education_verification
  ) {
    screeningsMap.education_verification = {
      ...screeningsMap.education_verification,
      ...additionalProperties.education_verification,
    };
  }

  if (
    screeningsMap.employment_verification &&
    additionalProperties.employment_verification
  ) {
    screeningsMap.employment_verification = {
      ...screeningsMap.employment_verification,
      ...additionalProperties.employment_verification,
    };
  }
  if (
    screeningsMap.professional_license_verification &&
    additionalProperties.professional_license_verification
  ) {
    screeningsMap.professional_license_verification = {
      ...screeningsMap.professional_license_verification,
      ...additionalProperties.professional_license_verification,
    };
  }

  if (
    screeningsMap.personal_reference_verification &&
    additionalProperties.personal_reference_verification
  ) {
    screeningsMap.personal_reference_verification = {
      ...screeningsMap.personal_reference_verification,
      ...additionalProperties.personal_reference_verification,
    };
  }

  if (
    screeningsMap.professional_reference_verification &&
    additionalProperties.professional_reference_verification
  ) {
    screeningsMap.professional_reference_verification = {
      ...screeningsMap.professional_reference_verification,
      ...additionalProperties.professional_reference_verification,
    };
  }

  basePackage.screenings.forEach((screening, index) => {
    if (
      screening.type === 'personal_reference_verification' ||
      screening.type === 'professional_reference_verification'
    ) {
      const requiredQuantity = screening.required_quantity;
      if (typeof requiredQuantity === 'number') {
        basePackage.screenings[index] = {
          ...screening,
          required_quantity: [true, requiredQuantity.toString()],
        };
      }
    }
  });

  const body = {
    ...basePackage,
    screenings: [...basePackage.screenings, ...Object.values(screeningsMap)],
    ...packageFields,
  };

  return body;
};

export const getDollarAmount = (val: number) =>
  (val / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

export const generateAddOnsPackageSlug = (slug: string) => {
  return `custom_${slug}`;
};

export const generateAddOnsPackageName = (name: string) => {
  return `Custom ${name}`;
};

export const getFacisPrice = (
  itemPrices: ScreeningPrice[],
  subtype: string,
  displayAsReadable = true,
) => {
  const itemPrice = itemPrices?.find(
    item => item.product_type === 'facis_search' && item.subtype === subtype,
  );
  if (!itemPrice || isNaN(itemPrice.base_price)) return 'Loading price...';
  return displayAsReadable
    ? getDollarAmount(itemPrice.base_price)
    : itemPrice.base_price;
};

export const getEmploymentVerificationPrice = (
  itemPrices: ScreeningPrice[],
  maximum_allowed_employers: string,
  displayAsReadable = true,
) => {
  const itemPrice = itemPrices?.find(
    item =>
      item.product_type === 'employment_verification' &&
      item.maximum_allowed_employers === maximum_allowed_employers,
  );
  if (!itemPrice || isNaN(itemPrice.base_price)) return 'Loading price...';
  return displayAsReadable
    ? getDollarAmount(itemPrice.base_price)
    : itemPrice.base_price;
};

export const getPersonalReferenceVerificationPrice = (
  itemPrices: ScreeningPrice[],
  required_quantity: string,
  displayAsReadable = true,
) => {
  const itemPrice = itemPrices?.find(
    item =>
      item.product_type === 'personal_reference_verification' &&
      item.required_quantity[1] === required_quantity,
  );
  if (!itemPrice || isNaN(itemPrice.base_price)) return 'Loading price...';
  return displayAsReadable
    ? getDollarAmount(itemPrice.base_price)
    : itemPrice.base_price;
};

export const getProfessionalReferenceVerificationPrice = (
  itemPrices: ScreeningPrice[],
  required_quantity: string,
  displayAsReadable = true,
) => {
  const itemPrice = itemPrices?.find(
    item =>
      item.product_type === 'professional_reference_verification' &&
      item.required_quantity[1] === required_quantity,
  );
  if (!itemPrice || isNaN(itemPrice.base_price)) return 'Loading price...';
  return displayAsReadable
    ? getDollarAmount(itemPrice.base_price)
    : itemPrice.base_price;
};

export const getPriceByType = (
  screeningType: ScreeningType,
  itemPrices: ScreeningPrice[],
  displayAsReadable = true,
) => {
  const itemPrice = itemPrices.find(
    item => item.product_type === screeningType,
  );
  if (!itemPrice || isNaN(itemPrice.base_price)) return 'Loading price...';
  return displayAsReadable
    ? getDollarAmount(itemPrice.base_price)
    : itemPrice.base_price;
};

export const getAddOnPrice = (
  screeningType: ScreeningType,
  itemPrices: ScreeningPrice[],
  displayAsReadable: boolean,
  additionalProperties: AdditionalPropertiesType = {},
) => {
  const property =
    additionalProperties[screeningType as keyof AdditionalPropertiesType];

  if (property && screeningType === 'facis_search') {
    const facisProperty = property as FACIS_SEARCH;
    return getFacisPrice(
      itemPrices,
      facisProperty.subtype.toString(),
      displayAsReadable,
    );
  } else if (property && screeningType === 'personal_reference_verification') {
    return getPersonalReferenceVerificationPrice(
      itemPrices,
      (property as PERSONAL_REFERENCE_VERIFICATION).required_quantity[1],
      displayAsReadable,
    );
  } else if (screeningType === 'professional_reference_verification') {
    return getProfessionalReferenceVerificationPrice(
      itemPrices,
      (property as PROFESSIONAL_REFERENCE_VERIFICATION).required_quantity[1],
      displayAsReadable,
    );
  } else if (screeningType === 'employment_verification') {
    return getEmploymentVerificationPrice(
      itemPrices,
      (property as EMPLOYMENT_VERIFICATION).maximum_allowed_employers,
      displayAsReadable,
    );
  } else {
    return getPriceByType(screeningType, itemPrices, displayAsReadable);
  }
};
