import React, { useEffect } from 'react';
import checkNavy from '../../../../../images/check-navy.svg';
import checkAqua from '../../../../../images/check-aqua.svg';
import checkGrey from '../../../../../images/check-grey.svg';

interface CheckIconProps {
  isSelected?: boolean;
  itemProp: string;
  isMostPopular?: boolean;
}
const CheckCircleIcon = ({
  isSelected,
  itemProp,
  isMostPopular,
}: CheckIconProps) => {
  const [icon, setIcon] = React.useState<string>('');

  useEffect(() => {
    if (
      isSelected &&
      isMostPopular &&
      (itemProp.includes('criminal') || itemProp.includes('verification'))
    ) {
      setIcon(checkAqua);
    } else if (isSelected) {
      setIcon(checkNavy);
    } else {
      setIcon(checkGrey);
    }
  }, [isSelected, itemProp, isMostPopular]);

  return (
    <img
      style={{
        width: '1em',
        fontWeight: '400',
        fontSize: 'medium',
        marginRight: '6px',
        marginTop: '-2px',
      }}
      src={icon}
    />
  );
};

export default CheckCircleIcon;
