import * as Form from '../../form';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { PROMO_CODES } from '../../../constants/identifiers';
@observer
export default class PromotionCodeInput extends Component {
  render() {
    const { form } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Input
              form={form}
              errorDataTestId={PROMO_CODES.promoCodeInputFieldError}
              dataTestId={PROMO_CODES.promoCodeInput}
              label='Promotion Code'
              name='promotionCode'
              field='promotionCode'
            >
              <p className='text-muted mt-1'>Have a Promotion Code?</p>
            </Form.Input>
          </div>
          <div className='col-md-3 promotion-code-apply-button'>
            <input
              type='button'
              data-testid={PROMO_CODES.applyPromoCode}
              value={form.promoLoading ? 'Loading...' : 'Apply'}
              onClick={e => form.validatePromotionCode(e)}
              className={'btn btn-md btn-block btn-success my-4'}
              disabled={!form.promotionCode || form.promoLoading}
            />
          </div>
          <div className='col-md-3' />
        </div>
        {form.promotionCodeApplied && (
          <div className='row'>
            <div className='col-md-8 success-message'>
              <i className='fa' />
              <span
                data-testid={PROMO_CODES.applyPromoCodeSuccessMsg}
                className='rule-description'
              >
                Promotional code has been applied.
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PromotionCodeInput.propTypes = {
  form: PropTypes.object.isRequired,
};
