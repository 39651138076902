import React from 'react';
import { CardContent, Typography } from '@mui/material/';
import { PARTNER_PACKAGES } from '../../../constants/identifiers';

const primaryColor =
  document.documentElement.style.getPropertyValue('--primary-color');

const PackageCardIntlDisclaimer = ({ isSelected }) => (
  <div className='d-flex' style={{ marginTop: 'auto' }}>
    <CardContent
      style={{ alignSelf: 'flex-end' }}
      data-testid={PARTNER_PACKAGES.intlDisclaimer}
    >
      <Typography
        variant='body2'
        color={isSelected ? '#FFF' : 'text.secondary'}
        fontSize='12px'
      >
        Pricing, requirements, and turnaround times vary by location.
        <a
          href='https://checkr.com/pricing/international'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            color: isSelected ? '#FFF' : primaryColor,
          }}
        >
          {' '}
          Learn more
        </a>
      </Typography>
    </CardContent>
  </div>
);

export default PackageCardIntlDisclaimer;
