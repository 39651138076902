/* eslint-disable max-len */
/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { pickBy, keys, isEmpty } from 'lodash';
import {
  SectionHeader,
  PageHeader,
  SelectLocationToggle,
  PackageErrorMsg,
  CommonAddOns,
  CandidateInvites,
  PackageCardGrid,
} from './invites';
import { useStore } from '../../../hooks/useStore';
import CheckrDirectProgressBar from './CheckrDirectProgressBar';
import { PACKAGE_CONFIG } from './data/packages.js';
import { statesWithCities } from './data/geos';
import { Box, FormControl, Grid, Typography } from '@mui/material/';
import {
  buildPostBodyWithAddOns,
  generateAddOnsPackageSlug,
  generateAddOnsPackageName,
} from '../SignupV4/PackageUtils/PackageUtils';
import { usePackages } from '../../../hooks/usePackages';
import { mapPackage } from '../SignupV4/PackageUtils/MapPackage';
import { useInvitesAnalyticsTrackers } from './hooks/useInvitesAnalyticsTrackers';
import { calculatePriceHelper } from './utils';
import {
  postCandidateInvites,
  deleteAccountPackage,
  postAccountPackage,
} from './services';
import { INVITES } from '../../../constants/identifiers';
import OrderSummary from './invites/OrderSummary';
import NavigationButtons from './invites/NavigationButtons';
import { InvitesLink } from './invites/shared';
import FullAddonsSection from './invites/FullAddonsSection';
import AmplitudeExperimentWrapper from '../../wrappers/AmplitudeExperimentWrapper';
import amplitudeExperiment from '../../../services/amplitudeExperiment';
import StickyFooter from './invites/StickyFooter';

const params = new URLSearchParams(document.location.search);

const currentStepHeaders = currentStep => {
  switch (currentStep) {
    case 1:
      return {
        header: 'Select a package',
        subheader:
          'You can prepare more packages in your dashboard at any time.',
      };
    case 2:
      return {
        header: 'Customize',
        subheader: 'Add searches or verifications to this report.',
      };
    case 3:
      return {
        header: 'Invite candidates',
        subheader: `Each candidate will receive an invitation from Checkr, on behalf of ${params.get(
          'company',
        )}, to complete their background check after we verify your business (typically less than one business day).`,
      };
    default:
      return {};
  }
};

const currentStepHeadersPhase2 = currentStep => {
  switch (currentStep) {
    case 1:
      return {
        header: 'Select a package',
        subheader:
          'You can prepare more packages in your dashboard at any time.',
      };
    case 2:
      return {
        header: 'Add more checks',
        subheader:
          'Need something else? You’ll find even more checks in your dashboard.',
      };
    case 3:
      return {
        header: 'Invite candidates',
        subheader: `Each candidate will receive an invitation from Checkr, on behalf of ${params.get(
          'company',
        )}, to complete their background check after we verify your business (typically less than one business day).`,
      };
    default:
      return {};
  }
};

const formSchema = yup.object().shape({
  country: yup.string().required('Country is required'),
  state: yup.string().when('country', {
    is: 'US',
    then: yup.string().required('State is required'),
    otherwise: yup.string(),
  }),
  city: yup.string().when('state', {
    is: val => statesWithCities.includes(val),
    then: yup.string().required('City is required'),
    otherwise: yup.string(),
  }),
  package: yup.string().required('Package is required'),
  candidates: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .email('Email is invalid')
        .required('Email is invalid'),
    }),
  ),
});

const CheckrDirectInvites = () => {
  const store = useStore();
  const auth = store.auth;
  const accountPackages = usePackages({ throwIfEmpty: false });
  const formHelpers = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      package: 'checkrdirect_essential_criminal',
      candidates: [{ value: '' }],
      checkrdirect_essential_criminal: {},
      checkrdirect_basic_plus_criminal: {},
      checkrdirect_professional_criminal: {},
    },
    mode: 'all',
  });

  const enableFullAddons =
    amplitudeExperiment.getVariantValue('ab-06-full-add-ons-experience') ===
    'treatment';

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setError,
    setValue,
    control,
    watch,
    getValues,
  } = formHelpers;

  const {
    trackInvitesFormError,
    trackInvitesSubmitted,
    trackInvitesSubmitError,
    trackOrderBGCCountryToggled,
    trackSelectPackageStepViewed,
    trackCustomizeAddOnsStepViewed,
    trackInviteCandidatesStepViewed,
    trackCustomerSignupLearnMoreClicked,
    trackSelectAPackageCompleted,
    trackAddonsStepCompleted,
    trackInviteCandidateCompleted,
  } = useInvitesAnalyticsTrackers();

  const [selectedRegion, setSelectedRegion] = useState('US');
  const [learnMoreClicked, setLearnMoreClicked] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const selectedState = watch('state');
  const selectedCity = watch('city');
  const selectedCountry = watch('country');
  const candidates = watch('candidates');

  // scroll to top of window on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // capture page load time on initial load and route changes
  useEffect(() => {
    store.performanceObserver.setEndLoadTime();
  }, [store.performanceObserver]);

  // set the appropriate default package on toggle between us and non-us work location
  useEffect(() => {
    setValue(
      'package',
      selectedRegion === 'US'
        ? 'checkrdirect_essential_criminal'
        : 'checkrdirect_international_professional',
    );
  }, [selectedRegion, setValue]);

  // set the country value to 'us' when the us work location is selected
  useEffect(() => {
    setValue('country', selectedRegion === 'US' ? 'US' : '');
  }, [selectedRegion, setValue]);

  // reset state and city values if the non-us work location is selected
  useEffect(() => {
    // if (selectedRegion !== 'US') {
    setValue('state', '');
    setValue('city', '');
    // }
  }, [selectedRegion, setValue]);

  // reset edited packages when the work location changes
  useEffect(() => {
    setValue('checkrdirect_essential_criminal', {});
    setValue('checkrdirect_basic_plus_criminal', {});
    setValue('checkrdirect_professional_criminal', {});
  }, [selectedRegion]);

  // reset the city value when the a new state is selected
  useEffect(() => {
    setValue('city', '');
  }, [selectedState, setValue]);

  // fire error tracking when the errors form state changes
  useEffect(() => {
    if (!isEmpty(errors)) {
      trackInvitesFormError(errors);
    }
  }, [errors, trackInvitesFormError]);

  const mainPackagesList =
    selectedRegion !== 'US'
      ? Object.values(PACKAGE_CONFIG.international)
      : Object.values(PACKAGE_CONFIG.domestic);

  const selectedPackage = mainPackagesList.find(
    pkg => watch('package') === pkg?.slug,
  );
  const packageAddOns = getValues(selectedPackage?.slug) || {};

  const calculatedPrices = (basePrice, slug) => {
    const vals = getValues();
    const addOns = keys(pickBy(vals[slug] || {})); // get an array of keys (screening types) where the values are true
    return calculatePriceHelper(basePrice, addOns);
  };

  const onSubmit = async data => {
    /*
      example add-ons form data
      checkr_direct_essential_criminal is the selected package slug
      the add-ons sections are keyed by package slug and represent the state of the checkboxes
      the section objects are keyed by screening type and represent whether the box for the screening in that section is checked
      data['checkr_direct_essential_criminal`] is therefore the add-ons section for the selected card

      this structure is built by react-hook-form
      using the register method on the checkbox component, with a dynamic value ${package_slug}.{screening_type}
      {
        package: 'checkrdirect_essential_criminal',
        checkrdirect_basic_plus_criminal: {
          motor_vehicle_report: false,
          federal_criminal_search: false,
          education_verification: false,
        },
        checkrdirect_essential_criminal: {
          motor_vehicle_report: true,
          federal_criminal_search: false,
          education_verification: false,
        },
        checkrdirect_professional_criminal: {
          motor_vehicle_report: true,
          federal_criminal_search: false,
        },
      }
    */

    const {
      candidates,
      country,
      state: _state,
      city,
      package: _package,
      [_package]: selectedAddOnsSection,
    } = data;
    const addOns = keys(pickBy(selectedAddOnsSection)); // get an array of keys (screening types) where the values are true
    const newPackageSlug = generateAddOnsPackageSlug(_package);
    const selectedConfigPackage = mainPackagesList.find(
      p => p.slug === _package,
    );
    let recentlyCreatedPackageId;

    const handleSubmitErrors = (e, errorType, exceptionType) => {
      window.scrollTo(0, 0);
      setError('serverError', {
        type: errorType,
        message: e.response?.data?.errors?.length
          ? e.response.data?.errors.join(', ')
          : 'Server Error. Contact Checkr Support if this error keeps occurring.',
      });
      if (e.response?.status === 400) {
        Sentry.captureException(
          e.response?.data?.errors?.join(', ') ||
            e.response?.data?.error ||
            'No error data available',
          { tags: { [exceptionType]: 'error' } },
        );
      }
    };

    store.performanceObserver.setStartLoadTime();

    // if unable to find base package
    // track error in sentry and show error banner
    const currentPackage = accountPackages.find(p => p.slug === _package);
    if (!currentPackage) {
      window.scrollTo(0, 0);
      setError('serverError', {
        type: 'package-error',
        message:
          'Base package is missing, Contact Checkr Support if this error keeps occurring.',
      });
      Sentry.captureMessage('Base Package is required', {
        tags: { 'package-error': 'missing-base-package' },
      });
      return;
    }

    if (addOns.length > 0) {
      const newPackageBody = buildPostBodyWithAddOns({
        basePackage: mapPackage(currentPackage),
        addedScreeningTypes: addOns,
        packageFields: {
          slug: newPackageSlug,
          name: generateAddOnsPackageName(selectedConfigPackage.name),
          origin: 'customer_via_signup',
          partner_origin_id: store.application?.account_resource_id || '',
        },
      });

      try {
        const {
          data: { id },
        } = await postAccountPackage(
          auth?.token,
          auth.getAccountId(),
          newPackageBody,
        );

        recentlyCreatedPackageId = id;
      } catch (e) {
        handleSubmitErrors(e, 'package_creation_error', 'package-creation');
        return;
      }
    }

    const filteredCandidateEmails = candidates
      .map(candidate => candidate.value)
      .filter(Boolean)
      .filter((thing, i, arr) => arr.indexOf(thing) === i);

    try {
      await postCandidateInvites(auth?.token, {
        emails: filteredCandidateEmails,
        package: addOns.length > 0 ? newPackageSlug : _package,
        invited_from: 'self-service',
        work_locations: [
          { country, state: _state, city: city === 'Other' ? '' : city },
        ],
      });
      const formValues = getValues();
      trackInviteCandidateCompleted();
      trackInvitesSubmitted(
        addOns,
        calculatedPrices(selectedConfigPackage.price, _package),
        formValues,
        selectedRegion,
        learnMoreClicked,
      );
      store.toStep('submission');
    } catch (e) {
      handleSubmitErrors(e, 'submission_error', 'candidate-invitations');
      trackInvitesSubmitError();

      // Only attempt to delete if package was created
      if (recentlyCreatedPackageId) {
        try {
          await deleteAccountPackage(
            auth?.token,
            auth.getAccountId(),
            recentlyCreatedPackageId,
          );
        } catch (e) {
          handleSubmitErrors(e, 'package_deletion_error', 'package-deletion');
        }
      }
    }
  };

  const commonAddOns = selectedPackageSlug => {
    let addons = { options: [], tooltipTestId: '' };

    mainPackagesList.forEach(({ commonAddOns, slug }) => {
      if (selectedPackageSlug === slug) {
        if (commonAddOns) {
          addons = commonAddOns;
        }
      }
    });

    return addons;
  };

  const trackStepCompletion = () => {
    switch (currentStep) {
      case 1:
        trackSelectAPackageCompleted();
        break;
      case 2:
        trackAddonsStepCompleted();
        break;
      default:
        break;
    }
  };

  const { header, subheader } = enableFullAddons
    ? currentStepHeadersPhase2(currentStep)
    : currentStepHeaders(currentStep);

  const handleSetCurrentStep = direction => {
    let newStep;
    if (direction === 'next') {
      newStep = currentStep + (selectedRegion === 'US' ? 1 : 2);
      setCurrentStep(newStep);
      trackStepCompletion();
    } else if (direction === 'back') {
      newStep = currentStep - (selectedRegion === 'US' ? 1 : 2);
      setCurrentStep(newStep);
    }
    window.scrollTo(0, 0);
  };

  const handleLearnMoreClicked = () => {
    trackCustomerSignupLearnMoreClicked('Pricing');
    setLearnMoreClicked(true);
  };

  useEffect(() => {
    switch (currentStep) {
      case 1:
        trackSelectPackageStepViewed();
        break;
      case 2:
        trackCustomizeAddOnsStepViewed();
        break;
      case 3:
        trackInviteCandidatesStepViewed();
        break;
      default:
        break;
    }
  }, [currentStep]);

  return (
    <div>
      <div>
        <CheckrDirectProgressBar activeStep={2} />
      </div>
      <div className='row'>
        <div
          className='col-md-12 main'
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <PageHeader />
          <FormProvider {...formHelpers}>
            <Grid container style={{ width: '100%', marginBottom: '48px' }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={9}
                sx={{ padding: { xs: '20px', sm: '20px', md: '32px' } }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                <SectionHeader
                  title={header}
                  subheader={subheader}
                  dataTestId={`${INVITES.MISC.header}-${currentStep}`}
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box
                    textAlign='center'
                    sx={{ marginBottom: '25px' }}
                    hidden={currentStep !== 1}
                  >
                    <FormControl>
                      <SelectLocationToggle
                        selectedRegion={selectedRegion}
                        setSelectedRegion={setSelectedRegion}
                        trackLocationChange={trackOrderBGCCountryToggled}
                      />
                    </FormControl>
                  </Box>

                  <Box hidden={currentStep === 3}>
                    <Controller
                      control={control}
                      name='package'
                      render={({
                        field: {
                          onChange,
                          value = 'checkrdirect_essential_criminal',
                        },
                      }) => (
                        <>
                          <PackageCardGrid
                            packages={mainPackagesList}
                            calculatedPrices={calculatedPrices}
                            onPackageChange={onChange}
                            selectedPackage={value}
                            selectedRegion={selectedRegion}
                            hidden={currentStep !== 1}
                          />
                          <AmplitudeExperimentWrapper
                            store={store}
                            experimentKey='ab-06-full-add-ons-experience'
                            controlComponent={
                              <CommonAddOns
                                selectedPackage={value}
                                commonAddOns={commonAddOns(value)}
                                slug={value}
                                hidden={currentStep !== 2}
                              />
                            }
                            treatmentComponent={
                              <FullAddonsSection
                                slug={value}
                                hidden={currentStep !== 2}
                                currentStep={currentStep}
                              />
                            }
                          />
                        </>
                      )}
                    />

                    {errors.package && <PackageErrorMsg />}
                    {selectedRegion !== 'US' && currentStep === 1 && (
                      <Typography
                        data-testid={INVITES.MISC.internationalText}
                        textAlign='left'
                        paddingTop='10px'
                        marginBottom='10px'
                        fontWeight='400'
                        lineHeight='150%'
                        fontSize='14px'
                        sx={{
                          fontSize: { xs: '12px', sm: '12px', md: '14px' },
                        }}
                        color='#334559'
                        fontFamily='National2Regular'
                        component='div'
                      >
                        An international package should only be ordered for a
                        candidate living and working outside of the United
                        States.
                      </Typography>
                    )}
                    {currentStep === 1 && (
                      <Typography
                        color='#576C82'
                        fontFamily='National2Regular'
                        sx={{ fontSize: { xs: '10px', md: '12px' } }}
                        lineHeight='16px'
                        fontWeight={400}
                        marginBottom='40px'
                        textAlign='left'
                      >
                        * Prices exclude{' '}
                        <InvitesLink
                          data-testid={INVITES.MISC.learnMore}
                          onClick={handleLearnMoreClicked}
                          href='https://checkr.com/pricing/passthrough-fees'
                          target='_blank'
                        >
                          passthrough fees.
                        </InvitesLink>
                      </Typography>
                    )}
                  </Box>
                  <Box hidden={currentStep !== 3}>
                    <CandidateInvites
                      selectedRegion={selectedRegion}
                      selectedState={selectedState}
                      selectedCity={selectedCity}
                      selectedCountry={selectedCountry}
                      register={register}
                      errors={errors}
                    />
                  </Box>
                  <AmplitudeExperimentWrapper
                    store={store}
                    experimentKey='ab-06-full-add-ons-experience'
                    controlComponent={
                      <NavigationButtons
                        selectedRegion={selectedRegion}
                        selectedState={selectedState}
                        selectedCity={selectedCity}
                        selectedCountry={selectedCountry}
                        currentStep={currentStep}
                        handleSetCurrentStep={handleSetCurrentStep}
                        selectedPackage={selectedPackage}
                        showLoading={isSubmitting || isSubmitSuccessful}
                        candidates={candidates}
                      />
                    }
                    treatmentComponent={
                      <StickyFooter
                        selectedRegion={selectedRegion}
                        selectedState={selectedState}
                        selectedCity={selectedCity}
                        selectedCountry={selectedCountry}
                        currentStep={currentStep}
                        handleSetCurrentStep={handleSetCurrentStep}
                        selectedPackage={selectedPackage}
                        showLoading={isSubmitting || isSubmitSuccessful}
                        candidates={candidates}
                      />
                    }
                  />
                </form>
              </Grid>
              <OrderSummary
                calculatedPrices={calculatedPrices}
                candidates={candidates}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                packageAddOns={packageAddOns}
                selectedPackage={selectedPackage}
                selectedRegion={selectedRegion}
                handleLearnMoreClicked={handleLearnMoreClicked}
              />
            </Grid>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default CheckrDirectInvites;
