import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Tooltip as _Tooltip, tooltipClasses } from '@mui/material';
import { Add, Checkmark, Information } from '@carbon/icons-react';
import { useFormContext } from 'react-hook-form';
import { useInvitesAnalyticsTrackers } from '../hooks/useInvitesAnalyticsTrackers';
import { INVITES } from '../../../../constants/identifiers';

const Name = styled('div')(() => ({
  fontSize: '16px',
  fontWeight: '500',
  fontFamily: 'National2Medium',
  fontStyle: 'normal',
  lineHeight: '150%',
  color: '#117091',
}));

const ButtonText = styled('div')(({ color }) => ({
  fontSize: '14px',
  fontWeight: '500',
  fontFamily: 'National2Medium',
  fontStyle: 'normal',
  lineHeight: '100%',
  color,
}));

const UnselectedButton = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  background: '#FFFFFF',
  border: '1px solid #E4E7ED',
  boxSizing: 'border-box',
  borderRadius: '4px',
  '&:hover': {
    background: '#F7F9FA',
  },
  padding: '6px 14px 6px 8px',
  gap: '8px',
  height: '32px',
  width: 'fit-content',
}));

const SelectedButton = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  background: '#117292',
  border: 'none',
  borderRadius: '4px',
  '&:hover': {
    background: '#06556F',
  },
  padding: '6px 14px 6px 8px',
  gap: '8px',
  height: '32px',
  width: 'fit-content',
}));

const LightTooltip = styled(({ className, ...props }) => (
  <_Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#5E5E64',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'National2Regular',
    fontStyle: 'normal',
    lineHeight: '150%',
    boxShadow: '0px 0px 16px 0px rgba(22, 22, 29, 0.18)',
    width: '250px',
    padding: '12px',
  },
}));

const FeaturedAddons = ({
  screeningName,
  screening,
  price,
  tooltipText,
  slug,
  hidden,
  dataTestId,
}) => {
  const { watch, setValue } = useFormContext();
  const {
    trackFeaturedAddOnSelected,
    trackAddOnSelected,
    trackFeaturedAddOnRemoved,
    trackAddOnRemoved,
  } = useInvitesAnalyticsTrackers();
  const selected = watch(`${slug}.${screening}`);

  const onClick = () => {
    setValue(`${slug}.${screening}`, !selected);

    if (selected === undefined || selected === false) {
      trackFeaturedAddOnSelected(screeningName);
      trackAddOnSelected(screeningName);
    } else {
      trackFeaturedAddOnRemoved(screeningName);
      trackAddOnRemoved(screeningName);
    }
  };

  if (hidden) return null;
  return (
    <div
      style={{
        padding: '16px 16px 20px 16px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #E4E7ED',
        borderRadius: '4px',
        boxShadow: '0px 4px 32px -16px rgba(26, 32, 38, 0.10)',
        background: '#FFFFFF',
        gap: '8px',
        flex: '1',
      }}
      data-testid={`addon-card-${dataTestId}`}
    >
      <div style={{ display: 'flex' }}>
        <Name>{screeningName}</Name>
        <LightTooltip title={tooltipText}>
          <IconButton size='small'>
            <Information
              style={{ color: '#8799AB', height: '16px', width: '16px' }}
            />
          </IconButton>
        </LightTooltip>
      </div>
      {selected ? (
        <SelectedButton
          onClick={onClick}
          data-testid={`${dataTestId}-${INVITES.MISC.removeBtn}`}
        >
          <Checkmark fill='#FFFFFF' />{' '}
          <ButtonText color='#FFFFFF'>Added</ButtonText>
        </SelectedButton>
      ) : (
        <UnselectedButton
          onClick={onClick}
          data-testid={`${dataTestId}-${INVITES.MISC.addBtn}`}
        >
          <Add fill='#334559' />{' '}
          <ButtonText color='#334559'>Add for {price}</ButtonText>
        </UnselectedButton>
      )}
    </div>
  );
};

export default FeaturedAddons;
