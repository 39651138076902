import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import upperFirst from 'lodash/upperFirst';
import Label from './Label';
import nextId from 'react-id-generator';
import { paramCase } from 'param-case';
import { ANALYTICS_EVENTS } from '../../services/analytics';

@observer
export default class Select extends Component {
  htmlId = `select-${nextId()}`;

  handleChange = e => {
    const key = this.props.name;
    let value = e.target ? e.target.value : e.field.value();

    if (value === '') {
      value = null;
    }

    if (this.props.onChange) {
      this.props.onChange(key, value);
    }
    if (this.props.form && this.props.form.handleChange) {
      this.props.form.handleChange(key, value);
    }
  };

  trackFieldFocused(fieldName) {
    const state = this.props.form._state || this.props.form.state;
    const trackableFields = {
      industry: ANALYTICS_EVENTS.INDUSTRY_FIELD_SELECTED,
    };

    if (fieldName in trackableFields) {
      state.trackAnalyticsEvent(trackableFields[fieldName]);
    }
  }

  render() {
    const {
      blankOptionText,
      form,
      label,
      labelSubText,
      name,
      options,
      tooltip,
      customClassName,
      dataTestId,
    } = this.props;

    const hasError = form && form.error && form.error[name];
    const inputClassName = `form-control ${'' || customClassName}
      ${hasError ? 'form-control-danger' : ''} select-${paramCase(name)}`;
    const groupClassName = `form-group ${hasError ? 'has-danger' : ''}`;

    let optionsList;
    if (typeof options[0] === 'string') {
      optionsList = options.map(o => (
        <option key={o} value={o}>
          {o}
        </option>
      ));
    } else {
      optionsList = options.map(o => (
        <option key={o.value} value={o.value}>
          {o.text}
        </option>
      ));
    }

    const select = (
      <select
        className={inputClassName}
        id={this.htmlId}
        data-testid={dataTestId}
        name={name}
        value={form[name] || ''}
        disabled={this.props.disabled}
        onChange={this.handleChange}
        onFocus={() => this.trackFieldFocused(name)}
      >
        <option value=''>{blankOptionText || 'Choose...'}</option>
        {optionsList}
      </select>
    );

    let error = null;
    if (hasError) {
      error = (
        <small
          data-testid={`field-error-${name}`}
          className='form-control-feedback'
        >
          {upperFirst(form.error[name][0])}
        </small>
      );
    }

    return (
      <div className={groupClassName}>
        {label && (
          <Label
            text={label}
            subText={labelSubText}
            tooltip={tooltip}
            htmlFor={this.htmlId}
          />
        )}
        {select}
        {error}
      </div>
    );
  }
}

Select.propTypes = {
  blankOptionText: PropTypes.string,
  customClassName: PropTypes.string,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelSubText: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
  dataTestId: PropTypes.string,
};
