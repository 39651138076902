import React from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { INVITES } from '../../../../constants/identifiers';
import { statesWithCities } from '../data/geos';

const Footer = styled.footer`
  box-sizing: border-box;
  height: 72px;
  position: fixed;
  display: flex;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: white;
  border-top: 1px solid #e4e7ed;
  z-index: 99;
  box-shadow:
    0px 24px 48px 0 rgba(87, 108, 130, 0.2),
    0px 24px 32px -24px rgba(6, 85, 111, 0.1);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 16px 80px;

  @media (max-width: 767px) {
    padding: 16px 48px;
  }

  @media (max-width: 599px) {
    padding: 12px 24px;
    gap: 24px;
  }
`;

type ButtonProps = {
  width?: string;
  background?: string;
  marginLeft?: string;
  border?: string;
  hover?: string;
  disabled?: boolean;
};

const Button = styled.button<ButtonProps>`
  width: ${props => props.width || '95px'};
  height: 40px;
  padding: 10px 18px;
  background: ${props => props.background || '#ffffff'};
  margin-left: ${props => props.marginLeft};
  box-shadow: 0px 2px 4px -2px rgba(72, 82, 94, 0.14);
  border-radius: 4px;
  overflow: hidden;
  border: ${props => props.border || '1px solid #e1e6eb'};
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: flex;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  @media (max-width: 599px) {
    width: 50%;
  }

  &:hover {
    background: ${props => (props.disabled ? props.background : props.hover)};
  }
`;

const HiddenButton = styled.div<ButtonProps>`
  width: 95px;
  height: 40px;
  padding: 10px 18px;
  @media (max-width: 599px) {
    width: 50%;
  }
`;

const ArrowContainer = styled.div`
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
`;

const Text = styled.div<{ color?: string }>`
  text-align: center;
  color: ${props => props.color || '#1A2026'};
  font-size: 16px;
  font-family: 'National2Regular';
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;

  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const BackButton = ({
  handleSetCurrentStep,
  currentStep,
}: {
  handleSetCurrentStep: (direction: string) => void;
  currentStep: number;
}) => {
  if (currentStep === 1) {
    return <HiddenButton />;
  }
  return (
    <Button
      data-testid={INVITES.MISC.backButton}
      type='button'
      hover='#F7F9FA'
      onClick={() => handleSetCurrentStep('back')}
    >
      <ArrowContainer>
        <ChevronLeft sx={{ color: '#1A2026' }} />
      </ArrowContainer>
      <Text>Back</Text>
    </Button>
  );
};

const NextButton = ({
  handleSetCurrentStep,
  currentStep,
  selectedPackage,
}: {
  handleSetCurrentStep: (direction: string) => void;
  currentStep: number;
  selectedPackage: unknown;
}) => {
  return (
    <Button
      data-testid={INVITES.MISC.nextButton}
      type='button'
      background='#117292'
      hover='#06556F'
      border='none'
      marginLeft='auto'
      onClick={e => {
        e.preventDefault();
        handleSetCurrentStep('next');
      }}
      hidden={currentStep === 3}
      disabled={!selectedPackage}
    >
      <Text color='#ffffff'>Next</Text>
      <ArrowContainer>
        <ChevronRight sx={{ color: 'white' }} />
      </ArrowContainer>
    </Button>
  );
};

const SubmitButton = ({
  showLoading,
  submitDisabled,
}: {
  showLoading: boolean;
  submitDisabled: boolean;
}) => {
  return (
    <Button
      data-testid={INVITES.MISC.submitButton}
      background={submitDisabled ? '#e1e6eb' : '#117292'}
      hover='#06556F'
      width='134px'
      border='none'
      disabled={submitDisabled}
    >
      {showLoading ? (
        <>
          <i className='fa fa-spinner fa-spin fa-fw' />
          <Text color='#1a20267a'>Loading...</Text>
        </>
      ) : (
        <Text color={submitDisabled ? '#1a20267a' : '#ffffff'}>
          Submit order
        </Text>
      )}
    </Button>
  );
};

type Props = {
  currentStep: number;
  selectedRegion: string;
  selectedState: string;
  selectedCity: string;
  selectedCountry: string;
  handleSetCurrentStep: (direction: string) => void;
  selectedPackage: unknown;
  showLoading: boolean;
  candidates: { value: string }[];
};

const StickyFooter = ({
  currentStep,
  selectedRegion,
  selectedCountry,
  selectedState,
  selectedCity,
  candidates,
  showLoading,
  handleSetCurrentStep,
  selectedPackage,
}: Props) => {
  const locationUnselected = () => {
    if (selectedRegion === 'nonUS') {
      return selectedCountry === '';
    } else {
      return (
        selectedState === '' ||
        (statesWithCities.includes(selectedState) && selectedCity === '')
      );
    }
  };

  const hasCandidates = () =>
    candidates.filter((candidate: { value: string }) => candidate.value !== '')
      .length;

  const submitDisabled =
    showLoading || !hasCandidates() || locationUnselected();

  return (
    <Footer>
      <ButtonContainer>
        <BackButton
          handleSetCurrentStep={handleSetCurrentStep}
          currentStep={currentStep}
        />
        {currentStep === 3 ? (
          <SubmitButton
            showLoading={showLoading}
            submitDisabled={submitDisabled}
          />
        ) : (
          <NextButton
            handleSetCurrentStep={handleSetCurrentStep}
            currentStep={currentStep}
            selectedPackage={selectedPackage}
          />
        )}
      </ButtonContainer>
    </Footer>
  );
};

export default StickyFooter;
