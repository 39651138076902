import React from 'react';
import styled from 'styled-components';
import { InfoDiv, Price } from './orderSummaryStyles';
import { INVITES } from '../../../../../constants/identifiers';

const Label = styled.div`
  color: #117091;
  font-size: ${props => (props.selectedPackage ? '12px' : '16px')};
  font-family: 'National2Regular';
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: ${props => (props.selectedPackage ? '12px' : '14px')};
  }

  @media (max-width: 600px) {
    font-size: ${props => (props.selectedPackage ? '12px' : '14px')};
  }
`;

const SelectedTitle = styled.div`
  color: #334559;
  font-size: 16px;
  font-family: 'National2Medium';
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const SelectPackage = ({ selectedPackage }) => {
  return (
    <>
      <Label selectedPackage={selectedPackage}>Select package</Label>
      <InfoDiv>
        {selectedPackage && (
          <>
            <SelectedTitle
              data-testid={`${INVITES.ORDER_SUMMARY.selectedPackageName}`}
            >
              {selectedPackage?.name}
            </SelectedTitle>
            <Price
              data-testid={`${INVITES.ORDER_SUMMARY.selectedPackagePrice}`}
            >
              {selectedPackage?.price}*
            </Price>
          </>
        )}
      </InfoDiv>
    </>
  );
};

export default SelectPackage;
