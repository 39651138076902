/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Box, Grid, Link } from '@mui/material/';
import getInternationalPricing from '../../helpers/internationalPackagePricing';
import { PARTNER_PACKAGES } from '../../../constants/identifiers';
import CustomizablePackageCard from './CustomizablePackageCard';
import StaticPackageCard from './StaticPackageCard';

const calculatePrice = pkg => {
  let price = pkg.international_only
    ? getInternationalPricing(pkg.slug)
    : pkg.price;

  if (pkg.drug_screening_price) {
    price += pkg.drug_screening_price;
  }

  return price;
};

// packages containing drug screenings, credit reports or occ health screenings are not cloned to the user's account
// hide these packages since the user won't see them on their account once the account is created
const filterPackages = packages => {
  if (!packages) return [];

  const excludedScreeningTypes = [
    'drug_screening',
    'credit_report',
    'occupational_health_screening',
  ];

  return packages.filter(
    pkg =>
      pkg.screenings?.length &&
      pkg.screenings.every(
        screening => !excludedScreeningTypes.includes(screening.type),
      ),
  );
};

const sortPackages = packages => {
  if (!packages) return [];

  const packagesWithPrices = packages.map(pkg => {
    pkg.calculated_price = calculatePrice(pkg);
    return pkg;
  });

  return packagesWithPrices.sort((a, b) => {
    // sort by featured packages, then by price ascending
    // international_only packages are always displayed last

    if (a['is_featured?'] !== b['is_featured?']) {
      return b['is_featured?'] - a['is_featured?'];
    } else if (a.international_only || b.international_only) {
      return a.international_only - b.international_only;
    } else {
      return a.calculated_price - b.calculated_price;
    }
  });
};

const PartnerPackages = ({
  application,
  trackViewAllPackages,
  selectedPackage,
  canCustomize,
  handleSelectPackage,
  handleOpenModal,
  editedPackages,
  dispatch,
  screeningPrices,
}) => {
  const [packagesExpanded, setPackagesExpanded] = useState(false);
  const partnerBilling = application.partner_billing;
  const partnerPackages = sortPackages(filterPackages(application.packages));
  const firstThreePackages = partnerPackages.slice(0, 3);
  const restOfPackages = partnerPackages.slice(3);

  const handleViewAllPackages = () => {
    setPackagesExpanded(prevState => !prevState);
    if (trackViewAllPackages) trackViewAllPackages();
  };

  const PackageCardComponent = canCustomize
    ? CustomizablePackageCard
    : StaticPackageCard;

  return (
    <Box>
      {!application.packages && (
        <div className='d-flex justify-content-center mt-5'>
          <p
            style={{
              fontFamily: 'National2Medium',
              fontSize: '16px',
              color: '#000000',
            }}
            data-testid={PARTNER_PACKAGES.noPackagesMessage}
          >
            Currently unable to display packages
          </p>
        </div>
      )}
      <Grid container spacing={2} direction='row' justifyContent='flex-start'>
        {firstThreePackages.map(partnerPackage => {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              data-testid={PARTNER_PACKAGES.topPackageGrid}
              key={partnerPackage.slug}
            >
              <PackageCardComponent
                partnerPackage={partnerPackage}
                handleSelectPackage={handleSelectPackage}
                selectedPackage={selectedPackage}
                partnerBilling={partnerBilling}
                handleOpenModal={handleOpenModal}
                editedPackages={editedPackages}
                dispatch={dispatch}
                screeningPrices={screeningPrices}
              />
            </Grid>
          );
        })}
        {packagesExpanded &&
          restOfPackages.map(partnerPackage => {
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                data-testid={PARTNER_PACKAGES.restOfPackageGrid}
                key={partnerPackage.slug}
              >
                <PackageCardComponent
                  partnerPackage={partnerPackage}
                  handleSelectPackage={handleSelectPackage}
                  selectedPackage={selectedPackage}
                  partnerBilling={partnerBilling}
                  handleOpenModal={handleOpenModal}
                  editedPackages={editedPackages}
                  dispatch={dispatch}
                  screeningPrices={screeningPrices}
                />
              </Grid>
            );
          })}
      </Grid>
      {!!restOfPackages.length && (
        <div className='d-flex justify-content-center mt-5'>
          <Link
            onClick={handleViewAllPackages}
            style={{
              fontFamily: 'National2Medium',
              cursor: 'pointer',
            }}
            sx={{
              typography: {
                xs: { fontSize: '14px' },
                lg: { fontSize: '16px' },
              },
            }}
            data-testid={PARTNER_PACKAGES.viewAllPackagesLink}
          >
            {packagesExpanded ? 'Show less' : 'View all packages'}
          </Link>
        </div>
      )}
    </Box>
  );
};

export default PartnerPackages;
