import React from 'react';
import { styled } from '@mui/material/styles';

const Container = styled('div')(() => ({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  borderRadius: '8px',
  border: ' 1px solid var(--brand-slate-2, #E4E7ED)',
  background: 'rgba(255, 255, 255, 0.58)',
}));

const Text = styled('div')(() => ({
  fontSize: '18px',
  fontWeight: '500',
  fontFamily: 'National2Medium',
  fontStyle: 'normal',
  lineHeight: '150%',
  color: '#334559',
}));

const ChildrenContainer = styled('div')(() => ({
  display: 'flex',
  gap: '20px',
  '@media (max-width:767px)': {
    flexDirection: 'column',
  },
}));

type Props = {
  children: React.ReactNode;
};

const FeaturedAddonsContainer = ({ children }: Props) => {
  return (
    <Container>
      <Text>
        Thousands of customers already improve their coverage by adding:
      </Text>
      <ChildrenContainer style={{ display: 'flex', gap: '20px' }}>
        {children}
      </ChildrenContainer>
    </Container>
  );
};

export default FeaturedAddonsContainer;
