import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';
import * as Form from '../../form';
import { NewPartnerSignupProgressBar } from '../../ProgressBar';
import SignupWrapper from './SignupWrapper';
import { CardHeader, Typography } from '@mui/material';
import { Lock } from '@mui/icons-material';
import BankAccountPaymentForm from './BankAccountPaymentForm';
import CreditCardPaymentForm from './CreditCardPaymentForm';
import { ANALYTICS_EVENTS } from '../../../services/analytics';
import {
  BANK_ACCOUNT_INPUT_FIELDS,
  CREDIT_CARD_INPUT_FIELDS,
} from '../../helpers/Constants';
import { PAYMENT } from '../../../constants/identifiers';

const BoldFont = styled.span`
  font-family: National2Bold !important;
`;
@inject('store')
@observer
export default class PaymentV4 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.PAYMENT_PAGE_VIEWED);
    this.store.getPromoCodeActive();
  }

  constructor(props) {
    super(props);
    const { store } = this.props;
    this.store = store;

    // If the user's name is in the AuthStore,
    // use that as a default for the name-on-card
    this.store.bankAccountPayment.setBankAccountName();
    this.store.creditCardPayment.setCardHolderName();

    // default to credit card
    this.state = {
      ...this.formSettingsForPaymentStore(this.store.creditCardPayment),
    };
  }

  formSettingsForPaymentStore(paymentStore) {
    if (paymentStore === this.store.bankAccountPayment) {
      return {
        activeNavLink: 'nav-link-bank-account-payment',
        form: this.store.bankAccountPayment,
        PaymentForm: BankAccountPaymentForm,
      };
    } else {
      return {
        activeNavLink: 'nav-link-credit-card-payment',
        form: this.store.creditCardPayment,
        PaymentForm: CreditCardPaymentForm,
      };
    }
  }

  handleBankAccountNavLinkClick = event => {
    event.preventDefault();
    this.setState(
      this.formSettingsForPaymentStore(this.store.bankAccountPayment),
    );
  };

  handleCreditCardNavLinkClick = event => {
    event.preventDefault();
    this.setState(
      this.formSettingsForPaymentStore(this.store.creditCardPayment),
    );
  };

  isFormFilled = () => {
    if (this.state.form === this.store.creditCardPayment) {
      return !CREDIT_CARD_INPUT_FIELDS.every(
        fieldValue => this.state.form[fieldValue],
      );
    } else {
      return !BANK_ACCOUNT_INPUT_FIELDS.every(
        fieldValue => this.state.form[fieldValue],
      );
    }
  };

  navLinkStateClassFor(navLink) {
    return this.state.activeNavLink === navLink ? 'active' : 'inactive';
  }

  render() {
    const { form, PaymentForm } = this.state;
    const { phase } = this.props;

    const paymentHeader =
      phase === '1b'
        ? 'Add your payment info'
        : 'Enter your payment information';
    const paymentSubheader =
      phase === '1b' ? (
        <>
          Finally, we need a primary payment method for your account. All
          information is securely processed and you’re only charged for
          completed background checks.{' '}
          <BoldFont>You don’t owe anything today.</BoldFont>
        </>
      ) : (
        <>
          Now, we just need a primary payment method for your account. All
          information is securely processed and you&apos;ll only be charged for
          completed background checks.
        </>
      );

    return (
      <SignupWrapper>
        {this.store.isSignup && <Redirect to={this.store.getStep('payment')} />}
        {!this.store.isBillable() && (
          <Redirect to={this.store.getStep('submission')} />
        )}
        {!this.store.auth.user && (
          <Redirect to={this.store.getStep('signin')} />
        )}
        <div className='container' style={{ maxWidth: '920px' }}>
          <NewPartnerSignupProgressBar
            type={this.store.showPackageCustomization() ? 'variant' : 'default'}
            currentStep={this.store.showPackageCustomization() ? 3 : 2}
          />

          <div className='row'>
            <div className='col-md-12 px-4'>
              <CardHeader
                width='725px'
                height='96px'
                left='358px'
                top='152px'
                title={
                  <Typography
                    textAlign='center'
                    fontWeight='600'
                    lineHeight='115%'
                    width='100%'
                    color='#001730'
                    fontFamily='ArgentCFRegular'
                    sx={{
                      typography: {
                        xs: { fontSize: '28px' },
                        lg: { fontSize: '42px' },
                      },
                    }}
                  >
                    {paymentHeader}
                  </Typography>
                }
                subheader={
                  <Typography
                    textAlign='center'
                    paddingTop='16px'
                    paddingBottom='30px'
                    fontWeight='400'
                    lineHeight='27px'
                    color='#0A1626'
                    fontFamily='National2Regular'
                    sx={{
                      typography: {
                        xs: { fontSize: '16px' },
                        lg: { fontSize: '18px' },
                      },
                    }}
                  >
                    {paymentSubheader}
                  </Typography>
                }
              ></CardHeader>
            </div>
          </div>

          <div className='col-md-6 mx-auto'>
            <div
              className={
                'mb-4 v4-left-div-' +
                this.navLinkStateClassFor('nav-link-credit-card-payment')
              }
            >
              <div
                className={
                  'v4-right-div-' +
                  this.navLinkStateClassFor('nav-link-credit-card-payment')
                }
              >
                <ul className='nav nav-payment-methods text-center'>
                  <li className='nav-item'>
                    <a
                      className={
                        'nav-link ' +
                        this.navLinkStateClassFor(
                          'nav-link-credit-card-payment',
                        )
                      }
                      href='#'
                      onClick={this.handleCreditCardNavLinkClick}
                      data-testid={PAYMENT.MISC.credit}
                    >
                      <span className='form-label-signup-v4'>
                        CREDIT / DEBIT
                      </span>
                      &nbsp;
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className={
                        'nav-link ' +
                        this.navLinkStateClassFor(
                          'nav-link-bank-account-payment',
                        )
                      }
                      href='#'
                      onClick={this.handleBankAccountNavLinkClick}
                      data-testid='testid-nav-link-bank'
                    >
                      <span className='form-label-signup-v4'>Bank Account</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <Form.Form form={form} version='V4'>
              <PaymentForm store={this.store} form={form} />
              <div>
                <div className='d-flex justify-content-center mt-5'>
                  <Form.Submit
                    dataTestId={PAYMENT.MISC.nextBtn}
                    customClassName='btn btn-success btn-signup-v4'
                    text='Next'
                    loading={form.loading}
                    disabled={this.isFormFilled()}
                  />
                </div>

                <div
                  className='d-flex justify-content-center mt-3 mb-5'
                  style={{
                    fontFamily: 'OpenSans',
                    color: '#576C82',
                    fontSize: '12px',
                    fontWeight: '700',
                  }}
                >
                  <Lock
                    style={{
                      fontSize: '0.6rem',
                      marginRight: '4px',
                      marginTop: '4px',
                      color: '#576C82',
                    }}
                  />
                  Secure SSL Encrypted Form
                </div>
              </div>
            </Form.Form>
          </div>
        </div>
      </SignupWrapper>
    );
  }
}

PaymentV4.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
