/* eslint-disable camelcase */
import * as Sentry from '@sentry/browser';

import { ScreeningType, ScreeningSetting } from './packageTypes';

export type MappedPackage = {
  pointer_lookback_in_years_for_county?: number | undefined;
  county_level_verification_fee?: number | undefined;
  auto_inaccessible_followup_type?: string | null | undefined;
  child_abuse_search_state_values?: string[] | undefined;
  inaccessible_followup_type?: string | null | undefined;
  only_search_states_values?: string[] | undefined;
  always_search_states_values?: string[] | undefined;
  always_search_counties_values?: string[][] | undefined;
  requires_observed_drug_test?: boolean | undefined;
  enabled_exams?: object[] | null | undefined;
  name?: string;
  slug?: string;
  price?: number;
  screenings: {
    name?: string;
    label?: string;
    type: ScreeningType;
    subtype?: string | string[] | null;
    maximum_allowed_employers?: number;
    lookback_years?: number;
    requires_education_history?: boolean;
    maximum_lookback_years?: number;
    requires_full_lookback?: boolean;
    position_matching?: string;
    manager_contact?: boolean;
    salary?: boolean;
    contract_type?: boolean;
    date_matching?: number;
    international_upgrade?: boolean;
    requires_employment_history?: boolean;
    co_state_source_name?: (boolean | string | null | undefined)[];
    wi_state_source_name?: (boolean | string | null | undefined)[];
    pa_state_source_name?: (boolean | string | null | undefined)[];
    required_quantity?: number;
    input_required?: [boolean];
    license_categories?: [boolean, string | null];
    locations?: [boolean, string | null];
    out_of_country_history?: boolean;
    test_reason?: [boolean, string | null];
    externally_ordered?: string | null;
    requires_observed_collection?: string | null;
    requires_completed_degrees?: boolean | null;
    document_collection?: boolean | null;
  }[];
  applicant_pay?: boolean | null;
  requires_fmcsa_compliance?: boolean;
  county_criminal_search_scope?: string;
  dl_history_verification_enabled?: boolean;
  county_coverage_for_all_state_searches?: boolean;
  skip_county_fees?: boolean;
  skip_mandatory_county_fees?: boolean;
  protected_invitation_flow?: boolean;
  criminal_self_disclosure?: boolean;
  skip_self_disclosure_validation?: boolean;
  skip_ssn_collection_on_candidate_create?: boolean;
  education_history_based_county_criminal_searches?: boolean;
  education_history_based_state_criminal_searches?: boolean;
  employment_history_based_county_criminal_searches?: boolean;
  employment_history_based_state_criminal_searches?: boolean;
  candidates_can_declare_no_ssn?: boolean;
  skip_criminal_searches_in_inaccessible_states_and_counties?: boolean;
  skip_criminal_searches_in_inaccessible_states_and_counties_type?:
    | boolean
    | null;
  minors_allowed?: boolean;
  assess_auto_engage_eligible_reports?: boolean;
  county_criminal_search_skip?: boolean;
  has_screenings_requiring_data?: boolean;
  continuous_check?: boolean;
  international_only?: boolean;
  skip_ssn_trace_exceptions?: object | null;
  additional_counties?: {
    state: string;
    county: string;
  }[];
  additional_states?: string[];
  only_search_states?: string[];
  child_abuse_search_states?: string;
  request_aliases_hard_cap_threshold?: number | null;
  health_screening_passport_expiration_days?: number | null;
  county_update_to_state_search_values?: string[];
  address_history_lookback_years?: number | null;
  protected_invitation_fields?: string[];
  auto_upgrade_on_assessment?: boolean;
  allow_dashboard_upgrade?: boolean;
  skip_thin_file_based_on_candidate_age?: unknown | null;
  us_only?: boolean;
};

type EmploymentVerificationType = {
  type: 'employment_verification';
  subtype?: string | null;
  preferences: {
    name: string;
    label: string;
    requires_history: boolean;
    maximum_allowed_employers: number;
    maximum_lookback_years: number;
    requires_full_lookback: boolean;
    position_matching: string;
    manager_contact: boolean;
    salary: boolean;
    contract_type: boolean;
    date_matching: number;
    international_upgrade: boolean;
    document_collection?: boolean;
  };
};

type PersonalReferenceVerification = {
  type: 'personal_reference_verification';
  subtype: string | null;
  preferences: {
    required_quantity: number;
  };
};

type ProfessionalReferenceVerification = {
  type: 'professional_reference_verification';
  subtype: string | null;
  preferences: {
    required_quantity: number;
  };
};

type EducationVerification = {
  type: 'education_verification';
  subtype: null;
  preferences: {
    international_upgrade: boolean;
    requires_history: boolean;
    requires_completed_degrees: boolean;
    document_collection: boolean;
  };
};

type CountyCriminalSearch = {
  type: 'county_criminal_search';
  subtype: string;
  preferences: {
    requires_education_history: boolean;
    requires_employment_history: boolean;
  };
};

type OccupationalHealthScreening = {
  type: 'occupational_health_screening';
  subtype: string;
};

type DrugScreening = {
  type: 'drug_screening';
  subtype: string;
  preferences: {
    cost: number | null;
  };
};

type ProfessionalLicenseVerification = {
  type: 'professional_license_verification';
  subtype?: null;
  preferences: {
    input_required: ScreeningSetting;
    license_categories: ScreeningSetting;
    locations: ScreeningSetting;
  };
};

type ExcludedScreenings =
  | 'employment_verification'
  | 'county_criminal_search'
  | 'education_verification'
  | 'personal_reference_verification'
  | 'professional_reference_verification'
  | 'professional_license_verification'
  | 'occupational_health_screening'
  | 'drug_screening';

export type MapPackageParam = {
  id: string;
  object: string;
  name: string;
  slug: string;
  price: number;
  screenings: (
    | EmploymentVerificationType
    | CountyCriminalSearch
    | EducationVerification
    | PersonalReferenceVerification
    | ProfessionalReferenceVerification
    | ProfessionalLicenseVerification
    | OccupationalHealthScreening
    | DrugScreening
    | {
        type: Exclude<ScreeningType, ExcludedScreenings>;
        subtype?: string | null;
        preferences?: {
          requires_education_history?: boolean;
          requires_employment_history?: boolean;
        } | null;
      }
  )[];
  screening_settings: ScreeningSetting[];
  version_id?: string;
  applicant_pay?: boolean | null;
  apply_url?: string;
  requires_fmcsa_compliance?: boolean;
  county_criminal_search_scope?: string;
  dl_history_verification_enabled?: boolean;
  county_coverage_for_all_state_searches?: boolean;
  skip_county_fees?: boolean;
  skip_mandatory_county_fees?: boolean;
  protected_invitation_flow?: boolean;
  criminal_self_disclosure?: boolean;
  skip_self_disclosure_validation?: boolean;
  skip_ssn_collection_on_candidate_create?: boolean;
  education_history_based_county_criminal_searches?: boolean;
  education_history_based_state_criminal_searches?: boolean;
  employment_history_based_county_criminal_searches?: boolean;
  employment_history_based_state_criminal_searches?: boolean;
  candidates_can_declare_no_ssn?: boolean;
  skip_criminal_searches_in_inaccessible_states_and_counties?: boolean;
  skip_criminal_searches_in_inaccessible_states_and_counties_type?:
    | boolean
    | null;
  minors_allowed?: boolean;
  assess_auto_engage_eligible_reports?: boolean;
  county_criminal_search_skip?: boolean;
  has_screenings_requiring_data?: boolean;
  continuous_check?: boolean;
  international_only?: boolean;
  skip_ssn_trace_exceptions?: string | null;
  pointer_lookback_in_years_for_county?: number;
  always_search_counties_values?: string[][];
  always_search_states_values?: string[];
  only_search_states_values?: string[];
  child_abuse_search_state_values?: string[];
  drug_screening_price?: number | null;
  enabled_exams?: object[] | null;
  requires_observed_drug_test?: boolean;
  created_at: string;
  deleted_at?: string | null;
  program_id?: string | null;
  program_name?: string | null;
  uri?: string;
  county_level_verification_fee?: number;
  auto_inaccessible_followup_type?: string | null;
  inaccessible_followup_type?: string | null;
  'is_featured?': boolean;
  account_resource_id: string;
  updated_at?: string | null;
  request_aliases_hard_cap_threshold: number | null;
  health_screening_passport_expiration_days: number | null;
  county_update_to_state_search_values: string[];
  address_history_lookback_years: number | null;
  protected_invitation_fields: string[];
  version_number: number;
  origin: string | null;
  partner_origin_id: string | null;
  auto_upgrade_on_assessment: boolean;
  allow_dashboard_upgrade: boolean;
  type: string;
  skip_thin_file_based_on_candidate_age: unknown | null;
  us_only: boolean;
};

const findStateSource = (stateSources: ScreeningSetting[], name: string) => {
  return stateSources.find(source => {
    return source.name === name;
  });
};

const invalidScreenings = [
  'credit_report',
  'occupational_health_screening',
  'drug_screening',
];

// Takes the base package and transforms it into the shape required to create the package.
export const mapPackage = (_package: MapPackageParam) => {
  if (!_package) {
    return {
      name: '',
      slug: '',
      price: 0,
      screenings: [],
    };
  }

  const postPackage: MappedPackage = {
    name: _package?.name,
    slug: _package?.slug,
    price: _package?.price,
    screenings: [],
  };

  postPackage.request_aliases_hard_cap_threshold =
    _package?.request_aliases_hard_cap_threshold;
  postPackage.health_screening_passport_expiration_days =
    _package?.health_screening_passport_expiration_days;
  postPackage.county_update_to_state_search_values =
    _package?.county_update_to_state_search_values;
  postPackage.address_history_lookback_years =
    _package?.address_history_lookback_years;
  postPackage.protected_invitation_fields =
    _package?.protected_invitation_fields;
  postPackage.auto_upgrade_on_assessment = _package?.auto_upgrade_on_assessment;
  postPackage.allow_dashboard_upgrade = _package?.allow_dashboard_upgrade;
  postPackage.skip_thin_file_based_on_candidate_age =
    _package?.skip_thin_file_based_on_candidate_age;
  postPackage.us_only = _package?.us_only;

  postPackage.applicant_pay = _package?.applicant_pay;

  postPackage.requires_fmcsa_compliance = _package?.requires_fmcsa_compliance;

  postPackage.dl_history_verification_enabled =
    _package?.dl_history_verification_enabled;

  postPackage.county_coverage_for_all_state_searches =
    _package?.county_coverage_for_all_state_searches;

  postPackage.skip_county_fees = _package?.skip_county_fees;

  postPackage.skip_mandatory_county_fees = _package?.skip_mandatory_county_fees;

  postPackage.protected_invitation_flow = _package?.protected_invitation_flow;

  postPackage.criminal_self_disclosure = _package?.criminal_self_disclosure;

  postPackage.skip_self_disclosure_validation =
    _package?.skip_self_disclosure_validation;

  postPackage.skip_ssn_collection_on_candidate_create =
    _package?.skip_ssn_collection_on_candidate_create;

  postPackage.education_history_based_county_criminal_searches =
    _package?.education_history_based_county_criminal_searches;

  postPackage.education_history_based_state_criminal_searches =
    _package?.education_history_based_state_criminal_searches;

  postPackage.employment_history_based_county_criminal_searches =
    _package?.employment_history_based_county_criminal_searches;

  postPackage.employment_history_based_state_criminal_searches =
    _package?.employment_history_based_state_criminal_searches;

  postPackage.candidates_can_declare_no_ssn =
    _package?.candidates_can_declare_no_ssn;

  postPackage.skip_criminal_searches_in_inaccessible_states_and_counties =
    _package?.skip_criminal_searches_in_inaccessible_states_and_counties;

  postPackage.skip_criminal_searches_in_inaccessible_states_and_counties_type =
    _package?.skip_criminal_searches_in_inaccessible_states_and_counties_type;

  postPackage.minors_allowed = _package?.minors_allowed;

  postPackage.assess_auto_engage_eligible_reports =
    _package?.assess_auto_engage_eligible_reports;

  postPackage.county_criminal_search_skip =
    _package?.county_criminal_search_skip;

  postPackage.has_screenings_requiring_data =
    _package?.has_screenings_requiring_data;

  postPackage.continuous_check = _package?.continuous_check;

  postPackage.international_only = _package?.international_only;

  postPackage.enabled_exams = _package?.enabled_exams;

  postPackage.requires_observed_drug_test =
    _package?.requires_observed_drug_test;

  postPackage.applicant_pay = _package?.applicant_pay;
  postPackage.county_level_verification_fee =
    _package?.county_level_verification_fee;
  postPackage.auto_inaccessible_followup_type =
    _package?.auto_inaccessible_followup_type;
  postPackage.child_abuse_search_state_values =
    _package?.child_abuse_search_state_values;
  postPackage.inaccessible_followup_type = _package?.inaccessible_followup_type;
  postPackage.only_search_states_values = _package?.only_search_states_values;
  postPackage.always_search_states_values =
    _package?.always_search_states_values;
  postPackage.always_search_counties_values =
    _package?.always_search_counties_values;

  if (_package?.skip_ssn_trace_exceptions) {
    postPackage.skip_ssn_trace_exceptions = JSON.parse(
      _package.skip_ssn_trace_exceptions,
    );
  }

  if (_package?.pointer_lookback_in_years_for_county) {
    postPackage.county_criminal_search_scope = `${_package.pointer_lookback_in_years_for_county.toString()}years`;
  }

  _package?.screenings?.forEach(screening => {
    try {
      if (!('type' in screening)) {
        return;
      }

      if (screening.type === 'national_criminal_search') {
        postPackage.screenings.push({
          type: 'national_criminal_search',
          subtype: screening.subtype,
        });
      } else if (screening.type === 'motor_vehicle_report') {
        const mvrTypeSetting = _package?.screening_settings.find(
          setting => setting.name === 'mvr_type',
        );
        postPackage.screenings.push({
          type: 'motor_vehicle_report',
          ...(mvrTypeSetting && {
            mvr_type: [mvrTypeSetting.enabled, mvrTypeSetting.value],
          }),
        });
      } else if (screening.type === 'county_criminal_search') {
        postPackage.screenings.push({
          type: 'county_criminal_search',
          subtype: screening.subtype,
          requires_education_history:
            screening.preferences.requires_education_history,
          requires_employment_history:
            screening.preferences.requires_employment_history,
        });

        if (
          _package.always_search_counties_values &&
          _package.always_search_counties_values.length > 0
        ) {
          postPackage.additional_counties =
            _package.always_search_counties_values.map(entry => ({
              state: entry[0],
              county: entry[1],
            }));
        }
      } else if (screening.type === 'state_criminal_search') {
        if (_package.screening_settings && screening.preferences) {
          const co_state_source = findStateSource(
            _package?.screening_settings,
            'co_state_source_name',
          );
          const pa_state_source = findStateSource(
            _package?.screening_settings,
            'pa_state_source_name',
          );
          const wi_state_source = findStateSource(
            _package?.screening_settings,
            'wi_state_source_name',
          );

          postPackage.screenings.push({
            type: 'state_criminal_search',
            subtype: screening.subtype,
            requires_education_history:
              screening.preferences.requires_education_history,
            requires_employment_history:
              screening.preferences.requires_employment_history,
            co_state_source_name: [
              co_state_source?.enabled,
              co_state_source?.value,
            ],
            pa_state_source_name: [
              pa_state_source?.enabled,
              pa_state_source?.value,
            ],
            wi_state_source_name: [
              wi_state_source?.enabled,
              wi_state_source?.value,
            ],
          });
        }

        if (
          _package.always_search_states_values &&
          _package.always_search_states_values.length > 0
        ) {
          postPackage.additional_states = _package.always_search_states_values;
        }

        if (
          _package.only_search_states_values &&
          _package.only_search_states_values.length > 0
        ) {
          postPackage.only_search_states = _package.only_search_states_values;
        }
      } else if (screening.type === 'federal_criminal_search') {
        postPackage.screenings.push({
          type: 'federal_criminal_search',
          subtype: screening.subtype,
        });
      } else if (screening.type === 'education_verification') {
        postPackage.screenings.push({
          type: 'education_verification',
          international_upgrade: screening.preferences.international_upgrade,
          requires_education_history: screening.preferences.requires_history,
          requires_completed_degrees:
            screening.preferences.requires_completed_degrees,
          document_collection: screening.preferences.document_collection,
        });
      } else if (screening.type === 'personal_reference_verification') {
        postPackage.screenings.push({
          type: 'personal_reference_verification',
          required_quantity: screening.preferences.required_quantity,
        });
      } else if (screening.type === 'professional_reference_verification') {
        postPackage.screenings.push({
          type: 'professional_reference_verification',
          required_quantity: screening.preferences.required_quantity,
        });
      } else if (screening.type === 'child_abuse_search') {
        const childAbuseSearchStates =
          _package.child_abuse_search_state_values &&
          _package.child_abuse_search_state_values.toString();
        postPackage.screenings.push({
          type: 'child_abuse_search',
          subtype: childAbuseSearchStates,
        });

        if (
          _package.child_abuse_search_state_values &&
          _package.child_abuse_search_state_values.length > 0
        ) {
          postPackage.child_abuse_search_states = childAbuseSearchStates;
        }
      } else if (screening.type === 'employment_verification') {
        postPackage.screenings.push({
          type: 'employment_verification',
          name: screening.preferences.name,
          label: screening.preferences.label,
          maximum_allowed_employers:
            screening.preferences.maximum_allowed_employers,
          lookback_years: screening.preferences.maximum_lookback_years,
          position_matching: screening.preferences.position_matching,
          date_matching: screening.preferences.date_matching,
          manager_contact: screening.preferences.manager_contact,
          salary: screening.preferences.salary,
          contract_type: screening.preferences.contract_type,
          international_upgrade: screening.preferences.international_upgrade,
          requires_employment_history: screening.preferences.requires_history,
          requires_full_lookback: screening.preferences.requires_full_lookback,
          document_collection: screening.preferences.document_collection,
        });
      } else if (screening.type === 'facis_search') {
        postPackage.screenings.push({
          type: 'facis_search',
          subtype: screening.subtype,
        });
      } else if (screening.type === 'professional_license_verification') {
        postPackage.screenings.push({
          type: 'pro_license_verification',
          input_required: [screening.preferences.input_required.enabled],
          license_categories: [
            screening.preferences.license_categories.enabled,
            screening.preferences.license_categories.value,
          ],
          locations: [
            screening.preferences.locations.enabled,
            screening.preferences.locations.value,
          ],
        });
      } else if (screening.type === 'international_criminal_search_v2') {
        postPackage.screenings.push({
          type: 'international_criminal_search_v2',
          out_of_country_history: true,
        });
      } else if (screening.type === 'international_adverse_media_search') {
        postPackage.screenings.push({
          type: 'international_adverse_media_search',
          out_of_country_history: true,
        });
      } else if (screening.type === 'county_civil_search') {
        postPackage.screenings.push({
          type: 'county_civil_search',
          subtype: screening.subtype,
        });
      } else if (invalidScreenings.includes(screening.type)) {
        // Do nothing if screening is 'credit_report', 'occupational_health_screening', 'drug_screening'
      } else {
        postPackage.screenings.push({
          type: screening.type,
          ...(screening?.subtype && { subtype: screening.subtype }),
        });
      }
    } catch (err) {
      Sentry.captureException(err, {
        tags: { 'map-package': 'bad-screening' },
      });
      // this will stop execution of the current iteration and move on to the next screening
    }
  });

  return postPackage;
};
