import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PasswordStrengthRuleListItem from './PasswordStrengthRuleListItem';
import { SIGNUP } from '../constants/identifiers';
export default class PasswordStrengthHelp extends Component {
  computeComponentClass = isActive => {
    const classNameForState = isActive
      ? 'password-strength-help-active'
      : 'password-strength-help-inactive';
    return `${classNameForState} password-strength-help text-muted small`;
  };

  render() {
    const { isActive, passwordStrength } = this.props;
    const componentClassName = this.computeComponentClass(isActive);

    const ruleListItems = passwordStrength.result.rules.map(rule => (
      <PasswordStrengthRuleListItem rule={rule} key={rule.code} />
    ));

    return (
      <div className={componentClassName}>
        <p>Password Requirements:</p>
        <ul
          data-testid={SIGNUP.MISC.passwordRequirements}
          className={'password-strength-rule-list'}
        >
          {this.props.children}
          {ruleListItems}
        </ul>
      </div>
    );
  }
}

PasswordStrengthHelp.propTypes = {
  isActive: PropTypes.bool,
  passwordStrength: PropTypes.object,
  children: PropTypes.object,
};
