import React from 'react';
import { Box, TextField, MenuItem } from '@mui/material';
import { INVITES } from '../../../../constants/identifiers';
import { internationalGeos } from '../data/geos';
import styled from 'styled-components';

const countryNameStyle = {
  color: '#334559',
  background: '#fff',
  fontWeight: '400',
  fontFamily: 'National2Regular',
};

const StyledTextField = styled(TextField)({
  width: '280px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E1E6EB',
      fontFamily: 'National2Regular',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#009bb5',
    },
  },
  '& .MuiSelect-select': countryNameStyle,
  '& label.Mui-focused': {
    color: '#009bb5',
  },
});

const CountrySelect = ({ selectedCountry, register, errors }) => {
  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight: {
          xs: 224,
          lg: 207,
        },
        maxWidth: 280,
      },
    },
  };
  return (
    <Box
      sx={{
        '& .MuiFormLabel-root': {
          fontSize: { xs: '14px', sm: '14px', md: '16px' },
        },
        '& .MuiSelect-select': {
          fontSize: { xs: '14px', sm: '14px', md: '16px' },
        },
      }}
    >
      <StyledTextField
        {...register('country')}
        label={selectedCountry === '' ? 'Select a country' : ''}
        value={selectedCountry}
        data-testid={INVITES.INPUT.country}
        error={Boolean(errors.country?.message)}
        helperText={errors.country?.message}
        SelectProps={{ MenuProps }}
        select
      >
        {internationalGeos.map(geo => (
          <MenuItem
            key={`option-${geo.country}`}
            data-testid={geo.name}
            value={geo.country}
            sx={countryNameStyle}
          >
            {geo.name}
          </MenuItem>
        ))}
      </StyledTextField>
    </Box>
  );
};

export default CountrySelect;
