import React from 'react';
import styled from 'styled-components';
import { INVITES } from '../../../../../constants/identifiers';
import amplitudeExperiment from '../../../../../services/amplitudeExperiment';

const Divider = styled.div`
  align-self: stretch;
  border-top: 1px #e1e6eb solid;
`;

const Subtotal = styled.div`
  align-self: stretch;
  justify-content: space-between;
  align-items: baseline;
  display: inline-flex;
  gap: 12px;
`;

const SubtotalLabelContainer = styled.div`
  width: 162px;
`;

const SubtotalLabel = styled.span`
  color: #001730;
  font-size: 16px;
  font-family: 'National2Medium';
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Asterisk = styled.span`
  color: #576c82;
`;

const PriceContainer = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 4px;
  display: inline-flex;
`;

const Price = styled.div`
  text-align: right;
  color: #001730;
  font-size: 16px;
  font-family: 'National2Medium';
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const ItemizedLine = styled.div`
  color: #576c82;
  font-size: 14px;
  font-family: 'National2Regular';
  font-weight: 400;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const SubTotal = ({
  calculatedSubTotal,
  numberOfCandidates,
  totalPackagePrice,
}) => {
  const enableFullAddons =
    amplitudeExperiment.getVariantValue('ab-06-full-add-ons-experience') ===
    'treatment';

  return (
    <>
      <Divider />
      <FooterContainer>
        <Subtotal>
          <SubtotalLabelContainer>
            <SubtotalLabel>Subtotal</SubtotalLabel>
            <Asterisk>*</Asterisk>
          </SubtotalLabelContainer>
          <PriceContainer>
            <Price data-testid={INVITES.ORDER_SUMMARY.subtotalPrice}>
              {calculatedSubTotal}
            </Price>
          </PriceContainer>
        </Subtotal>
        {enableFullAddons && numberOfCandidates > 0 && (
          <ItemizedLine data-testid={INVITES.ORDER_SUMMARY.itemizedPrice}>
            {numberOfCandidates} × {totalPackagePrice} per report
          </ItemizedLine>
        )}
      </FooterContainer>
    </>
  );
};

export default SubTotal;
