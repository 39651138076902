import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const InternationalEducationVerification = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo =
    'Confirms candidate’s provided education or certification history outside of the United States';

  const advantages = [
    'Returns a candidate’s degree received, course of study, and dates of attendance',
    'Helps maintain compliance, mitigate risk, and make informed hiring decisions',
  ];

  const considerations = [
    'Turnaround may be longer compared to US screenings',
    'Verifies education, not identity',
  ];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>International Education Verification</ScreeningTitle>
        <ScreeningPrice
          priceByType='international_education_verification'
          priceVaries
        />
        <AddScreeningsAction
          screeningType='international_education_verification'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default InternationalEducationVerification;
