import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const InternationalEmploymentVerification = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo =
    'Confirms candidate’s provided work history outside of the United States';

  const advantages = [
    'Verifies last 3 employers',
    // eslint-disable-next-line max-len
    'Reports may include employer name, dates of employment, position or title held, employment gaps, comments, and salary when available',
    'Helps maintain compliance, mitigate risk, and make informed hiring decisions',
  ];

  const considerations = [
    'Turnaround may be longer compared to US screenings',
    'Verifies employment, not identity',
  ];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>International Employment Verification</ScreeningTitle>
        <ScreeningPrice
          priceByType='international_employment_verification'
          priceVaries
        />
        <AddScreeningsAction
          screeningType='international_employment_verification'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default InternationalEmploymentVerification;
