import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Form from './form';
import { observer } from 'mobx-react';
import { SIGNUP } from '../constants/identifiers';

@observer
export default class WorkLocationSelector extends Component {
  constructor(props) {
    super(props);
    this.form = this.props.form;
  }

  render() {
    let complianceStateAndCitySelector;

    if (this.form.numberOfComplianceLocations === 'single') {
      const states = this.form.availableComplianceStates;
      const stateOptions = Object.keys(states).map(stateCode => {
        return {
          text: states[stateCode].state_name,
          value: states[stateCode].state,
        };
      });

      const citiesForState =
        this.form.availableComplianceCitiesForSelectedState;

      complianceStateAndCitySelector = (
        <div className='form-group'>
          <Form.Label
            text='Work Location'
            tooltip='Select the state and city (if prompted) <br />
                            of your candidates’ primary work location.'
          />
          <p className='text-muted'>
            This helps determine the default location-specific compliance rules
            in Checkr.
          </p>
          <Form.Select
            blankOptionText='Select a state'
            form={this.form}
            name='complianceState'
            options={stateOptions}
            dataTestId={SIGNUP.INPUT.complianceState}
          />
          {citiesForState.length > 0 && (
            <div>
              <Form.Select
                blankOptionText='Select a city'
                form={this.form}
                name='complianceCity'
                options={citiesForState}
                dataTestId={SIGNUP.INPUT.complianceCity}
              />
              <p className='text-muted small'>
                If you don&apos;t see your city, please select
                &quot;Other&quot;.
              </p>
            </div>
          )}
        </div>
      );
    }

    const hasError =
      this.form &&
      this.form.error &&
      !!this.form.error.numberOfComplianceLocations;
    const groupClassName = `form-group ${hasError ? 'has-danger' : ''}`;

    return (
      <div>
        <div className={`multiple-location-selector ${groupClassName}`}>
          <Form.Label text='Do you hire for multiple locations?' />
          <Form.Radio
            form={this.form}
            dataTestId='multiple-locations'
            name='numberOfComplianceLocations'
            value='multiple'
            labelSubText='Yes'
          />
          <Form.Radio
            form={this.form}
            dataTestId='single-location'
            name='numberOfComplianceLocations'
            value='single'
            labelSubText='No'
          />
        </div>
        {complianceStateAndCitySelector}
      </div>
    );
  }
}

WorkLocationSelector.propTypes = {
  form: PropTypes.object.isRequired,
};
