/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';
import Logos from '../../Logos';
import Title from '../../Title';
import Steps from '../../Steps';
import Footer from '../../Footer';
import BankAccountPaymentForm from './BankAccountPaymentForm';
import CreditCardPaymentForm from './CreditCardPaymentForm';
import TestPaymentForm from './TestPaymentForm';
import PricingV3 from './PricingV3';
import { ANALYTICS_EVENTS } from '../../../services/analytics';
import { PAYMENT } from '../../../constants/identifiers';

@inject('store')
@observer
export default class PaymentV3 extends Component {
  constructor(props) {
    super(props);
    const { store } = this.props;
    this.store = store;

    // If the user's name is in the AuthStore,
    // use that as a default for the name-on-card
    this.store.bankAccountPayment.setBankAccountName();
    this.store.creditCardPayment.setCardHolderName();

    // default to credit card
    this.state = {
      ...this.formSettingsForPaymentStore(this.store.creditCardPayment),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.PAYMENT_PAGE_VIEWED);
    this.store.getPromoCodeActive();
  }

  formSettingsForPaymentStore(paymentStore) {
    if (paymentStore === this.store.bankAccountPayment) {
      return {
        activeNavLink: 'nav-link-bank-account-payment',
        form: this.store.bankAccountPayment,
        PaymentForm: this.store.isLive()
          ? BankAccountPaymentForm
          : TestPaymentForm,
      };
    } else {
      return {
        activeNavLink: 'nav-link-credit-card-payment',
        form: this.store.creditCardPayment,
        PaymentForm: this.store.isLive()
          ? CreditCardPaymentForm
          : TestPaymentForm,
      };
    }
  }

  handleBankAccountNavLinkClick = event => {
    event.preventDefault();
    this.setState(
      this.formSettingsForPaymentStore(this.store.bankAccountPayment),
    );
  };

  handleCreditCardNavLinkClick = event => {
    event.preventDefault();
    this.setState(
      this.formSettingsForPaymentStore(this.store.creditCardPayment),
    );
  };

  navLinkStateClassFor(navLink) {
    return this.state.activeNavLink === navLink ? 'active' : 'inactive';
  }

  render() {
    const { PaymentForm } = this.state;
    // eslint-disable-next-line max-len
    const pricingInfo =
      'https://help.checkr.com/hc/en-us/articles/115001342747-What-are-the-additional-or-pass-through-fees-on-my-invoice-';
    const formStep = 2;

    return (
      <div>
        {this.store.isSignup && <Redirect to={this.store.getStep('payment')} />}
        {!this.store.isBillable() && (
          <Redirect to={this.store.getStep('submission')} />
        )}
        <div className='container'>
          <div className='row justify-content-lg-center'>
            <div className='col-lg-7 col-md-12'>
              <div className='row container-outer py-4'>
                <Logos />
                <Title label='Connect to Checkr' baseClass='mb-0' />
                <Steps step={formStep} isBillable={this.store.isBillable()} />
                <div className='row justify-content-md-center'>
                  <p className='col-md-9 text-center'>
                    Please enter your payment information.
                    <br />
                    There is no charge to create a Checkr account and you will
                    be charged only for the background checks you run.
                  </p>
                </div>
                <div className='col-lg-12 container-inner mb-3'>
                  <div>
                    <div className='row'>
                      <div className='col-lg-12 px-5 main'>
                        <ul className='nav nav-payment-methods mb-4'>
                          <li className='nav-item'>
                            <a
                              className={
                                'nav-link ' +
                                this.navLinkStateClassFor(
                                  'nav-link-credit-card-payment',
                                )
                              }
                              href='#'
                              data-testid={PAYMENT.MISC.credit}
                              onClick={this.handleCreditCardNavLinkClick}
                            >
                              <i className='fa fa-credit-card mr-2' />
                              Credit / Debit
                            </a>
                          </li>
                          <li className='nav-item'>
                            <a
                              className={
                                'nav-link ' +
                                this.navLinkStateClassFor(
                                  'nav-link-bank-account-payment',
                                )
                              }
                              href='#'
                              data-testid={PAYMENT.MISC.bank}
                              onClick={this.handleBankAccountNavLinkClick}
                            >
                              <i className='fa fa-bank mr-2' />
                              Bank Account
                            </a>
                          </li>
                        </ul>
                        <PaymentForm
                          store={this.store}
                          form={this.state.form}
                        />
                        <hr />
                        <br />
                        <PricingV3 application={this.store.application} />
                        <br />
                        <p className='mb-0 text-center'>
                          County court fees and state DMV fees may apply.
                          Additional pricing information can be found{' '}
                          <a
                            href={pricingInfo}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            here
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

PaymentV3.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
