import PromotionCodeInput from './PromotionCodeInput';
import React, { Component, forwardRef } from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { IMaskInput } from 'react-imask';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import {
  customCardPaymentErrorMsgs,
  fallbackCardPaymentErrorMsg,
} from '../CheckrDirect/data/cardPaymentErrors';

import { observer } from 'mobx-react';
import { PAYMENT } from '../../../constants/identifiers';

@observer
export default class CreditCardPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = { isCvcVisible: false };
    this.handleAdornmentClick = this.handleAdornmentClick.bind(this);
    this.handleExpiriationChange = this.handleExpiriationChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  handleAdornmentClick() {
    this.setState({ isCvcVisible: !this.state.isCvcVisible });
  }

  handleExpiriationChange(e) {
    if (e.target.value.length !== 5) {
      this.props.form.handleChange(e.target.name, e.target.value);
    } else {
      const [month, year] = e.target.value.split('/');
      this.props.form.handleChange(e.target.name, {
        month,
        year: `20${year}`,
      });
    }
  }

  handleFormChange(e) {
    this.props.form.handleChange(e.target.name, e.target.value);
  }

  render() {
    const { isCvcVisible } = this.state;
    const { store, form } = this.props;

    return (
      <div
        className='form-credit-card'
        data-testid={PAYMENT.MISC.formCreditCard}
      >
        <Grid xs={12} className='d-flex justify-content-center' item>
          <Box
            className='d-flex flex-column gap-0'
            sx={{ width: { xs: '100%', lg: '450px' } }}
          >
            {!!form.paymentError && !!form.error && (
              <div className='payment-error'>
                <ErrorIcon fontSize='12px' />
                <span
                  className='payment-error-msg'
                  data-testid={PAYMENT.ERROR.cardPaymentError}
                >
                  {customCardPaymentErrorMsgs[form.error?.error] ||
                    fallbackCardPaymentErrorMsg}
                </span>
              </div>
            )}
            <TextField
              className='input-signup-v4'
              type='text'
              label='Cardholder name'
              name='name'
              value={form.name || ''}
              error={Boolean(form.error?.name)}
              helperText={form.error?.name}
              inputProps={{
                maxLength: 35,
                'data-testid': PAYMENT.INPUT.ccName,
              }}
              InputLabelProps={{ shrink: true }}
              FormHelperTextProps={{
                'data-testid': PAYMENT.ERROR.ccNameFieldError,
              }}
              onChange={this.handleFormChange}
            />
            <TextField
              className='input-signup-v4'
              type='text'
              label='Card number'
              name='number'
              placeholder='•••• •••• •••• ••••'
              value={form.number || ''}
              error={Boolean(form.error?.number)}
              helperText={form.error?.number}
              InputProps={{
                inputComponent: CreditCardMask,
              }}
              inputProps={{ 'data-testid': PAYMENT.INPUT.ccNumber }}
              InputLabelProps={{ shrink: true }}
              FormHelperTextProps={{
                'data-testid': PAYMENT.ERROR.ccNumberFieldError,
              }}
              onChange={this.handleFormChange}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  className='d-flex input-signup-v4'
                  type='text'
                  label='ZIP code'
                  name='zipcode'
                  value={form.zipcode || ''}
                  error={Boolean(form.error?.zipcode)}
                  helperText={form.error?.zipcode}
                  InputProps={{
                    inputComponent: ZipCodeMask,
                  }}
                  inputProps={{ 'data-testid': PAYMENT.INPUT.ccZip }}
                  InputLabelProps={{ shrink: true }}
                  FormHelperTextProps={{
                    'data-testid': PAYMENT.ERROR.ccZipFieldError,
                  }}
                  onChange={this.handleFormChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type='text'
                  className='d-flex input-signup-v4'
                  label='Expiration date'
                  name='expiration'
                  placeholder='MM/YY'
                  autoComplete='off'
                  value={
                    typeof form.expiration === 'string'
                      ? form.expiration
                      : form.expiration?.month && form.expiration?.year // eslint-disable-next-line indent
                        ? `${
                            form.expiration?.month
                          }/${form.expiration?.year.slice(2)}` // eslint-disable-next-line indent
                        : ''
                  }
                  error={Boolean(form.error?.expiration)}
                  helperText={form.error?.expiration}
                  InputProps={{
                    inputComponent: ExpirationMask,
                  }}
                  inputProps={{
                    maxLength: 5,
                    'data-testid': PAYMENT.INPUT.ccExpiration,
                  }}
                  InputLabelProps={{ shrink: true }}
                  FormHelperTextProps={{
                    'data-testid': PAYMENT.ERROR.ccExpirationFieldError,
                  }}
                  onChange={this.handleExpiriationChange}
                />
              </Grid>
            </Grid>
            <TextField
              className='input-signup-v4'
              type={this.state.isCvcVisible ? 'text' : 'password'}
              label='Security code'
              name='cvc'
              value={form.cvc || ''}
              error={Boolean(form.error?.cvc)}
              helperText={form.error?.cvc}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      data-testid={PAYMENT.MISC.ccCvcVisibilityButton}
                      aria-label='toggle cvc visibility'
                      onClick={this.handleAdornmentClick}
                      edge='end'
                    >
                      {isCvcVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 4,
                'data-testid': PAYMENT.INPUT.ccCvc,
              }}
              InputLabelProps={{ shrink: true }}
              FormHelperTextProps={{
                'data-testid': PAYMENT.ERROR.ccCvcFieldError,
              }}
              onChange={e => {
                const { value } = e.target;
                const cvc = value.replace(/[^0-9]/g, '');
                if (cvc.length <= 4)
                  this.props.form.handleChange(e.target.name, cvc);
              }}
            />
            {store.promoCodeActive && <PromotionCodeInput form={form} />}
          </Box>
        </Grid>
      </div>
    );
  }
}

const CreditCardMask = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask='0000 0000 0000 0000'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value, { el }) => {
        onChange({ target: { name: el.input.name, value } });
      }}
      onChange={() => {}}
    />
  );
});

const ExpirationMask = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask='00/00'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value, { el }) => {
        onChange({ target: { name: el.input.name, value } });
      }}
      onChange={() => {}}
    />
  );
});

const ZipCodeMask = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask='00000'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value, { el }) => {
        onChange({ target: { name: el.input.name, value } });
      }}
      onChange={() => {}}
    />
  );
});
