import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { FLAG_KEYS } from '../../services/flagr';
import loadUser from './loadUser';

import Loading from '../Loading';
import Welcome from '../views/SignupV3/Welcome';
import Signin from '../views/SignupV3/Signin';
import SignupV3 from '../views/SignupV3/SignupV3';
import PaymentV3 from '../views/SignupV3/PaymentV3';
import Connect from '../views/SignupV3/Connect';
import Submission from '../views/SignupV3/Submission';
import Auth0Callback from '../views/Auth0Callback';
import {
  NewWelcome,
  PaymentV4,
  SignupV4,
  SigninV4,
  ConnectV4,
  SubmissionV4,
} from '../views/SignupV4';
import { PHASE_1B } from '../../components/helpers/Constants';

const VARIANTS = {
  welcome: {
    v3: Welcome,
    v4: NewWelcome,
  },
  signin: {
    v3: Signin,
    v4: SigninV4,
  },
  signup: {
    v3: SignupV3,
    v4: SignupV4,
  },
  payment: {
    v3: PaymentV3,
    v4: PaymentV4,
  },
  connect: {
    v3: Connect,
    v4: ConnectV4,
  },
  submission: {
    v3: Submission,
    v4: SubmissionV4,
  },
  auth0Callback: {
    v3: Auth0Callback,
    v4: Auth0Callback,
  },
};

// ABSwitcher is responsible for rendering legacy (v3) or redesigned (v4, 1b) signup pages

const ABSwitcher = observer(props => {
  const { store, componentName, protectedRoute = false } = props;
  const flagr = toJS(store.flagr);
  const name = componentName.trim();
  const flagrKey = flagr?.flags?.[FLAG_KEYS.SIGN_UP_REDESIGN]?.variantKey;
  let Variant;

  if (flagrKey === PHASE_1B) {
    store.signUpFlowProperties.version = 'v4';
    Variant = VARIANTS[name].v4;
  } else {
    store.signUpFlowProperties.version = 'v3';
    Variant = VARIANTS[name].v3;
  }

  Variant = protectedRoute ? loadUser(Variant) : Variant;

  return !flagr ? <Loading /> : <Variant phase={flagrKey} {...props} />;
});

ABSwitcher.propTypes = {
  componentName: PropTypes.string.isRequired,
  protectedRoute: PropTypes.bool,
};

export default ABSwitcher;
