import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  Box,
  CardHeader,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material/';
import { SIGNUP } from '../../../constants/identifiers';

@observer
export default class CheckrDirectWorkLocationSelector extends Component {
  constructor(props) {
    super(props);
    this.form = this.props.form;
  }

  render() {
    let complianceStateAndCitySelector;

    if (this.form.numberOfComplianceLocations === 'single') {
      const states = this.form.availableComplianceStates;
      const stateOptions = Object.keys(states).map(stateCode => {
        return {
          text: states[stateCode].state_name,
          value: states[stateCode].state,
        };
      });

      const citiesForState =
        this.form.availableComplianceCitiesForSelectedState;

      complianceStateAndCitySelector = (
        <Box style={{ marginBottom: '48px' }}>
          <CardHeader
            title={
              <Typography
                textAlign='left'
                fontWeight='500'
                fontSize='18px'
                marginTop='-30px'
                marginLeft='-10px'
                color='#001730'
                fontFamily='National2Medium'
              >
                Work Location
              </Typography>
            }
          />

          <TextField
            style={{ width: '100%', margin: '5px' }}
            label='State'
            variant='outlined'
            name='complianceState'
            error={Boolean(this.form.error?.complianceState)}
            helperText={this.form.error?.complianceState}
            InputProps={{
              style: { backgroundColor: 'white' },
              'data-testid': SIGNUP.INPUT.complianceState,
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    maxHeight: {
                      // ITEM_HEIGHT: 48, ITEM_PADDING_TOP: 8
                      xs: 224, // ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
                      lg: 207, // ITEM_HEIGHT * 4.15 + ITEM_PADDING_TOP
                    },
                  },
                },
              },
            }}
            onChange={e => {
              this.form.handleChange('complianceState', e.target.value);
            }}
            select
          >
            {stateOptions.map(stateOption => (
              <MenuItem value={stateOption.value} key={stateOption?.text}>
                {stateOption.text}
              </MenuItem>
            ))}
          </TextField>
          {citiesForState.length > 0 && (
            <TextField
              style={{ width: '100%', margin: '5px' }}
              label='City'
              variant='outlined'
              name='complianceCity'
              error={Boolean(this.form.error?.complianceCity)}
              helperText={this.form.error?.complianceCity}
              InputProps={{
                style: { backgroundColor: 'white' },
                'data-testid': SIGNUP.INPUT.complianceCity,
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: {
                        // ITEM_HEIGHT: 48, ITEM_PADDING_TOP: 8
                        xs: 224, // ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
                        lg: 207, // ITEM_HEIGHT * 4.15 + ITEM_PADDING_TOP
                      },
                    },
                  },
                },
              }}
              onChange={e => {
                this.form.handleChange('complianceCity', e.target.value);
              }}
              select
            >
              {citiesForState.map(city => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
      );
    }

    const hasError =
      this.form &&
      this.form.error &&
      !!this.form.error.numberOfComplianceLocations;
    const groupClassName = `form-group ${hasError ? 'has-danger' : ''}`;

    return (
      <div>
        <div className={groupClassName}>
          <CardHeader
            title={
              <Typography
                textAlign='left'
                fontWeight='500'
                fontSize='18px'
                marginTop='-15px'
                marginLeft='-10px'
                color='#001730'
                fontFamily='National2Medium'
              >
                Do you hire for multiple locations?
              </Typography>
            }
          />
          <div className='col-md-6'>
            <FormControl>
              <RadioGroup
                row
                style={{ display: 'flex' }}
                name='numberOfComplianceLocations'
                defaultValue={null}
                onChange={e =>
                  this.form.handleChange(
                    'numberOfComplianceLocations',
                    e.target.value,
                  )
                }
              >
                <FormControlLabel
                  control={<Radio inputProps={{ 'data-testid': 'yes' }} />}
                  value='multiple'
                  label='Yes'
                />
                <FormControlLabel
                  control={<Radio inputProps={{ 'data-testid': 'no' }} />}
                  value='single'
                  label='No'
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <br />
        {complianceStateAndCitySelector}
      </div>
    );
  }
}

CheckrDirectWorkLocationSelector.propTypes = {
  form: PropTypes.object.isRequired,
};
