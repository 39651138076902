import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Footer from '../../Footer';
import successLogo from '../../../images/success.svg';
import Loading from '../../Loading';

@inject('store')
@observer
export default class Success extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.form = this.store.success;
    this._handleRedirect = this.handleRedirect.bind(this);
  }

  componentDidMount() {
    this.form.startCountDown();
  }

  UNSAFE_componentWillUnmount() {
    // eslint-disable-line camelcase
    this.form.stopCountDown();
  }

  handleRedirect(e) {
    e.preventDefault();
    this.form.handleRedirect();
  }

  render() {
    if (this.form.loading) {
      return <Loading />;
    }

    return (
      <div>
        <div className='container'>
          <div className='row justify-content-lg-center'>
            <div className='col-lg-7 col-md-12'>
              <div className='row container-outer pt-4'>
                <div className='text-center col-lg-12'>
                  <h4 className='my-4 font-weight-bold'>Success!</h4>
                  <div className='mb-4'>
                    <img className='img-thumbnail' src={successLogo} />
                  </div>
                  <h6 className='font-weight-bold my-0'>
                    Your Checkr account has been created
                  </h6>
                  <div className='row'>
                    <hr className='col-4 my-3' />
                  </div>
                  <div className='row justify-content-md-center'>
                    <p className='col-md-10 text-center mb-4'>
                      You will receive an email once we have verified your
                      business information. You will then be able to run
                      background checks.
                    </p>
                  </div>
                  <p className='font-weight-light text-muted mb-5'>
                    Redirecting to {this.store.application.name} in&nbsp;
                    <span className='text-danger'>
                      {this.form.secondsRemaining}
                    </span>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Success.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
