import { Experiment } from '@amplitude/experiment-js-client';
import { datadogRum } from '@datadog/browser-rum';
import analyticsCheck from './analytics';

let experiment;

const initializeAndStart = async userProperties => {
  if (!analyticsCheck) {
    console.warn(
      'Amplitude experiment: analytics not initialized. Cannot initialize experiment',
    );
    return null;
  }

  const deploymentKey =
    window?.__env?.REACT_APP_AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY;

  if (!deploymentKey) {
    console.warn(
      'Amplitude experiment: no deployment key defined. Cannot initialize experiment!',
    );
    return null;
  }

  experiment = Experiment.initializeWithAmplitudeAnalytics(deploymentKey, {
    fetchTimeoutMillis: 2000,
    fetchOnStart: false,
  });

  if (experiment) {
    try {
      await experiment.start({ user: userProperties });
      datadogRum.addAction('Amplitude Initialization', { status: 'Success' });
      return experiment;
    } catch (err) {
      datadogRum.addAction('Amplitude Initialization', {
        status: 'Failed',
        message: err?.message,
      });
      console.warn(
        'Amplitude experiment: could not fetch variants',
        err?.message,
      );
    }
  } else {
    console.warn('Amplitude experiment: initialization failed');
  }

  return null;
};

const getVariantValue = flagKey => {
  if (!experiment) {
    console.warn(
      'Amplitude experiment: tried to get variant but experiment not initialized!',
    );
    return null;
  }

  if (!flagKey) {
    console.warn(
      'Amplitude experiment: no amplitude experiment flag key provided!',
    );
    return null;
  }

  const variant = experiment.variant(flagKey);

  if (!variant.value) {
    console.warn('Amplitude experiment: no variant found for flag key!');
    return null;
  }

  return variant.value;
};

const getExperiment = () => {
  return experiment;
};

export default { initializeAndStart, getVariantValue, getExperiment };
