import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SkipContainer = styled.div`
  background: white;
  padding: 32px 46px;
  font-family: Open Sans;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  button {
    margin-left: 16px;
    margin-right: 16px;
  }

  .btn-clear {
    background: white;
    color: #043f93;
    border: solid 1px #043f93;
  }

  @media only screen and (max-width: 766px) {
    flex-direction: column;

    button {
      margin-bottom: 10px;
    }
  }
`;

export default function SkipCTA({ onStartQueueing, checkrDashboardLink }) {
  return (
    <SkipContainer>
      <p>
        Skip this step?
        <br /> <br />
        If you go to your dashboard now, you won&apos;t be able to order any
        background checks until we&apos;re finished verifying your business.
      </p>
      <ButtonsContainer>
        <a href={checkrDashboardLink}>
          <button className={'btn btn-lg btn-block btn-clear'}>
            Explore dashboard
          </button>
        </a>
        <button
          className={'btn btn-lg btn-block btn-success'}
          onClick={onStartQueueing}
        >
          Order background checks
        </button>
      </ButtonsContainer>
    </SkipContainer>
  );
}

SkipCTA.propTypes = {
  onStartQueueing: propTypes.func,
};
