import React, { useCallback } from 'react';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Typography,
} from '@mui/material/';
import styled from 'styled-components';

import { ScreeningPrice, ScreeningType } from './PackageUtils/packageTypes';
import { SCREENING_NAMES } from '../../../components/helpers/Constants';
import { Actions, actionTypes } from './reducer';
import { getDollarAmount } from './PackageUtils/PackageUtils';
import CommonAddonsTooltip from './CommonAddonsTooltip';
import LoadingBlock from './PackageScreenings/shared/LoadingBlock';

type LabelProps = {
  slug: string;
  isSelected: boolean;
  screening: ScreeningType;
  price: string | JSX.Element;
};

const Label = ({ screening, isSelected, price }: LabelProps) => {
  return (
    <Typography
      fontSize='14px'
      fontWeight='400'
      color={isSelected ? '#F9F6F2' : 'text.secondary'}
      marginLeft='-6px'
      component={'div'}
    >
      {SCREENING_NAMES[screening]}
      <Typography
        display='inline-block'
        fontWeight='bold'
        fontSize='14px'
        color={isSelected ? '#FFF' : '#117091'}
        component={'div'}
        marginLeft='8px'
        style={{ verticalAlign: 'middle' }}
      >
        {price}
      </Typography>
    </Typography>
  );
};

type CheckboxProps = {
  slug: string;
  checked: boolean;
  isSelected: boolean;
  onChange: () => void;
  dataTestId: string;
};

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 12px;
`;

const Checkbox = ({
  slug,
  onChange,
  isSelected,
  checked,
  dataTestId,
}: CheckboxProps) => (
  <MuiCheckbox
    key={slug}
    data-testid={dataTestId}
    onChange={onChange}
    sx={{
      color: isSelected ? '#F9F6F2' : '#5E5E63',
      '&.Mui-checked': {
        color: isSelected ? '#F9F6F2' : '#117091',
      },
      padding: '0 9px',
      alignSelf: 'baseline',
    }}
    checked={checked}
  />
);

type PackageCardAddOnsProps = {
  slug: string;
  addedScreenings: Array<ScreeningType>;
  defaultScreenings: Array<{ type: ScreeningType; subtype?: string }>;
  isSelected: boolean;
  dispatch: React.Dispatch<Actions>;
  screeningPrices: ScreeningPrice[];
};

const PackageCardAddOns = ({
  slug,
  addedScreenings,
  isSelected,
  dispatch,
  defaultScreenings,
  screeningPrices,
}: PackageCardAddOnsProps) => {
  const educationVerificationChecked =
    addedScreenings?.includes('education_verification') || false;

  const fedCrimSearchChecked =
    addedScreenings?.includes('federal_criminal_search') || false;

  const mvrChecked = addedScreenings?.includes('motor_vehicle_report') || false;

  const showEducationVerification = !defaultScreenings.find(
    obj => obj.type === 'education_verification',
  );

  const showFedCrim = !defaultScreenings.find(
    obj => obj.type === 'federal_criminal_search',
  );

  const showMvr = !defaultScreenings.find(
    obj => obj.type === 'motor_vehicle_report',
  );

  const handleEducationVerification = useCallback(() => {
    if (educationVerificationChecked) {
      dispatch({
        type: actionTypes.REMOVE_SCREENING,
        payload: { package_slug: slug, screening: 'education_verification' },
      });
    } else {
      dispatch({
        type: actionTypes.ADD_SCREENING,
        payload: {
          package_slug: slug,
          screening: 'education_verification',
        },
      });
    }
  }, [dispatch, slug, educationVerificationChecked]);

  const handleFedCrimSearch = useCallback(() => {
    if (fedCrimSearchChecked) {
      dispatch({
        type: actionTypes.REMOVE_SCREENING,
        payload: { package_slug: slug, screening: 'federal_criminal_search' },
      });
    } else {
      dispatch({
        type: actionTypes.ADD_SCREENING,
        payload: {
          package_slug: slug,
          screening: 'federal_criminal_search',
        },
      });
    }
  }, [dispatch, slug, fedCrimSearchChecked]);

  const handleMvr = useCallback(() => {
    if (mvrChecked) {
      dispatch({
        type: actionTypes.REMOVE_SCREENING,
        payload: { package_slug: slug, screening: 'motor_vehicle_report' },
      });
    } else {
      dispatch({
        type: actionTypes.ADD_SCREENING,
        payload: {
          package_slug: slug,
          screening: 'motor_vehicle_report',
        },
      });
    }
  }, [mvrChecked, dispatch, slug]);

  const getPrice = (
    screeningType: ScreeningType,
    itemPrices: ScreeningPrice[],
  ) => {
    const itemPrice = itemPrices.find(
      item => item.product_type === screeningType,
    );
    if (!itemPrice) return <LoadingBlock />;
    return getDollarAmount(itemPrice.base_price);
  };

  if (!showEducationVerification && !showFedCrim && !showMvr) return null;

  return (
    <div>
      <Typography
        display='inline'
        fontWeight='400'
        fontSize='14px'
        color={isSelected ? '#FFF' : '#001730'}
        component={'span'}
        data-testid={`${slug}-common-add-ons`}
      >
        Common add-ons
      </Typography>
      <CommonAddonsTooltip
        showMvr={showMvr}
        showEducationVerification={showEducationVerification}
        showFedCrim={showFedCrim}
        isSelected={isSelected}
      />
      <div className='d-flex flex-column mt-2'>
        {showMvr && (
          <StyledFormControlLabel
            control={
              <Checkbox
                slug={slug}
                onChange={handleMvr}
                checked={mvrChecked}
                isSelected={isSelected}
                dataTestId={`common-addons-${slug}-motor_vehicle_report`}
              />
            }
            label={
              <Label
                screening='motor_vehicle_report'
                isSelected={isSelected}
                slug={slug}
                price={getPrice('motor_vehicle_report', screeningPrices)}
              />
            }
          />
        )}
        {showFedCrim && (
          <StyledFormControlLabel
            control={
              <Checkbox
                slug={slug}
                onChange={handleFedCrimSearch}
                checked={fedCrimSearchChecked}
                isSelected={isSelected}
                dataTestId={`common-addons-${slug}-federal_criminal_search`}
              />
            }
            label={
              <Label
                screening='federal_criminal_search'
                isSelected={isSelected}
                slug={slug}
                price={getPrice('federal_criminal_search', screeningPrices)}
              />
            }
          />
        )}
        {showEducationVerification && (
          <StyledFormControlLabel
            control={
              <Checkbox
                slug={slug}
                onChange={handleEducationVerification}
                checked={educationVerificationChecked}
                isSelected={isSelected}
                dataTestId={`common-addons-${slug}-education_verification`}
              />
            }
            label={
              <Label
                screening='education_verification'
                isSelected={isSelected}
                slug={slug}
                price={getPrice('education_verification', screeningPrices)}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default PackageCardAddOns;
