import React from 'react';
import { Link, LinkProps } from '@mui/material';

const SkipThisStepLink = (props: LinkProps) => {
  return (
    <Link
      sx={{
        fontSize: { xs: '16px', sm: '16px', md: '18px' },
        fontStyle: 'normal',
        fontFamily: 'National2Regular',
        fontWeight: '400',
        textDecorationColor: '#576C82 !important',
        color: '#047591 !important',
        cursor: 'pointer',
      }}
      {...props}
    />
  );
};

export default SkipThisStepLink;
