import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const CountyCivilSearch = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo = `Searches both upper- and lower-level state courts 
  (sometimes referred to as county or circuit courts) for civil cases`;

  const advantages = [
    'Helps provide a comprehensive screening for financial and managerial roles',
    'Identifies non-criminal lawsuits at the county or state level',
    'Shows claims, suits, or judgments where the candidate is named as a plaintiff or defendant',
  ];

  const considerations = [
    'May be limited to 7 years of history',
    'Turnaround varies based on county',
  ];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>
          County Civil Records Search - 7-year address history
        </ScreeningTitle>
        <ScreeningPrice priceByType='county_civil_search' />
        <AddScreeningsAction
          screeningType='county_civil_search'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default CountyCivilSearch;
