import React, { Component } from 'react';
import PropTypes from 'prop-types';
const util = require('util');

export default class PasswordStrengthRuleListItem extends Component {
  static defaultProps = {
    isSuppressed: false,
  };

  buildSubRuleListItems = rule => {
    let subRuleListItems;

    if (rule.items) {
      subRuleListItems = rule.items.map(subRule => {
        const isSubRuleIgnored = rule.verified && !subRule.verified;

        return (
          <PasswordStrengthRuleListItem
            rule={subRule}
            key={subRule.code}
            isSuppressed={isSubRuleIgnored}
          />
        );
      });
    }

    return subRuleListItems;
  };

  computeComponentClass = (isSuppressed, rule) => {
    let classNameForRuleState;

    if (isSuppressed) {
      classNameForRuleState = 'rule-suppressed';
    } else {
      classNameForRuleState = rule.verified ? 'rule-passed' : 'rule-failed';
    }

    return `${classNameForRuleState} rule-${rule.code}`;
  };

  render() {
    const { isSuppressed, rule } = this.props;
    const ruleDescription = rule.format
      ? util.format(rule.message, ...rule.format)
      : rule.message;
    const componentClass = this.computeComponentClass(isSuppressed, rule);
    const subRuleListItems = this.buildSubRuleListItems(rule);

    return (
      <li className={componentClass}>
        <i className='fa' />
        <span data-testid={componentClass} className='rule-description'>
          {ruleDescription}
        </span>
        {subRuleListItems ? (
          <ul className='password-strength-rule-list'>{subRuleListItems}</ul>
        ) : null}
      </li>
    );
  }
}

PasswordStrengthRuleListItem.propTypes = {
  isSuppressed: PropTypes.bool,
  rule: PropTypes.object,
};
