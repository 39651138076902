import React, { useContext } from 'react';

/**
 * This is an attempt to migrate away from mobx by creating a provider/context to inject state into functional
 * components. This is basically what the new version of mobx-react-lite does anyway
 */
export const StoreContext = React.createContext(null);

export const StoreProvider = StoreContext.Provider;

export function useStore() {
  const store = useContext(StoreContext);

  return store;
}
