import React, { useEffect } from 'react';
import { Box, FormControl, Typography } from '@mui/material/';
import CountrySelect from './CountrySelect';
import StateCitySelect from './StateCitySelect';
import CandidateEmailGrid from './CandidateEmailGrid';
import styled from 'styled-components';

const SectionHeader = styled(Typography)`
  font-family: National2Regular;
  color: #334559;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const CandidateInvites = ({
  selectedRegion,
  selectedState = '',
  selectedCity = '',
  selectedCountry = '',
  register,
  errors,
}) => {
  return (
    <>
      <Box sx={{ marginBottom: '16px' }}>
        <FormControl sx={{ width: '100%' }}>
          <SectionHeader>Where will your candidates be located?</SectionHeader>
          <Box>
            {selectedRegion === 'US' ? (
              <StateCitySelect
                selectedState={selectedState}
                selectedCity={selectedCity}
                register={register}
                errors={errors}
              />
            ) : (
              <CountrySelect
                selectedCountry={selectedCountry}
                register={register}
                errors={errors}
              />
            )}
          </Box>
        </FormControl>
      </Box>
      <Box sx={{ marginBottom: '24px' }}>
        <SectionHeader>Where should we send your invitations?</SectionHeader>
        <CandidateEmailGrid />
      </Box>
    </>
  );
};

export default CandidateInvites;
