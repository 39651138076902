import React from 'react';
import {
  AdditionalPropertiesType,
  ScreeningType,
} from './PackageUtils/packageTypes';
import { Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { SCREENING_NAMES } from '../../../components/helpers/Constants';

const checkIconStyle = {
  fontSize: 'medium',
  width: '1em',
  color: '#117091',
  fontWeight: '400',
  marginRight: '6px',
  marginTop: '-2px',
};

const displayScreening = (
  screening: ScreeningType,
  additionalProperties: AdditionalPropertiesType,
  internationalOnly: boolean,
) => {
  switch (screening) {
    case 'facis_search':
      return additionalProperties[screening]?.subtype === 3
        ? SCREENING_NAMES.facis_search_3
        : SCREENING_NAMES.facis_search_1;
    case 'international_criminal_search_v2':
      return internationalOnly
        ? SCREENING_NAMES.international_criminal_search_v2
        : SCREENING_NAMES.global_watchlist_search;
    default:
      return SCREENING_NAMES[screening];
  }
};

type ListElementProps = {
  slug: string;
  screening: ScreeningType;
  additionalProperties: AdditionalPropertiesType;
  isSelected: boolean;
  internationalOnly: boolean;
};

const ListElement = ({
  screening,
  slug,
  isSelected,
  additionalProperties,
  internationalOnly,
}: ListElementProps) => {
  return (
    <div
      style={{ marginTop: '-3px' }}
      data-testid={`addons-${slug}-${screening}`}
    >
      <Typography
        variant='body2'
        color={isSelected ? '#FFF' : 'text.secondary'}
        sx={{
          typography: {
            xs: { fontSize: '12px' },
            lg: { fontSize: '14px' },
          },
        }}
        display='inline'
      >
        <Check
          style={{
            ...checkIconStyle,
            color: isSelected ? '#FFF' : '#117091',
          }}
        />
        {displayScreening(screening, additionalProperties, internationalOnly)}
      </Typography>
    </div>
  );
};

type PackageCardAddOnsListProps = {
  slug: string;
  addedScreenings: Array<ScreeningType>;
  additionalProperties: AdditionalPropertiesType;
  isSelected: boolean;
  internationalOnly: boolean;
};

const PackageCardAddOnsList = ({
  slug,
  addedScreenings,
  additionalProperties,
  isSelected,
  internationalOnly,
}: PackageCardAddOnsListProps) => {
  const screenings = addedScreenings?.filter(screening => {
    return (
      screening !== 'education_verification' &&
      screening !== 'federal_criminal_search' &&
      screening !== 'motor_vehicle_report'
    );
  });

  if (!screenings || screenings.length === 0) return null;

  return (
    <div>
      <Typography
        variant='body2'
        color={isSelected ? '#FFF' : 'text.secondary'}
        sx={{
          typography: {
            xs: { fontSize: '12px' },
            lg: { fontSize: '14px' },
          },
        }}
        display='inline'
        style={{ fontWeight: 'bold' }}
      >
        Add-ons
      </Typography>
      {screenings?.map(screening => (
        <ListElement
          key={`desc-${slug}-${screening}`}
          screening={screening}
          slug={slug}
          isSelected={isSelected}
          additionalProperties={additionalProperties}
          internationalOnly={internationalOnly}
        />
      ))}
    </div>
  );
};

export default PackageCardAddOnsList;
