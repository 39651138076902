/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';

import DomesticAddScreeningsList from './Domestic';
import {
  screeningTypes,
  ssnDependentScreeningTypes,
} from '../PackageUtils/ScreeningPayloadsByType';

import {
  BasePackage,
  ScreeningPrice,
  ScreeningType,
} from '../PackageUtils/packageTypes';
import InternationalAddScreeningsList from './International';
import { AddScreeningsContext } from './shared/context';

type Props = {
  basePackage: BasePackage;
  onAddClick: (
    screeningType: ScreeningType,
    additionalProperties?: object,
  ) => void;
  onRemoveClick: (screeningType: ScreeningType) => void;
  addedScreenings: ScreeningType[];
  additionalProperties: { [key: string]: object };
  basePackageScreeningTypes: ScreeningType[];
  selectedGeo: 'domestic' | 'international';
  screeningPrices: ScreeningPrice[];
};

const AddScreeningsList: React.FC<Props> = ({
  basePackage,
  onAddClick,
  onRemoveClick,
  addedScreenings = [],
  additionalProperties = {},
  basePackageScreeningTypes = [],
  selectedGeo,
  screeningPrices,
}) => {
  const [initialAdditionalProperties, setInitialAdditionalProperties] =
    useState({});

  let disabledScreenings = screeningTypes.filter(
    (screeningType: ScreeningType) =>
      basePackageScreeningTypes.includes(screeningType),
  );

  if (!basePackageScreeningTypes.includes('ssn_trace')) {
    disabledScreenings = disabledScreenings.concat(ssnDependentScreeningTypes);
  }

  useEffect(() => {
    let additionalProperties = {};

    const [facisScreening] = basePackage.screenings.filter(
      screening => screening.type === 'facis_search',
    );
    const [employmentVerification] = basePackage.screenings.filter(
      screening => screening.type === 'employment_verification',
    );

    if (facisScreening?.type && 'subtype' in facisScreening) {
      additionalProperties = {
        ...additionalProperties,
        facis_search: { subtype: facisScreening.subtype },
      };
    }

    if (
      employmentVerification?.type &&
      'maximum_allowed_employers' in employmentVerification &&
      !isNaN(employmentVerification.maximum_allowed_employers as number)
    ) {
      additionalProperties = {
        ...additionalProperties,
        employment_verification: {
          maximum_allowed_employers:
            employmentVerification.maximum_allowed_employers,
        },
      };
    }

    setInitialAdditionalProperties(additionalProperties);
  }, [basePackage.screenings]);

  const contextValue = {
    onAddClick,
    onRemoveClick,
    basePackage,
    addedScreenings,
    additionalProperties: {
      ...additionalProperties,
      ...initialAdditionalProperties,
    },
    basePackageScreeningTypes,
    selectedGeo,
    screeningPrices,
    disabledScreenings,
  };

  return (
    <AddScreeningsContext.Provider value={contextValue}>
      {selectedGeo === 'international' ? (
        <InternationalAddScreeningsList />
      ) : (
        <DomesticAddScreeningsList />
      )}
    </AddScreeningsContext.Provider>
  );
};

export default AddScreeningsList;
