const centsToDollars = cents =>
  Math.floor(cents / 100) +
  (cents % 100 > 0 ? '.' + ('00' + (cents % 100)).slice(-2) : '');

const displayPricing = (pkg, price) => {
  if (pkg.international_only) return 'Price varies';
  if (price) return `$${centsToDollars(price)}`;
  return `$${centsToDollars(pkg?.calculated_price)}`;
};

export { displayPricing };
