import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import checkrLogo from '../images/checkr.svg';
import arrows from '../images/arrows.svg';

@inject('store')
@observer
export default class Logos extends Component {
  render() {
    const partnerLogo = get(this.props.store, 'application.logo_url', null);

    return (
      <div className='col-lg-12'>
        <div className='logos'>
          <div className='logo'>
            <span className='align-middle d-inline-block h-100' />
            <img className='img-thumbnail' src={checkrLogo} />
          </div>
          <img className='arrows' src={arrows} />
          {!!partnerLogo && (
            <div className='logo'>
              <span className='align-middle d-inline-block h-100' />
              <img className='img-thumbnail' src={partnerLogo} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Logos.wrappedComponent.propTypes = {
  store: PropTypes.object,
};
