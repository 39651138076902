import ValidationStore from '../ValidationStore';
import { makeObservable } from 'mobx';

export default class SigninStore extends ValidationStore {
  constructor(state) {
    super();
    this.state = state;
    makeObservable(this);
  }

  async signin() {
    try {
      await this.state.auth.getCurrentUser();
      await this.state.auth.getOauthProgress();
      let step =
        this.state.auth.oauth && this.state.auth.oauth.step
          ? this.state.auth.oauth.step
          : 'connect';
      const clientId = this.state.auth.getClientId();
      const isCheckrDirectA =
        this.state.env.CHECKR_DIRECT_A_CLIENT_ID === clientId;
      const isGoodhireWebsite =
        this.state.env.GOODHIRE_WEBSITE_CLIENT_ID === clientId;
      const stateParam = this.state.auth.getStateParam();
      let pathBody;
      switch (clientId) {
        case this.state.env.CHECKR_DIRECT_A_CLIENT_ID:
          pathBody = 'checkr';
          break;
        case this.state.env.GOODHIRE_WEBSITE_CLIENT_ID:
          pathBody = 'checkr-goodhire';
          break;
        default:
          pathBody = `authorize/${clientId}`;
      }

      if (step === 'connect') {
        step = 'submission';
      }

      // always show the connect button, even if they have completed signing up
      if (step === 'complete') {
        step = 'connect';
      }

      if (step === 'payment' && !(isCheckrDirectA || isGoodhireWebsite)) {
        step = 'v3/payment';
      }

      const { history } = this.state.router.router;
      const location = `/${pathBody}/${step}${
        stateParam ? `?state=${this.state.auth.getStateParam()}` : ''
      }`;

      history.push(location, { from: 'signin' });
    } catch (errors) {
      console.error(errors);
      this.error = errors;
    } finally {
      this.loading = false;
    }
  }
}
