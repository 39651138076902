import styled from 'styled-components';

export const InfoDiv = styled.div`
  align-self: stretch;
  justify-content: space-between;
  align-items: baseline;
  display: inline-flex;
  gap: 12px;
`;

export const SelectedTitle = styled.div`
  color: #334559;
  font-size: 14px;
  font-family: 'National2Regular';
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 12px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const Price = styled.div`
  color: #576c82;
  font-size: 14px;
  font-family: 'National2Regular';
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: 12px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
