/* eslint-disable max-len */
import { RumEvent } from '@datadog/browser-rum';

export const errors = [
  `Cannot read properties of undefined (reading 'sendMessage')`,
  `NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`,
  `NotFoundError: The object can not be found here.`,
  `Cannot read properties of undefined (reading 'dispatch')`,
  `Uncaught "Script error."`,
];

export const discardErrorMessages = (event: RumEvent) => {
  if (event.type === 'error') {
    for (const error of errors) {
      if (event.error.message.includes(error)) {
        return true;
      }
    }
  }
  return false;
};

const ACCESS_TOKEN_KEY = 'access_token';
const REDACTED_TEXT = 'redacted';

export const scrubSensitiveData = (event: RumEvent) => {
  if (!event?.view?.url) return;

  try {
    const url = new URL(event.view.url);

    // scrub access token from query parameters
    if (url.searchParams.has(ACCESS_TOKEN_KEY)) {
      url.searchParams.set(ACCESS_TOKEN_KEY, REDACTED_TEXT);
    }

    // scrub access token from URL fragment
    const fragmentParams = new URLSearchParams(url.hash.substring(1));
    if (fragmentParams.has(ACCESS_TOKEN_KEY)) {
      fragmentParams.set(ACCESS_TOKEN_KEY, REDACTED_TEXT);
      url.hash = fragmentParams.toString();
    }
    event.view.url = url.toString();
  } catch {
    // ignore errors from invalid URLs
  }
};
