/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import keys from 'lodash/keys';
import upperFirst from 'lodash/upperFirst';
import omit from 'lodash/omit';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../services/analytics';
import { ERRORS } from '../../constants/identifiers';

const INVALID_CHARACTER =
  ' Name contains invalid characters. Examples of invalid characters are < and >., Support phone contains invalid characters. Valid characters include, numbers, letters, (,), -, ., and +.';
const GOOGLE_CLEAR_CACHE_URL =
  'https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop';
const CLEAR_CACHE_MESSAGE = `If this error persists please try clearing your browser cache and refresh the page. If you are unsure how to do so please see more information `;

export default class Error extends Component {
  render() {
    if (!this.props.error) {
      return null;
    }
    if (!this.props.error.error) {
      return null;
    }

    let error = this.props.error.error;
    if (error.error) {
      error = error.error;
    }

    const errorMessage = this.messageForError(error);

    return (
      <div className='error-msg text-danger' data-testid={ERRORS.signupError}>
        <b>Error:</b> {errorMessage}
      </div>
    );
  }

  messageForError(error) {
    if (error === 'email.alreadyExists') {
      return (
        <span data-testid={ERRORS.emailTaken}>
          Email has been taken. Already have a Checkr account?{' '}
          <Link
            onClick={() => {
              if (this.props.store.isSignup) {
                this.props.store.trackAnalyticsEvent(
                  'Self-Serve Customer Signup Checkr User Account Signed In',
                  {},
                );
              } else {
                this.props.store.trackAnalyticsEvent(ANALYTICS_EVENTS.SIGN_IN, {
                  [ANALYTICS_PROPERTIES.PAGE_NAME]:
                    'Sign In from Signup Email Error',
                });
              }
            }}
            to={this.props.store?.getStep('signin')}
          >
            Sign in
          </Link>
        </span>
      );
    } else if (error === 'signupV3.errors') {
      const modifiedErrors = omit(this.props.error, ['error']);

      return (
        <span data-testid={ERRORS.formErrors}>
          Please correct the error(s) below.
          <small>
            <ul className='mt-2 mb-0'>
              {keys(modifiedErrors).map(key => (
                <li key={key}>{upperFirst(this.props.error[key][0])}</li>
              ))}
            </ul>
          </small>
        </span>
      );
    } else if (error === 'account.inValidCharacter') {
      return (
        <span>
          <span>
            {INVALID_CHARACTER} <br /> <br /> {CLEAR_CACHE_MESSAGE}{' '}
            <a
              href={GOOGLE_CLEAR_CACHE_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              here.
            </a>
          </span>
        </span>
      );
    } else {
      return <span>{error}</span>;
    }
  }
}

Error.propTypes = {
  error: PropTypes.object,
  store: PropTypes.object.isRequired,
  form: PropTypes.object,
};
