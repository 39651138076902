import React, { Component } from 'react';
import logo from '../images/checkr.svg';
import Footer from './Footer';

export default class Home extends Component {
  render() {
    return (
      <div className='text-center'>
        <br />
        <br />
        <br />
        <img src={logo} height='50px' />
        <br />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}
