import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Link as RouterLink, Redirect } from 'react-router-dom';

import SignupWrapper from './SignupWrapper';
import { SIGNUP } from '../../../constants/identifiers';
import Auth0 from '../../../services/Auth0';

const SigninV4 = props => {
  useEffect(() => {
    if (!store.isSignup) {
      store.trackAnalyticsEvent(
        'Partner Customer Onboarding Sign In Page Viewed',
      );
    }
  });

  const { store } = props;

  const handleSignin = () => {
    store.auth.setClientId(store.application.client_id);
    if (
      store.router.location.search &&
      new URLSearchParams(store.router.location.search).get('state')
    ) {
      store.auth.setStateParam(
        new URLSearchParams(store.router.location.search).get('state'),
      );
    }

    Auth0.authorize({ partner_client_id: store.application.client_id });
  };

  return (
    <SignupWrapper>
      {(store.isCheckrDirectA || store.isGoodhireWebsite) && (
        <Redirect to={store.getStep('signin')} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h3'
          className='secondary-text-signup-v4'
          fontFamily='ArgentCFRegular'
          fontSize='42px'
          sx={{
            marginTop: {
              xs: '50px',
              lg: 0,
            },
            marginBottom: '25px',
          }}
        >
          Log in to continue
        </Typography>
        <Typography
          variant='body1'
          fontFamily='National2Regular'
          fontWeight='400'
        >
          You’re just a few steps away from completing your account setup.
        </Typography>
        <Box
          component='button'
          className='cd-btn btn-signup-v4'
          sx={{ marginTop: '30px', marginBottom: '50px' }}
          data-testid='se-login-btn'
          onClick={handleSignin}
        >
          Login
        </Box>
        {store.application.sign_up_flow && (
          <Box className='d-flex justify-content-center'>
            <Typography
              variant='body1'
              fontFamily='National2Regular'
              fontWeight='400'
            >
              Need a Checkr account?
            </Typography>
            <RouterLink
              className='aqua-3'
              data-testid={SIGNUP.MISC.signUp}
              to={store.getStep('signup')}
              style={{ marginLeft: '5px' }}
            >
              Sign up
            </RouterLink>
          </Box>
        )}
      </Box>
    </SignupWrapper>
  );
};

export default SigninV4;
