import React, { useEffect, useMemo } from 'react';
import { toJS } from 'mobx';

const CheckrSignupEmbed = ({ ...props }) => {
  const { store } = props;
  const application = toJS(store.application);

  const partner = useMemo(
    () => ({ id: application.client_id, name: application.name }),
    [application.client_id, application.name],
  );
  const env = store.env.REACT_APP_ENV;

  // prefer redirect_uri from params
  const paramRedirect =
    store.router.location &&
    store.router.location.search &&
    new URLSearchParams(store.router.location.search).get('redirect_uri');

  const oauthRedirectUrl = paramRedirect || application.redirect_uri;

  const oauthRedirectState =
    store.router.location &&
    store.router.location.search &&
    new URLSearchParams(store.router.location.search).get('state');

  useEffect(() => {
    const embed = new window.Checkr.Embeds.SignUpFlow({
      oauthRedirectUrl,
      oauthRedirectState,
      partner,
      env,
    });
    embed.render('#checkr-embed-container');
  }, [oauthRedirectUrl, oauthRedirectState, partner, env]);

  return (
    <div
      id='checkr-embed-container'
      style={{
        minWidth: '100vw',
        minHeight: '100vh',
      }}
    ></div>
  );
};

export default CheckrSignupEmbed;
