import PasswordPolicy from 'password-sheriff';

// Read the strength enum from the current Auth0 connection object when support for it lands (its on Auth0's roadmap)
const AUTH0_PASSWORD_POLICY_STRENGTH_FOR_CURRENT_CONNECTION = 'good';
const passwordPolicy = PasswordPolicy(
  AUTH0_PASSWORD_POLICY_STRENGTH_FOR_CURRENT_CONNECTION,
);

export default class PasswordStrength {
  constructor(value) {
    this.value = value;
    this.verify();
  }

  update(value) {
    this.value = value;
    this.verify();
  }

  verify() {
    this.result = passwordPolicy.missing(this.value);
  }

  static isAcceptable(value) {
    return passwordPolicy.missing(value).verified;
  }
}
