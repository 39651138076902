import React from 'react';
import { Box, Typography } from '@mui/material';
import colors from '../../../../../styles/_colors.scss';

type SectionHeaderProps = {
  title: string;
  subheader?: string;
  dataTestId?: string;
};

const SectionHeader = ({
  title,
  subheader,
  dataTestId,
}: SectionHeaderProps) => {
  return (
    <Box sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
      <Typography
        fontSize='30px'
        sx={{ fontSize: { xs: '20px', scrollMarginBlock: '20px', md: '30px' } }}
        color={colors.slate7}
        fontFamily='National2Medium'
        lineHeight='120%'
        data-testid={dataTestId}
      >
        {title}
      </Typography>
      {subheader && (
        <Typography
          marginTop='8px'
          color={colors.slate6}
          fontFamily='National2Regular'
          lineHeight='150%'
          sx={{ fontSize: { xs: '14px', sm: '14px', md: '16px' } }}
        >
          {subheader}
        </Typography>
      )}
    </Box>
  );
};

export default SectionHeader;
