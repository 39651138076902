import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import styled from 'styled-components';

import { SURVEYS } from '../../helpers/Constants';
import smallBusinessSpring from '../../../images/small-business-spring.png';
import leaderSpring from '../../../images/leader-spring.png';
import pbsaAccredited from '../../../images/pbsa-accredited.png';
import bbbAccredited from '../../../images/bbb-accredited.png';
import CustomElement from '../../custom/CustomElement';
import { WELCOME } from '../../../constants/identifiers';

const BADGES = [
  {
    url: smallBusinessSpring,
    alt: 'Background Check MomentumLeader Leader 2023',
    height: { height: '90px' },
  },
  {
    url: leaderSpring,
    alt: 'Background Check Leader 2023',
    height: { height: '90px' },
  },
  {
    url: pbsaAccredited,
    alt: 'Accredited PBSA',
    height: { height: '58px' },
  },
  {
    url: bbbAccredited,
    alt: 'Accredited BBB',
    height: { height: '71px' },
  },
];

const Survey = () => {
  return (
    <Grid xs={12}>
      <Typography
        data-testid={WELCOME.advantageHeader}
        variant='h3'
        className='secondary-text-signup-v4'
        fontFamily='ArgentCFRegular'
        marginBottom='25px'
        sx={{
          typography: {
            xs: { fontSize: '28px' },
            lg: { fontSize: '42px' },
          },
        }}
      >
        <CustomElement elementKey='advantageHeader' />
      </Typography>
      <Typography
        data-testid={WELCOME.advantageSubHeader}
        variant='h5'
        className='primary-text-signup-v4'
        display='inline'
        fontFamily='National2Medium'
        fontWeight='500'
        sx={{
          typography: {
            xs: { fontSize: '16px' },
            lg: { fontSize: '22px' },
          },
        }}
      >
        <CustomElement elementKey='advantageSubHeader' />
      </Typography>
      <Box sx={{ flexGrow: 1, marginTop: '30px' }}>
        <Grid
          container
          spacing={2}
          columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}
          data-testid={WELCOME.advantageSurveyList}
        >
          <CustomElement elementKey='advantageSurveyList' />
        </Grid>
      </Box>
    </Grid>
  );
};

const FairChance = () => {
  return (
    <Grid container spacing={2} className='d-flex justify-content-between'>
      <Grid item xs={12} lg={4.9}>
        <Typography
          data-testid={WELCOME.fairChanceHeader}
          variant='h3'
          className='secondary-text-signup-v4'
          fontFamily='ArgentCFRegular'
          sx={{
            typography: {
              xs: { fontSize: '28px' },
              lg: { fontSize: '36px' },
            },
          }}
        >
          <CustomElement elementKey='fairChanceHeader' />
        </Typography>
        <Box data-testid={WELCOME.fairChanceBody}>
          <CustomElement elementKey='fairChanceBody' />
        </Box>
      </Grid>
      <Grid item xs={12} lg={5}>
        <Box className='d-flex justify-content-center flex-column'>
          <CustomElement
            dataTestId='single-location'
            elementKey='fairChanceImage'
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'space-evenly', lg: 'space-between' },
              alignItems: 'center',
              padding: {
                xs: '25px 0px',
                lg: '45px',
              },
            }}
          >
            {BADGES.map(({ url, height, alt }) => (
              <img
                src={url}
                alt={alt}
                style={{ height, width: '70px' }}
                key={alt}
              />
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const Achievements = () => {
  return (
    <Grid xs={12} item>
      <Survey />
      <FairChance />
    </Grid>
  );
};

export default Achievements;
