import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, Box, Typography } from '@mui/material/';
import { Add as AddIcon, Check as CheckIcon } from '@mui/icons-material';
import { Truck, Scales, Education } from '../svgs';
import styled from 'styled-components';
import colors from '../../../../../styles/_colors.scss';

const AddOnContainer = styled(Box)`
  @media only screen and (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const StyledTypography = styled(Typography)`
  font-family: National2Regular;
  font-size: 16px;
  font-weight: 300;
  color: ${colors.slate6};
  line-height: 150%;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const AddOnIcon = ({ name }) => {
  switch (name) {
    case 'mvrAddOn':
      return <Truck />;
    case 'federalAddOn':
      return <Scales />;
    case 'educationAddOn':
      return <Education />;
  }
};

/*
  the register method from react-hook-form automatically adds an onChange handler
  and assigns a property to the form data object corresponding to the inputName prop

  the inputName provided by the CommonAddOns component is structured like packageSlug.addOnType
  this creates an object structure in the form data, but this component can work with a simple inputName as well
*/

const PackageAddOnCheckbox = ({ addOn, slug, cardTestId, checkboxTestId }) => {
  const { name, price, type: addOnType, displayName, description } = addOn;
  const inputName = `${slug}.${addOnType}`;
  const { register, watch, setValue } = useFormContext();
  const isChecked = watch(inputName) || false;

  return (
    <AddOnContainer
      sx={{
        display: 'flex',
        gap: '24px',
        alignItems: 'flex-start',
        minHeight: '120px',
        border: 1,
        backgroundColor: '#fff',
        borderColor: colors.slate3,
        borderRadius: '8px',
        padding: '24px',
        boxShadow: '0px 4px 32px -16px rgba(26, 32, 38, 0.10)',
        marginBottom: '16px',
        cursor: 'pointer',
      }}
      onClick={() => setValue(inputName, !isChecked)}
      data-testid={cardTestId}
    >
      <Box sx={{ marginRight: '-7px' }}>
        <AddOnIcon name={name} />
      </Box>
      <Box>
        <Typography
          fontFamily='National2Medium'
          sx={{ fontSize: { xs: '16px', md: '18px' } }}
          fontWeight={500}
          color={colors.aqua4}
          lineHeight={'150%'}
        >
          {name === 'educationAddOn'
            ? `${displayName} (highest-level)`
            : displayName}
        </Typography>
        <StyledTypography paddingRight='8px'>{description}</StyledTypography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
          marginLeft: { xs: '0', sm: 'auto' },
        }}
      >
        <StyledTypography padding='4px 0'>{price}</StyledTypography>
        <Checkbox
          data-testid={checkboxTestId}
          {...register(inputName)}
          icon={<AddIcon fontSize='small' />}
          checkedIcon={
            <CheckIcon
              fontSize='small'
              sx={{
                color: 'white',
              }}
            />
          }
          checked={isChecked}
          onClick={e => e.stopPropagation()}
          sx={{
            border: `1px solid ${colors.slate2}`,
            borderRadius: '4px',
            width: '32px',
            height: '32px',
            '&.Mui-checked': {
              backgroundColor: colors.aqua4,
            },
          }}
        />
      </Box>
    </AddOnContainer>
  );
};

export default PackageAddOnCheckbox;
