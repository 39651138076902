import React from 'react';

export default class StepUnloadWarningModal extends React.Component {
  componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeunload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeunload);
  }

  handleBeforeunload = event => {
    const returnValue = 'Changes you made may not be saved.';

    event.returnValue = returnValue;
    return returnValue;
  };

  render() {
    return null;
  }
}
