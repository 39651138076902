import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import Loading from '../Loading';
import { withRouter } from 'react-router';
import { ApplicationProvider } from '../../hooks/useApplication';

export default function loadApplication(WrapperComponent) {
  @inject('store')
  @observer
  class Wrapper extends Component {
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
      const { store, match } = this.props;
      const { params } = match;
      const checkrDirectAClientId =
        store.router.location.pathname &&
        store.router.location.pathname.includes('checkr') &&
        !store.router.location.pathname.includes('checkr-goodhire') &&
        store.env.CHECKR_DIRECT_A_CLIENT_ID;

      const goodhireClientId =
        store.router.location.pathname &&
        store.router.location.pathname.includes('checkr-goodhire') &&
        store.env.GOODHIRE_WEBSITE_CLIENT_ID;

      if (!store.application) {
        const clientId = params.client_id || store.auth?.clientId;
        store.loadDependencies(
          checkrDirectAClientId || goodhireClientId || clientId,
        );
      }
    }

    render() {
      const { store } = this.props;
      if (store.loading) {
        return <Loading />;
      }

      if (!store.application) {
        return (
          <pre className='error-msg text-danger'>
            <b>Error:</b> invalid client_id
          </pre>
        );
      }
      return (
        <ApplicationProvider value={store.application}>
          <WrapperComponent {...this.props} />
        </ApplicationProvider>
      );
    }
  }
  Wrapper.wrappedComponent.propTypes = {
    store: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };
  return withRouter(Wrapper);
}
