import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import CheckrDirectCreditCardPaymentForm from './CheckrDirectCreditCardPaymentForm';
import { creditCardInfo, paymentInfo } from './CheckrDirectConfig';
import ReactTooltip from 'react-tooltip';
import * as Form from '../../form';
import CheckrDirectProgressBar from './CheckrDirectProgressBar';
import { CardHeader, Typography } from '@mui/material/';

import { Lock } from '@mui/icons-material';
import { PAYMENT } from '../../../constants/identifiers';

@inject('store')
@observer
export default class CheckrDirectPaymentA extends Component {
  componentDidMount() {
    this.store.performanceObserver.setEndLoadTime();
    window.scrollTo(0, 0);
    this.store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Payment Information Viewed',
      { 'Self Serve Signup Version': 'Simplify Order Page' },
    );
  }

  constructor(props) {
    super(props);
    const { store } = this.props;
    this.store = store;

    // If the user's name is in the AuthStore,
    // use that as a default for the name-on-card
    this.store.creditCardPayment.setCardHolderName();

    // default to credit card
    this.state = {
      form: this.store.creditCardPayment,
      PaymentForm: CheckrDirectCreditCardPaymentForm,
      showTooltip: false,
      showIntlText: store.application.packages?.some(p => p.international_only),
      showPromoCodeText:
        !this.store.isGoodhireWebsite && !this.store.promotionDisabled,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    const { showTooltip } = this.state;
    this.setState({
      showTooltip: !showTooltip,
    });
  }

  render() {
    const { PaymentForm } = this.state;
    return (
      <div>
        <div>
          <CheckrDirectProgressBar activeStep={1} />
        </div>
        <div className='row'>
          <div
            className={
              'col-md-12 px-5 ' +
              (this.store.promotionDisabled ? 'main-small' : 'main')
            }
          >
            <CardHeader
              width='725px'
              height='96px'
              left='358px'
              top='152px'
              title={
                <Typography
                  textAlign='center'
                  fontWeight='600'
                  lineHeight='115%'
                  width='100%'
                  fontSize='42px'
                  color='#001730'
                  fontFamily='ArgentCFRegular'
                >
                  Enter your payment information
                </Typography>
              }
              subheader={
                <Typography
                  textAlign='center'
                  paddingTop='10px'
                  fontWeight='400'
                  lineHeight='150%'
                  fontSize='16px'
                  color='#576C82'
                  fontFamily='National2Regular'
                >
                  Your account is free. You only pay for completed searches.
                  <span
                    data-tip={paymentInfo}
                    data-for='cd-tooltip2'
                    className='cd-tooltip-icon'
                  >
                    <i
                      className='fa fa-question-circle'
                      data-testid={PAYMENT.MISC.headerTooltip}
                      onClick={this.toggleTooltip}
                    />
                    <ReactTooltip
                      place='right'
                      id='cd-tooltip2'
                      role='tooltip'
                      className='text-left cd-tooltip tooltip-shadow'
                      clickable
                      effect='solid'
                      type='light'
                      html
                    />
                  </span>
                </Typography>
              }
            />
          </div>
        </div>

        <div className='col-md-6 mx-auto'>
          <div className='mb-4 left-div-active'>
            <div className='right-div-inactive'>
              <ul className='nav nav-payment-methods text-center'>
                <li className='nav-item'>
                  <div className='nav-link active'>
                    <span
                      className='cd-form-label'
                      data-testid={PAYMENT.MISC.credit}
                    >
                      CREDIT / DEBIT
                    </span>
                    &nbsp;
                    <span
                      data-tip={creditCardInfo}
                      data-for='cd-tooltip'
                      className='cd-tooltip-icon'
                    >
                      <i
                        className='fa fa-question-circle'
                        onClick={this.toggleTooltip}
                      />
                      <ReactTooltip
                        place='bottom'
                        id='cd-tooltip'
                        className='text-left cd-tooltip tooltip-shadow'
                        clickable
                        effect='solid'
                        type='light'
                        html
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <Form.Form form={this.state.form}>
            <PaymentForm store={this.store} form={this.state.form} />

            <div className='d-flex justify-content-center my-5'>
              <Form.Submit
                customClassName='cd-btn text-center'
                text='Continue'
                loading={this.state.form.loading}
                dataTestId={PAYMENT.MISC.nextBtn}
              />
            </div>

            <div
              className='d-flex justify-content-center my-5'
              style={{
                fontFamily: 'OpenSans',
                color: '#576C82',
                fontSize: '12px',
              }}
            >
              <Lock
                style={{
                  fontSize: '0.6rem',
                  marginRight: '4px',
                  marginTop: '4px',
                  color: '#576C82',
                }}
              />
              Secure SSL Encrypted Form
            </div>
          </Form.Form>
        </div>
      </div>
    );
  }
}

CheckrDirectPaymentA.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
