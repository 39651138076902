const allGeos = [
  {
    id: '46a0d1284cd42257ccd44fe2',
    uri: '/v1/geos/46a0d1284cd42257ccd44fe2',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Andorra',
    city: null,
    state: null,
    country: 'AD',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '94b30b75359e49d9126dafc7',
    uri: '/v1/geos/94b30b75359e49d9126dafc7',
    created_at: '2023-01-18T19:04:48Z',
    name: 'United Arab Emirates',
    city: null,
    state: null,
    country: 'AE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '80879f11b53f59b6b7494f49',
    uri: '/v1/geos/80879f11b53f59b6b7494f49',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Afghanistan',
    city: null,
    state: null,
    country: 'AF',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '21e23663968b79973b6247e6',
    uri: '/v1/geos/21e23663968b79973b6247e6',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Antigua and Barbuda',
    city: null,
    state: null,
    country: 'AG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '3a37e7282de767564c47d9fe',
    uri: '/v1/geos/3a37e7282de767564c47d9fe',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Anguilla',
    city: null,
    state: null,
    country: 'AI',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '313d2e3da7518a50a5261910',
    uri: '/v1/geos/313d2e3da7518a50a5261910',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Albania',
    city: null,
    state: null,
    country: 'AL',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '40d4db9de678316e6a3360e9',
    uri: '/v1/geos/40d4db9de678316e6a3360e9',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Armenia',
    city: null,
    state: null,
    country: 'AM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '2060090990d3219679648b4e',
    uri: '/v1/geos/2060090990d3219679648b4e',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Netherlands Antilles',
    city: null,
    state: null,
    country: 'AN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd313c81dd4478ccf1b08aec0',
    uri: '/v1/geos/d313c81dd4478ccf1b08aec0',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Angola',
    city: null,
    state: null,
    country: 'AO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '185b77b42f6a43a85577487c',
    uri: '/v1/geos/185b77b42f6a43a85577487c',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Antarctica',
    city: null,
    state: null,
    country: 'AQ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'e02124bb411ffcb99cbfeb18',
    uri: '/v1/geos/e02124bb411ffcb99cbfeb18',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Argentina',
    city: null,
    state: null,
    country: 'AR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '9a626e87b81a753da8938d5c',
    uri: '/v1/geos/9a626e87b81a753da8938d5c',
    created_at: '2023-01-18T19:04:48Z',
    name: 'American Samoa',
    city: null,
    state: null,
    country: 'AS',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'eb16b904fd7b789280d1ef34',
    uri: '/v1/geos/eb16b904fd7b789280d1ef34',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Austria',
    city: null,
    state: null,
    country: 'AT',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '84f32624caa9751c6801ba5b',
    uri: '/v1/geos/84f32624caa9751c6801ba5b',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Australia',
    city: null,
    state: null,
    country: 'AU',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '84850a5f54872b962013dd7b',
    uri: '/v1/geos/84850a5f54872b962013dd7b',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Aruba',
    city: null,
    state: null,
    country: 'AW',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '48d37e93b35603a108482e73',
    uri: '/v1/geos/48d37e93b35603a108482e73',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Åland Islands',
    city: null,
    state: null,
    country: 'AX',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '79b0b8dae8b9bd2515a1ee19',
    uri: '/v1/geos/79b0b8dae8b9bd2515a1ee19',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Azerbaijan',
    city: null,
    state: null,
    country: 'AZ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '79faeb5dd0a31db6475c6ca8',
    uri: '/v1/geos/79faeb5dd0a31db6475c6ca8',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Bosnia and Herzegovina',
    city: null,
    state: null,
    country: 'BA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'be5dff7be02ea0cc36b45c94',
    uri: '/v1/geos/be5dff7be02ea0cc36b45c94',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Barbados',
    city: null,
    state: null,
    country: 'BB',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '4510ed5a9b422193aca0a2da',
    uri: '/v1/geos/4510ed5a9b422193aca0a2da',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Bangladesh',
    city: null,
    state: null,
    country: 'BD',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6d32777607834162648ea2e2',
    uri: '/v1/geos/6d32777607834162648ea2e2',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Belgium',
    city: null,
    state: null,
    country: 'BE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6bbeb2e0006f767c87990bcb',
    uri: '/v1/geos/6bbeb2e0006f767c87990bcb',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Burkina Faso',
    city: null,
    state: null,
    country: 'BF',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '3105fed9362daa0fee72ebbc',
    uri: '/v1/geos/3105fed9362daa0fee72ebbc',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Bulgaria',
    city: null,
    state: null,
    country: 'BG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '459cad277c53ec0941d5a41d',
    uri: '/v1/geos/459cad277c53ec0941d5a41d',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Bahrain',
    city: null,
    state: null,
    country: 'BH',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '9964adc7ca21caf9a6adb33f',
    uri: '/v1/geos/9964adc7ca21caf9a6adb33f',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Burundi',
    city: null,
    state: null,
    country: 'BI',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '76a8b6cc3d338307d6e75314',
    uri: '/v1/geos/76a8b6cc3d338307d6e75314',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Benin',
    city: null,
    state: null,
    country: 'BJ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'fe1d2d2ab60ea856db226a51',
    uri: '/v1/geos/fe1d2d2ab60ea856db226a51',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Saint Barthélemy',
    city: null,
    state: null,
    country: 'BL',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a0b03b85382789623771c47b',
    uri: '/v1/geos/a0b03b85382789623771c47b',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Bermuda',
    city: null,
    state: null,
    country: 'BM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '954155a40c59909e88235baa',
    uri: '/v1/geos/954155a40c59909e88235baa',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Brunei Darussalam',
    city: null,
    state: null,
    country: 'BN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b3b57d8ff61b2961f4266dd1',
    uri: '/v1/geos/b3b57d8ff61b2961f4266dd1',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Bolivia',
    city: null,
    state: null,
    country: 'BO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '32766d2463b9ef0d2ef72f37',
    uri: '/v1/geos/32766d2463b9ef0d2ef72f37',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Bonaire, Sint Eustatius and Saba',
    city: null,
    state: null,
    country: 'BQ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '05d3dc8d846ed46fa95ebd82',
    uri: '/v1/geos/05d3dc8d846ed46fa95ebd82',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Brazil',
    city: null,
    state: null,
    country: 'BR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'f78e65fbf6014a41ebbee21a',
    uri: '/v1/geos/f78e65fbf6014a41ebbee21a',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Bahamas',
    city: null,
    state: null,
    country: 'BS',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5e5d8f1495abc1f78844f90e',
    uri: '/v1/geos/5e5d8f1495abc1f78844f90e',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Bhutan',
    city: null,
    state: null,
    country: 'BT',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '47779b78ac91e1ab39732c02',
    uri: '/v1/geos/47779b78ac91e1ab39732c02',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Bouvet Island',
    city: null,
    state: null,
    country: 'BV',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '27b51e15f19e0eac8d1129da',
    uri: '/v1/geos/27b51e15f19e0eac8d1129da',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Botswana',
    city: null,
    state: null,
    country: 'BW',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '055525ce312ee88787c7be84',
    uri: '/v1/geos/055525ce312ee88787c7be84',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Belarus',
    city: null,
    state: null,
    country: 'BY',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '252875ca59135d93a640fc71',
    uri: '/v1/geos/252875ca59135d93a640fc71',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Belize',
    city: null,
    state: null,
    country: 'BZ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '8a561fd33a679d16bc3957eb',
    uri: '/v1/geos/8a561fd33a679d16bc3957eb',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Canada',
    city: null,
    state: null,
    country: 'CA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'fc3f99c11f1410049d1cc8c4',
    uri: '/v1/geos/fc3f99c11f1410049d1cc8c4',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Cocos (Keeling) Islands',
    city: null,
    state: null,
    country: 'CC',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'cb04f31ef6e520a1fcc01bdf',
    uri: '/v1/geos/cb04f31ef6e520a1fcc01bdf',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Congo, The Democratic Republic Of The',
    city: null,
    state: null,
    country: 'CD',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '47c689bb2dc262707a51ce61',
    uri: '/v1/geos/47c689bb2dc262707a51ce61',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Central African Republic',
    city: null,
    state: null,
    country: 'CF',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '22f6d9b96787644f1f4f38dc',
    uri: '/v1/geos/22f6d9b96787644f1f4f38dc',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Congo',
    city: null,
    state: null,
    country: 'CG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '9eaf86d7cfa1e45d33f1cdc8',
    uri: '/v1/geos/9eaf86d7cfa1e45d33f1cdc8',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Switzerland',
    city: null,
    state: null,
    country: 'CH',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'cd19b7530451720ce73b27ba',
    uri: '/v1/geos/cd19b7530451720ce73b27ba',
    created_at: '2023-01-18T19:04:48Z',
    name: "Côte D'Ivoire",
    city: null,
    state: null,
    country: 'CI',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '04ef9d5b0bf24da5239f4173',
    uri: '/v1/geos/04ef9d5b0bf24da5239f4173',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Cook Islands',
    city: null,
    state: null,
    country: 'CK',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a3bc8af58e924b79030d6595',
    uri: '/v1/geos/a3bc8af58e924b79030d6595',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Chile',
    city: null,
    state: null,
    country: 'CL',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'bd75a48fda9a80c2dbd56038',
    uri: '/v1/geos/bd75a48fda9a80c2dbd56038',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Cameroon',
    city: null,
    state: null,
    country: 'CM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a16887eb6b8b73f8c71e179a',
    uri: '/v1/geos/a16887eb6b8b73f8c71e179a',
    created_at: '2023-01-18T19:04:48Z',
    name: 'China',
    city: null,
    state: null,
    country: 'CN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'f6bb4cec3a74f7fcc92cc830',
    uri: '/v1/geos/f6bb4cec3a74f7fcc92cc830',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Colombia',
    city: null,
    state: null,
    country: 'CO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd54a6e823837292b18b8b8a3',
    uri: '/v1/geos/d54a6e823837292b18b8b8a3',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Costa Rica',
    city: null,
    state: null,
    country: 'CR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '57994a0aaafe6164f87d4332',
    uri: '/v1/geos/57994a0aaafe6164f87d4332',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Cuba',
    city: null,
    state: null,
    country: 'CU',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '2189952000f73896bcc9aa81',
    uri: '/v1/geos/2189952000f73896bcc9aa81',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Cape Verde',
    city: null,
    state: null,
    country: 'CV',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5437ac3a8ed7620227f778dd',
    uri: '/v1/geos/5437ac3a8ed7620227f778dd',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Curaçao',
    city: null,
    state: null,
    country: 'CW',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '4fc58359d540ab62bbf461b5',
    uri: '/v1/geos/4fc58359d540ab62bbf461b5',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Christmas Island',
    city: null,
    state: null,
    country: 'CX',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'aac5cc1615cda82c80015e7f',
    uri: '/v1/geos/aac5cc1615cda82c80015e7f',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Cyprus',
    city: null,
    state: null,
    country: 'CY',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6ae55a49ce936e140a56b52c',
    uri: '/v1/geos/6ae55a49ce936e140a56b52c',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Czech Republic',
    city: null,
    state: null,
    country: 'CZ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd8a7db93b60fadefef83911e',
    uri: '/v1/geos/d8a7db93b60fadefef83911e',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Germany',
    city: null,
    state: null,
    country: 'DE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '736e88c82c491b2df1d3c00d',
    uri: '/v1/geos/736e88c82c491b2df1d3c00d',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Djibouti',
    city: null,
    state: null,
    country: 'DJ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '64913c477740ce12d510f35b',
    uri: '/v1/geos/64913c477740ce12d510f35b',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Denmark',
    city: null,
    state: null,
    country: 'DK',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a43ce8843b7ab05f0feae325',
    uri: '/v1/geos/a43ce8843b7ab05f0feae325',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Dominica',
    city: null,
    state: null,
    country: 'DM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '1f43332293cd660fb5d21414',
    uri: '/v1/geos/1f43332293cd660fb5d21414',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Dominican Republic',
    city: null,
    state: null,
    country: 'DO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6937f8b025342d9aec0a882b',
    uri: '/v1/geos/6937f8b025342d9aec0a882b',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Algeria',
    city: null,
    state: null,
    country: 'DZ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '1c28a17b5fcce1d08e7cd837',
    uri: '/v1/geos/1c28a17b5fcce1d08e7cd837',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Ecuador',
    city: null,
    state: null,
    country: 'EC',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'f77d2ba6639b7167223def6f',
    uri: '/v1/geos/f77d2ba6639b7167223def6f',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Estonia',
    city: null,
    state: null,
    country: 'EE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'e7e0f036e7807fe86ea4950d',
    uri: '/v1/geos/e7e0f036e7807fe86ea4950d',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Egypt',
    city: null,
    state: null,
    country: 'EG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd7d2805d1bdbbc93a334ce4a',
    uri: '/v1/geos/d7d2805d1bdbbc93a334ce4a',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Western Sahara',
    city: null,
    state: null,
    country: 'EH',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '95c7885782d6891b3acf5e97',
    uri: '/v1/geos/95c7885782d6891b3acf5e97',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Eritrea',
    city: null,
    state: null,
    country: 'ER',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '7cdef69d27f3a60fb8eee155',
    uri: '/v1/geos/7cdef69d27f3a60fb8eee155',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Spain',
    city: null,
    state: null,
    country: 'ES',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '4cabc2d6ffca4a29236d1067',
    uri: '/v1/geos/4cabc2d6ffca4a29236d1067',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Ethiopia',
    city: null,
    state: null,
    country: 'ET',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0e88de7c84f7d0f880b6cd0d',
    uri: '/v1/geos/0e88de7c84f7d0f880b6cd0d',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Finland',
    city: null,
    state: null,
    country: 'FI',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'eb1161201a6298639a3fa92f',
    uri: '/v1/geos/eb1161201a6298639a3fa92f',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Fiji',
    city: null,
    state: null,
    country: 'FJ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'e92ede7ec9c13e6165f3f84d',
    uri: '/v1/geos/e92ede7ec9c13e6165f3f84d',
    created_at: '2023-01-18T19:04:48Z',
    name: 'Falkland Islands (Malvinas)',
    city: null,
    state: null,
    country: 'FK',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '67b53608683f4d6e2bd02902',
    uri: '/v1/geos/67b53608683f4d6e2bd02902',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Micronesia, Federated States Of',
    city: null,
    state: null,
    country: 'FM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ebb1b0c4a9b397884ffc4f9a',
    uri: '/v1/geos/ebb1b0c4a9b397884ffc4f9a',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Faroe Islands',
    city: null,
    state: null,
    country: 'FO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '4ee4bd82f169e92656b16633',
    uri: '/v1/geos/4ee4bd82f169e92656b16633',
    created_at: '2023-01-18T19:04:49Z',
    name: 'France',
    city: null,
    state: null,
    country: 'FR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '646a814da5f40adfc4e4b062',
    uri: '/v1/geos/646a814da5f40adfc4e4b062',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Gabon',
    city: null,
    state: null,
    country: 'GA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ea0333ff5f6855e8e34dc928',
    uri: '/v1/geos/ea0333ff5f6855e8e34dc928',
    created_at: '2023-01-18T19:04:49Z',
    name: 'United Kingdom',
    city: null,
    state: null,
    country: 'GB',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '7e595ce2f33d8224db8ca970',
    uri: '/v1/geos/7e595ce2f33d8224db8ca970',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Grenada',
    city: null,
    state: null,
    country: 'GD',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'f64d9409535654bfb94c23e2',
    uri: '/v1/geos/f64d9409535654bfb94c23e2',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Georgia',
    city: null,
    state: null,
    country: 'GE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'dcf05db7dbbecdcea56c13cf',
    uri: '/v1/geos/dcf05db7dbbecdcea56c13cf',
    created_at: '2023-01-18T19:04:49Z',
    name: 'French Guiana',
    city: null,
    state: null,
    country: 'GF',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '1ab7e400d33cce9eaeb2f892',
    uri: '/v1/geos/1ab7e400d33cce9eaeb2f892',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Guernsey',
    city: null,
    state: null,
    country: 'GG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a63293ad6e2857656af9468a',
    uri: '/v1/geos/a63293ad6e2857656af9468a',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Ghana',
    city: null,
    state: null,
    country: 'GH',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5f4d26955308eb134dc13cea',
    uri: '/v1/geos/5f4d26955308eb134dc13cea',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Gibraltar',
    city: null,
    state: null,
    country: 'GI',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a1b61dfba3066525fa428aad',
    uri: '/v1/geos/a1b61dfba3066525fa428aad',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Greenland',
    city: null,
    state: null,
    country: 'GL',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '7e3eaf922a0dbf8a6c9e52b2',
    uri: '/v1/geos/7e3eaf922a0dbf8a6c9e52b2',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Gambia',
    city: null,
    state: null,
    country: 'GM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '67477e71cf42b5024bcc8a12',
    uri: '/v1/geos/67477e71cf42b5024bcc8a12',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Guinea',
    city: null,
    state: null,
    country: 'GN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c1ab7c5b6b9834bead932944',
    uri: '/v1/geos/c1ab7c5b6b9834bead932944',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Guadeloupe',
    city: null,
    state: null,
    country: 'GP',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '7867aa874630fd171d3ef0ba',
    uri: '/v1/geos/7867aa874630fd171d3ef0ba',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Equatorial Guinea',
    city: null,
    state: null,
    country: 'GQ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '2d8ad5f1c33bf7db8359eee7',
    uri: '/v1/geos/2d8ad5f1c33bf7db8359eee7',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Greece',
    city: null,
    state: null,
    country: 'GR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a00edf2d72af6f8b7d1cdc35',
    uri: '/v1/geos/a00edf2d72af6f8b7d1cdc35',
    created_at: '2023-01-18T19:04:49Z',
    name: 'South Georgia and the South Sandwich Islands',
    city: null,
    state: null,
    country: 'GS',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd6550a3f5b6352272011b12b',
    uri: '/v1/geos/d6550a3f5b6352272011b12b',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Guatemala',
    city: null,
    state: null,
    country: 'GT',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '33ce733997abe896cc32a8b0',
    uri: '/v1/geos/33ce733997abe896cc32a8b0',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Guam',
    city: null,
    state: null,
    country: 'GU',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '3dd2f2fc857e811e8782df16',
    uri: '/v1/geos/3dd2f2fc857e811e8782df16',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Guinea-Bissau',
    city: null,
    state: null,
    country: 'GW',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '83143756504410e9bd50061d',
    uri: '/v1/geos/83143756504410e9bd50061d',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Guyana',
    city: null,
    state: null,
    country: 'GY',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0202526919a5f8b969ee35c9',
    uri: '/v1/geos/0202526919a5f8b969ee35c9',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Hong Kong',
    city: null,
    state: null,
    country: 'HK',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '53ecb364981cf6cee7842b04',
    uri: '/v1/geos/53ecb364981cf6cee7842b04',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Heard and McDonald Islands',
    city: null,
    state: null,
    country: 'HM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5082cdb284c538805a86ff43',
    uri: '/v1/geos/5082cdb284c538805a86ff43',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Honduras',
    city: null,
    state: null,
    country: 'HN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'e99ac831bae12439d5a7aff9',
    uri: '/v1/geos/e99ac831bae12439d5a7aff9',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Croatia',
    city: null,
    state: null,
    country: 'HR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'dad0bbb09c91a8f27782ee21',
    uri: '/v1/geos/dad0bbb09c91a8f27782ee21',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Haiti',
    city: null,
    state: null,
    country: 'HT',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'f99770822c28598b3c3386e8',
    uri: '/v1/geos/f99770822c28598b3c3386e8',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Hungary',
    city: null,
    state: null,
    country: 'HU',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c56be69f1bf97bec66976b2e',
    uri: '/v1/geos/c56be69f1bf97bec66976b2e',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Indonesia',
    city: null,
    state: null,
    country: 'ID',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '04e75cd8fbbb5ac61f764525',
    uri: '/v1/geos/04e75cd8fbbb5ac61f764525',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Ireland',
    city: null,
    state: null,
    country: 'IE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ed4e03ccb4e749d04eceb1ec',
    uri: '/v1/geos/ed4e03ccb4e749d04eceb1ec',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Israel',
    city: null,
    state: null,
    country: 'IL',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c69b182843a5cf2d87c9796f',
    uri: '/v1/geos/c69b182843a5cf2d87c9796f',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Isle of Man',
    city: null,
    state: null,
    country: 'IM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '3007fa42d60707bf1f9cb334',
    uri: '/v1/geos/3007fa42d60707bf1f9cb334',
    created_at: '2023-01-18T19:04:49Z',
    name: 'India',
    city: null,
    state: null,
    country: 'IN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '85689b40ba28b39163f9bb00',
    uri: '/v1/geos/85689b40ba28b39163f9bb00',
    created_at: '2023-01-18T19:04:49Z',
    name: 'British Indian Ocean Territory',
    city: null,
    state: null,
    country: 'IO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'faa39d88d543f71ec5541799',
    uri: '/v1/geos/faa39d88d543f71ec5541799',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Iraq',
    city: null,
    state: null,
    country: 'IQ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'dfcbd6ec49e20be27308cdb7',
    uri: '/v1/geos/dfcbd6ec49e20be27308cdb7',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Iran, Islamic Republic Of',
    city: null,
    state: null,
    country: 'IR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '1658741a4092fa20c50f9ec8',
    uri: '/v1/geos/1658741a4092fa20c50f9ec8',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Iceland',
    city: null,
    state: null,
    country: 'IS',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'dbe2c3a47d27f7565adb2f1a',
    uri: '/v1/geos/dbe2c3a47d27f7565adb2f1a',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Italy',
    city: null,
    state: null,
    country: 'IT',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0ebe8b3bc7741f707c6e50c6',
    uri: '/v1/geos/0ebe8b3bc7741f707c6e50c6',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Jersey',
    city: null,
    state: null,
    country: 'JE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ac5e2553598da0416bbe0577',
    uri: '/v1/geos/ac5e2553598da0416bbe0577',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Jamaica',
    city: null,
    state: null,
    country: 'JM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '8c0fd2b7861309b8540f3401',
    uri: '/v1/geos/8c0fd2b7861309b8540f3401',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Jordan',
    city: null,
    state: null,
    country: 'JO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b5499c45e3082470df3150f6',
    uri: '/v1/geos/b5499c45e3082470df3150f6',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Japan',
    city: null,
    state: null,
    country: 'JP',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '041bc7fcff48af7ced3c2791',
    uri: '/v1/geos/041bc7fcff48af7ced3c2791',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Kenya',
    city: null,
    state: null,
    country: 'KE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '590f5c975c5a920002f8b7ca',
    uri: '/v1/geos/590f5c975c5a920002f8b7ca',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Kyrgyzstan',
    city: null,
    state: null,
    country: 'KG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a621cb26f1c020af21a3886d',
    uri: '/v1/geos/a621cb26f1c020af21a3886d',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Cambodia',
    city: null,
    state: null,
    country: 'KH',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '68f7b952189ccd84fae5f475',
    uri: '/v1/geos/68f7b952189ccd84fae5f475',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Kiribati',
    city: null,
    state: null,
    country: 'KI',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '60dc62567ae8f9d85720106b',
    uri: '/v1/geos/60dc62567ae8f9d85720106b',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Comoros',
    city: null,
    state: null,
    country: 'KM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '1eafeb5730dcf0b246ba0058',
    uri: '/v1/geos/1eafeb5730dcf0b246ba0058',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Saint Kitts And Nevis',
    city: null,
    state: null,
    country: 'KN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '97c51cffe66c7214ecbf63dd',
    uri: '/v1/geos/97c51cffe66c7214ecbf63dd',
    created_at: '2023-01-18T19:04:49Z',
    name: "Korea, Democratic People's Republic Of",
    city: null,
    state: null,
    country: 'KP',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c0b672812f60273412fd1b3e',
    uri: '/v1/geos/c0b672812f60273412fd1b3e',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Korea, Republic of',
    city: null,
    state: null,
    country: 'KR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '007895c16615d4a801baf088',
    uri: '/v1/geos/007895c16615d4a801baf088',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Kuwait',
    city: null,
    state: null,
    country: 'KW',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '77c2fc91c8b248fd2e9f09bf',
    uri: '/v1/geos/77c2fc91c8b248fd2e9f09bf',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Cayman Islands',
    city: null,
    state: null,
    country: 'KY',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '31530e6f09bb1e6aea3d0365',
    uri: '/v1/geos/31530e6f09bb1e6aea3d0365',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Kazakhstan',
    city: null,
    state: null,
    country: 'KZ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b502f53de0518c11a30cfc7d',
    uri: '/v1/geos/b502f53de0518c11a30cfc7d',
    created_at: '2023-01-18T19:04:49Z',
    name: "Lao People's Democratic Republic",
    city: null,
    state: null,
    country: 'LA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '73ff228ac3afbc5820bd959d',
    uri: '/v1/geos/73ff228ac3afbc5820bd959d',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Lebanon',
    city: null,
    state: null,
    country: 'LB',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '99dc3bc2f94f86271b6e585d',
    uri: '/v1/geos/99dc3bc2f94f86271b6e585d',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Saint Lucia',
    city: null,
    state: null,
    country: 'LC',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0a1acc50974b27278deefc86',
    uri: '/v1/geos/0a1acc50974b27278deefc86',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Liechtenstein',
    city: null,
    state: null,
    country: 'LI',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '3fdd4645ed69446c398e1081',
    uri: '/v1/geos/3fdd4645ed69446c398e1081',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Sri Lanka',
    city: null,
    state: null,
    country: 'LK',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '3c3807b8bb428b69e7f8e3f3',
    uri: '/v1/geos/3c3807b8bb428b69e7f8e3f3',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Liberia',
    city: null,
    state: null,
    country: 'LR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'fe25ed4e82ef77a0b01b994f',
    uri: '/v1/geos/fe25ed4e82ef77a0b01b994f',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Lesotho',
    city: null,
    state: null,
    country: 'LS',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '3d0368846ef131ff7b5ed2a1',
    uri: '/v1/geos/3d0368846ef131ff7b5ed2a1',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Lithuania',
    city: null,
    state: null,
    country: 'LT',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '832063da9bbfdf70385f3bab',
    uri: '/v1/geos/832063da9bbfdf70385f3bab',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Luxembourg',
    city: null,
    state: null,
    country: 'LU',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'fd9e07a27353f51f251bda95',
    uri: '/v1/geos/fd9e07a27353f51f251bda95',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Latvia',
    city: null,
    state: null,
    country: 'LV',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '64401be1fc15994e9b47541b',
    uri: '/v1/geos/64401be1fc15994e9b47541b',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Libya',
    city: null,
    state: null,
    country: 'LY',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '32cf7f44232587c80a3c5c3e',
    uri: '/v1/geos/32cf7f44232587c80a3c5c3e',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Morocco',
    city: null,
    state: null,
    country: 'MA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ea5adc95eaf2a6aaec7ffbd2',
    uri: '/v1/geos/ea5adc95eaf2a6aaec7ffbd2',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Monaco',
    city: null,
    state: null,
    country: 'MC',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c8649a6b7869df2674409c94',
    uri: '/v1/geos/c8649a6b7869df2674409c94',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Moldova, Republic of',
    city: null,
    state: null,
    country: 'MD',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'f8f2599c70739399beb80c15',
    uri: '/v1/geos/f8f2599c70739399beb80c15',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Montenegro',
    city: null,
    state: null,
    country: 'ME',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '9477d8ceb4b5fd5584f354f4',
    uri: '/v1/geos/9477d8ceb4b5fd5584f354f4',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Saint Martin',
    city: null,
    state: null,
    country: 'MF',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '7c77c5ffe77eaeb30237eb68',
    uri: '/v1/geos/7c77c5ffe77eaeb30237eb68',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Madagascar',
    city: null,
    state: null,
    country: 'MG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'add759092cef5bc8daf5695e',
    uri: '/v1/geos/add759092cef5bc8daf5695e',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Marshall Islands',
    city: null,
    state: null,
    country: 'MH',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '30c5a65a8422684338572ad2',
    uri: '/v1/geos/30c5a65a8422684338572ad2',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Macedonia, the Former Yugoslav Republic Of',
    city: null,
    state: null,
    country: 'MK',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '7eb29ead4adfd1ce7e36b7a8',
    uri: '/v1/geos/7eb29ead4adfd1ce7e36b7a8',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Mali',
    city: null,
    state: null,
    country: 'ML',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6b3c6b2802a9a20d66e0db97',
    uri: '/v1/geos/6b3c6b2802a9a20d66e0db97',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Myanmar',
    city: null,
    state: null,
    country: 'MM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '24336d2fc2b6044b419360df',
    uri: '/v1/geos/24336d2fc2b6044b419360df',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Mongolia',
    city: null,
    state: null,
    country: 'MN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a9ddcb1aaa5ace1acb066b58',
    uri: '/v1/geos/a9ddcb1aaa5ace1acb066b58',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Macao',
    city: null,
    state: null,
    country: 'MO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '599acfdde4ae9e20e4da7b7b',
    uri: '/v1/geos/599acfdde4ae9e20e4da7b7b',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Northern Mariana Islands',
    city: null,
    state: null,
    country: 'MP',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '994ffd7d8eb0564aec85f314',
    uri: '/v1/geos/994ffd7d8eb0564aec85f314',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Martinique',
    city: null,
    state: null,
    country: 'MQ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '22e1fe90ebfb33898e236386',
    uri: '/v1/geos/22e1fe90ebfb33898e236386',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Mauritania',
    city: null,
    state: null,
    country: 'MR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '785cc3a5e97d4fd18ab3c50a',
    uri: '/v1/geos/785cc3a5e97d4fd18ab3c50a',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Montserrat',
    city: null,
    state: null,
    country: 'MS',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c310a38d4349a7b28d8b7f30',
    uri: '/v1/geos/c310a38d4349a7b28d8b7f30',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Malta',
    city: null,
    state: null,
    country: 'MT',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6bce5dd7c1b11df833f91633',
    uri: '/v1/geos/6bce5dd7c1b11df833f91633',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Mauritius',
    city: null,
    state: null,
    country: 'MU',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '7dc9aeef686043c0d857604c',
    uri: '/v1/geos/7dc9aeef686043c0d857604c',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Maldives',
    city: null,
    state: null,
    country: 'MV',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '519cc1985f6baa3b1e5efd6a',
    uri: '/v1/geos/519cc1985f6baa3b1e5efd6a',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Malawi',
    city: null,
    state: null,
    country: 'MW',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b5a38a7b4fbdfff293103662',
    uri: '/v1/geos/b5a38a7b4fbdfff293103662',
    created_at: '2023-01-18T19:04:49Z',
    name: 'Mexico',
    city: null,
    state: null,
    country: 'MX',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '69db8644633584d3527915c5',
    uri: '/v1/geos/69db8644633584d3527915c5',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Malaysia',
    city: null,
    state: null,
    country: 'MY',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0f503f4b8ece1957f48d9676',
    uri: '/v1/geos/0f503f4b8ece1957f48d9676',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Mozambique',
    city: null,
    state: null,
    country: 'MZ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ad268ae8c7932ace344284eb',
    uri: '/v1/geos/ad268ae8c7932ace344284eb',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Namibia',
    city: null,
    state: null,
    country: 'NA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c26ca442cd0f1b1c41c1973a',
    uri: '/v1/geos/c26ca442cd0f1b1c41c1973a',
    created_at: '2023-01-18T19:04:50Z',
    name: 'New Caledonia',
    city: null,
    state: null,
    country: 'NC',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '63663a1e8da5682794fdbe87',
    uri: '/v1/geos/63663a1e8da5682794fdbe87',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Niger',
    city: null,
    state: null,
    country: 'NE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '554c9dc61f2e06a5f187b0b9',
    uri: '/v1/geos/554c9dc61f2e06a5f187b0b9',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Norfolk Island',
    city: null,
    state: null,
    country: 'NF',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '9ab7a8dd0661b86a79448174',
    uri: '/v1/geos/9ab7a8dd0661b86a79448174',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Nigeria',
    city: null,
    state: null,
    country: 'NG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '811e0c728f4d9869a06d6054',
    uri: '/v1/geos/811e0c728f4d9869a06d6054',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Nicaragua',
    city: null,
    state: null,
    country: 'NI',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ec06f927ad99d74fd4acac11',
    uri: '/v1/geos/ec06f927ad99d74fd4acac11',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Netherlands',
    city: null,
    state: null,
    country: 'NL',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0ffd2fa5a8a2c2b781033aa7',
    uri: '/v1/geos/0ffd2fa5a8a2c2b781033aa7',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Norway',
    city: null,
    state: null,
    country: 'NO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd60b19640d504c735f5f2880',
    uri: '/v1/geos/d60b19640d504c735f5f2880',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Nepal',
    city: null,
    state: null,
    country: 'NP',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '23ae76fee7fc0b3f601b92e1',
    uri: '/v1/geos/23ae76fee7fc0b3f601b92e1',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Nauru',
    city: null,
    state: null,
    country: 'NR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '835610e936a66734185d3570',
    uri: '/v1/geos/835610e936a66734185d3570',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Niue',
    city: null,
    state: null,
    country: 'NU',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'dd54780857e39750e4ee94ca',
    uri: '/v1/geos/dd54780857e39750e4ee94ca',
    created_at: '2023-01-18T19:04:50Z',
    name: 'New Zealand',
    city: null,
    state: null,
    country: 'NZ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '3ba97a9995c4e756492524fd',
    uri: '/v1/geos/3ba97a9995c4e756492524fd',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Oman',
    city: null,
    state: null,
    country: 'OM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'fc77b5b9b1fc9975fdcd86e7',
    uri: '/v1/geos/fc77b5b9b1fc9975fdcd86e7',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Panama',
    city: null,
    state: null,
    country: 'PA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5ed61fd61e9633b22c77285b',
    uri: '/v1/geos/5ed61fd61e9633b22c77285b',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Peru',
    city: null,
    state: null,
    country: 'PE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'bdb7abdfef79c1848987b3b7',
    uri: '/v1/geos/bdb7abdfef79c1848987b3b7',
    created_at: '2023-01-18T19:04:50Z',
    name: 'French Polynesia',
    city: null,
    state: null,
    country: 'PF',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c330ea47928d05a5bdbdfc13',
    uri: '/v1/geos/c330ea47928d05a5bdbdfc13',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Papua New Guinea',
    city: null,
    state: null,
    country: 'PG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '69fe0e46713ef53c75c86abd',
    uri: '/v1/geos/69fe0e46713ef53c75c86abd',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Philippines',
    city: null,
    state: null,
    country: 'PH',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '7ac61fd115bcd7e5f9395c7b',
    uri: '/v1/geos/7ac61fd115bcd7e5f9395c7b',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Pakistan',
    city: null,
    state: null,
    country: 'PK',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'deeb4f11a7a66f0d68a2c252',
    uri: '/v1/geos/deeb4f11a7a66f0d68a2c252',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Poland',
    city: null,
    state: null,
    country: 'PL',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a5df9f2c94f6b58051488466',
    uri: '/v1/geos/a5df9f2c94f6b58051488466',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Saint Pierre And Miquelon',
    city: null,
    state: null,
    country: 'PM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'bfba52febf17a80860afb319',
    uri: '/v1/geos/bfba52febf17a80860afb319',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Pitcairn',
    city: null,
    state: null,
    country: 'PN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '3dbf729966f70a33ed0ac115',
    uri: '/v1/geos/3dbf729966f70a33ed0ac115',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Puerto Rico',
    city: null,
    state: null,
    country: 'PR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a557b7e279fdcb19c46605ef',
    uri: '/v1/geos/a557b7e279fdcb19c46605ef',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Palestinian Territory, Occupied',
    city: null,
    state: null,
    country: 'PS',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '75be896c51b315a2bbb7ccbc',
    uri: '/v1/geos/75be896c51b315a2bbb7ccbc',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Portugal',
    city: null,
    state: null,
    country: 'PT',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '62fb9e2ac6ef09bee521159c',
    uri: '/v1/geos/62fb9e2ac6ef09bee521159c',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Palau',
    city: null,
    state: null,
    country: 'PW',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '51b95e5482a9dfa6180bb141',
    uri: '/v1/geos/51b95e5482a9dfa6180bb141',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Paraguay',
    city: null,
    state: null,
    country: 'PY',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'eba0efc034c949bfac7f7449',
    uri: '/v1/geos/eba0efc034c949bfac7f7449',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Qatar',
    city: null,
    state: null,
    country: 'QA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0eba206abe8f0d4445d25868',
    uri: '/v1/geos/0eba206abe8f0d4445d25868',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Réunion',
    city: null,
    state: null,
    country: 'RE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'cf6eff77247f3c3f93cd7cf1',
    uri: '/v1/geos/cf6eff77247f3c3f93cd7cf1',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Romania',
    city: null,
    state: null,
    country: 'RO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '1674c8c6bb735843a9b3ae10',
    uri: '/v1/geos/1674c8c6bb735843a9b3ae10',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Serbia',
    city: null,
    state: null,
    country: 'RS',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5c94d4e167bc33bc9c55a9d8',
    uri: '/v1/geos/5c94d4e167bc33bc9c55a9d8',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Russian Federation',
    city: null,
    state: null,
    country: 'RU',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5dfc69893c3b64321e24996e',
    uri: '/v1/geos/5dfc69893c3b64321e24996e',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Rwanda',
    city: null,
    state: null,
    country: 'RW',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'dbb522faafec3d6007ceb5b7',
    uri: '/v1/geos/dbb522faafec3d6007ceb5b7',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Saudi Arabia',
    city: null,
    state: null,
    country: 'SA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'cee4df1a4e9c88989feb07f8',
    uri: '/v1/geos/cee4df1a4e9c88989feb07f8',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Solomon Islands',
    city: null,
    state: null,
    country: 'SB',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'e1de0f9a246556cc25aad5ee',
    uri: '/v1/geos/e1de0f9a246556cc25aad5ee',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Seychelles',
    city: null,
    state: null,
    country: 'SC',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '367392e8d90d235c4a759fd6',
    uri: '/v1/geos/367392e8d90d235c4a759fd6',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Sudan',
    city: null,
    state: null,
    country: 'SD',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '037986e830daee437056a244',
    uri: '/v1/geos/037986e830daee437056a244',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Sweden',
    city: null,
    state: null,
    country: 'SE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5fad3f6439df05cadb8ab58a',
    uri: '/v1/geos/5fad3f6439df05cadb8ab58a',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Singapore',
    city: null,
    state: null,
    country: 'SG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b2e577ebc5b090b54f7263b2',
    uri: '/v1/geos/b2e577ebc5b090b54f7263b2',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Saint Helena',
    city: null,
    state: null,
    country: 'SH',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6554748ebf00170f757f8fa1',
    uri: '/v1/geos/6554748ebf00170f757f8fa1',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Slovenia',
    city: null,
    state: null,
    country: 'SI',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '8e6f94a3a0a4b64b2e995060',
    uri: '/v1/geos/8e6f94a3a0a4b64b2e995060',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Svalbard And Jan Mayen',
    city: null,
    state: null,
    country: 'SJ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '507dbcc718e990bceaecc351',
    uri: '/v1/geos/507dbcc718e990bceaecc351',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Slovakia',
    city: null,
    state: null,
    country: 'SK',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b8037e59575b67ec7363263e',
    uri: '/v1/geos/b8037e59575b67ec7363263e',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Sierra Leone',
    city: null,
    state: null,
    country: 'SL',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a0a6d587a4118bbe915abaab',
    uri: '/v1/geos/a0a6d587a4118bbe915abaab',
    created_at: '2023-01-18T19:04:50Z',
    name: 'San Marino',
    city: null,
    state: null,
    country: 'SM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6630887dbdc7e2d8d08a524f',
    uri: '/v1/geos/6630887dbdc7e2d8d08a524f',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Senegal',
    city: null,
    state: null,
    country: 'SN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0c117e06a8aebfa2f42d601d',
    uri: '/v1/geos/0c117e06a8aebfa2f42d601d',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Somalia',
    city: null,
    state: null,
    country: 'SO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0e898aa8028e8922b8ca4ccc',
    uri: '/v1/geos/0e898aa8028e8922b8ca4ccc',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Suriname',
    city: null,
    state: null,
    country: 'SR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6ea50a5f8e4d59e1da42be1f',
    uri: '/v1/geos/6ea50a5f8e4d59e1da42be1f',
    created_at: '2023-01-18T19:04:50Z',
    name: 'South Sudan',
    city: null,
    state: null,
    country: 'SS',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a4664a9b1f9a51fc96a2eb04',
    uri: '/v1/geos/a4664a9b1f9a51fc96a2eb04',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Sao Tome and Principe',
    city: null,
    state: null,
    country: 'ST',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '60642743ea6619647de28ccc',
    uri: '/v1/geos/60642743ea6619647de28ccc',
    created_at: '2023-01-18T19:04:50Z',
    name: 'El Salvador',
    city: null,
    state: null,
    country: 'SV',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'bfa5c7b4d0e6cfd12338f28f',
    uri: '/v1/geos/bfa5c7b4d0e6cfd12338f28f',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Sint Maarten',
    city: null,
    state: null,
    country: 'SX',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c16960175c76634e9017cd8e',
    uri: '/v1/geos/c16960175c76634e9017cd8e',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Syrian Arab Republic',
    city: null,
    state: null,
    country: 'SY',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '4f4354e3be9c9647e07ce739',
    uri: '/v1/geos/4f4354e3be9c9647e07ce739',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Swaziland',
    city: null,
    state: null,
    country: 'SZ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '70a50d40cbec4ff6d9682efc',
    uri: '/v1/geos/70a50d40cbec4ff6d9682efc',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Turks and Caicos Islands',
    city: null,
    state: null,
    country: 'TC',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '05ab8b7c83441009fbbcb713',
    uri: '/v1/geos/05ab8b7c83441009fbbcb713',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Chad',
    city: null,
    state: null,
    country: 'TD',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd09ac20dbebed263246a126e',
    uri: '/v1/geos/d09ac20dbebed263246a126e',
    created_at: '2023-01-18T19:04:50Z',
    name: 'French Southern Territories',
    city: null,
    state: null,
    country: 'TF',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '45c5c42abb6a2c19a5c9035d',
    uri: '/v1/geos/45c5c42abb6a2c19a5c9035d',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Togo',
    city: null,
    state: null,
    country: 'TG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c582726831d2980c51f9a888',
    uri: '/v1/geos/c582726831d2980c51f9a888',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Thailand',
    city: null,
    state: null,
    country: 'TH',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b6e853bea2fa93f7ed952efa',
    uri: '/v1/geos/b6e853bea2fa93f7ed952efa',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Tajikistan',
    city: null,
    state: null,
    country: 'TJ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'f3ff0f17e3f2ba53606a18fd',
    uri: '/v1/geos/f3ff0f17e3f2ba53606a18fd',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Tokelau',
    city: null,
    state: null,
    country: 'TK',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '802c99c87cea2a2d0927ddd2',
    uri: '/v1/geos/802c99c87cea2a2d0927ddd2',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Timor-Leste',
    city: null,
    state: null,
    country: 'TL',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'f057f2931a0df806f76d4e2b',
    uri: '/v1/geos/f057f2931a0df806f76d4e2b',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Turkmenistan',
    city: null,
    state: null,
    country: 'TM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '01f8287a7d823366e2d3a3b7',
    uri: '/v1/geos/01f8287a7d823366e2d3a3b7',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Tunisia',
    city: null,
    state: null,
    country: 'TN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'be46dc75175ee2eb2fa20de1',
    uri: '/v1/geos/be46dc75175ee2eb2fa20de1',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Tonga',
    city: null,
    state: null,
    country: 'TO',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '99f414824b5f9e9e6d9e685e',
    uri: '/v1/geos/99f414824b5f9e9e6d9e685e',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Turkey',
    city: null,
    state: null,
    country: 'TR',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '1c09bf5a32528d2804aa5984',
    uri: '/v1/geos/1c09bf5a32528d2804aa5984',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Trinidad and Tobago',
    city: null,
    state: null,
    country: 'TT',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6753080b7eeec71f2e9438bc',
    uri: '/v1/geos/6753080b7eeec71f2e9438bc',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Tuvalu',
    city: null,
    state: null,
    country: 'TV',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5a61542bd81df146d0cffe9f',
    uri: '/v1/geos/5a61542bd81df146d0cffe9f',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Taiwan, Republic Of China',
    city: null,
    state: null,
    country: 'TW',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ebbbbbaa0b2b8c295cfe2a8c',
    uri: '/v1/geos/ebbbbbaa0b2b8c295cfe2a8c',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Tanzania, United Republic of',
    city: null,
    state: null,
    country: 'TZ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '8cdfb47f221dfd1f6c3222cc',
    uri: '/v1/geos/8cdfb47f221dfd1f6c3222cc',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Ukraine',
    city: null,
    state: null,
    country: 'UA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '301644479cc519c83034f629',
    uri: '/v1/geos/301644479cc519c83034f629',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Uganda',
    city: null,
    state: null,
    country: 'UG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '09b514329e714001b01cb04d',
    uri: '/v1/geos/09b514329e714001b01cb04d',
    created_at: '2023-01-18T19:04:50Z',
    name: 'United States Minor Outlying Islands',
    city: null,
    state: null,
    country: 'UM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a282eb68cb07c5e7304e39a0',
    uri: '/v1/geos/a282eb68cb07c5e7304e39a0',
    created_at: '2023-01-18T19:04:50Z',
    name: 'United States',
    city: null,
    state: null,
    country: 'US',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd402807a73ac6f4fdd49d803',
    uri: '/v1/geos/d402807a73ac6f4fdd49d803',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Uruguay',
    city: null,
    state: null,
    country: 'UY',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '78c1f71e638ecd278b7940be',
    uri: '/v1/geos/78c1f71e638ecd278b7940be',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Uzbekistan',
    city: null,
    state: null,
    country: 'UZ',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '34b5138709f3c48a30eb3178',
    uri: '/v1/geos/34b5138709f3c48a30eb3178',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Holy See (Vatican City State)',
    city: null,
    state: null,
    country: 'VA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '32d5dc40f5cb803924ba6263',
    uri: '/v1/geos/32d5dc40f5cb803924ba6263',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Saint Vincent And The Grenadines',
    city: null,
    state: null,
    country: 'VC',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '2bb9e8fa2c0775205378dfad',
    uri: '/v1/geos/2bb9e8fa2c0775205378dfad',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Venezuela, Bolivarian Republic of',
    city: null,
    state: null,
    country: 'VE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '247a1c16355879d1f23e8356',
    uri: '/v1/geos/247a1c16355879d1f23e8356',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Virgin Islands, British',
    city: null,
    state: null,
    country: 'VG',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5542beb9a276f4ba4701b928',
    uri: '/v1/geos/5542beb9a276f4ba4701b928',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Virgin Islands, U.S.',
    city: null,
    state: null,
    country: 'VI',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '220af7ccbbda7fa17b5d53c1',
    uri: '/v1/geos/220af7ccbbda7fa17b5d53c1',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Vietnam',
    city: null,
    state: null,
    country: 'VN',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '1016ecb02893120aea7f7f5b',
    uri: '/v1/geos/1016ecb02893120aea7f7f5b',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Vanuatu',
    city: null,
    state: null,
    country: 'VU',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'e9ed98df2b87e0d354e77211',
    uri: '/v1/geos/e9ed98df2b87e0d354e77211',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Wallis and Futuna',
    city: null,
    state: null,
    country: 'WF',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ac4414b2709c0506cd07a01c',
    uri: '/v1/geos/ac4414b2709c0506cd07a01c',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Samoa',
    city: null,
    state: null,
    country: 'WS',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0e8f5158b3026d9f6c6d17cd',
    uri: '/v1/geos/0e8f5158b3026d9f6c6d17cd',
    created_at: '2023-01-18T19:04:50Z',
    name: 'Yemen',
    city: null,
    state: null,
    country: 'YE',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6e892c9536735a33c64d8a62',
    uri: '/v1/geos/6e892c9536735a33c64d8a62',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Mayotte',
    city: null,
    state: null,
    country: 'YT',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '1f03605a661b6db1df5b80e6',
    uri: '/v1/geos/1f03605a661b6db1df5b80e6',
    created_at: '2023-01-18T19:04:51Z',
    name: 'South Africa',
    city: null,
    state: null,
    country: 'ZA',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ba13d709d0819cf1fb67d3a4',
    uri: '/v1/geos/ba13d709d0819cf1fb67d3a4',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Zambia',
    city: null,
    state: null,
    country: 'ZM',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '433f4b4e30c8c57960ba171d',
    uri: '/v1/geos/433f4b4e30c8c57960ba171d',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Zimbabwe',
    city: null,
    state: null,
    country: 'ZW',
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '9d068f0b6b12f316c2613163',
    uri: '/v1/geos/9d068f0b6b12f316c2613163',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Alaska',
    city: null,
    state: 'AK',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '82991b6922ed28f4b773f02f',
    uri: '/v1/geos/82991b6922ed28f4b773f02f',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Alabama',
    city: null,
    state: 'AL',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '2af2940390c7e83fd6bb8dbb',
    uri: '/v1/geos/2af2940390c7e83fd6bb8dbb',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Arkansas',
    city: null,
    state: 'AR',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '4f326b4a0d6c6e15fa98ca6f',
    uri: '/v1/geos/4f326b4a0d6c6e15fa98ca6f',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Arizona',
    city: null,
    state: 'AZ',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'ee0e71b09febafe0afb54c13',
    uri: '/v1/geos/ee0e71b09febafe0afb54c13',
    created_at: '2023-01-18T19:04:51Z',
    name: 'California',
    city: null,
    state: 'CA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c4a5c5e825da99acdd009df0',
    uri: '/v1/geos/c4a5c5e825da99acdd009df0',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Colorado',
    city: null,
    state: 'CO',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a08ce7bd6e568d65d6bc5c17',
    uri: '/v1/geos/a08ce7bd6e568d65d6bc5c17',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Connecticut',
    city: null,
    state: 'CT',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '87e5347278b9b7f226e65756',
    uri: '/v1/geos/87e5347278b9b7f226e65756',
    created_at: '2023-01-18T19:04:51Z',
    name: 'District of Columbia',
    city: null,
    state: 'DC',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a35c5efd4a5fad9482f206d4',
    uri: '/v1/geos/a35c5efd4a5fad9482f206d4',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Delaware',
    city: null,
    state: 'DE',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '65285dadeaac7f9afe7624b0',
    uri: '/v1/geos/65285dadeaac7f9afe7624b0',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Florida',
    city: null,
    state: 'FL',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd79fca3c870dac46a120b385',
    uri: '/v1/geos/d79fca3c870dac46a120b385',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Georgia',
    city: null,
    state: 'GA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6fd0857cfecab4ff5d90526a',
    uri: '/v1/geos/6fd0857cfecab4ff5d90526a',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Hawaii',
    city: null,
    state: 'HI',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0e47ae735bf39c094d9a7e52',
    uri: '/v1/geos/0e47ae735bf39c094d9a7e52',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Iowa',
    city: null,
    state: 'IA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b7d5aa62de70244db40f5943',
    uri: '/v1/geos/b7d5aa62de70244db40f5943',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Idaho',
    city: null,
    state: 'ID',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '72f170b63e8c9378004beb89',
    uri: '/v1/geos/72f170b63e8c9378004beb89',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Illinois',
    city: null,
    state: 'IL',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '94e44505a113971878f2a5bc',
    uri: '/v1/geos/94e44505a113971878f2a5bc',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Indiana',
    city: null,
    state: 'IN',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '696a663430e86c487e775ea6',
    uri: '/v1/geos/696a663430e86c487e775ea6',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Kansas',
    city: null,
    state: 'KS',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b3fb658d42ff2d06677f9ff8',
    uri: '/v1/geos/b3fb658d42ff2d06677f9ff8',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Kentucky',
    city: null,
    state: 'KY',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0361a20c7dcdeb970241bba0',
    uri: '/v1/geos/0361a20c7dcdeb970241bba0',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Louisiana',
    city: null,
    state: 'LA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '03688909123efa4ef51b5116',
    uri: '/v1/geos/03688909123efa4ef51b5116',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Massachusetts',
    city: null,
    state: 'MA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6c27a57b96e1e855e80d07a1',
    uri: '/v1/geos/6c27a57b96e1e855e80d07a1',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Maryland',
    city: null,
    state: 'MD',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '61d47a97d1886f50b55e9e77',
    uri: '/v1/geos/61d47a97d1886f50b55e9e77',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Maine',
    city: null,
    state: 'ME',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'db5483d401fe49bc998f7098',
    uri: '/v1/geos/db5483d401fe49bc998f7098',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Michigan',
    city: null,
    state: 'MI',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '78ff12550bc323ccf371cc1c',
    uri: '/v1/geos/78ff12550bc323ccf371cc1c',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Minnesota',
    city: null,
    state: 'MN',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '60625ec7a3e14191790d971c',
    uri: '/v1/geos/60625ec7a3e14191790d971c',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Missouri',
    city: null,
    state: 'MO',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '03675e86bbca3a234d8072bc',
    uri: '/v1/geos/03675e86bbca3a234d8072bc',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Mississippi',
    city: null,
    state: 'MS',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '73321b25cff4dd5f205db336',
    uri: '/v1/geos/73321b25cff4dd5f205db336',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Montana',
    city: null,
    state: 'MT',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'e85a8724c8294ae9fa76bedc',
    uri: '/v1/geos/e85a8724c8294ae9fa76bedc',
    created_at: '2023-01-18T19:04:51Z',
    name: 'North Carolina',
    city: null,
    state: 'NC',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '076e0a1e892d7da306baddf1',
    uri: '/v1/geos/076e0a1e892d7da306baddf1',
    created_at: '2023-01-18T19:04:51Z',
    name: 'North Dakota',
    city: null,
    state: 'ND',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '13b6935bab35ea72866407a0',
    uri: '/v1/geos/13b6935bab35ea72866407a0',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Nebraska',
    city: null,
    state: 'NE',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '38a3b239292035307ee85de0',
    uri: '/v1/geos/38a3b239292035307ee85de0',
    created_at: '2023-01-18T19:04:51Z',
    name: 'New Hampshire',
    city: null,
    state: 'NH',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6cdd5a5c58e5ab2cd23dfc3a',
    uri: '/v1/geos/6cdd5a5c58e5ab2cd23dfc3a',
    created_at: '2023-01-18T19:04:51Z',
    name: 'New Jersey',
    city: null,
    state: 'NJ',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5d4dab5da10b42c6605f7539',
    uri: '/v1/geos/5d4dab5da10b42c6605f7539',
    created_at: '2023-01-18T19:04:51Z',
    name: 'New Mexico',
    city: null,
    state: 'NM',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5d3c973081211f125b06f037',
    uri: '/v1/geos/5d3c973081211f125b06f037',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Nevada',
    city: null,
    state: 'NV',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '87b55d1887c403572415611e',
    uri: '/v1/geos/87b55d1887c403572415611e',
    created_at: '2023-01-18T19:04:51Z',
    name: 'New York',
    city: null,
    state: 'NY',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '9c8554d9336784ac836b1fe5',
    uri: '/v1/geos/9c8554d9336784ac836b1fe5',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Ohio',
    city: null,
    state: 'OH',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd1499191eb6c92773bcef833',
    uri: '/v1/geos/d1499191eb6c92773bcef833',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Oklahoma',
    city: null,
    state: 'OK',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'a616636b9a71a0124da12b85',
    uri: '/v1/geos/a616636b9a71a0124da12b85',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Oregon',
    city: null,
    state: 'OR',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b4e2c381ffd3e014cbe7571f',
    uri: '/v1/geos/b4e2c381ffd3e014cbe7571f',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Pennsylvania',
    city: null,
    state: 'PA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '157b76f1eaeadbaa8e4636e0',
    uri: '/v1/geos/157b76f1eaeadbaa8e4636e0',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Rhode Island',
    city: null,
    state: 'RI',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '9b29c79b94a725a96f9f7bff',
    uri: '/v1/geos/9b29c79b94a725a96f9f7bff',
    created_at: '2023-01-18T19:04:51Z',
    name: 'South Carolina',
    city: null,
    state: 'SC',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'b233ce64b71b6bd5ca784dab',
    uri: '/v1/geos/b233ce64b71b6bd5ca784dab',
    created_at: '2023-01-18T19:04:51Z',
    name: 'South Dakota',
    city: null,
    state: 'SD',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '6d44586381aa131f5e05ff6b',
    uri: '/v1/geos/6d44586381aa131f5e05ff6b',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Tennessee',
    city: null,
    state: 'TN',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '77ad4229d2385799c670573e',
    uri: '/v1/geos/77ad4229d2385799c670573e',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Texas',
    city: null,
    state: 'TX',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '73986dd2e92a6534f568e711',
    uri: '/v1/geos/73986dd2e92a6534f568e711',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Utah',
    city: null,
    state: 'UT',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '443b8f9b245f1e6bb0857cda',
    uri: '/v1/geos/443b8f9b245f1e6bb0857cda',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Virginia',
    city: null,
    state: 'VA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'e7002d4ba43d5865ba6ac20a',
    uri: '/v1/geos/e7002d4ba43d5865ba6ac20a',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Vermont',
    city: null,
    state: 'VT',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'bf4c2d4a4b0e784f8db4a643',
    uri: '/v1/geos/bf4c2d4a4b0e784f8db4a643',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Washington',
    city: null,
    state: 'WA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0e06e5d590df97ee3e05a4ba',
    uri: '/v1/geos/0e06e5d590df97ee3e05a4ba',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Wisconsin',
    city: null,
    state: 'WI',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '38ec059c16464b6fd85c1cfd',
    uri: '/v1/geos/38ec059c16464b6fd85c1cfd',
    created_at: '2023-01-18T19:04:51Z',
    name: 'West Virginia',
    city: null,
    state: 'WV',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '2fb8710ce80a7185556aefea',
    uri: '/v1/geos/2fb8710ce80a7185556aefea',
    created_at: '2023-01-18T19:04:51Z',
    name: 'Wyoming',
    city: null,
    state: 'WY',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '2c69e26e54fdd50dcb237d36',
    uri: '/v1/geos/2c69e26e54fdd50dcb237d36',
    created_at: '2023-01-18T19:04:51Z',
    name: 'CA - Los Angeles',
    city: 'Los Angeles',
    state: 'CA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '091cef366f76424066edb787',
    uri: '/v1/geos/091cef366f76424066edb787',
    created_at: '2023-01-18T19:04:51Z',
    name: 'CA - San Francisco',
    city: 'San Francisco',
    state: 'CA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0eb92beb86e9d8a3fc6c2416',
    uri: '/v1/geos/0eb92beb86e9d8a3fc6c2416',
    created_at: '2023-01-18T19:04:52Z',
    name: 'IL - Chicago',
    city: 'Chicago',
    state: 'IL',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'd0e9759591b78eddae4a2f64',
    uri: '/v1/geos/d0e9759591b78eddae4a2f64',
    created_at: '2023-01-18T19:04:52Z',
    name: 'MD - Baltimore',
    city: 'Baltimore',
    state: 'MD',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'fb79e051738c9048200db90d',
    uri: '/v1/geos/fb79e051738c9048200db90d',
    created_at: '2023-01-18T19:04:52Z',
    name: 'MO - Kansas City',
    city: 'Kansas City',
    state: 'MO',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '81c2f80b952506d536506c3c',
    uri: '/v1/geos/81c2f80b952506d536506c3c',
    created_at: '2023-01-18T19:04:52Z',
    name: 'MO - Columbia',
    city: 'Columbia',
    state: 'MO',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c1cc22bf64c1e13a31abacff',
    uri: '/v1/geos/c1cc22bf64c1e13a31abacff',
    created_at: '2023-01-18T19:04:52Z',
    name: 'NJ - Newark',
    city: 'Newark',
    state: 'NJ',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '0f6c3c4975e1b082ece7019a',
    uri: '/v1/geos/0f6c3c4975e1b082ece7019a',
    created_at: '2023-01-18T19:04:52Z',
    name: 'NY - New York',
    city: 'New York',
    state: 'NY',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '87ce3e6ea9e79f71f263ea79',
    uri: '/v1/geos/87ce3e6ea9e79f71f263ea79',
    created_at: '2023-01-18T19:04:52Z',
    name: 'NY - Buffalo',
    city: 'Buffalo',
    state: 'NY',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '4a845e8fdf3269d86b56110c',
    uri: '/v1/geos/4a845e8fdf3269d86b56110c',
    created_at: '2023-01-18T19:04:52Z',
    name: 'NY - Rochester',
    city: 'Rochester',
    state: 'NY',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '5ad866ae319698683dc4868d',
    uri: '/v1/geos/5ad866ae319698683dc4868d',
    created_at: '2023-01-18T19:04:52Z',
    name: 'OR - Portland',
    city: 'Portland',
    state: 'OR',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '914c7d6ce2ae71e7f5615082',
    uri: '/v1/geos/914c7d6ce2ae71e7f5615082',
    created_at: '2023-01-18T19:04:52Z',
    name: 'PA - Philadelphia',
    city: 'Philadelphia',
    state: 'PA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: '789ea23339d31812b7ca8e51',
    uri: '/v1/geos/789ea23339d31812b7ca8e51',
    created_at: '2023-01-18T19:04:52Z',
    name: 'TX - Austin',
    city: 'Austin',
    state: 'TX',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'e525f561933e24012c662449',
    uri: '/v1/geos/e525f561933e24012c662449',
    created_at: '2023-01-18T19:04:52Z',
    name: 'WA - Seattle',
    city: 'Seattle',
    state: 'WA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
  {
    id: 'c4d6ebbbbad9e317aa6c205a',
    uri: '/v1/geos/c4d6ebbbbad9e317aa6c205a',
    created_at: '2023-01-18T19:04:52Z',
    name: 'WA - Spokane',
    city: 'Spokane',
    state: 'WA',
    country: null,
    deleted_at: null,
    object: 'geo',
  },
];

export const internationalGeos = allGeos
  .filter(geo => geo.country && geo.country !== 'US')
  .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));

export const stateGeos = allGeos.filter(
  geo =>
    geo.name !== 'United States' &&
    geo.city === null &&
    (geo.country === 'US' || geo.country === null),
);

const cityGeos = allGeos.filter(geo => Boolean(geo.city));

export const cityGeosByState = cityGeos.reduce((acc, curr) => {
  return { ...acc, [curr.state]: [...(acc[curr.state] || []), curr] };
}, {});

export const statesWithCities = Object.keys(cityGeosByState);
