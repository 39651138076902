import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import { withRouter } from 'react-router';

import { Provider as FlagrProvider } from '@dashboard-experience/react-flagr';
import { FLAGR_URL, FLAG_KEYS } from '../../services/flagr';

export default function loadFlagr(WrapperComponent) {
  @inject('store')
  @observer
  class Wrapper extends Component {
    componentDidMount() {
      // eslint-disable-line camelcase
      const { store } = this.props;

      if (!store.flagr) {
        store.loadFlagr(Object.values(FLAG_KEYS));
      }
    }

    render() {
      const { store } = this.props;

      if (!store.flagr?.flags) return <WrapperComponent {...this.props} />;

      const { flags, context } = store.flagr;

      return (
        <FlagrProvider
          flags={flags}
          entityId={'131231'}
          entityType='account'
          entityContext={context}
          url={FLAGR_URL}
        >
          <WrapperComponent {...this.props} />
        </FlagrProvider>
      );
    }
  }
  Wrapper.wrappedComponent.propTypes = {
    store: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };
  return withRouter(Wrapper);
}
