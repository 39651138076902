import React from 'react';
import styled from 'styled-components';

import colors from '../../../../../styles/_colors.scss';
import { Icon } from '../shared/ListItemElements';
import certificate from '../shared/svg/certificate.svg';
import ScreeningsSectionAccordion from '../shared/ScreeningsSectionAccordion';
import InternationalEducationVerification from './VerificationScreenings/InternationalEducationVerification';
import InternationalEmploymentVerification from './VerificationScreenings/InternationalEmploymentVerification';
import InternationalIdentityDocumentValidation from './VerificationScreenings/InternationalIdentityDocumentValidation';
import { ADD_SCREENINGS } from '../../../../../constants/identifiers';

const Screenings = () => (
  <ul>
    <InternationalEmploymentVerification />
    <InternationalEducationVerification />
    <InternationalIdentityDocumentValidation />
  </ul>
);

const Title = styled.span`
  align-self: center;
  color: ${colors.navy3};
  font-family: National2Regular;
  font-size: 20px;
  font-weight: 500;
`;

const VerificationScreenings = () => {
  return (
    <ScreeningsSectionAccordion
      IconComponent={<Icon src={certificate} className='screening-icon' />}
      TitleComponent={<Title>Verifications</Title>}
      ScreeningsComponent={<Screenings />}
      dataTestId={ADD_SCREENINGS.internationalVerificationScreenings}
    />
  );
};

export default VerificationScreenings;
