import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Submit extends Component {
  onClick(e) {
    if (this.props.submitWithRecaptcha) {
      this.props.submitWithRecaptcha(e);
    }
  }

  render() {
    let children = this.props.children || this.props.text || 'Submit';
    const baseClass = this.props.customClassName || 'btn btn-lg btn-block';

    if (this.props.loading) {
      children = (
        <span>
          <i className='fa fa-spinner fa-spin fa-fw' /> Loading...
        </span>
      );
    }

    return (
      <button
        className={`${baseClass} ${
          this.props.loading ? 'btn-secondary' : 'btn-success'
        }`}
        data-testid={this.props.dataTestId}
        disabled={this.props.loading || this.props.disabled || false}
        onClick={e => this.onClick(e)}
      >
        {children}
      </button>
    );
  }
}

Submit.propTypes = {
  customClassName: PropTypes.string,
  loading: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.array,
  submitWithRecaptcha: PropTypes.func,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
};
