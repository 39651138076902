import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import FieldKit from 'field-kit';

class TextField extends Component {
  constructor(props) {
    super(props);
    this._onChange = this.onChange.bind(this);
    this._onBlur = this.props.onBlur.bind(this);
  }

  componentDidMount() {
    this.buildFieldKitField();
    this.field.setValue(this.props.value);

    this.setUpPlaceholders();

    this.setupEvents();
  }

  buildFieldKitField() {
    const field = this.getField();

    if (field) {
      this.field = field;
    } else {
      const formatter = this.getFormatter();

      if (formatter) {
        this.field = new FieldKit.TextField(this.getElement(), formatter);
      } else {
        this.field = new FieldKit.TextField(this.getElement());
      }
    }

    return this.field;
  }

  setUpPlaceholders() {
    if (this.props.placeholder || this.props.unfocusedPlaceholder) {
      this.field.setUnfocusedPlaceholder(
        this.props.placeholder || this.props.unfocusedPlaceholder,
      );
    }

    if (this.props.focusedPlaceholder) {
      this.field.setFocusedPlaceholder(this.props.focusedPlaceholder);
    }
  }

  setupEvents() {
    this.field.setDelegate({
      textFieldDidBeginEditing: this._onChange,
      textFieldDidEndEditing: this._onChange,
      textDidChange: this._onChange,
    });
  }

  getField() {
    return null;
  }

  getFormatter() {
    return null;
  }

  getElement() {
    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(this);
  }

  onChange(field) {
    this.setState({ rawValue: field.value() });

    if (this.props.onChange) setTimeout(() => this.props.onChange(this));
  }

  render() {
    return (
      <input
        className={this.props.className}
        data-testid={this.props.dataTestId}
        type='text'
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        onBlur={this._onBlur}
      />
    );
  }

  static getBasePropTypes() {
    return {
      className: PropTypes.string,
      rawValue: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
      placeholder: PropTypes.string,
      disabled: PropTypes.bool,
      focusedPlaceholder: PropTypes.string,
      unfocusedPlaceholder: PropTypes.string,
      onChange: PropTypes.func,
      didBeginEditing: PropTypes.func,
      didEndEditing: PropTypes.func,
      onBlur: PropTypes.func,
      dataTestId: PropTypes.string,
    };
  }

  // Helper method for subclasses
  static getBaseDefaultProps() {
    return {
      onBlur: () => {},
      didBeginEditing: () => {},
      didEndEditing: () => {},
    };
  }
}

TextField.propTypes = TextField.getBasePropTypes();

TextField.defaultProps = TextField.getBaseDefaultProps();

export default TextField;
