import styled from 'styled-components';
import React from 'react';

import LearnMoreLink from './LearnMoreLink';
import colors from '../../../../../styles/_colors.scss';
import { ScreeningType } from '../../PackageUtils/packageTypes';
import { useAddScreeningsContext } from './context';
import { getPriceByType } from '../../PackageUtils/PackageUtils';
import { ADD_SCREENINGS } from '../../../../../constants/identifiers';

export const Icon = styled.img`
  padding: 6px;
  border-radius: 25px;
  background: ${colors.slate2};
  margin: 2px 16px 0 0;
`;

export const getPriceVaries = (priceByType: ScreeningType) => {
  if (priceByType === 'international_criminal_search_v2') {
    return (
      <LearnMoreLink
        linkText='Price Varies'
        href='https://checkr.com/pricing/international'
      />
    );
  }

  return 'Price Varies';
};

export const Price = styled.span`
  text-align: right;
  color: ${colors.navy03};
  flex-basis: 120px;
  align-self: center;
  padding: 10px 0;

  @media (max-device-width: 530px) {
    flex: 1 100%;
    text-align: left;
    margin: 4px 0px 16px 32px;
  }
`;

type ScreeningPriceProps = {
  priceByType: ScreeningType;
  priceVaries?: boolean;
  children?: React.ReactNode;
};

export const ScreeningPrice: React.FC<ScreeningPriceProps> = ({
  priceByType,
  priceVaries = false,
  children,
}) => {
  const { screeningPrices } = useAddScreeningsContext();
  if (!children) {
    return (
      <Price data-testid={`${ADD_SCREENINGS.screeningPrice}-${priceByType}`}>
        {priceVaries
          ? getPriceVaries(priceByType)
          : getPriceByType(priceByType, screeningPrices)}
      </Price>
    );
  }
  return (
    <Price data-testid={`${ADD_SCREENINGS.screeningPrice}-${priceByType}`}>
      {children}
    </Price>
  );
};

export const StyledLink = styled.a`
  color: ${colors.checkrBlue};
  text-decoration: underline;
`;

export const ScreeningTitle = styled.span`
  align-self: center;
  width: 55%;
  text-align: left;
  padding: 10px 0;
`;

export const ScreeningSectionTitle = styled.span`
  align-self: center;
  color: ${colors.navy3};
  font-family: National2Regular;
  font-size: 20px;
  font-weight: 500;
`;
