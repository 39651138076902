import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';
import Footer from '../../Footer';
import Logos from '../../Logos';
import * as Form from '../../form';
import Title from '../../Title';
import { ANALYTICS_EVENTS } from '../../../services/analytics';
import { SIGNUP } from '../../../constants/identifiers';
@inject('store')
@observer
export default class Connect extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.form = this.store.connect;
  }

  componentDidMount() {
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.CONNECT_PAGE_VIEWED);
  }

  render() {
    return (
      <div>
        {this.store.isSignup && <Redirect to={this.store.getStep('connect')} />}
        <div className='container'>
          <div className='row justify-content-lg-center'>
            <div className='col-lg-7 col-md-12'>
              <div className='row container-outer pt-4'>
                <Logos />
                <Title
                  label='Connect your Checkr Account'
                  baseClass='mb-0 mt-4'
                />
                <div className='col-md-12 container-inner'>
                  <div className='mt-3'>
                    <div className='row'>
                      <div className='col-lg-10 offset-lg-1 main'>
                        <Form.Form form={this.form}>
                          <br />
                          <p className='text-center'>
                            Are you ready to connect{' '}
                            <b>{this.store.application.name}</b> to Checkr so
                            you can order background checks?
                          </p>
                          <br />
                          <Form.Submit
                            dataTestId={SIGNUP.MISC.connect}
                            text='Click here to connect'
                            loading={this.form.loading}
                          />
                          <br />
                          <Form.Error
                            error={this.form.error}
                            store={this.store}
                          />
                        </Form.Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Connect.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
