import * as React from 'react';
import propTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import { Grid } from '@mui/material';
import CheckrDirectHeader from './CheckrDirectHeader';

const steps = ['Create account', 'Add payment', 'Place order'];
export default function ProgressBar(props) {
  const { activeStep } = props;
  return (
    <Grid container direction='row' spacing={5} marginTop='1%'>
      <Grid item xs={12} sm={1} md={1}>
        <CheckrDirectHeader />
      </Grid>
      <Grid item xs={12} sm={11} md={10}>
        <div className='cd-progress-bar'>
          <Grid direction='row' container justifyContent='center'>
            <Grid item xs={12} sm={9} md={9}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                  // padding between steps
                  '& .MuiStep-root': {
                    paddingLeft: '4px',
                    paddingRight: '4px',
                  },
                }}
              >
                {steps.map(label => (
                  <Step
                    key={label}
                    sx={{
                      // hides the step icon
                      '& .MuiSvgIcon-root': {
                        display: 'none',
                      },
                      // create the line above the step
                      '& .MuiStepLabel-root': {
                        borderTop: '2px solid',
                        borderColor: '#117091',
                      },
                      // label styling
                      '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                        color: '#117091',
                        marginTop: '8px',
                        fontSize: { xs: '12px', sm: '12px', md: '14px' },
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '13px',
                        paddingLeft: '0',
                        paddingRight: '20px',
                        textTransform: 'none',
                        textAlign: 'left',
                        letter: '6%',
                        fontFamily: 'National2Regular',
                      },
                      // active step font styling
                      '& .Mui-active': {
                        fontFamily: 'National2Medium !important',
                        fontWeight: '500 !important',
                      },
                      // disabled steps styling
                      '& .Mui-disabled': {
                        color: '#8799AB !important',
                        borderColor: '#8799AB',
                      },
                      // hide the connector line
                      '& .MuiStepConnector-line': {
                        display: 'none',
                      },
                    }}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

ProgressBar.propTypes = {
  activeStep: propTypes.number,
};
