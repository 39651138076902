import React, { Component } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';
import * as Form from '../../form';

import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../../services/analytics';
import colors from '../../../styles/_colors.scss';
import SignupWrapper from './SignupWrapper';
import { SIGNUP } from '../../../constants/identifiers';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  .connect-page-content {
    width: 800px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-self: center;
    font-size: 18px;
    font-family: National2Regular;
  }

  h1 {
    font-weight: 600;
    font-family: ArgentCFRegular;
    color: ${colors.navy4};
  }

  .visit-dashboard-text {
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }

  span {
    font-weight: 700;
    font-family: National2Bold;
  }

  button {
    margin: 40px auto 23px;
  }
`;

const LoadingSpinner = () => (
  <span>
    <i className='fa fa-spinner fa-spin fa-fw' /> Loading...
  </span>
);

@inject('store')
@observer
export default class Connect extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.form = this.store.connect;
    this._handleRedirect = this.handleRedirect.bind(this);
  }

  componentDidMount() {
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.CONNECT_PAGE_VIEWED);
  }

  handleRedirect(e) {
    e.preventDefault();
    this.form.submit();
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.ACCOUNT_CONNECTED, {
      [ANALYTICS_PROPERTIES.BUTTON_CLICK]: 'Connect Account Clicked',
    });
  }

  render() {
    return (
      <SignupWrapper>
        {this.store.isSignup && <Redirect to={this.store.getStep('connect')} />}
        <Wrapper component='Wrapper' className='connect-page-wrapper'>
          <div className='connect-page-content'>
            <Box
              component='h1'
              sx={{
                typography: {
                  xs: { fontSize: '28px' },
                  lg: { fontSize: '42px' },
                },
              }}
            >
              Ready to connect?
            </Box>
            <Box
              component='p'
              sx={{
                color: '#0A1626',
                typography: {
                  xs: { fontSize: '16px' },
                  lg: { fontSize: '18px' },
                },
              }}
            >
              If you&apos;re ready to connect{' '}
              {this.store.application.partner_name} to your Checkr account,
              select <span>Connect my account</span> to continue.
            </Box>
            <Form.Error error={this.form.error} store={this.store} />
            <Box
              component='button'
              className='btn btn-success btn-signup-v4'
              disabled={this.form.loading}
              data-testid={SIGNUP.MISC.connect}
              onClick={this._handleRedirect}
              sx={{
                width: {
                  lg: '175px',
                },
                mt: '20px',
              }}
            >
              {this.form.loading ? <LoadingSpinner /> : 'Connect my account'}
            </Box>
          </div>
        </Wrapper>
      </SignupWrapper>
    );
  }
}

Connect.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
