/* eslint-disable camelcase */
import React from 'react';

import MotorVehicleRecordsCheckItem from './MotorVehicleRecord';
import VerificationScreenings from './VerificationSceenings';
import CriminalRecords from './CriminalRecords';
import CivilRecords from './CivilRecords';
import FACIS from './FACIS';

const DomesticAddScreeningsList = () => {
  return (
    <>
      <MotorVehicleRecordsCheckItem />
      <CriminalRecords />
      <VerificationScreenings />
      <CivilRecords />
      <FACIS />
    </>
  );
};

export default DomesticAddScreeningsList;
