/* eslint-disable max-len */

const SIGNUP_WELCOME_PAGE_HEADING =
  'Sign up and start running background checks';

const WELCOME_HEADER =
  'Sign up for free and join tens of thousands of companies that rely on Checkr';

const WELCOME_HEADER_CUSTOM_PLANNING_CENTER =
  'Create your account and join the many organizations who trust Checkr to support their volunteer programs';

const WELCOME_HEADER_CUSTOM_WELLSKY =
  'Join the large network of healthcare companies that rely on Checkr';

const WELCOME_HEADER_CUSTOM_RIPPLING =
  'Create your free Checkr account and seamlessly add background checks to your hiring or onboarding process';

const WELCOME_HEADER_CUSTOM =
  'This is just a test and should never show in prod';

const SIGNUP_BUSINESS_DESCRIPTIONS = {
  'Easy to use': 'For you and your candidates – on web and mobile devices',
  'Get results fast':
    '98% of nationwide criminal checks complete within one hour',
  'Built-in compliance': 'Helps you stay compliant with FCRA and local laws',
};

const SIGNUP_BUSINESS_DESCRIPTIONS_B = {
  'Maximized productivity':
    'Leverage machine learning to increase accuracy & speed',
  'Built-in compliance': 'Helps you stay compliant with FCRA and local laws',
  'No commitments or set-up fees':
    'You only pay for completed background checks',
};

const SIGNUP_BUSINESS_DESCRIPTIONS_CUSTOM = {
  'Productivity maximized': 'Increase accuracy & speed with machine learning',
  'Compliance built-in': 'Helps you stay compliant with FCRA and local laws',
  'No set-up-fees or committments':
    'You only pay for completed background checks',
};

const SIGNUP_BUSINESS_DESCRIPTIONS_PLANNING_CENTER = {
  'Built-in compliance':
    'Checkr helps you stay compliant with background check laws and regulations',
  'Streamlined process':
    'Order and review background checks right from Planning Center',
  'No commitments or set-up fees':
    'You only pay for completed background checks',
};

const SIGNUP_BUSINESS_DESCRIPTIONS_WELLSKY = {
  'Streamlined process':
    'Order and review background checks right from Wellsky',
  'Built-in compliance':
    'Comply with healthcare industry regulations to mitigate risk for your organization',
  'No commitments or set-up fees':
    'You only pay for completed background checks',
};

const SIGNUP_BUSINESS_DESCRIPTIONS_RIPPLING = {
  'Built-in compliance':
    'Checkr helps you stay compliant with background check laws and regulations',
  'Streamlined process':
    'Order and review background checks right from Rippling',
  'No commitments or set-up fees':
    'You only pay for completed background checks',
};

const SIGNUP_PACKAGE_SUBTEXT = `gives you the following packages at great prices. 
    You’ll also get access to the <span style="font-family: National2Bold">Checkr Dashboard</span> 
    where you can customize new packages or take advantage of other comprehensive features.`;

const SIGNUP_PACKAGE_SUBTEXT_CUSTOM =
  'This is a custom package subtext and should never be used in production';

const SIGNUP_PACKAGE_SUBTEXT_PLANNING_CENTER = `Our most popular packages will automatically be added to your Checkr account and available in Planning Center. 
  You’ll also get full access to the <span style="font-family: National2Bold"> Checkr Dashboard</span> 
  where you can edit packages and take advantage of other features like continuous monitoring.`;

const SIGNUP_PACKAGE_SUBTEXT_WELLSKY = `These packages will automatically be added to your Checkr account and available in Wellsky. 
  You’ll also get full access to the Checkr Dashboard where you can edit packages and take 
  advantage of other features including drug tests and professional license verifications.`;

const SIGNUP_PACKAGE_SUBTEXT_RIPPLING = `Our most popular packages will automatically be added to your Checkr account and available in Rippling. 
  You’ll also get full access to the Checkr Dashboard where you can take advantage of other comprehensive features.`;

const PACKAGE_HEADER = 'Simple, customizable packages';

const PACKAGE_HEADER_CUSTOM =
  'this is just a test package header and should never show in prod';

const PACKAGE_HEADER_PLANNING_CENTER =
  'Packages built for volunteers, with preferred pricing';

const PACKAGE_HEADER_WELLSKY =
  'Pre-set package options, designed for Wellsky customers';

const PACKAGE_HEADER_RIPPLING = 'Simple, straightforward packages';

const FAIR_CHANCE_HEADER = 'What is fair chance hiring?';

const FAIR_CHANCE_HEADER_CUSTOM = 'This is a custom fair change hiring header!';

const FAIR_CHANCE_HEADER_PLANING_CENTER =
  'Why nonprofit organizations rely on Checkr';

const FAIR_CHANCE_HEADER_WELLSKY =
  'Improve patient care with healthcare background checks';

const FAIR_CHANCE_HEADER_RIPPLING = 'Background checks for the modern era';

const ADVANTAGE_HEADER = 'The Checkr advantage';

const ADVANTAGE_HEADER_CUSTOM = 'The Checkr advantage custom';

const ADVANTAGE_HEADER_WELLSKY = 'Why healthcare companies choose Checkr';

const ADVANTAGE_SUB_HEADER =
  'Based on results of the 2022 Checkr Customer Survey';

const ADVANTAGE_SUB_HEADER_CUSTOM =
  'Based on results of the 2022 Checkr Customer Survey CUSTOM';

const ADVANTAGE_SUB_HEADER_PLANNING_CENTER_AND_RIPPLING =
  'Join the tens of thousands of customers who trust and rely on Checkr:';

const ADVANTAGE_SUB_HEADER_WELLSKY =
  'Since switching to Checkr, customers in the healthcare industry say they’ve experienced these results:';

const SURVEYS = [
  {
    percentage: '97%',
    text: 'spend less time adjudicating',
  },
  {
    percentage: '94%',
    text: 'say Checkr prices are the same or better',
  },
  {
    percentage: '90%',
    text: 'are happier with Checkr',
  },
  {
    percentage: '84%',
    text: 'of reports are completed within 15 minutes',
    subText: 'Industry average is between 3-5 business days',
  },
];

const CUSTOM_SURVEYS_PLANNING_CENTER = [
  {
    percentage: '97%',
    text: 'say our turnaround time is faster than the competition',
  },
  {
    percentage: '90%',
    text: 'say Checkr has simpified their daily work',
  },
  {
    percentage: '94%',
    text: 'say Checkr prices are the same or better',
  },
  {
    percentage: '84%',
    text: 'of reports are completed in less than 15 minutes',
  },
];

const CUSTOM_SURVEYS_WELLSKY = [
  {
    percentage: '75%',
    text: 'say they spend less time processing background checks',
  },
  {
    percentage: '86%',
    text: 'report faster turnaround times',
  },
  {
    percentage: '95%',
    text: 'say they’ve experienced a simplified workflow',
  },
  {
    percentage: '86%',
    text: 'report a better candidate experience',
  },
];

const CUSTOM_SURVEYS_RIPPLING = [
  {
    percentage: '97%',
    text: 'say our turnaround time is faster than the competition',
  },
  {
    percentage: '90%',
    text: 'say they are happier with Checkr',
  },
  {
    percentage: '94%',
    text: 'say Checkr prices are the same or better',
  },
  {
    percentage: '84%',
    text: 'of reports are completed in less than 15 minutes',
  },
];

const CUSTOM_SURVEYS_1 = [
  {
    percentage: '97%',
    text: 'spend less time customizing',
  },
  {
    percentage: '94%',
    text: 'say Checkr prices are custom',
  },
  {
    percentage: '90%',
    text: 'are happier with custom',
  },
  {
    percentage: '84%',
    text: 'of reports are custom',
    subText: 'Industry average is custom',
  },
];

export {
  SIGNUP_BUSINESS_DESCRIPTIONS,
  SIGNUP_BUSINESS_DESCRIPTIONS_B,
  SIGNUP_BUSINESS_DESCRIPTIONS_CUSTOM,
  SIGNUP_PACKAGE_SUBTEXT,
  SIGNUP_PACKAGE_SUBTEXT_CUSTOM,
  SIGNUP_WELCOME_PAGE_HEADING,
  WELCOME_HEADER,
  WELCOME_HEADER_CUSTOM,
  PACKAGE_HEADER,
  PACKAGE_HEADER_CUSTOM,
  FAIR_CHANCE_HEADER,
  FAIR_CHANCE_HEADER_CUSTOM,
  ADVANTAGE_HEADER,
  ADVANTAGE_HEADER_CUSTOM,
  ADVANTAGE_SUB_HEADER,
  ADVANTAGE_SUB_HEADER_CUSTOM,
  SURVEYS,
  CUSTOM_SURVEYS_1,
  WELCOME_HEADER_CUSTOM_PLANNING_CENTER,
  SIGNUP_BUSINESS_DESCRIPTIONS_PLANNING_CENTER,
  PACKAGE_HEADER_PLANNING_CENTER,
  SIGNUP_PACKAGE_SUBTEXT_PLANNING_CENTER,
  ADVANTAGE_SUB_HEADER_PLANNING_CENTER_AND_RIPPLING,
  CUSTOM_SURVEYS_PLANNING_CENTER,
  FAIR_CHANCE_HEADER_PLANING_CENTER,
  WELCOME_HEADER_CUSTOM_WELLSKY,
  SIGNUP_BUSINESS_DESCRIPTIONS_WELLSKY,
  PACKAGE_HEADER_WELLSKY,
  SIGNUP_PACKAGE_SUBTEXT_WELLSKY,
  ADVANTAGE_HEADER_WELLSKY,
  ADVANTAGE_SUB_HEADER_WELLSKY,
  CUSTOM_SURVEYS_WELLSKY,
  FAIR_CHANCE_HEADER_WELLSKY,
  WELCOME_HEADER_CUSTOM_RIPPLING,
  SIGNUP_BUSINESS_DESCRIPTIONS_RIPPLING,
  PACKAGE_HEADER_RIPPLING,
  SIGNUP_PACKAGE_SUBTEXT_RIPPLING,
  CUSTOM_SURVEYS_RIPPLING,
  FAIR_CHANCE_HEADER_RIPPLING,
};
