import React, { Component } from 'react';
import { Box, CircularProgress, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { PROMO_CODES } from '../../../constants/identifiers';

@observer
export default class PromotionCodeInput extends Component {
  render() {
    const { form } = this.props;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <TextField
            className='d-flex input-signup-v4'
            type='text'
            label='Have a promo code?'
            error={Boolean(form.error?.promotionCode)}
            helperText={form.error?.promotionCode}
            inputProps={{
              'data-testid': PROMO_CODES.promoCodeInput,
            }}
            InputLabelProps={{
              className: 'primary-text-signup-v4',
              shrink: true,
            }}
            FormHelperTextProps={{
              'data-testid': PROMO_CODES.promoCodeInputFieldError,
            }}
            onChange={e => form.handleChange('promotionCode', e.target.value)}
          />
        </Grid>
        <Grid item xs={6} className='d-flex align-items-center'>
          <Box
            component='button'
            className='btn btn-promo-code-v4'
            data-testid={PROMO_CODES.applyPromoCode}
            disabled={!form.promotionCode || form.promoLoading}
            sx={{ position: 'relative' }}
            onClick={e => {
              e.preventDefault();
              form.validatePromotionCode(e);
            }}
          >
            {form.promoLoading ? (
              <CircularProgress
                data-tesid='apply-promo-code-loading-spinner'
                size={16}
                sx={{
                  color: '#576C82',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-8px',
                  marginLeft: '-8px',
                }}
              />
            ) : (
              'Apply'
            )}
          </Box>
        </Grid>
        {form.promotionCodeApplied && (
          <Grid item xs={12} sx={{ paddingTop: '-8px' }}>
            <div
              className='success-message'
              data-testid={PROMO_CODES.applyPromoCodeSuccessMsg}
            >
              <i className='fa' />
              <Box
                component='span'
                className='rule-description'
                fontFamily='National2Regular'
              >
                {' '}
                Promotional code has been applied.
              </Box>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

PromotionCodeInput.propTypes = {
  form: PropTypes.object.isRequired,
};
