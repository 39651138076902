/* eslint-disable camelcase */
import React, { useCallback } from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import CheckboxWithTooltip from '../../shared/CheckboxWithTooltip';
import { useAddScreeningsContext } from '../../shared/context';
import QuantityDropdown from '../../shared/QuantityDropdown';
import { getEmploymentVerificationPrice } from '../../../PackageUtils/PackageUtils';

const EmploymentVerification = () => {
  const {
    onAddClick,
    onRemoveClick,
    disabledScreenings,
    addedScreenings,
    additionalProperties,
    screeningPrices,
  } = useAddScreeningsContext();

  const screeningInfo =
    'Verifies candidate’s employment history for last 7 years';

  const advantages = [
    'Reports dates of employment and titles held',
    'Helps to determine if candidate’s work history is accurate',
    'Eliminates burden of calling employers',
  ];

  const considerations = [
    'Turnaround may depend on employers',
    'Verifies employment, not identity',
  ];

  const maximum_allowed_employers =
    additionalProperties.employment_verification &&
    additionalProperties.employment_verification.maximum_allowed_employers;

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onAddClick('employment_verification', {
        international_upgrade: event.target.checked,
        maximum_allowed_employers: maximum_allowed_employers || '1',
      });
    },
    [onAddClick, maximum_allowed_employers],
  );

  const handleOnAddClick = useCallback(
    () =>
      onAddClick('employment_verification', {
        maximum_allowed_employers: '1',
      }),
    [onAddClick],
  );

  const handleDropdownChange = useCallback(
    (count: string) =>
      onAddClick('employment_verification', {
        maximum_allowed_employers: count,
      }),
    [onAddClick],
  );

  const checked = !!(
    additionalProperties.employment_verification &&
    additionalProperties.employment_verification.international_upgrade
  );

  const price = getEmploymentVerificationPrice(screeningPrices, '1');

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
      height='92px'
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>Employment Verification</ScreeningTitle>
        {addedScreenings.includes('employment_verification') ? (
          <QuantityDropdown
            value={maximum_allowed_employers}
            onChange={handleDropdownChange}
            screeningType='employment_verification'
          />
        ) : (
          <ScreeningPrice priceByType='employment_verification'>
            {price}
          </ScreeningPrice>
        )}

        <AddScreeningsAction
          screeningType='employment_verification'
          onAddClick={handleOnAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
      {!disabledScreenings.includes('employment_verification') && (
        <CheckboxWithTooltip
          onAddClick={handleCheckboxClick}
          checked={checked}
          screening='employment_verification'
        />
      )}
    </ScreeningAccordion>
  );
};

export default EmploymentVerification;
