import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Form from '../../form';
import { observer } from 'mobx-react';
import { MenuItem, TextField, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import Tooltip from '../../form/Tooltip';
import { SIGNUP } from '../../../constants/identifiers';

const SectionTitle = props => (
  <Typography
    textAlign='left'
    fontWeight='500'
    marginBottom='16px'
    fontSize='18px'
    color='#001730'
    width='100%'
    fontFamily='National2Medium'
    {...props}
  />
);

@observer
export default class WorkLocationSelector extends Component {
  constructor(props) {
    super(props);
    this.form = this.props.form;
    this.TooltipComponent = this.props.TooltipComponent;
  }

  render() {
    let complianceStateAndCitySelector;

    if (this.form.numberOfComplianceLocations === 'single') {
      const states = this.form.availableComplianceStates;
      const stateOptions = Object.keys(states).map(stateCode => {
        return {
          text: states[stateCode].state_name,
          value: states[stateCode].state,
        };
      });

      const citiesForState =
        this.form.availableComplianceCitiesForSelectedState;

      complianceStateAndCitySelector = (
        <div className='form-group'>
          <div className='text-align-center'>
            <span className='form-control-label'>
              <strong>Work Location</strong>
            </span>
            {this.TooltipComponent ? (
              <this.TooltipComponent />
            ) : (
              <Tooltip
                tooltip='Select the state and city (if prompted) <br />
                            of your candidates’ primary work location.'
              />
            )}
          </div>
          <p className='text-muted'>
            This helps determine the default location-specific compliance rules
            in Checkr.
          </p>
          <TextField
            sx={{ width: '100%' }}
            className='input-signup-v4'
            label={'State'}
            value={this.form.complianceState || ''}
            error={Boolean(this.form.error?.complianceState)}
            helperText={this.form.error?.complianceState}
            onChange={e =>
              this.form.handleChange('complianceState', e.target.value)
            }
            select
            SelectProps={{
              IconComponent: KeyboardArrowDown,
              'data-testid': SIGNUP.INPUT.complianceState,
            }}
            inputProps={{ id: 'complianceState' }}
            InputLabelProps={{
              htmlFor: 'complianceState',
            }}
          >
            {stateOptions.map(({ text, value }) => (
              <MenuItem
                value={value}
                key={text}
                data-testid={`${SIGNUP.INPUT.complianceState}-option-${value}`}
              >
                {text}
              </MenuItem>
            ))}
          </TextField>

          {citiesForState.length > 0 && (
            <div>
              <TextField
                sx={{ width: '100%' }}
                className='input-signup-v4'
                label={'City'}
                value={this.form.complianceCity || ''}
                error={Boolean(this.form.error?.complianceCity)}
                helperText={this.form.error?.complianceCity}
                onChange={e =>
                  this.form.handleChange('complianceCity', e.target.value)
                }
                select
                SelectProps={{
                  IconComponent: KeyboardArrowDown,
                  'data-testid': SIGNUP.INPUT.complianceCity,
                }}
                inputProps={{ id: 'complianceCity' }}
                InputLabelProps={{
                  htmlFor: 'complianceCity',
                }}
              >
                {citiesForState.map(city => (
                  <MenuItem
                    value={city}
                    key={city}
                    data-testid={`${SIGNUP.INPUT.complianceCity}-option-${city}`}
                  >
                    {city}
                  </MenuItem>
                ))}
              </TextField>
              <p className='text-muted small'>
                If you don&apos;t see your city, please select
                &quot;Other&quot;.
              </p>
            </div>
          )}
        </div>
      );
    }

    const hasError =
      this.form &&
      this.form.error &&
      !!this.form.error.numberOfComplianceLocations;
    const groupClassName = `form-group ${hasError ? 'has-danger' : ''}`;

    return (
      <div>
        <div className={`multiple-location-selector ${groupClassName}`}>
          <SectionTitle>Do you hire for multiple locations?</SectionTitle>
          <Form.Radio
            form={this.form}
            dataTestId='multiple-locations'
            name='numberOfComplianceLocations'
            value='multiple'
            labelSubText='Yes'
          />
          <Form.Radio
            form={this.form}
            dataTestId='single-location'
            name='numberOfComplianceLocations'
            value='single'
            labelSubText='No'
          />
        </div>
        {complianceStateAndCitySelector}
      </div>
    );
  }
}

WorkLocationSelector.propTypes = {
  form: PropTypes.object.isRequired,
};
