import React from 'react';
import { Typography, Box, Modal, IconButton, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useInvitesAnalyticsTrackers } from '../hooks/useInvitesAnalyticsTrackers';
import { useStore } from '../../../../hooks/useStore';
import { InvitesModalBox } from './shared';
import { INVITES } from '../../../../constants/identifiers';
import styled from 'styled-components';

const ModalButton = styled(Button)`
  text-transform: none;
  border-radius: 0px;
  max-height: 50px;
  font-size: 16px;
  border-radius: 4px;
  font-family: National2Regular;
  padding: 17px 20px;
  font-weight: 500;
  line-height: 100%;
`;

const SkipOrderFlow = ({ handleCloseModal, modalOpen }) => {
  const store = useStore();
  const { trackSkipOrderBGCFlowCompleted } = useInvitesAnalyticsTrackers();

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      style={{ backgroundColor: 'white' }}
      data-testid={INVITES.MISC.skipOrderFlowModal}
    >
      <InvitesModalBox>
        <IconButton
          style={{ position: 'absolute', top: '8px', right: '8px' }}
          onClick={handleCloseModal}
          data-testid={INVITES.MISC.skipThisStepModalClose}
        >
          <Close />
        </IconButton>
        <Typography
          sx={{
            color: '#001730',
            fontFamily: 'National2Regular',
            fontSize: '30px',
            fontWeight: '500',
            lineHeight: '120%',
          }}
        >
          Not ready to order?
        </Typography>
        <Typography
          sx={{
            marginTop: '20px',
            fontFamily: 'National2Regular',
            fontSize: '18px',
            lineHeight: '150%',
          }}
        >
          <b>Continue</b> to complete your sign-up and order later in your
          Checkr dashboard.
        </Typography>
        <Box
          textAlign='center'
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '38px',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <ModalButton
            variant='outlined'
            disableRipple
            sx={{
              color: '#1A2026',
              border: '2px solid #e1e6eb',
              boxShadow: `0px 2px 4px -2px rgba(72, 82, 94, 0.14)`,
              '&:hover': {
                border: '2px solid #e1e6eb',
                backgroundColor: '#f7f9fa',
              },
              '&:active': { backgroundColor: '#f2f5f7' },
            }}
            onClick={() => {
              handleCloseModal();
            }}
            data-testid={INVITES.MISC.skipOrderFlowModalCancel}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant='contained'
            disableRipple
            sx={{
              backgroundColor: '#117091',
              '&:hover': { backgroundColor: '#06556F' },
              '&:active': { backgroundColor: '#02394F' },
            }}
            onClick={() => {
              store.performanceObserver.setStartLoadTime();
              trackSkipOrderBGCFlowCompleted();
              store.toStep('submission_DoThisLater');
            }}
            data-testid={INVITES.MISC.skippedOrderFlow}
          >
            Continue
          </ModalButton>
        </Box>
      </InvitesModalBox>
    </Modal>
  );
};

export default SkipOrderFlow;
