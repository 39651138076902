import { autorun, toJS } from 'mobx';
import { assign, intersection, pick } from 'lodash';
const AUTO_SAVE_DELAY_IN_MILLISECONDS = 1000;

export default class AutoSave {
  constructor(store, storeId, trackedProperties) {
    this.store = store;
    this.storeId = storeId;
    this.trackedProperties = trackedProperties;

    if (!window.localStorage) {
      return;
    }

    this.loadFromStorage();

    // Save is debounced and throttled
    autorun(() => this.saveToStorage(), AUTO_SAVE_DELAY_IN_MILLISECONDS);
  }

  loadFromStorage() {
    const storageData = JSON.parse(localStorage.getItem(this.storeId)) || {};
    const permittedProperties = intersection(
      this.trackedProperties,
      Object.keys(storageData),
    );
    assign(this.store, pick(storageData, permittedProperties));
  }

  saveToStorage() {
    const currentState = pick(this.storeData(), this.trackedProperties);
    localStorage.setItem(this.storeId, JSON.stringify(currentState));
  }

  storeData() {
    return toJS(this.store);
  }
}
