import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Loading from '../Loading';
import amplitudeExperiment from '../../services/amplitudeExperiment';

const AmplitudeExperimentWrapper = ({
  store,
  experimentKey,
  controlComponent,
  treatmentComponent,
  shouldFetchManually,
}) => {
  const [isVariantLoading, setIsVariantLoading] = useState(true);

  useEffect(() => {
    const fetchAmplitudeExperiment = async () => {
      if (shouldFetchManually) {
        await amplitudeExperiment
          .getExperiment()
          .fetch(store.getUserProperties());
      }
      setIsVariantLoading(false);
    };

    fetchAmplitudeExperiment().catch(err => {
      console.warn(err);
    });
  }, []);

  if (isVariantLoading) return <Loading />;

  return amplitudeExperiment.getVariantValue(experimentKey) === 'treatment'
    ? treatmentComponent
    : controlComponent;
};
AmplitudeExperimentWrapper.propTypes = {
  store: PropTypes.object.isRequired,
  experimentKey: PropTypes.string.isRequired,
  controlComponent: PropTypes.object.isRequired,
  treatmentComponent: PropTypes.object.isRequired,
  shouldFetchManually: PropTypes.bool.isRequired,
};

AmplitudeExperimentWrapper.defaultProps = {
  shouldFetchManually: true,
  // If fetchOnStart is true then shouldFetchManually should be false, and vice versa.
  //  Ideally, we would base this value off of amplitude experiment instead of passing it
  //  as a prop, but in the interest of not disrupting current experiments we should test
  //  this between experiments
};

export default AmplitudeExperimentWrapper;
