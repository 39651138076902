import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material/';
import { InfoOutlined } from '@mui/icons-material';
import { SCREENING_TOOLTIPS } from '../../helpers/Constants';
import { PARTNER_PACKAGES } from '../../../constants/identifiers';

const ScreeningToolTip = ({ isSelected, screening, slug }) => {
  if (!SCREENING_TOOLTIPS[screening]) return null;

  return (
    <Tooltip
      sx={{
        cursor: 'auto !important',
      }}
      title={
        <Box
          sx={{
            fontSize: '14px',
            color: '#5E5E64',
          }}
          onClick={e => e.stopPropagation()}
        >
          {SCREENING_TOOLTIPS[screening]}
        </Box>
      }
      onClick={e => e.stopPropagation()}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '15px 20px',
            boxShadow: '0px 0px 16px 0px #16161D2E',
            bgcolor: '#FFFFFF',
            '& .MuiTooltip-arrow': {
              color: '#FFFFFF',
            },
          },
        },
      }}
      arrow
    >
      <IconButton style={{ padding: '2px' }}>
        <InfoOutlined
          data-testid={`${PARTNER_PACKAGES.screeningTooltip}-${slug}-${screening}`}
          style={{
            fontSize: '0.9rem',
            marginTop: '-2px',
            color: isSelected ? '#FFF' : '#334559',
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default ScreeningToolTip;
