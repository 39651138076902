import React from 'react';
import { Card } from '@mui/material';

type PackageCardProps = {
  isSelected?: boolean;
  handleClick: () => void;
  testId: string;
  children: React.ReactNode;
  isMostPopular?: boolean;
  hasSelectedPackage?: boolean;
};

const PackageCard = ({
  handleClick,
  testId,
  children,
  isMostPopular,
  hasSelectedPackage,
  isSelected,
}: PackageCardProps) => {
  return (
    <Card
      data-testid={testId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0.5rem',
        border: `1px solid #e4e7ed`,
        cursor: 'pointer',
        backgroundColor: '#FFF',
        boxShadow: hasSelectedPackage
          ? isSelected
            ? '0px 24px 48px -16px #576c7826'
            : 'none'
          : '0px 24px 48px -16px #576c7833',
        fontFamily: 'National2Regular',
      }}
      onClick={handleClick}
    >
      {children}
    </Card>
  );
};

export default PackageCard;
