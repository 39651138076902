import React from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { INVITES } from '../../../../constants/identifiers';
import { statesWithCities } from '../data/geos';
import { useInvitesAnalyticsTrackers } from '../hooks/useInvitesAnalyticsTrackers';

const Container = styled.div`
  width: 100%;
  padding-top: 32px;
  border-top: 1px solid #e4e7ed;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
`;

const Button = styled.button`
  type: ${props => props.type};
  width: ${props => props.width};
  padding: 10px 18px;
  background: ${props => props.background || '#ffffff'};
  box-shadow: 0px 2px 4px -2px rgba(72, 82, 94, 0.14);
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e1e6eb;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: flex;
  cursor: pointer;
`;

const ArrowContainer = styled.div`
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
`;

const Text = styled.div`
  text-align: center;
  color: ${props => props.color || '#1A2026'};
  font-size: 16px;
  font-family: 'National2Regular';
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;

  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const NavigationButtons = ({
  selectedRegion,
  selectedState,
  selectedCity,
  selectedCountry,
  currentStep,
  handleSetCurrentStep,
  selectedPackage,
  showLoading,
  candidates,
}) => {
  const {
    trackSelectAPackageNextClicked,
    trackSelectAddonsNextClicked,
    trackInviteCandidatesNextClicked,
  } = useInvitesAnalyticsTrackers();

  const locationUnselected = () => {
    if (selectedRegion === 'nonUS') {
      return selectedCountry === '';
    } else {
      return (
        selectedState === '' ||
        (statesWithCities.includes(selectedState) && selectedCity === '')
      );
    }
  };

  const hasCandidates = () =>
    candidates.filter(candidate => candidate.value !== '').length;

  const submitDisabled =
    showLoading || !hasCandidates() || locationUnselected();

  const trackNextButtonClicks = () => {
    switch (currentStep) {
      case 1:
        trackSelectAPackageNextClicked();
        break;
      case 2:
        trackSelectAddonsNextClicked();
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <Button
        data-testid={INVITES.MISC.backButton}
        type='button'
        width='95px'
        onClick={() => handleSetCurrentStep('back')}
        hidden={currentStep === 1}
      >
        <ArrowContainer>
          <ChevronLeft />
        </ArrowContainer>
        <Text>Back</Text>
      </Button>
      {currentStep === 3 ? (
        <Button
          data-testid={INVITES.MISC.submitButton}
          type='submit'
          background={submitDisabled ? '#e1e6eb' : '#117292'}
          disabled={submitDisabled}
        >
          {showLoading ? (
            <>
              <i className='fa fa-spinner fa-spin fa-fw' />
              <Text color='#1a20267a'>Loading...</Text>
            </>
          ) : (
            <Text
              onClick={() => trackInviteCandidatesNextClicked()}
              color={submitDisabled ? '#1a20267a' : '#ffffff'}
            >
              Submit order
            </Text>
          )}
        </Button>
      ) : (
        <Button
          data-testid={INVITES.MISC.nextButton}
          type='button'
          background='#117292'
          width='95px'
          onClick={e => {
            e.preventDefault();
            trackNextButtonClicks();
            handleSetCurrentStep('next');
          }}
          hidden={currentStep === 3}
          disabled={!selectedPackage}
        >
          <Text color='#ffffff'>Next</Text>
          <ArrowContainer>
            <ChevronRight sx={{ color: 'white' }} />
          </ArrowContainer>
        </Button>
      )}
    </Container>
  );
};

export default NavigationButtons;
