import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const StatewideCriminalSearch = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo = 'Searches criminal records at the state level';

  const advantages = [
    'Helps meet compliance requirements for industries, such as healthcare and childcare',
    'Included counties varies by state',
    'Potential cost savings',
  ];

  const considerations = [
    'Not offered in every state',
    'Quality of data varies by state',
    'May require additional fees',
  ];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>
          Statewide Criminal Records Search - 7-year address history
        </ScreeningTitle>
        <ScreeningPrice priceByType='state_criminal_search' />
        <AddScreeningsAction
          screeningType='state_criminal_search'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default StatewideCriminalSearch;
