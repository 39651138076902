import React from 'react';
import { Box, BoxProps } from '@mui/material';

const InvitesModalBox = React.forwardRef(function InvitesModalBoxRef(
  props: BoxProps,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <Box
      ref={ref}
      fontFamily='National2Regular'
      textAlign='center'
      position='absolute'
      top='40%'
      left='50%'
      borderRadius='0px'
      border='none'
      sx={{
        transform: 'translate(-50%, -50%)',
        width: { xs: '80%', sm: '30%' },
      }}
      bgcolor='background.paper'
      boxShadow='24'
      padding='32'
      {...props}
    />
  );
});

export default InvitesModalBox;
