import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import URI from 'urijs';

const ALLOWED_SCOPES = ['read_write'];

function areDomainsMatching(domain1, domain2) {
  return (
    !domain1 || !domain2 || URI(domain1).domain() === URI(domain2).domain()
  );
}

export default function checkParams(WrapperComponent) {
  @inject('store')
  @observer
  class Wrapper extends React.Component {
    render() {
      const scope =
        this.props.store.router.location &&
        this.props.store.router.location.search &&
        new URLSearchParams(this.props.store.router.location.search).get(
          'scope',
        );

      if (scope && ALLOWED_SCOPES.indexOf(scope) === -1) {
        return (
          <pre className='error-msg text-danger'>
            <b>Error:</b> invalid scope
          </pre>
        );
      }

      const applicationRedirect =
        this.props.store &&
        this.props.store.application &&
        this.props.store.application.redirect_uri;

      const clientRedirect =
        this.props.store.router.location &&
        this.props.store.router.location.search &&
        new URLSearchParams(this.props.store.router.location.search).get(
          'redirect_uri',
        );

      if (
        clientRedirect &&
        !areDomainsMatching(clientRedirect, applicationRedirect)
      ) {
        return (
          <pre className='error-msg text-danger'>
            <b>Error:</b> redirect_uri does not match the application&apos;s
            redirect domain.
          </pre>
        );
      }

      return <WrapperComponent {...this.props} />;
    }
  }
  Wrapper.wrappedComponent.propTypes = {
    store: PropTypes.object.isRequired,
  };
  return Wrapper;
}
