import ValidationStore from '../ValidationStore';
import { authorizeAndRedirectToApplication } from './helpers/ConnectHelper';
import { makeObservable, observable } from 'mobx';

export default class SuccessStore extends ValidationStore {
  @observable secondsRemaining = 10;

  constructor(state) {
    super();
    this._state = state;
    makeObservable(this);
  }

  async tick() {
    const sec = this.secondsRemaining - 1;

    if (sec <= 0) {
      await this.handleRedirect();
      return;
    }

    this.secondsRemaining = sec;
  }

  async handleRedirect() {
    this.loading = true;
    this.stopCountDown();
    await authorizeAndRedirectToApplication(this._state);
  }

  startCountDown() {
    this.intervalHandle = setInterval(() => this.tick(), 1000);
  }

  stopCountDown() {
    clearInterval(this.intervalHandle);
  }
}
