/* eslint-disable max-len */
import { INVITES } from '../../../../constants/identifiers';

export const ADD_ON_OPTIONS_CONFIG = {
  motor_vehicle_report: {
    id: 'motor_vehicle_report',
    name: 'mvrAddOn',
    displayName: 'Motor Vehicle Record',
    type: 'motor_vehicle_report',
    price: '$9.50',
    description: `Searches a state’s DMV or similar entity to confirm a candidate’s
    driver license and identify driving incidents.`,
  },
  federal_criminal_search: {
    id: 'federal_criminal_search',
    name: 'federalAddOn',
    displayName: 'Federal Criminal Search',
    subtype: '10years',
    type: 'federal_criminal_search',
    price: '$10.00',
    description: `Searches all US federal district courts for federal offenses
    not shown in county and state.`,
  },
  education_verification: {
    id: 'education_verification',
    name: 'educationAddOn',
    displayName: 'Education Verification',
    type: 'education_verification',
    price: '$12.50',
    description: `Verifies the highest degree obtained from high school or equivalent through doctorates.`,
    preferences: {
      requires_history: false,
      international_upgrade: false,
      passthrough_fees: true,
      requires_completed_degrees: false,
      document_collection: true,
    },
  },
};

export const PHASE2_ADD_ON_OPTIONS_CONFIG = {
  motor_vehicle_report: {
    displayName: 'Motor vehicle record (MVR) search',
    type: 'motor_vehicle_report',
    price: '$9.50',
    description: `Confirms a candidate’s driver's license and identifies driving incidents`,
  },
  federal_criminal_search: {
    displayName: 'Federal criminal search',
    subtype: '10years',
    type: 'federal_criminal_search',
    price: '$10.00',
    description: `Searches for federal felony and misdemeanor offenses including crimes that cross state lines`,
  },
  employment_verification: {
    displayName: 'Employment verification (7-year lookback)',
    type: 'employment_verification',
    price: '$35.00',
    description: `Verifies the accuracy of a candidate's employment history to help provide a comprehensive understanding of their experience and qualifications`,
  },
  state_criminal_search: {
    displayName: 'State criminal search (7-year lookback)',
    type: 'state_criminal_search',
    price: '$25.00',
    description: `Searches for potential criminal records in the state where your candidate works and/or has address history (where applicable)`,
  },
  county_criminal_search: {
    displayName: 'County criminal search (7-year lookback)',
    type: 'county_criminal_search',
    price: '$25.00',
    description: `Searches county records for an in-depth look at a candidate’s criminal history including most felony and misdemeanor cases`,
  },
  federal_civil_search: {
    displayName: 'Federal civil search',
    type: 'federal_civil_search',
    price: '$15.00',
    description: `Searches the US District Court level for civil cases filed in federal court to help provide a comprehensive screening for financial and managerial roles (ex: bankruptcy)`,
  },
  county_civil_search: {
    displayName: 'County civil search',
    type: 'county_civil_search',
    price: '$15.00',
    description: `Searches county and/or circuit courts to help identify non-criminal lawsuits and show claims, suits, or judgments where the candidate is named as a plaintiff or defendant (ex: restraining orders)`,
  },
  education_verification: {
    displayName: 'Education verification (Highest level)',
    type: 'education_verification',
    price: '$12.50',
    description: `Verifies the highest degree obtained from high school or equivalent through doctorates`,
    preferences: {
      requires_history: false,
      international_upgrade: false,
      passthrough_fees: true,
      requires_completed_degrees: false,
      document_collection: true,
    },
  },
  professional_license_verification: {
    displayName: 'Professional license verification',
    type: 'professional_license_verification',
    price: '$12.00',
    description: `Verifies a professional license to determine eligibility for organizations with specialized or regulated roles`,
  },
  personal_reference_verification: {
    displayName: 'Personal reference verification',
    type: 'personal_reference_verification',
    price: '$10.00',
    description: `Contacts a reference to verify character in a personal capacity`,
  },
  professional_reference_verification: {
    displayName: 'Professional reference verification',
    type: 'professional_reference_verification',
    price: '$10.00',
    description: `Contacts a reference to verify qualifications and job history`,
  },
  facis_search: {
    displayName: 'Fraud Abuse Control Information System (FACIS) Level 1M',
    type: 'facis_search',
    subtype: 3,
    price: '$6.00',
    description: `Searches for penalties, suspensions, or punitive or disciplinary actions (meets minimum federal requirements)`,
  },
};

export const PACKAGE_CONFIG = {
  domestic: {
    basic_plus_domestic: {
      name: 'Basic +',
      subHeader: 'Simple and limited search',
      icon: 'basic',
      slug: 'checkrdirect_basic_plus_criminal',
      label: '',
      price: '$29.99',
      description: [
        { name: 'SSN trace', testId: INVITES.MISC.ssnTrace },
        {
          name: 'Sex offender registry search',
          testId: INVITES.MISC.sexOffender,
        },
        {
          name: 'Global watchlist search',
          testId: INVITES.MISC.globalWatchlist,
        },
        {
          name: 'National criminal search',
          testId: INVITES.MISC.nationalCriminal,
          details: [
            // eslint-disable-next-line max-len
            'Checks national databases to find records that might require attention. These results are often incomplete, lack identifying information, and/or don’t show final dispositions.',
            // eslint-disable-next-line max-len
            'If a national criminal record is found, it automatically triggers a county criminal search for the identified counties only. A $15 verification fee and additional court fees may apply.',
          ],
        },
      ],
      commonAddOns: {
        options: [
          'motor_vehicle_report',
          'federal_criminal_search',
          'education_verification',
        ],
      },
      styleConfig: {
        hover: {
          button: {
            backgroundColor: '#f6f6f7',
            color: '#334559',
          },
        },
        selected: {
          backgroundColor: '#576C82',
          button: {
            backgroundColor: '#576C82',
            color: '#fff',
          },
        },
        unselected: {
          backgroundColor: '#f6f6f7',
          primaryText: '#001730',
          secondaryText: 'rgba(26, 32, 38, 0.78)',
          button: {
            backgroundColor: '#FFF',
            color: '#334559',
          },
        },
      },
    },
    essential_domestic: {
      name: 'Essential',
      subHeader: 'In-depth criminal search, down to the county',
      icon: 'essential',
      slug: 'checkrdirect_essential_criminal',
      label: 'most popular',
      isMostPopular: true,
      price: '$54.99',
      description: [
        {
          name: 'Everything in Basic +',
          isSummary: true,
          testId: INVITES.MISC.allBasic,
        },
        {
          name: 'County criminal search (7-year)',
          testId: INVITES.MISC.comprehensiveCounty,
          isHighlighted: true,
          details: [
            // eslint-disable-next-line max-len
            `Searches for criminal records at the county level (where most records occur) anywhere a registered address is found for the past 7 years. County-level verification fee included, but additional court fees may apply.`,
          ],
        },
      ],
      commonAddOns: {
        options: [
          'motor_vehicle_report',
          'federal_criminal_search',
          'education_verification',
        ],
      },
      styleConfig: {
        hover: {
          button: {
            backgroundColor: '#E8FAFC',
            color: '#334559',
          },
        },
        selected: {
          backgroundColor: '#047591',
          primaryText: '#FFF',
          secondaryText: 'rgba(255, 255, 255, 0.78',
          button: {
            backgroundColor: '#047591',
            color: '#FFF',
          },
        },
        unselected: {
          backgroundColor: '#E8FAFC',
          primaryText: '#001730',
          secondaryText: 'rgba(26, 32, 38, 0.78)',
          button: {
            backgroundColor: '#FFF',
            color: '#334559',
          },
        },
      },
    },
    professional_domestic: {
      name: 'Professional',
      subHeader: 'Additional verification for advanced roles',
      icon: 'professional',
      slug: 'checkrdirect_professional_criminal',
      label: '',
      price: '$79.99',
      description: [
        {
          name: 'Everything in Essential',
          isSummary: true,
          testId: INVITES.MISC.allEssential,
        },
        {
          name: 'Education verification',
          testId: INVITES.MISC.educationVerification,
          isHighlighted: true,
          details: [
            // eslint-disable-next-line max-len
            `Confirms the highest level of education history to help ensure a person has the required degree to operate in a role.`,
          ],
        },
        {
          name: 'Employment verification',
          testId: INVITES.MISC.employmentVerification,
          isHighlighted: true,
          details: [
            // eslint-disable-next-line max-len
            `Confirms most recent work history to help ensure a person has appropriate work experience to operate in a role.`,
          ],
        },
      ],
      commonAddOns: {
        options: [
          'motor_vehicle_report',
          'federal_criminal_search',
          'education_verification',
        ],
      },
      styleConfig: {
        hover: {
          button: {
            backgroundColor: '#E8F0F9',
            color: '#334559',
          },
        },
        selected: {
          backgroundColor: '#004989',
          color: '#000',
          button: {
            backgroundColor: '#004989',
            color: '#fff',
          },
        },
        unselected: {
          backgroundColor: '#E8F0F9',
          primaryText: '#001730',
          secondaryText: 'rgba(26, 32, 38, 0.78)',
          button: {
            backgroundColor: '#FFF',
            color: '#334559',
          },
        },
      },
    },
  },
  international: {
    basic_plus_intl: {
      name: 'International Basic+',
      subHeader: 'Simple and limited search',
      slug: 'checkrdirect_international_basic_plus',
      icon: 'basic',
      label: '',
      price: '$32',
      description: [
        {
          name: 'International criminal (or adverse media)',
          testId: INVITES.MISC.internationalCriminal,
        },
        {
          name: 'Global watchlist search',
          testId: INVITES.MISC.globalWatchlistIntl,
        },
      ],
      styleConfig: {
        hover: {
          button: {
            backgroundColor: '#f6f6f7',
            color: '#334559',
          },
        },
        selected: {
          backgroundColor: '#576C82',
          button: {
            backgroundColor: '#576C82',
            color: '#fff',
          },
        },
        unselected: {
          backgroundColor: '#f6f6f7',
          primaryText: '#001730',
          secondaryText: 'rgba(26, 32, 38, 0.78)',
          button: {
            backgroundColor: '#FFF',
            color: '#334559',
          },
        },
      },
    },
    professional_intl: {
      name: 'International Professional',
      subHeader: 'Additional verifications for advanced roles',
      slug: 'checkrdirect_international_professional',
      icon: 'essential',
      label: 'most popular',
      isMostPopular: true,
      price: '$102',
      description: [
        {
          name: 'Everything in International Basic +',
          isSummary: true,
          testId: INVITES.MISC.allBasicIntl,
        },
        {
          name: 'Employment verification',
          testId: INVITES.MISC.employmentVerificationIntl,
          isHighlighted: true,
          details: [
            // eslint-disable-next-line max-len
            `Confirms most recent work history to help ensure a person has appropriate work experience to operate in a role.`,
          ],
        },
        {
          name: 'Education verification',
          testId: INVITES.MISC.educationVerificationIntl,
          isHighlighted: true,
          details: [
            // eslint-disable-next-line max-len
            `Confirms the highest level of education history to help ensure a person has the required degree to operate in a role.`,
          ],
        },
      ],
      styleConfig: {
        hover: {
          button: {
            backgroundColor: '#E8FAFC',
            color: '#334559',
          },
        },
        selected: {
          backgroundColor: '#047591',
          primaryText: '#FFF',
          secondaryText: 'rgba(255, 255, 255, 0.78',
          button: {
            backgroundColor: '#047591',
            color: '#FFF',
          },
        },
        unselected: {
          backgroundColor: '#E8FAFC',
          primaryText: '#001730',
          secondaryText: 'rgba(26, 32, 38, 0.78)',
          button: {
            backgroundColor: '#FFF',
            color: '#334559',
          },
        },
      },
    },
    professional_plus_intl: {
      name: 'International Professional+',
      subHeader: 'Also verifies the authenticity of ID documents',
      slug: 'checkrdirect_international_professional_plus',
      label: '',
      price: '$124',
      icon: 'professional',
      description: [
        {
          name: 'Everything in International Professional',
          isSummary: true,
          testId: INVITES.MISC.allProfessional,
        },
        {
          name: 'Identity document verification',
          isHighlighted: true,
          testId: INVITES.MISC.identityDocument,
        },
      ],
      styleConfig: {
        hover: {
          button: {
            backgroundColor: '#E8F0F9',
            color: '#334559',
          },
        },
        selected: {
          backgroundColor: '#004989',
          color: '#000',
          button: {
            backgroundColor: '#004989',
            color: '#fff',
          },
        },
        unselected: {
          backgroundColor: '#E8F0F9',
          primaryText: '#001730',
          secondaryText: 'rgba(26, 32, 38, 0.78)',
          button: {
            backgroundColor: '#FFF',
            color: '#334559',
          },
        },
      },
    },
  },
};
