import React, { Component, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import styled from 'styled-components';
import {
  HelpOutlineOutlined,
  InfoOutlined,
  KeyboardArrowDown,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material/';

import * as Form from '../../form';
import WorkLocationSelector from './WorkLocationSelector';
import StepUnloadWarningModal from '../../StepUnloadWarningModal';
import SignupWrapper from './SignupWrapper';
import ReCAPTCHA from '../../CheckrReCAPTCHA';
import { STATE_OPTIONS } from '../../helpers/Constants';
import { NewPartnerSignupProgressBar } from '../../ProgressBar';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../../services/analytics';
import PasswordStrengthHelper, {
  Icon as PasswordStrengthIcon,
} from '../CheckrDirect/PasswordStrengthHelper';
import { SIGNUP } from '../../../constants/identifiers';
import { PhoneMask } from './Welcome';

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainContent = styled.div`
  padding: 0 16px;
  max-width: 925px;
`;

const CopyWrapper = styled.div`
  max-width: 800px;
  margin: auto;
`;

const FormWrapper = styled.div`
  margin: auto;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
  padding: 0 124px;
`;

const WrapWorkLocationSelector = styled.div`
  margin: 40px 0px;
  max-width: 445px;
  font-family: National2Regular;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  .multiple-location-selector {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .form-control-label {
    width: 100%;
  }

  .form-control-label strong {
    font-family: National2Medium;
  }

  .custom-input {
    margin-left: 32px;
  }

  .text-muted {
    color: #0a1626 !important;
  }
`;

const SectionTitle = props => (
  <Typography
    textAlign='left'
    fontWeight='500'
    marginBottom='16px'
    fontSize='18px'
    color='#001730'
    fontFamily='National2Medium'
    {...props}
  />
);

const CheckboxText = props => (
  <Typography
    fontWeight='400'
    lineHeight='24px'
    fontSize='16px'
    color='#0A1626'
    fontFamily='National2Regular'
    {...props}
  />
);

const TextFieldMask = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, mask, definitions, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={definitions}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      onChange={() => {}}
    />
  );
});

const WorkLocationTooltip = () => {
  return (
    <Tooltip
      title={
        <Box
          component='p'
          sx={{
            fontSize: '14px',
            color: '#5E5E64',
            maxHeight: '116px',
            my: 'auto',
          }}
        >
          Select the state and city (if prompted) <br />
          of your candidates’ primary work location.
        </Box>
      }
      componentsProps={{
        tooltip: {
          sx: {
            padding: '15px 18px',
            boxShadow: '0px 0px 16px 0px #16161D2E',
            bgcolor: '#FFFFFF',
            '& .MuiTooltip-arrow': {
              color: '#FFFFFF',
            },
          },
        },
      }}
      arrow
    >
      <IconButton
        sx={{ cursor: 'auto !important', padding: '0px', marginLeft: '8px' }}
      >
        <InfoOutlined
          style={{
            color: '#000000',
            fontSize: '0.9rem',
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

@inject('store')
@observer
export default class PartnerSignup extends Component {
  constructor(props) {
    super(props);
    this.state = { passwordVisible: false };
    this.store = this.props.store;
    this.form = this.store.signupV3;
    this.form.assignParams();
    this.recaptchaRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    if (this.form.firstName || this.form.lastName) {
      this.form.handleChange(
        'fullName',
        `${this.form.firstName ? `${this.form.firstName} ` : ''}${
          this.form.lastName || ''
        }`,
      );
    }
  }

  componentDidMount() {
    if (this.store.application.sign_up_flow) {
      this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.SIGN_UP_PAGE_VIEWED);
    }
  }

  trackErrorsDisplayed(errors = {}) {
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.ERROR_DISPLAYED_CONTACT, {
      [ANALYTICS_PROPERTIES.ERROR_MESSAGE]: Object.values(errors).map(
        ([message]) => message,
      ),
    });
  }

  trackMultipleWorkLocations(form) {
    const eventValue =
      form.numberOfComplianceLocations === 'multiple' ? 'Yes' : 'No';
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.WORK_LOCATION_SELECTED, {
      [ANALYTICS_PROPERTIES.MULTIPLE_WORK_LOCATIONS]: eventValue,
    });
  }

  serviceAgreementUrl = () => {
    return (
      this.store.application.custom_service_agreement_url ||
      'https://checkr.com/customer-agreement/'
    );
  };

  submitWithRecaptcha = e => {
    this.form.loading = true;
    e.preventDefault();
    const errors = this.form.validateOnEvent();

    if (errors) {
      this.form.error.error = 'signupV3.errors';
      this.form.loading = false;
      this.trackErrorsDisplayed(errors);
      window.scrollTo(0, 0);
      return;
    } else {
      this.recaptchaRef.current
        .executeAsync()
        .then(recaptchaCode => {
          this.form.submit(recaptchaCode);
        })
        .then(() => this.trackMultipleWorkLocations(this.form))
        .catch(() => {
          window.scrollTo(0, 0);
          this.form.error = {
            error: 'Recaptcha encountered an error',
          };
        })
        .finally(() => {
          this.recaptchaRef.current?.reset();
        });
    }

    this.form.loading = false;
  };

  render() {
    if (!this.store.application.sign_up_flow) {
      return <Redirect to={this.store.getStep('signin')} />;
    }

    if (!this.form.company || !this.form.fullName) {
      return <Redirect to={this.store.getStep('welcome')} />;
    }

    return (
      <SignupWrapper>
        <StepUnloadWarningModal />
        <NewPartnerSignupProgressBar
          style={{ maxWidth: '480px' }}
          type={this.store.showPackageCustomization() ? 'variant' : 'default'}
          currentStep={1}
        />
        <FlexColumn className='sc-flex-column'>
          <MainContent className='sc-main-content container'>
            <CopyWrapper>
              <Typography
                textAlign='center'
                fontWeight='600'
                color='#001730'
                fontFamily='ArgentCFRegular'
                sx={{
                  typography: {
                    xs: { fontSize: '28px' },
                    lg: { fontSize: '42px' },
                  },
                }}
              >
                Create your free account
              </Typography>
              <Typography
                textAlign='center'
                my='18px'
                fontWeight='400'
                lineHeight='150%'
                sx={{
                  typography: {
                    xs: { fontSize: '16px' },
                    lg: { fontSize: '18px' },
                  },
                }}
                color='#0A1626'
                fontFamily='National2Regular'
              >
                {`We’re excited for you to join us! To get started, we’ll create
              your free Checkr account. Then, you can customize one of the
              Checkr packages you’ll find in ${this.store.application?.partner_name}.`}
              </Typography>
              <Typography
                textAlign='center'
                fontWeight='500'
                lineHeight='120%'
                sx={{
                  typography: {
                    xs: { fontSize: '24px' },
                    lg: { fontSize: '30px' },
                  },
                }}
                color='#001730'
                fontFamily='National2Medium'
              >
                Your account information
              </Typography>
              <Typography
                textAlign='center'
                marginTop='18px'
                marginBottom='36px'
                fontWeight='400'
                lineHeight='150%'
                sx={{
                  typography: {
                    xs: { fontSize: '16px' },
                    lg: { fontSize: '18px' },
                  },
                }}
                color='#0A1626'
                fontFamily='National2Regular'
              >
                {`First, let’s create a login password to use with your business
              email. In addition to ordering background checks through ${this.store.application?.partner_name}, 
              this also gives you access to the `}
                <Typography fontWeight='700' fontFamily='National2Medium'>
                  Checkr Dashboard.
                </Typography>
              </Typography>
            </CopyWrapper>
            <FormWrapper className='sc-form-wrapper'>
              <Form.Form form={this.form}>
                <Form.Error error={this.form.error} store={this.store} />
                <TextField
                  className='input-signup-v4'
                  style={{
                    marginBottom: '16px',
                  }}
                  type='text'
                  label='Business email'
                  value={this.form.email}
                  variant='outlined'
                  error={Boolean(this.form.error?.email)}
                  helperText={
                    <span data-testid={SIGNUP.ERROR.email}>
                      {this.form.error?.email}
                    </span>
                  }
                  inputProps={{
                    maxLength: 256,
                    style: { backgroundColor: 'white' },
                    'data-testid': SIGNUP.INPUT.email,
                  }}
                  onChange={e => {
                    this.form.handleChange('email', e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
                <SpaceBetween className='sc-space-between'>
                  <TextField
                    className='input-signup-v4'
                    style={{
                      width: '49%',
                    }}
                    type={this.state.passwordVisible ? 'text' : 'password'}
                    label='Create password'
                    variant='outlined'
                    error={Boolean(this.form.error?.password)}
                    inputProps={{
                      autoComplete: 'off',
                      'data-testid': SIGNUP.INPUT.password,
                    }}
                    InputProps={{
                      style: { backgroundColor: 'white' },
                      maxLength: 35,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                passwordVisible: !this.state.passwordVisible,
                              })
                            }
                          >
                            {this.state.passwordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    FormHelperTextProps={{
                      style: {
                        backgroundColor: 'transparent',
                        paddingTop: '8px',
                        marginBottom: '16px',
                      },
                      'data-testid': SIGNUP.MISC.passwordStrengthHelper,
                    }}
                    helperText={
                      <>
                        <div>
                          <span data-testid={SIGNUP.ERROR.password}>
                            {this.form.error?.password}
                          </span>
                        </div>
                        {!this.form.password ? (
                          <Box className='d-flex' sx={{ marginLeft: '-12px' }}>
                            <Box
                              sx={{ color: '#002853' }}
                              data-testid={SIGNUP.MISC.passwordRequirements}
                            >
                              Requirements
                            </Box>
                            <Tooltip
                              sx={{
                                cursor: 'auto !important',
                              }}
                              data-testid={`${SIGNUP.MISC.passwordRequirements}-tooltip`}
                              title={
                                <Box
                                  component='p'
                                  sx={{
                                    fontSize: '14px',
                                    color: '#5E5E64',
                                    maxHeight: '140px',
                                  }}
                                >
                                  Passwords must have at least:
                                  <Box
                                    component='p'
                                    sx={{
                                      marginTop: '-20px',
                                      px: '20px',
                                    }}
                                  >
                                    <br />
                                    <br />
                                    8 characters
                                    <br />
                                    One special character (!@#$%^&*)
                                    <br />
                                    One uppercase letter
                                    <br />
                                    One lowercase letter
                                    <br />
                                    One number (0-9)
                                  </Box>
                                </Box>
                              }
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    padding: '15px 20px',
                                    boxShadow: '0px 0px 16px 0px #16161D2E',
                                    bgcolor: '#FFFFFF',
                                    '& .MuiTooltip-arrow': {
                                      color: '#FFFFFF',
                                    },
                                  },
                                },
                              }}
                              arrow
                            >
                              <IconButton style={{ padding: '2px' }}>
                                <HelpOutlineOutlined
                                  style={{
                                    color: '#000000',
                                    fontSize: '0.9rem',
                                    marginTop: '-2px',
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box sx={{ marginLeft: '-12px' }}>
                            <PasswordStrengthIcon
                              pwLength={this.form.password?.length || 0}
                              verified={
                                this.form.password ===
                                this.form.confirmedPassword
                              }
                            />
                            <span>Passwords match</span>
                            <br />
                            <PasswordStrengthHelper
                              password={this.form.password}
                            />
                          </Box>
                        )}
                      </>
                    }
                    onChange={e => {
                      this.form.handleChange('password', e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                  <TextField
                    className='input-signup-v4'
                    style={{
                      width: '49%',
                    }}
                    type={this.state.passwordVisible ? 'text' : 'password'}
                    label='Confirm password'
                    variant='outlined'
                    error={Boolean(this.form.error?.confirmedPassword)}
                    inputProps={{
                      autoComplete: 'off',
                      'data-testid': SIGNUP.INPUT.confirmPassword,
                    }}
                    InputProps={{
                      style: { backgroundColor: 'white' },
                      maxLength: 35,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                passwordVisible: !this.state.passwordVisible,
                              })
                            }
                          >
                            {this.state.passwordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={e => {
                      this.form.handleChange(
                        'confirmedPassword',
                        e.target.value,
                      );
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </SpaceBetween>
                <CopyWrapper>
                  <Typography
                    marginTop='16px'
                    textAlign='center'
                    fontWeight='500'
                    lineHeight='120%'
                    sx={{
                      typography: {
                        xs: { fontSize: '24px' },
                        lg: { fontSize: '30px' },
                      },
                    }}
                    color='#001730'
                    fontFamily='National2Medium'
                  >
                    Your business information
                  </Typography>
                  <Typography
                    textAlign='center'
                    marginTop='18px'
                    marginBottom='36px'
                    fontWeight='400'
                    lineHeight='150%'
                    sx={{
                      typography: {
                        xs: { fontSize: '16px' },
                        lg: { fontSize: '18px' },
                      },
                    }}
                    color='#0A1626'
                    fontFamily='National2Regular'
                  >
                    Next, we need to know a few more details about your business
                    to help with verification, which typically takes 1-2
                    business days. We’ll also quickly confirm your permissible
                    purpose.
                  </Typography>
                </CopyWrapper>
                <SpaceBetween className='sc-space-between'>
                  <TextField
                    className='input-signup-v4'
                    style={{
                      width: '49%',
                    }}
                    type='text'
                    label='Business name'
                    value={this.form.company}
                    variant='outlined'
                    error={Boolean(this.form.error?.company)}
                    helperText={
                      <span data-testid={SIGNUP.ERROR.company}>
                        {this.form.error?.company}
                      </span>
                    }
                    inputProps={{
                      maxLength: 256,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.company,
                    }}
                    onChange={e => {
                      this.form.handleChange('company', e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    className='input-signup-v4'
                    style={{
                      width: '49%',
                    }}
                    type='text'
                    label='Phone number'
                    value={this.form.phone}
                    variant='outlined'
                    error={Boolean(this.form.error?.phone)}
                    helperText={
                      <span data-testid={SIGNUP.ERROR.phone}>
                        {this.form.error?.phone}
                      </span>
                    }
                    inputProps={{
                      maxLength: 12,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.phone,
                    }}
                    onChange={e => {
                      this.form.handleChange('phone', e.target.value);
                    }}
                    InputProps={{ inputComponent: PhoneMask }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </SpaceBetween>
                <SpaceBetween className='sc-space-between'>
                  <TextField
                    className='input-signup-v4'
                    style={{ marginBottom: '16px', width: '49%' }}
                    type='text'
                    label='Business address 1'
                    value={this.form.street || ''}
                    variant='outlined'
                    error={Boolean(this.form.error?.street)}
                    helperText={
                      <span data-testid={SIGNUP.ERROR.street}>
                        {this.form.error?.street}
                      </span>
                    }
                    inputProps={{
                      maxLength: 35,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.street,
                    }}
                    onChange={e => {
                      this.form.handleChange('street', e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                  <TextField
                    className='input-signup-v4'
                    style={{ marginBottom: '16px', width: '49%' }}
                    type='text'
                    label='Business address 2'
                    value={this.form.street2 || ''}
                    variant='outlined'
                    error={Boolean(this.form.error?.street2)}
                    helperText={
                      <span data-testid={SIGNUP.ERROR.street2}>
                        {this.form.error?.street2}
                      </span>
                    }
                    inputProps={{
                      maxLength: 35,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.street2,
                    }}
                    onChange={e => {
                      this.form.handleChange('street2', e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </SpaceBetween>
                <SpaceBetween className='sc-space-between'>
                  <TextField
                    className='input-signup-v4'
                    style={{ marginBottom: '16px', width: '49%' }}
                    type='text'
                    label='City'
                    value={this.form.city || ''}
                    variant='outlined'
                    error={Boolean(this.form.error?.city)}
                    helperText={
                      <span data-testid={SIGNUP.ERROR.city}>
                        {this.form.error?.city}
                      </span>
                    }
                    inputProps={{
                      maxLength: 35,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.city,
                    }}
                    InputProps={{
                      endAdornment: (
                        <Tooltip
                          title={
                            <Box
                              component='p'
                              sx={{
                                fontSize: '14px',
                                color: '#5E5E64',
                                maxHeight: '116px',
                                my: 'auto',
                              }}
                            >
                              Address must match the one associated with your
                              Tax ID / EIN
                            </Box>
                          }
                          componentsProps={{
                            tooltip: {
                              sx: {
                                padding: '15px 18px',
                                boxShadow: '0px 0px 16px 0px #16161D2E',
                                bgcolor: '#FFFFFF',
                                '& .MuiTooltip-arrow': {
                                  color: '#FFFFFF',
                                },
                              },
                            },
                          }}
                          arrow
                        >
                          <IconButton sx={{ cursor: 'auto !important' }}>
                            <InfoOutlined
                              style={{
                                color: '#000000',
                                fontSize: '0.9rem',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    onChange={e => {
                      this.form.handleChange('city', e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                  <SpaceBetween style={{ width: '49%' }}>
                    <TextField
                      className='input-signup-v4'
                      style={{ width: '39%' }}
                      label='State'
                      value={this.form.stateName || ''}
                      variant='outlined'
                      error={Boolean(this.form.error?.stateName)}
                      helperText={
                        <span data-testid={SIGNUP.ERROR.state}>
                          {this.form.error?.stateName}
                        </span>
                      }
                      name='stateName'
                      onChange={e => {
                        this.form.handleChange('stateName', e.target.value);
                      }}
                      inputProps={{
                        maxLength: 5,
                        style: { backgroundColor: 'white' },
                        id: 'state',
                      }}
                      InputLabelProps={{
                        htmlFor: 'state',
                      }}
                      SelectProps={{
                        IconComponent: KeyboardArrowDown,
                        'data-testid': SIGNUP.INPUT.state,
                      }}
                      select
                    >
                      {STATE_OPTIONS.map(stateOption => (
                        <MenuItem
                          key={stateOption.text}
                          value={stateOption.value}
                        >
                          {stateOption.text}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      className='input-signup-v4'
                      style={{ width: '59%' }}
                      type='text'
                      label='Zip code'
                      value={this.form.zipcode || ''}
                      variant='outlined'
                      error={Boolean(this.form.error?.zipcode)}
                      helperText={
                        <span data-testid={SIGNUP.ERROR.zip}>
                          {this.form.error?.zipcode}
                        </span>
                      }
                      inputProps={{
                        maxLength: 5,
                        style: { backgroundColor: 'white' },
                        'data-testid': SIGNUP.INPUT.zip,
                      }}
                      onChange={e => {
                        this.form.handleChange('zipcode', e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </SpaceBetween>
                </SpaceBetween>
                <SpaceBetween>
                  <TextField
                    className='input-signup-v4'
                    style={{ width: '49%' }}
                    type='text'
                    label='US tax ID (ex: 12-3456789)'
                    variant='outlined'
                    value={this.form.taxId || ''}
                    error={Boolean(this.form.error?.taxId)}
                    helperText={
                      <span data-testid={SIGNUP.ERROR.taxID}>
                        {this.form.error?.taxId}
                      </span>
                    }
                    inputProps={{
                      maxLength: 10,
                      minLength: 9,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.taxID,
                      mask: '00-0000000',
                      definitions: { '#': /[1-9]/ },
                    }}
                    InputProps={{
                      inputComponent: TextFieldMask,
                      style: {
                        backgroundColor: 'white',
                      },
                      endAdornment: (
                        <Tooltip
                          title={
                            <Box
                              component='p'
                              sx={{
                                fontSize: '14px',
                                color: '#5E5E64',
                                maxHeight: '116px',
                                my: 'auto',
                              }}
                            >
                              If your business is tax exempt, send a copy of
                              your certificate to tax@checkr.com after sign up.
                            </Box>
                          }
                          componentsProps={{
                            tooltip: {
                              sx: {
                                padding: '15px 18px',
                                boxShadow: '0px 0px 16px 0px #16161D2E',
                                bgcolor: '#FFFFFF',
                                '& .MuiTooltip-arrow': {
                                  color: '#FFFFFF',
                                },
                              },
                            },
                          }}
                          arrow
                        >
                          <IconButton sx={{ cursor: 'auto !important' }}>
                            <InfoOutlined
                              style={{
                                color: '#000000',
                                fontSize: '0.9rem',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    onChange={e => {
                      this.form.handleChange('taxId', e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                  <TextField
                    className='input-signup-v4'
                    style={{ marginBottom: '16px', width: '49%' }}
                    type='text'
                    label='Doing business as (optional)'
                    value={this.form.nameDba || ''}
                    variant='outlined'
                    error={Boolean(this.form.error?.nameDba)}
                    helperText={
                      <span data-testid={SIGNUP.ERROR.na}>
                        {this.form.error?.nameDba}
                      </span>
                    }
                    inputProps={{
                      maxLength: 35,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.nameDba,
                    }}
                    InputProps={{
                      endAdornment: (
                        <Tooltip
                          title={
                            <Box
                              component='p'
                              sx={{
                                fontSize: '14px',
                                color: '#5E5E64',
                                maxHeight: '116px',
                                my: 'auto',
                              }}
                            >
                              Use if your organization operates under a name
                              that’s different than your legal business name.
                              Additional verification may be required.
                            </Box>
                          }
                          componentsProps={{
                            tooltip: {
                              sx: {
                                padding: '15px 18px',
                                boxShadow: '0px 0px 16px 0px #16161D2E',
                                bgcolor: '#FFFFFF',
                                '& .MuiTooltip-arrow': {
                                  color: '#FFFFFF',
                                },
                              },
                            },
                          }}
                          arrow
                        >
                          <IconButton sx={{ cursor: 'auto !important' }}>
                            <InfoOutlined
                              style={{
                                color: '#000000',
                                fontSize: '0.9rem',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    onChange={e => {
                      this.form.handleChange('nameDba', e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </SpaceBetween>
                <Form.Checkbox
                  form={this.form}
                  className='mt-2 mb-2'
                  dataTestId={SIGNUP.INPUT.taxExempt}
                  label={
                    <CheckboxText>
                      Check this box if your business is tax exempt. (Sales tax
                      exemption certificate required)
                    </CheckboxText>
                  }
                  name='taxExempt'
                  field='taxExempt'
                  value='true'
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <WrapWorkLocationSelector className='wrap-work-location-selector'>
                    <WorkLocationSelector
                      form={this.form}
                      TooltipComponent={WorkLocationTooltip}
                    />
                  </WrapWorkLocationSelector>
                </Box>
                <SectionTitle>Confirmation of purpose</SectionTitle>
                <Form.Checkbox
                  label={
                    <>
                      <CheckboxText marginBottom='48px'>
                        {`I certify that I will order and use the Reports only for a lawful and appropriate 
                        permissible purpose, as defined by applicable law (including but not limited to the 
                        FCRA) and will use reports in accordance with all applicable law and the terms set 
                        forth in the Checkr, Inc. Services Agreement. `}
                        <a
                          href='https://checkr.com/customer-agreement'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Checkr Customer Services Agreement.
                        </a>
                      </CheckboxText>
                    </>
                  }
                  form={this.form}
                  name='purpose'
                  value='employment'
                  dataTestId={SIGNUP.MISC.purpose}
                />
                <ReCAPTCHA recaptchaRef={this.recaptchaRef} />
                <FormFooter className='sc-form-footer'>
                  <Form.Submit
                    customClassName='btn btn-signup-v4'
                    text='Continue'
                    loading={this.form.loading}
                    submitWithRecaptcha={this.submitWithRecaptcha}
                    dataTestId={SIGNUP.MISC.submit}
                    disabled={
                      !this.form.email ||
                      !this.form.password ||
                      !this.form.confirmedPassword ||
                      !this.form.company ||
                      !this.form.phone ||
                      !this.form.taxId ||
                      !this.form.street ||
                      !this.form.city ||
                      !this.form.zipcode ||
                      !this.form.stateName ||
                      !this.form.numberOfComplianceLocations ||
                      !this.form.purpose
                    }
                  />
                </FormFooter>
                <p
                  className='text-muted text-center cd-footer mt-2'
                  style={{ fontFamily: 'National2Medium' }}
                >
                  By continuing, you agree to the Checkr, Inc.{' '}
                  <a
                    href='https://checkr.com/privacy-policy/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    href={this.serviceAgreementUrl()}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Services Agreement
                  </a>
                  .
                </p>
              </Form.Form>
            </FormWrapper>
          </MainContent>
        </FlexColumn>
      </SignupWrapper>
    );
  }
}
