import { datadogRum } from '@datadog/browser-rum';

function getRedirectUri(state) {
  let uri = state.application.redirect_uri;

  const redirect =
    state.router.location &&
    state.router.location.search &&
    new URLSearchParams(state.router.location.search).get('redirect_uri');

  if (redirect && redirect.length > 0) {
    uri = redirect;
  }

  return uri;
}

export function buildRedirectURL(state, code) {
  const redirect =
    state.router.location &&
    state.router.location.search &&
    new URLSearchParams(state.router.location.search).get('redirect_uri');

  const routerState =
    state.router.location &&
    state.router.location.search &&
    new URLSearchParams(state.router.location.search).get('state');

  const params = {
    code,
    redirect,
    state: routerState,
    defaultRedirect: state.application.redirect_uri,
  };

  let queryString = '?code=' + params.code;
  if (params.state) {
    queryString += '&state=' + params.state;
  }
  let url = params.defaultRedirect;
  if (params.redirect) {
    url = params.redirect;
  }
  return url + queryString;
}

export function authorize(state) {
  const scope =
    state.router.location &&
    state.router.location.search &&
    new URLSearchParams(state.router.location.search).get('scope');

  const clientId =
    state.isCheckrDirectA || state.isGoodhireWebsite
      ? window.__env.CHECKR_DIRECT_A_CLIENT_ID
      : state.router.params.client_id;

  const request = {
    method: 'post',
    url: '/oauth/authorize',
    data: {
      client_id: clientId,
      scope: scope || 'read_write',
      redirect_uri: getRedirectUri(state),
    },
  };

  return new Promise((resolve, reject) => {
    state
      .fetch(request)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject({
          _api: error?.response?.data,
          error:
            error?.response?.data?.error ||
            error?.response?.data?.errors?.join(', ') ||
            error.message,
          isAuthorizationCall: true,
        });
      });
  });
}

export async function authorizeAndRedirectToApplication(appState) {
  const response = await authorize(appState);
  const url = buildRedirectURL(appState, response.code);

  logRedirectToPartner(url);

  appState.redirect(url);
}

export function logRedirectToPartner(url) {
  datadogRum.addAction('redirect_to_partner', {
    uri: url.replace(/[?&]code=[^&]+/, '?code=<redacted>'),
  });
}

export default {
  authorize,
  logRedirectToPartner,
};
