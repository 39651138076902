import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import nextId from 'react-id-generator';
import Tooltip from './Tooltip';

@observer
export default class Checkbox extends Component {
  htmlId = `checkbox-${nextId()}`;

  handleChange = e => {
    const key = this.props.name;
    let value = null;

    if (e.target.checked) {
      value = e.target ? e.target.value : e.field.value();
    }

    if (this.props.onChange) {
      this.props.onChange(key, value);
    }

    if (this.props.form && this.props.form.handleChange) {
      this.props.form.handleChange(key, value);
    }
  };

  render() {
    const {
      form,
      label,
      labelSubText,
      name,
      tooltip,
      value,
      className,
      dataTestId,
    } = this.props;
    const hasError = form && form.error && form.error[name];
    const groupClassName = `${className || ''} form-check custom-input ${
      hasError ? 'has-danger' : ''
    }`;
    const isChecked = form[name] === value;

    return (
      <div className={groupClassName}>
        <label className='form-control-label ml-4'>
          <input
            className='form-check-input'
            checked={isChecked}
            id={this.htmlId}
            onChange={this.handleChange}
            type='checkbox'
            value={value}
          />
          <span data-testid={dataTestId} className='checkmark' />
          <strong>{label}</strong> {labelSubText}
          {tooltip ? <Tooltip tooltip={tooltip} /> : null}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelSubText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};
