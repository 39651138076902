import React from 'react';
import { Check } from '@mui/icons-material';
import { IconProps } from '@mui/material';

interface CheckIconProps extends IconProps {
  isSelected: boolean;
}

const CheckIcon = ({ isSelected }: CheckIconProps) => {
  return (
    <Check
      style={{
        width: '1em',
        fontWeight: '400',
        fontSize: 'medium',
        marginRight: '6px',
        marginTop: '-2px',
        color: isSelected ? '#F9F6F2' : '#117091',
      }}
    />
  );
};

export default CheckIcon;
