import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';
import Footer from '../../Footer';
import Logos from '../../Logos';
import Auth0 from '../../../services/Auth0';

@inject('store')
@observer
export default class Signin extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.form = this.store.signin;
  }

  onClick() {
    Auth0.authorize({ partner_client_id: this.store.application.client_id });
  }

  UNSAFE_componentWillMount() {
    // eslint-disable-line camelcase
    this.store.auth.setClientId(this.store.application.client_id);
    if (
      this.store.router.location.search &&
      new URLSearchParams(this.store.router.location.search).get('state')
    ) {
      this.store.auth.setStateParam(
        new URLSearchParams(this.store.router.location.search).get('state'),
      );
    }
  }

  render() {
    return (
      <div>
        {this.store.isSignup && <Redirect to={this.store.getStep('signin')} />}
        <div className='container'>
          <div className='row justify-content-lg-center'>
            <div className='col-lg-7 col-md-12'>
              <div className='row container-outer pt-4'>
                <Logos />
                <div className='text-center col-lg-12'>
                  <h5>Sign in to your Checkr Account</h5>
                  <p className=''>
                    This will allow you to connect your Checkr account to{' '}
                    {this.store.application.name}
                  </p>
                  {this.store.application.sign_up_flow && (
                    <p className='text-muted'>
                      Need a Checkr account?&nbsp;
                      <Link to={this.store.getStep('signup')}>Sign up</Link>
                    </p>
                  )}
                </div>
                <div className='col-lg-12 mb-4'>
                  <div className='col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-sm-12'>
                    <button
                      className={'btn btn-lg btn-block btn-success my-4'}
                      onClick={e => this.onClick(e)}
                    >
                      Sign in with Checkr
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Signin.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
