import React from 'react';
import PropTypes from 'prop-types';
import Step from './Step';
import map from 'lodash/map';

export default function Steps({ step, isBillable }) {
  let colClass = 'col-lg-12';
  let baseClass = 'col-4 steps-step';
  let labelNames = [
    'Contact/Business information',
    'Payment method',
    'Submit account',
  ];

  if (!isBillable) {
    colClass = 'col-lg-6 offset-lg-3 col-md-6 offset-md-3';
    baseClass = 'col-6 steps-step';
    labelNames = ['Contact/Business information', 'Submit account'];
  }

  const stepElements = map(labelNames, (labelName, labelIdx) => (
    <Step
      baseClass={baseClass}
      currentStep={step}
      labelStep={labelIdx + 1}
      labelName={labelName}
      key={labelIdx}
    />
  ));

  return (
    <div className={`${colClass} col-sm-12`}>
      <div className='row steps'>{stepElements}</div>
    </div>
  );
}

Steps.propTypes = {
  step: PropTypes.number.isRequired,
  isBillable: PropTypes.bool.isRequired,
};
