/* eslint-disable max-len */
import React, { SetStateAction, useEffect, useState } from 'react';

import FeaturedAddon from './FeaturedAddon';
import FeaturedAddonsContainer from './FeaturedAddonsContainer';
import MvrScreenings from './FullAddons/MvrScreenings';
import VerificationScreenings from './FullAddons/VerificationScreenings';
import FACISScrenings from './FullAddons/FACISScrenings';
import CrimAndCivilScreenings from './FullAddons/CrimAndCivilScreenings';
import { INVITES } from '../../../../constants/identifiers';
import { useInvitesAnalyticsTrackers } from '../hooks/useInvitesAnalyticsTrackers';
import { PHASE2_ADD_ON_OPTIONS_CONFIG } from '../data/packages';

interface iTestIds {
  [key: string]: {
    [key: string]: string;
  };
}

const TestIds: iTestIds = INVITES;

type Props = {
  slug: string;
  hidden: boolean;
  currentStep: number;
};

const FullAddonsSection = ({ hidden, slug, currentStep }: Props) => {
  const [expanded, setExpanded] = useState('');
  const { trackAddOnCategoryExpanded } = useInvitesAnalyticsTrackers();

  const handleChange =
    (panel: SetStateAction<string>, category: string) =>
    (event: unknown, isExpanded: boolean) => {
      trackAddOnCategoryExpanded(category);
      setExpanded(isExpanded ? panel : '');
    };

  useEffect(() => {
    setExpanded('');
  }, [currentStep]);

  if (hidden) return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <FeaturedAddonsContainer>
        <FeaturedAddon
          screeningName='Motor vehicle record (MVR)'
          price={PHASE2_ADD_ON_OPTIONS_CONFIG.motor_vehicle_report.price}
          tooltipText='Confirms a candidate’s driver’s license and identifies driving incidents'
          screening='motor_vehicle_report'
          slug={slug}
          dataTestId={TestIds.MISC.mvrFeaturedAddOn}
          hidden={false}
        />
        <FeaturedAddon
          screeningName='Federal criminal search'
          price={PHASE2_ADD_ON_OPTIONS_CONFIG.federal_criminal_search.price}
          tooltipText='Searches for federal felony and misdemeanor offenses including crimes that cross state lines'
          screening='federal_criminal_search'
          slug={slug}
          dataTestId={TestIds.MISC.federalFeaturedAddOn}
          hidden={false}
        />
        <FeaturedAddon
          screeningName='Employment verification'
          price={PHASE2_ADD_ON_OPTIONS_CONFIG.employment_verification.price}
          tooltipText='Verifies the accuracy of a candidate’s employment history for the past 7 years'
          screening='employment_verification'
          slug={slug}
          hidden={slug === 'checkrdirect_professional_criminal'}
          dataTestId={TestIds.MISC.employmentFeaturedAddOn}
        />
      </FeaturedAddonsContainer>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <CrimAndCivilScreenings
          expanded={expanded}
          handleChange={handleChange}
          slug={slug}
        />
        <MvrScreenings
          expanded={expanded}
          handleChange={handleChange}
          slug={slug}
        />
        <VerificationScreenings
          expanded={expanded}
          handleChange={handleChange}
          slug={slug}
        />
        <FACISScrenings
          expanded={expanded}
          handleChange={handleChange}
          slug={slug}
        />
      </div>
    </div>
  );
};

export default FullAddonsSection;
