import React, { useMemo } from 'react';
import { Grid, TextField, MenuItem, Box } from '@mui/material';
import { INVITES } from '../../../../constants/identifiers';
import { stateGeos, cityGeosByState } from '../data/geos';
import styled from 'styled-components';

const stateCityNameStyle = {
  color: '#334559',
  background: '#fff',
  fontWeight: '400',
  fontFamily: 'National2Regular',
};

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E1E6EB',
      fontFamily: 'National2Regular',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#009bb5',
    },
  },
  '& .MuiSelect-select': stateCityNameStyle,
  '& label.Mui-focused': {
    color: '#009bb5',
  },
});

const StateCitySelect = ({ selectedState, selectedCity, register, errors }) => {
  const cities = useMemo(
    () => cityGeosByState[selectedState] || [],
    [selectedState],
  );

  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight: {
          xs: 224,
          lg: 207,
        },
        maxWidth: 180,
      },
    },
  };

  return (
    <Grid container direction='row' spacing={1}>
      <Grid
        item
        sx={{
          '& .MuiFormLabel-root': {
            fontSize: { xs: '14px', sm: '14px', md: '16px' },
          },
          '& .MuiSelect-select': {
            fontSize: { xs: '14px', sm: '14px', md: '16px' },
          },
        }}
      >
        <StyledTextField
          id='Select a state'
          {...register('state')}
          label={selectedState === '' ? 'Select a state' : ''}
          data-testid={INVITES.INPUT.state}
          value={selectedState || ''}
          error={Boolean(errors.state?.message)}
          helperText={errors.state?.message}
          FormHelperTextProps={{ 'data-testid': INVITES.ERROR.stateFieldError }}
          select
          SelectProps={{
            sx: {
              width: cities.length
                ? { xs: '130px', sm: '180px', md: '180px' }
                : '180px',
            },
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: {
                    // Same dimensions as state selectors in CheckrDirectWorkLocationSelector and CheckrDirectVerifyA
                    xs: 224,
                    lg: 207,
                  },
                  '& .MuiMenuItem-root': {
                    fontSize: { xs: '16px', sm: '16px', md: '18px' },
                  },
                },
              },
            },
          }}
        >
          {stateGeos.map(geo => (
            <MenuItem
              key={`option-${geo.state}`}
              data-testid={geo.name}
              value={geo.state}
              sx={stateCityNameStyle}
            >
              {geo.name}
            </MenuItem>
          ))}
        </StyledTextField>
      </Grid>
      {Boolean(cities.length) && (
        <Grid
          item
          sx={{
            '& .MuiFormLabel-root': {
              fontSize: { xs: '14px', sm: '14px', md: '16px' },
            },
            '& .MuiSelect-select': {
              fontSize: { xs: '14px', sm: '14px', md: '16px' },
            },
          }}
        >
          <StyledTextField
            {...register('city')}
            label={selectedCity === '' ? 'Select a city' : ''}
            value={selectedCity || ''}
            data-testid={INVITES.INPUT.city}
            error={Boolean(errors.city?.message)}
            helperText={errors.city?.message}
            select
            SelectProps={{
              sx: { width: { xs: '140px', sm: '180px', md: '180px' } },
              MenuProps: {
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontSize: { xs: '16px', sm: '16px', md: '18px' },
                    },
                  },
                },
              },
            }}
          >
            {cities
              .map(geo => (
                <MenuItem
                  key={`option-${geo.city}`}
                  value={geo.city}
                  data-testid={geo.city}
                  sx={stateCityNameStyle}
                >
                  {geo.city}
                </MenuItem>
              ))
              .concat(
                <MenuItem
                  key='option-other'
                  value='Other'
                  data-testid={`${INVITES.INPUT.city}-other`}
                  sx={stateCityNameStyle}
                >
                  Other
                </MenuItem>,
              )}
          </StyledTextField>
        </Grid>
      )}
    </Grid>
  );
};

export default StateCitySelect;
