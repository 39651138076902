/* global __env */
import Axios from 'axios';

export function createClient(token) {
  return Axios.create({
    baseURL: __env.CHECKR_API,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
