import {
  buildRedirectURL,
  authorize,
  logRedirectToPartner,
} from './helpers/ConnectHelper';
import { makeObservable, observable } from 'mobx';

export default class ConnectStore {
  @observable error;
  @observable loading;

  constructor(state) {
    this.state = state;
    makeObservable(this);
  }

  async submit() {
    try {
      this.loading = true;
      const response = await authorize(this.state);
      await this.state.utms.track();
      this.error = null;
      const url = buildRedirectURL(this.state, response.code);
      logRedirectToPartner(url);

      this.state.redirect(url);
    } catch (errors) {
      console.error(errors);
      this.error = errors;
      this.loading = false;
    }
  }
}
