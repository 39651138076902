/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { SetStateAction } from 'react';
import ScreeningAccordion from '../ScreeningAccordion';
import Screening from '../Screening';
import verifications from '../svgs/3-verifications.svg';
import { PHASE2_ADD_ON_OPTIONS_CONFIG } from '../../data/packages';

type Props = {
  expanded: string;
  handleChange: (
    panel: SetStateAction<string>,
    category: string,
  ) => (event: unknown, isExpanded: boolean) => void;
  slug: string;
};

const Screenings = ({ expanded, handleChange, slug }: Props) => {
  const {
    employment_verification,
    education_verification,
    professional_license_verification,
    personal_reference_verification,
    professional_reference_verification,
  } = PHASE2_ADD_ON_OPTIONS_CONFIG;
  return (
    <ScreeningAccordion
      icon={<img src={verifications} alt='icon' height={24} width={24} />}
      title='Want to verify employment, education, or references?'
      subtitle='Verify education, employment, professional license history, and references'
      expanded={expanded === 'panel3'}
      onChange={handleChange('panel3', 'Verifications')}
      dataTestId='screening-accordion-3'
    >
      {slug !== 'checkrdirect_professional_criminal' && (
        <>
          <Screening
            name={employment_verification.displayName}
            description={employment_verification.description}
            price={employment_verification.price}
            screening='employment_verification'
            slug={slug}
          />
          <Screening
            name={education_verification.displayName}
            description={education_verification.description}
            price={education_verification.price}
            screening='education_verification'
            slug={slug}
          />
        </>
      )}
      <Screening
        name={professional_license_verification.displayName}
        description={professional_license_verification.description}
        price={professional_license_verification.price}
        screening='professional_license_verification'
        slug={slug}
      />
      <Screening
        name={personal_reference_verification.displayName}
        description={personal_reference_verification.description}
        price={personal_reference_verification.price}
        screening='personal_reference_verification'
        slug={slug}
      />
      <Screening
        name={professional_reference_verification.displayName}
        description={professional_reference_verification.description}
        price={professional_reference_verification.price}
        screening='professional_reference_verification'
        slug={slug}
      />
    </ScreeningAccordion>
  );
};

export default Screenings;
