import React from 'react';
import PartnerHeader from './PartnerHeader';
import { Box, Typography } from '@mui/material';

const SignupWrapper = ({ children }) => {
  return (
    <Box id='bg-signup-v4'>
      <PartnerHeader />
      <Box
        sx={{
          px: { xs: '30px', lg: '9rem' },
          my: 'auto',
        }}
      >
        {children}
      </Box>
      <Typography
        variant='body1'
        className='secondary-text-signup-v4'
        fontFamily='National2Regular'
        fontSize='14px'
        sx={{
          padding: { xs: '20px 30px', lg: '60px 80px' },
          mt: 'auto',
        }}
      >
        © 2023 Checkr. All rights reserved.
      </Typography>
    </Box>
  );
};

export default SignupWrapper;
