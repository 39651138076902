import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { Provider } from 'mobx-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  connectRouter,
  checkParams,
  loadApplication,
  loadUser,
  checkrDirect,
  loadFlagr,
  ABSwitcher,
  EmbedSwitcher,
} from './wrappers';
import Home from './Home';
import Current from './Current';
import NotFound from './NotFound';
import Deauthorize from './views/Deauthorize';
import Success from './views/SignupV3/Success';
import Signup from './views/SignupV3/Signup';
import * as CD from '../components/views/CheckrDirect';

const validateWrapper = function (WrapComponent) {
  return connectRouter(loadApplication(loadFlagr(checkParams(WrapComponent))));
};

const signupWrapper = function (WrapComponent) {
  return connectRouter(loadApplication(checkParams(WrapComponent)));
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

export default function App({ store }) {
  return (
    <Router>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <div>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route
                exact
                path='/auth'
                component={validateWrapper(props => (
                  <ABSwitcher componentName='auth0Callback' {...props} />
                ))}
              />
              <Route
                exact
                path='/authorize/:client_id/welcome'
                component={validateWrapper(props => (
                  <EmbedSwitcher componentName='welcome' {...props} />
                ))}
              />
              <Route
                exact
                path='/authorize/:client_id/signin'
                component={validateWrapper(props => (
                  <ABSwitcher componentName='signin' {...props} />
                ))}
              />
              <Route
                exact
                path='/authorize/:client_id/signup'
                component={validateWrapper(Signup)}
              />
              <Route
                exact
                path='/authorize/:client_id/v3/signup'
                component={validateWrapper(props => (
                  <ABSwitcher componentName='signup' {...props} />
                ))}
              />
              <Route
                exact
                path='/authorize/:client_id/v3/payment'
                component={validateWrapper(props => (
                  <ABSwitcher
                    componentName='payment'
                    protectedRoute={true}
                    {...props}
                  />
                ))}
              />
              <Route
                path='/authorize/:client_id/connect'
                component={validateWrapper(props => (
                  <ABSwitcher
                    componentName='connect'
                    protectedRoute={true}
                    {...props}
                  />
                ))}
              />
              <Route
                path='/authorize/:client_id/success'
                component={validateWrapper(loadUser(Success))}
              />
              <Route
                exact
                path='/authorize/:client_id/submission'
                component={validateWrapper(props => (
                  <ABSwitcher
                    componentName='submission'
                    protectedRoute={true}
                    {...props}
                  />
                ))}
              />
              <Route
                exact
                path='/authorize/:client_id'
                component={validateWrapper(Current)}
              />
              <Redirect
                from='/checkr-direct/signup'
                to={{
                  pathname: '/checkr/signup',
                  search: window.location.search,
                }}
              />
              <Redirect from='/checkr-direct/signin' to='/checkr/signin' />
              <Redirect from='/checkr-direct/payment' to='/checkr/payment' />
              <Redirect
                from='/checkr-direct/submission'
                to='/checkr/submission'
              />
              <Redirect from='/checkr-direct/connect' to='/checkr/connect' />
              <Redirect from='/checkr-direct/invites' to='/checkr/invites' />
              <Route
                path='/checkr/signin'
                component={validateWrapper(CD.CheckrDirectSigninA)}
              />
              <Route
                path='/checkr/signup'
                component={signupWrapper(checkrDirect(CD.CheckrDirectVerifyA))}
              />
              <Route
                path='/checkr/payment'
                component={validateWrapper(
                  loadUser(checkrDirect(CD.CheckrDirectPaymentA)),
                )}
              />
              <Route
                path='/checkr/submission'
                component={validateWrapper(
                  loadUser(checkrDirect(CD.CheckrDirectSubmissionA)),
                )}
              />
              <Route
                path='/checkr/connect'
                component={validateWrapper(
                  loadUser(checkrDirect(CD.CheckrDirectSubmissionA)),
                )}
              />
              <Route
                path='/checkr/invites'
                component={validateWrapper(
                  loadUser(checkrDirect(CD.CheckrDirectInvites, 12)),
                )}
              />
              <Route
                path='/checkr/invites'
                component={validateWrapper(
                  checkrDirect(CD.CheckrDirectInvites, 12),
                )}
              />
              <Route
                path='/checkr-goodhire/signin'
                component={validateWrapper(CD.CheckrDirectSigninA)}
              />
              <Route
                path='/checkr-goodhire/signup'
                component={signupWrapper(checkrDirect(CD.CheckrDirectVerifyA))}
              />
              <Route
                path='/checkr-goodhire/payment'
                component={validateWrapper(
                  loadUser(checkrDirect(CD.CheckrDirectPaymentA)),
                )}
              />
              <Route
                path='/checkr-goodhire/submission'
                component={validateWrapper(
                  loadUser(checkrDirect(CD.CheckrDirectSubmissionA)),
                )}
              />
              <Route
                path='/checkr-goodhire/connect'
                component={validateWrapper(
                  loadUser(checkrDirect(CD.CheckrDirectSubmissionA)),
                )}
              />
              <Route
                path='/checkr-goodhire/invites'
                component={validateWrapper(
                  loadUser(checkrDirect(CD.CheckrDirectInvites, 12)),
                )}
              />
              <Route
                path='/deauthorize/:client_id'
                component={validateWrapper(loadUser(Deauthorize))}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        </QueryClientProvider>
      </Provider>
    </Router>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};
