import React, { Component, forwardRef } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { Box, Link, MenuItem, TextField, Typography } from '@mui/material/';
import { KeyboardArrowDown } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { IMaskInput } from 'react-imask';

import PartnerPackages from './PartnerPackages';
import Achievements from './Achievements';
import SignupWrapper from './SignupWrapper';
import CustomElement from '../../custom/CustomElement';

import {
  SIGNUP_BUSINESS_DESCRIPTIONS_B,
  SIGNUP_INPUT_FIELDS,
  SIGNUP_VOLUME_OPTIONS,
} from '../../helpers/Constants';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../../services/analytics';
import Auth0 from '../../../services/Auth0';
import { WELCOME } from '../../../constants/identifiers';

const defaultInputFieldProps = fieldValue => {
  if (fieldValue === 'yearlyBgcVolume') {
    return {
      type: 'text',
      InputLabelProps: {
        className: 'primary-text-signup-v4',
        shrink: true,
        htmlFor: fieldValue,
      },
      FormHelperTextProps: {
        'data-testid': `${WELCOME.fieldError}-${fieldValue}`,
      },
    };
  } else {
    return {
      type: 'text',
      inputProps: {
        maxLength: 256,
        'data-testid': `${fieldValue}-${WELCOME.input}`,
      },
      InputLabelProps: { className: 'primary-text-signup-v4', shrink: true },

      FormHelperTextProps: {
        'data-testid': `${WELCOME.fieldError}-${fieldValue}`,
      },
    };
  }
};

const getSpecificInputFieldProps = fieldValue => {
  switch (fieldValue) {
    case 'phone':
      return {
        InputProps: { inputComponent: PhoneMask },
      };

    case 'yearlyBgcVolume':
      return {
        select: true,
        SelectProps: {
          IconComponent: KeyboardArrowDown,
          'data-testid': `${fieldValue}-${WELCOME.input}`,
        },
        inputProps: {
          id: fieldValue,
        },
      };

    default:
      return {};
  }
};

const OneBHeader = styled.p`
  font-size: 30px !important;
  margin-bottom: 0px !important;
`;

const BoldFont = styled.span`
  font-family: National2Bold !important;
`;

@inject('store')
@observer
export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.form = this.store.signupV3;
    this.trackViewAllPackages = this.trackViewAllPackages.bind(this);
    this.state = {};
  }

  trackViewAllPackages() {
    this.store.trackAnalyticsEvent(
      'Partner Customer Onboarding View All Packages Selected',
    );
  }

  componentDidMount() {
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.WELCOME_PAGE_VIEWED);
  }

  getSignupBusinessDescriptions = () => {
    return SIGNUP_BUSINESS_DESCRIPTIONS_B;
  };

  getHeading = () => {
    return (
      <OneBHeader>
        <CustomElement elementKey='welcomeHeader' />
      </OneBHeader>
    );
  };

  handleSignUp = e => {
    this.form.loading = true;
    e.preventDefault();
    const errors = this.form.validateOnEventV4();

    if (errors) {
      this.trackErrorsDisplayed(Object.values(errors));
      this.form.loading = false;
      return;
    }

    this.form.loading = false;
    this.form._state.toStep('v3/signup');
  };

  handleSignin = e => {
    e.preventDefault();

    this.store.auth.setClientId(this.store.application.client_id);
    if (
      this.store.router.location.search &&
      new URLSearchParams(this.store.router.location.search).get('state')
    ) {
      this.store.auth.setStateParam(
        new URLSearchParams(this.store.router.location.search).get('state'),
      );
    }

    this.trackSignUpSignIn();

    Auth0.authorize({ partner_client_id: this.store.application.client_id });
  };

  onFormChange = (field, value) => {
    this.form.handleChangeV4(field, value);
  };

  trackErrorsDisplayed(errors) {
    errors = errors.map(error => error[0]);
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.ERROR_DISPLAYED_CONTACT, {
      [ANALYTICS_PROPERTIES.ERROR_MESSAGE]: errors,
    });
  }

  trackSignUpSignIn = () => {
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.SIGN_IN, {
      [ANALYTICS_PROPERTIES.PAGE_NAME]: 'Sign In from Welcome',
    });
  };

  render() {
    const isFormFilled = !Object.keys(SIGNUP_INPUT_FIELDS).every(
      fieldValue => this.form[fieldValue],
    );

    return (
      <>
        <SignupWrapper>
          <Box className='d-flex justify-content-center'>
            <Grid
              container
              rowSpacing={4}
              columnSpacing={2}
              sx={{ maxWidth: '1240px' }}
            >
              <Grid xs={12} lg={6} className='d-flex' item>
                <Box className='d-flex flex-column gap-0'>
                  <Typography
                    data-testid={WELCOME.welcomeHeader}
                    variant='h3'
                    className='secondary-text-signup-v4'
                    fontFamily='ArgentCFRegular'
                    marginBottom='25px'
                    sx={{
                      marginTop: {
                        xs: '50px',
                        lg: 0,
                      },
                      typography: {
                        xs: { fontSize: '28px' },
                        lg: { fontSize: '42px' },
                      },
                    }}
                  >
                    {this.getHeading()}
                  </Typography>
                  <Box
                    data-testid={WELCOME.bizDescription}
                    sx={{ display: { xs: 'none', lg: 'block' } }}
                  >
                    <CustomElement elementKey='businessDescriptions' />
                  </Box>
                </Box>
              </Grid>
              <Grid
                xs={12}
                lg={6}
                className='d-flex justify-content-center'
                item
              >
                <Box
                  component='form'
                  className='d-flex flex-column gap-0'
                  sx={{ width: { xs: '100%', lg: '450px' } }}
                >
                  {Object.entries(SIGNUP_INPUT_FIELDS).map(
                    ([fieldValue, fieldLabel]) => (
                      <TextField
                        className='input-signup-v4'
                        label={fieldLabel}
                        value={this.form[fieldValue] || ''}
                        error={Boolean(this.form.error?.[fieldValue])}
                        helperText={this.form.error?.[fieldValue]}
                        onChange={e =>
                          this.onFormChange(fieldValue, e.target.value)
                        }
                        key={fieldValue}
                        {...defaultInputFieldProps(fieldValue)}
                        {...getSpecificInputFieldProps(fieldValue)}
                      >
                        {fieldValue === 'yearlyBgcVolume' &&
                          Object.entries(SIGNUP_VOLUME_OPTIONS).map(
                            ([value, description], index) => (
                              <MenuItem
                                value={value}
                                key={value}
                                data-testid={`${WELCOME.yearlyVolume}-${index}`}
                              >
                                {description}
                              </MenuItem>
                            ),
                          )}
                      </TextField>
                    ),
                  )}
                  <Box
                    className='d-flex justify-content-center'
                    sx={{ mx: { xs: '0px', lg: '8px' } }}
                  >
                    <Box
                      component='button'
                      className='btn btn-success btn-signup-v4'
                      data-testid={WELCOME.signUp}
                      onClick={this.handleSignUp}
                      disabled={isFormFilled}
                      sx={{
                        width: {
                          xs: '100%',
                          lg: '175px',
                        },
                        mt: '20px',
                      }}
                    >
                      Start sign up
                    </Box>
                  </Box>
                  <Box
                    className='d-flex justify-content-center'
                    sx={{
                      typography: {
                        xs: { fontSize: '12px' },
                        lg: { fontSize: '14px' },
                      },
                      mt: {
                        xs: '2rem',
                        lg: '0.5rem',
                      },
                      mb: {
                        xs: '1.7rem',
                        lg: '0.5rem',
                      },
                    }}
                  >
                    <Typography
                      variant='body1'
                      className='secondary-text-signup-v4'
                      sx={{
                        typography: {
                          xs: { fontSize: '12px' },
                          lg: { fontSize: '14px' },
                        },
                      }}
                    >
                      Already have an account?
                    </Typography>
                    <Link
                      component='button'
                      underline='none'
                      className='app-primary-color'
                      sx={{
                        marginLeft: '5px',
                        typography: {
                          fontFamily: 'National2Regular',
                        },
                      }}
                      data-testid={WELCOME.signIn}
                      onClick={this.handleSignin}
                    >
                      Sign In
                    </Link>
                  </Box>
                  <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
                    <CustomElement elementKey='businessDescriptions' />
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Grid xs={12} lg={7} item>
                  <Typography
                    data-testid={WELCOME.packageHeader}
                    variant='h3'
                    className='secondary-text-signup-v4'
                    fontFamily='ArgentCFRegular'
                    marginBottom='25px'
                    sx={{
                      typography: {
                        xs: { fontSize: '28px' },
                        lg: { fontSize: '42px' },
                      },
                    }}
                  >
                    <CustomElement elementKey='packageHeader' />
                  </Typography>
                </Grid>
                <Typography
                  data-testid={WELCOME.packageSubtext}
                  variant='body1'
                  className='secondary-text-signup-v4'
                  fontFamily='National2Regular'
                  paddingX='8px'
                  marginTop='-16px'
                  marginBottom='25px'
                  sx={{
                    typography: {
                      xs: { fontSize: '16px' },
                      lg: { fontSize: '18px' },
                    },
                  }}
                >
                  {/* GOING TO NEED TO CHANGE THIS IN THE FUTURE. CURRENTLY HARD CODED FOR 3 PARTNERS */}
                  {[
                    'a3d8c24e4a33c2d111f551b8',
                    '3bde5de358d3812c548cc25c',
                    'caf35da2386a45643816d26f',
                    'a0ca49dc3616ac1468a7ecf6',
                  ].includes(this.store.application.client_id) ? null : (
                    <span>{this.store.application.partner_name} </span>
                  )}
                  <CustomElement elementKey='packageSubtext' />
                </Typography>
                <PartnerPackages
                  application={this.store.application}
                  trackViewAllPackages={this.trackViewAllPackages}
                  canCustomize={false}
                />
                <Typography
                  variant='body1'
                  fontFamily='HelveticaNeue'
                  marginTop='24px'
                  fontSize='12px'
                  fontWeight='700'
                  align='center'
                >
                  Certain taxes and fees may not be included.
                </Typography>
              </Grid>
              <Achievements />
            </Grid>
          </Box>
        </SignupWrapper>
      </>
    );
  }
}

export const PhoneMask = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='000-000-0000'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      onChange={() => {}}
    />
  );
});
