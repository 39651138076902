import { action, makeObservable, observable } from 'mobx';
import { get } from 'lodash';
import * as Sentry from '@sentry/browser';
import ValidationStore from '../ValidationStore';
import { authorize } from './helpers/ConnectHelper';
import {
  createPromotionCode,
  validatePromotionCode,
} from './helpers/PromotionCode';
import { FLAG_KEYS } from '../../services/flagr';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../services/analytics';
import { errorValuesForTracking } from './helpers/TrackErrorValues';

export const computeErrorKeyForStripeParam = stripeParamName => {
  const stripeToCheckrMapping = {
    'bank_account[account_holder_name]': 'name',
    'bank_account[account_holder_type]': 'accountHolderType',
    'bank_account[account_number]': 'accountNumber',
    'bank_account[routing_number]': 'routingNumber',
  };

  return stripeToCheckrMapping[stripeParamName] || 'error';
};

export default class BankAccountPaymentStore extends ValidationStore {
  @observable accountNumber;
  @observable confirmedAccountNumber;
  @observable accountHolderType = 'company';
  @observable name;
  @observable routingNumber;
  @observable token;
  @observable promotionCode;
  @observable promotionCodeApplied = false;
  @observable promoLoading = false;
  @observable paymentError = false;

  static messages = {
    required: ':attribute cannot be blank.',
    'required.name': 'Name cannot be blank.',
    'required.routingNumber': 'Routing number cannot be blank',
    'required.accountNumber': 'Account number cannot be blank',
  };

  static messagesV4 = {
    required: ':attribute cannot be blank.',
    'required.name': 'Name cannot be blank.',
    'required.routingNumber': 'Routing number cannot be blank',
    'required.accountNumber': 'Account number cannot be blank',
    'required.confirmedAccountNumber':
      'Confirmed Account number cannot be blank',
  };

  static attributes = {
    promotion_code: 'promotionCode',
  };

  constructor(state) {
    super();
    this.state = state;
    makeObservable(this);
  }

  get rules() {
    return {
      accountNumber: 'required',
      accountHolderType: 'required',
      name: 'required',
      routingNumber: 'required',
    };
  }

  get rulesV4() {
    return {
      accountNumber: 'required',
      accountHolderType: 'required',
      confirmedAccountNumber: ['required', 'account_number_confirmation'],
      name: 'required',
      routingNumber: 'required',
    };
  }

  @action handleChange = (name, value, version = 'V3') => {
    this[name] = value;

    if (this.error) {
      // Only if was submitted should validate while typing
      if (version === 'V3') this.debounceValidation();
      else if (version === 'V4') this.debounceValidationV4();
    }

    if (version === 'V3') this.debounceValidity();
    else if (version === 'V4') this.debounceValidityV4();
  };

  async trackPaymentSubmitted() {
    this.state.trackAnalyticsEvent(ANALYTICS_EVENTS.PAYMENT_SUBMITTED, {
      [ANALYTICS_PROPERTIES.PAYMENT_METHOD]: 'Bank Account',
    });
  }

  trackSignupPaymentSubmitted() {
    this.state.trackAnalyticsEvent(
      'Self-Serve Customer Signup Payment Information Completed',
      {},
    );
  }

  trackErrors(errors) {
    const errorMessages = errorValuesForTracking(errors);

    this.state.trackAnalyticsEvent(ANALYTICS_EVENTS.ERROR_DISPLAYED_PAYMENT, {
      [ANALYTICS_PROPERTIES.ERROR_MESSAGE]: errorMessages,
    });
  }

  trackSignupErrors() {
    this.state.trackAnalyticsEvent('Self-Serve Customer Signup Error Viewed', {
      'Error Name': this.error
        ? Object.values(this.error).join(',')
        : 'Server Error',
      'Page Name': 'payment',
    });
  }

  async submit(version = 'V3') {
    this.loading = true;

    if (!this.state.isLive() || !this.state.isBillable()) {
      await this.state.utms.track();
      if (this.state.isCheckrDirectA || this.state.isGoodhireWebsite) {
        Sentry.addBreadcrumb(
          'Not live account. Bank Payment submitted redirecting to invite',
        );
        this.state.toStep('invites');
        await authorize(this.state);
      } else {
        Sentry.addBreadcrumb(
          'Not live account. Bank Payment submitted redirecting to submission',
        );
        this.state.toStep('submission');
      }
      return;
    }

    try {
      if (version === 'V3') this.validate();
      else if (version === 'V4') this.validateV4();

      this.token = await this.createStripeToken();
      await this.createBankAccount();
      await this.state.utms.track();

      if (this.state.isSignup) {
        this.trackSignupPaymentSubmitted();
      } else {
        await this.trackPaymentSubmitted();
      }

      if (this.promotionCode) {
        await createPromotionCode(this.state, this.promotionCode);
      }

      this.error = null;
      this.paymentError = false;
      if (this.state.isCheckrDirectA || this.state.isGoodhireWebsite) {
        try {
          await authorize(this.state);
          Sentry.addBreadcrumb(
            'Checkr Signup Bank Payment submitted redirecting to invite',
          );
          this.loading = false;
          this.state.toStep('invites');
        } catch (error) {
          // If the authorization step fails, queueing invites will fail. Redirect to submission.
          Sentry.addBreadcrumb(
            'Checkr Signup Authorize failed redirecting to submission',
          );
          this.loading = false;
          this.state.toStep('submission');
        }
      } else {
        Sentry.addBreadcrumb(
          'Bank Payment submitted redirecting to submission',
        );
        this.loading = false;
        this.state.toStep('submission');
      }
    } catch (error) {
      if (this.state.isSignup) {
        this.trackSignupErrors(error);
      } else {
        this.trackErrors(error);
      }
      this.loading = false;
      this.error = this.friendlyErrors(error);
      if ('_api' in this.error) {
        this.paymentError = true;
      }

      // Only capture Sentry exceptions for 5xx server errors
      if (error?.response?.status >= 500) {
        Sentry.captureException(error, {
          tags: { 'add-payment-error': 'bank-payment' },
        });
      }
    }
  }

  setBankAccountName = () => {
    this.name = get(this.state, 'auth.user.full_name');
  };

  async validatePromotionCode() {
    this.promoLoading = true;
    this.error = null;
    this.promotionCodeApplied = false;

    try {
      await validatePromotionCode(this.state, this.promotionCode);
      this.promoLoading = false;
      this.promotionCodeApplied = true;
    } catch (errors) {
      if (this.state.isSignup) {
        this.trackSignupErrors(errors);
      } else {
        this.trackErrors(errors);
      }

      this.promoLoading = false;
      this.error = this.friendlyErrors(errors);
    }
  }

  createBankAccount = () => {
    const account = this.state.auth.user.account;

    const request = {
      method: 'post',
      url: `/billing/accounts/${account.id}/bank_accounts`,
      data: {
        account_holder_type: this.accountHolderType,
        account_holder_name: this.name,
        account_name: account.name,
        billing_email: account.billing_email,
        stripe_token: this.token.id,
      },
    };

    return new Promise((resolve, reject) => {
      this.state
        .fetch(request)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject({
            _api: error?.response?.data?.error,
            error: error?.response?.data?.error || error.message,
          });
        });
    });
  };

  createStripeToken = () => {
    const stripeParams = {
      account_holder_name: this.name,
      account_holder_type: this.accountHolderType,
      account_number: this.accountNumber,
      country: 'US',
      currency: 'USD',
      routing_number: this.routingNumber,
    };

    return new Promise((resolve, reject) => {
      window.Stripe.bankAccount.createToken(
        stripeParams,
        (status, response) => {
          if (status === 200) {
            resolve(response);
          } else {
            const errorKey = computeErrorKeyForStripeParam(
              response.error.param,
            );

            const errors = {
              _stripe: response.error,
              _status: response.status,
              [errorKey]: [response.error.message],
            };
            reject(errors);
          }
        },
      );
    });
  };
}
