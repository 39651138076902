/* eslint-disable max-len */
import React from 'react';

export default function EducationIcon(props) {
  return (
    <svg
      {...props}
      width='40'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='education'>
        <path
          id='Vector'
          d='M26 30H24V27C23.9984 25.6744 23.4712 24.4035 22.5338 23.4662C21.5965 22.5288 20.3256 22.0016 19 22H13C11.6744 22.0016 10.4035 22.5288 9.46619 23.4662C8.52885 24.4035 8.00156 25.6744 8 27V30H6V27C6.00217 25.1441 6.74037 23.3649 8.05265 22.0526C9.36493 20.7404 11.1442 20.0022 13 20H19C20.8559 20.0022 22.6351 20.7404 23.9474 22.0526C25.2596 23.3649 25.9978 25.1441 26 27V30ZM5 6C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7V16H6V7C6 6.73478 5.89464 6.48043 5.70711 6.29289C5.51957 6.10536 5.26522 6 5 6Z'
          fill='#576C82'
        />
        <path
          id='Vector_2'
          d='M4 2V4H9V11C9 12.8565 9.7375 14.637 11.0503 15.9497C12.363 17.2625 14.1435 18 16 18C17.8565 18 19.637 17.2625 20.9497 15.9497C22.2625 14.637 23 12.8565 23 11V4H28V2H4ZM11 4H21V7H11V4ZM16 16C14.6739 16 13.4021 15.4732 12.4645 14.5355C11.5268 13.5979 11 12.3261 11 11V9H21V11C21 12.3261 20.4732 13.5979 19.5355 14.5355C18.5979 15.4732 17.3261 16 16 16Z'
          fill='#576C82'
        />
      </g>
    </svg>
  );
}
