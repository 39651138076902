import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PasswordMismatchAlert extends Component {
  classNameForRuleState = (
    password,
    passwordConfirm,
    confirmedPasswordMatchesInitialPassword,
  ) => {
    let text;
    if (
      password === null ||
      passwordConfirm === null ||
      (confirmedPasswordMatchesInitialPassword && password === '')
    ) {
      text = 'rule-suppressed';
    } else if (
      confirmedPasswordMatchesInitialPassword &&
      password !== null &&
      password !== ''
    ) {
      text = 'rule-passed';
    } else {
      text = 'rule-failed';
    }
    return text;
  };

  render() {
    const { password, passwordConfirm } = this.props;
    const confirmedPasswordMatchesInitialPassword =
      password === passwordConfirm;
    const classToShow = this.classNameForRuleState(
      password,
      passwordConfirm,
      confirmedPasswordMatchesInitialPassword,
    );

    return (
      <li className={classToShow}>
        <i className='fa' />
        <span data-testid='rule-failed-mismatch' className='rule-description'>
          Confirmed password must match initial password
        </span>
      </li>
    );
  }
}

PasswordMismatchAlert.propTypes = {
  password: PropTypes.string,
  passwordConfirm: PropTypes.string,
};
