import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';
import Footer from '../../Footer';
import Logos from '../../Logos';
import Title from '../../Title';
import lightbulb from '../../../images/lightbulb.png';
import tools from '../../../images/tools.png';
import SetupModal from './SetupModal';
import LearnModal from './LearnModal';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../../services/analytics';
import { LEGACY_PARTNER_WELCOME } from '../../../constants/identifiers';

@inject('store')
@observer
export default class Welcome extends Component {
  constructor(props) {
    super(props);
    const { store } = this.props;
    this.store = store;

    this.state = {
      setupModalOpen: false,
      learnModalOpen: false,
      packageGuideOpen: false,
    };

    this.toggleSetupModal = this.toggleSetupModal.bind(this);
    this.toggleLearnModal = this.toggleLearnModal.bind(this);
    this.togglePackageGuide = this.togglePackageGuide.bind(this);
    this.getSignupStep = this.getSignupStep.bind(this);
  }

  componentDidMount() {
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.WELCOME_PAGE_VIEWED);
  }

  toggleSetupModal() {
    if (!this.state.setupModalOpen) {
      this.store.trackAnalyticsEvent(
        ANALYTICS_EVENTS.CREATE_ACCOUNT_POPUP_VIEWED,
        { [ANALYTICS_PROPERTIES.CREATE_ACCOUNT_POPUP]: 'Create Account Popup' },
      );
    }

    this.setState({ setupModalOpen: !this.state.setupModalOpen });
  }

  toggleLearnModal() {
    this.setState({ learnModalOpen: !this.state.learnModalOpen });

    // When the modal is closed, this ensures the package guide is not
    // first displayed if the modal is re-opened
    if (!this.state.learnModalOpen) {
      this.setState({ packageGuideOpen: false });
      this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.LEARN_MORE_VIEWED, {
        [ANALYTICS_PROPERTIES.LEARN_MORE_VIEWED]: 'Learn More',
      });
    }
  }

  togglePackageGuide() {
    if (!this.state.packageGuideOpen) {
      this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.LEARN_MORE_PACKAGE);
    }

    this.setState({ packageGuideOpen: !this.state.packageGuideOpen });
  }

  getSignupStep() {
    return this.store.toStep('v3/signup');
  }

  trackWelcomeSignIn = () => {
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.SIGN_IN, {
      [ANALYTICS_PROPERTIES.PAGE_NAME]: 'Sign In from Welcome',
    });
  };

  render() {
    return (
      <div>
        {this.store.isSignup && <Redirect to={this.store.getStep('signup')} />}
        <div className='container'>
          <div className='row justify-content-lg-center'>
            <div className='col-lg-7 col-md-12'>
              <div className='row container-outer py-4'>
                <Logos />
                <Title label='Welcome to Checkr!' baseClass='mb-3' />
                <div className='col-lg-12 text-center mb-4'>
                  <p className='lead'>I&apos;m ready to...</p>
                </div>
                <div className='col-lg-12 text-center mb-5'>
                  <div className='welcome-tiles'>
                    <WelcomeTile
                      text='Set up a Checkr account'
                      onClick={this.toggleSetupModal}
                      img={tools}
                      dataTestId={LEGACY_PARTNER_WELCOME.welcomeSetup}
                      id='setup'
                    />
                    <WelcomeTile
                      text='Learn more about Checkr'
                      onClick={this.toggleLearnModal}
                      img={lightbulb}
                      dataTestId={LEGACY_PARTNER_WELCOME.learnMore}
                      id='learn'
                    />
                  </div>
                </div>
                <SetupModal
                  getStep={this.getSignupStep}
                  isOpen={this.state.setupModalOpen}
                  partnerName={this.store.application.name}
                  toggle={this.toggleSetupModal}
                />
                <LearnModal
                  isOpen={this.state.learnModalOpen}
                  toggleModal={this.toggleLearnModal}
                  packageGuideOpen={this.state.packageGuideOpen}
                  togglePackageGuide={this.togglePackageGuide}
                  application={this.store.application}
                  trackAnalyticsEvent={this.store.trackAnalyticsEvent}
                />
                <div className='col-lg-12 text-center mb-4'>
                  <p>
                    Have an existing Checkr account?&nbsp;
                    <Link
                      className='font-weight-bold'
                      data-testid='sign-in'
                      onClick={this.trackWelcomeSignIn}
                      to={this.store.getStep('signin')}
                    >
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Welcome.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};

export function WelcomeTile({ text, img, onClick, id, dataTestId }) {
  return (
    <div
      className='welcome-tile'
      onClick={onClick}
      id={id}
      data-testid={dataTestId}
    >
      <div className='tile-img-container'>
        <img className='tile-img' src={img} />
      </div>
      <strong>{text}</strong>
    </div>
  );
}

WelcomeTile.propTypes = {
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
};
