export const inputFields = {
  user: {
    fullName: {
      name: 'fullName',
      placeholder: 'Full Name',
    },
    email: {
      name: 'email',
      placeholder: 'Email address',
    },
  },
  businessValidation: {
    company: {
      name: 'company',
      placeholder: 'Legal Business Name',
    },
    taxId: {
      name: 'taxId',
      field: 'tin',
      placeholder: 'Tax ID',
      tooltip:
        '<p>We use your tax ID, or employer identification number (EIN), ' +
        'to verify your business and its valid permissible purpose.</p>',
    },
  },
  companyAddress: {
    street: {
      name: 'street',
      placeholder: 'Street',
    },
    zipcode: {
      name: 'zipcode',
      field: 'zipcode',
      placeholder: 'Zipcode',
    },
    city: {
      name: 'city',
      placeholder: 'City',
    },
    state: {
      name: 'stateName',
    },
  },
  bankAccount: {
    name: {
      name: 'name',
      placeholder: 'Name on account',
    },
    routingNumber: {
      name: 'routingNumber',
      placeholder: 'Routing number',
    },
    accountNumber: {
      name: 'accountNumber',
      placeholder: 'Account number',
    },
  },
  creditCard: {
    name: {
      name: 'name',
      placeholder: 'Card holder name',
      field: 'name',
    },
    number: {
      name: 'number',
      placeholder: 'Card number',
      field: 'card',
    },
    zipcode: {
      name: 'zipcode',
      placeholder: 'Zip code',
      field: 'zipcode',
    },
    expiration: {
      name: 'expiration',
      placeholder: 'Expiration date MM/YY',
      field: 'expiry',
    },
    cvc: {
      name: 'cvc',
      placeholder: 'Security code',
      field: 'cvc',
    },
  },
};

export const creditCardInputs = Object.keys(inputFields.creditCard);
export const bankAccountInputs = Object.keys(inputFields.bankAccount);
export const userInputs = Object.keys(inputFields.user);
export const businessValidationInputs = Object.keys(
  inputFields.businessValidation,
);
export const companyAddressInputs = Object.keys(inputFields.companyAddress);

export const welcomeScreen = {
  subtitle: 'You’re on your way to running your first background check.',
  description:
    'We take pride in empowering you to run secure and compliant background checks for your ' +
    'business. We first need to verify your business. Please enter your business information ' +
    'and credit card information below to get started with an account.',
};

export const creditCardInfo =
  '<b>Why do you need my credit or debit card?</b><br /><br />' +
  'We require a credit or debit card to set up your account. It allows you to start ordering ' +
  'background checks after you log into the Checkr dashboard.';

export const pricingInfo = {
  header: 'Checkr Background Check Packages',
  description:
    'After you run your first background check*, you’ll be able to continue ' +
    'to use Checkr and choose from a variety of packages and screenings within your account. ' +
    'If you need to customize your packages please contact us once your have completed your ' +
    'sign-up process.',
  link:
    'https://help.checkr.com/hc/en-us/articles/115001342747-What-are-the-additional-' +
    'or-pass-through-fees-on-my-invoice-',
};

export const verifyInfo =
  'Helps us verify that your business is legitimate and has a permissible purpose for running background checks. ' +
  'Verification typically takes less than one business day.';

export const paymentInfo = "We don't have any subscription or set-up fees.";
