import React, { useCallback } from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';
import { getFacisPrice } from '../../../PackageUtils/PackageUtils';

const Facis1 = () => {
  const {
    onAddClick,
    onRemoveClick,
    disabledScreenings,
    addedScreenings,
    additionalProperties,
    screeningPrices,
  } = useAddScreeningsContext();

  const screeningInfo =
    'Searches for penalties, suspensions, or punitive or disciplinary actions; meets minimum federal requirements';

  const advantages = [
    'Helps meet regulatory obligations to check specific healthcare sanctions lists',
    'Level options available to meet or exceed federal requirements',
    'Provides insight into a healthcare professional’s medical background',
  ];

  const considerations = [
    'PII may be limited depending on the specific record source',
    'Some records may be limited',
    'Limited to 7 years of history',
  ];

  const handleAddClick = useCallback(() => {
    onAddClick('facis_search', { subtype: 3 });
  }, [onAddClick]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick('facis_search');
  }, [onRemoveClick]);

  const isDisabled =
    additionalProperties.facis_search &&
    'subtype' in additionalProperties.facis_search &&
    additionalProperties.facis_search.subtype === 1;

  const price = getFacisPrice(screeningPrices, '3');

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>FACIS Level 3</ScreeningTitle>
        <ScreeningPrice priceByType='facis_search'>{price}</ScreeningPrice>
        <AddScreeningsAction
          screeningType='facis_search'
          onAddClick={handleAddClick}
          onRemoveClick={handleRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
          isDisabled={isDisabled}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default Facis1;
