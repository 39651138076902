import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';

export default class Label extends Component {
  render() {
    const { htmlFor, subText, text, tooltip } = this.props;

    return (
      <label className='form-control-label' htmlFor={htmlFor}>
        <strong>{text}</strong> {subText}
        {tooltip ? <Tooltip tooltip={tooltip} /> : null}
      </label>
    );
  }
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  subText: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.string,
};
