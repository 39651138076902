import * as React from 'react';
import PasswordPolicy from 'password-sheriff';
import { Circle, Close, Check } from '@mui/icons-material';

// Read the strength enum from the current Auth0 connection object when support for it lands (its on Auth0's roadmap)
const AUTH0_PASSWORD_POLICY_STRENGTH_FOR_CURRENT_CONNECTION = 'good';
const passwordPolicy = PasswordPolicy(
  AUTH0_PASSWORD_POLICY_STRENGTH_FOR_CURRENT_CONNECTION,
);

export const Icon = ({ pwLength, verified }) => {
  if (!pwLength) {
    return (
      <Circle
        style={{
          width: '0.3em',
          marginRight: '12px',
        }}
      />
    );
  } else if (pwLength > 0 && verified) {
    return (
      <Check
        color='success'
        style={{
          width: '0.6em',
          marginRight: '5px',
        }}
      />
    );
  } else if (pwLength > 0 && !verified) {
    return (
      <Close
        color='error'
        style={{
          width: '0.6em',
          marginRight: '5px',
        }}
      />
    );
  }
};

export default function PasswordStrengthHelper({ password }) {
  const result = passwordPolicy.missing(password);
  const hasMinimumCharacters = result.rules.find(
    rule => rule.code === 'lengthAtLeast',
  ).verified;
  const hasLowerCaseCharacters = result.rules
    .find(rule => rule.code === 'containsAtLeast')
    .items.find(item => item.code === 'lowerCase').verified;
  const hasUpperCaseCharacters = result.rules
    .find(rule => rule.code === 'containsAtLeast')
    .items.find(item => item.code === 'upperCase').verified;
  const hasNumbers = result.rules
    .find(rule => rule.code === 'containsAtLeast')
    .items.find(item => item.code === 'numbers').verified;
  const hasSpecialCharacters = result.rules
    .find(rule => rule.code === 'containsAtLeast')
    .items.find(item => item.code === 'specialCharacters').verified;
  return (
    <>
      <Icon pwLength={password?.length} verified={hasMinimumCharacters}></Icon>
      <span>Minimum 8 characters</span>
      <br />
      <Icon pwLength={password?.length} verified={hasSpecialCharacters}></Icon>
      <span>At least one special character (!@#$%^&*)</span>
      <br />
      <Icon
        pwLength={password?.length}
        verified={hasUpperCaseCharacters}
      ></Icon>
      <span>At least one uppercase letter</span>
      <br />
      <Icon
        pwLength={password?.length}
        verified={hasLowerCaseCharacters}
      ></Icon>
      <span>At least one lowercase letter</span>
      <br />
      <Icon pwLength={password?.length} verified={hasNumbers}></Icon>
      <span>At least one number (0-9)</span>
    </>
  );
}
