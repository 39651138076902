import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const FederalCriminalSearch = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo =
    'Searches all 94 federal district courts; often used when hiring C-level execs, CPAs, and financial staff';

  const advantages = [
    'Looks for federal-level crimes such as kidnapping, embezzlement, drug trafficking, etc.',
    'Includes data that might not be found elsewhere',
    'Federal criminal data is maintained in a single centralized source',
  ];

  const considerations = [
    'Limited PII provided by the source means limited reportable records',
    'Doesn’t include convictions prosecuted at the state-level (a majority of crimes)',
  ];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>Federal Criminal Records Search</ScreeningTitle>
        <ScreeningPrice priceByType='federal_criminal_search' />
        <AddScreeningsAction
          screeningType='federal_criminal_search'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default FederalCriminalSearch;
