import React from 'react';
import { styled } from '@mui/material/styles';
import { Add, Checkmark } from '@carbon/icons-react';
import { useFormContext } from 'react-hook-form';
import { useInvitesAnalyticsTrackers } from '../hooks/useInvitesAnalyticsTrackers';
import { INVITES } from '../../../../constants/identifiers';

const Container = styled('div')(() => ({
  padding: '20px 0 8px 16px',
  display: 'flex',
  gap: '20px',
  borderTop: '1px solid #E4E7ED',
  justifyContent: 'space-between',
  '@media (max-width: 599px)': {
    padding: '20px 0 0 0',
    flexDirection: 'column',
  },
}));

const Name = styled('div')(() => ({
  fontSize: '16px',
  fontWeight: '500',
  fontFamily: 'National2Medium',
  fontStyle: 'normal',
  lineHeight: '150%',
  color: '#117091',
}));

const Description = styled('div')(() => ({
  fontSize: '14px',
  fontWeight: '400',
  fontFamily: 'National2Regular',
  fontStyle: 'normal',
  lineHeight: '150%',
  color: '#576C82',
}));

const Price = styled('div')(() => ({
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'National2Regular',
  fontStyle: 'normal',
  lineHeight: '150%',
  color: '#576C82',
  display: 'flex',
  alignItems: 'center',
  maxHeight: '28px',
}));

const UnselectedButton = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#FFFFFF',
  border: '1px solid #E4E7ED',
  boxSizing: 'border-box',
  borderRadius: '4px',
  '&:hover': {
    background: '#F7F9FA',
  },
  height: '32px',
  width: 'auto',
  minWidth: '32px',
}));

const SelectedButton = styled('button')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#117292',
  border: 'none',
  borderRadius: '4px',
  '&:hover': {
    background: '#06556F',
  },
  height: '32px',
  width: 'auto',
  minWidth: '32px',
}));

const Screening = ({ name, description, price, screening, slug }) => {
  const { watch, setValue } = useFormContext();
  const { trackAddOnSelected, trackAddOnRemoved } =
    useInvitesAnalyticsTrackers();
  const selected = watch(`${slug}.${screening}`);

  const onClick = () => {
    setValue(`${slug}.${screening}`, !watch(`${slug}.${screening}`));

    if (selected === undefined || selected === false) {
      trackAddOnSelected(name);
    } else {
      trackAddOnRemoved(name);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        <Name>{name}</Name>
        <Description>{description}</Description>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <Price>{price}</Price>
        {selected ? (
          <SelectedButton
            onClick={onClick}
            data-testid={`${screening}-${INVITES.MISC.removeBtn}`}
          >
            <Checkmark fill='#FFFFFF' />
          </SelectedButton>
        ) : (
          <UnselectedButton
            onClick={onClick}
            data-testid={`${screening}-${INVITES.MISC.addBtn}`}
          >
            <Add fill='#334559' />
          </UnselectedButton>
        )}
      </div>
    </Container>
  );
};

export default Screening;
