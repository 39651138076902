import styled, { keyframes } from 'styled-components';

const shine = keyframes`
  to {
    background-position: 200% center;
  }
`;

interface LoadingBlockProps {
  height?: string;
  alignSelf?: string;
  dataTestId?: string;
}

export const LoadingBlock = styled.div.attrs<LoadingBlockProps>(props => ({
  'data-testid': props.dataTestId,
}))<LoadingBlockProps>`
  background: linear-gradient(
    to right,
    #f2f5f7 20%,
    #f2f5f7 40%,
    #e1e6eb 60%,
    #f2f5f7 80%
  );
  background-size: 200% auto;
  color: rgba(0, 0, 0, 0.1);
  -webkit-text-fill-color: transparent;
  animation: ${shine} 1.5s linear infinite;
  animation-direction: reverse;
  border-radius: 2px;
  width: 44px;
  margin: 0;
  height: ${props => props.height || '18px'};
  align-self: ${props => props.alignSelf || 'auto'};
`;

export default LoadingBlock;
