import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { StoreProvider } from '../../hooks/useStore';
import { Link } from 'react-router-dom';
import { IdleTimerProvider } from 'react-idle-timer';
import Loading from '../Loading';
import NotFound from '../NotFound';
import SigninV4 from '../views/SignupV4/SigninV4';
import { PHASE_1B } from '../helpers/Constants.js';

export default function loadUser(WrapperComponent) {
  @inject('store')
  @observer
  class Wrapper extends Component {
    constructor(props) {
      super(props);
      this.onIdle = this.onIdle.bind(this);
    }

    trackSignupPaymentSubmitted() {
      const { store } = this.props;
      store.trackAnalyticsEvent(
        'Self-Serve Customer Signup Return to Payment Page Viewed',
        { 'Test Variation': 'Control' },
      );
    }

    /* eslint-disable-next-line camelcase */
    UNSAFE_componentWillMount() {
      const { store } = this.props;
      if (!store.auth.user) {
        store.auth.getCurrentUser();
      }
      const searchParams = new URLSearchParams(window.location.search);
      if (
        (this.props.location.pathname === '/checkr/payment' ||
          this.props.location.pathname === '/checkr-goodhire/payment') &&
        (searchParams.has('utm_campaign') ||
          searchParams.has('utm_medium') ||
          searchParams.has('utm_content') ||
          searchParams.has('utm_source') ||
          searchParams.has('utm_term'))
      ) {
        this.trackSignupPaymentSubmitted();
      }
    }

    onIdle() {
      const { store } = this.props;
      store.auth.removeToken();
      window.location.reload();
    }

    render() {
      const { store, phase } = this.props;

      if (store.auth.loading) {
        return <Loading />;
      }

      if (!store.auth.user) {
        const redesignEnabled = phase === PHASE_1B;
        return redesignEnabled ? (
          <SigninV4 {...this.props} />
        ) : (
          <NotFound>
            <p>
              You need to sign in to continue...{' '}
              <Link to={store.getStep('signin')}>Sign In</Link>
            </p>
          </NotFound>
        );
      }

      return (
        <StoreProvider value={store}>
          <IdleTimerProvider timeout={1000 * 60 * 60} onIdle={this.onIdle}>
            <WrapperComponent {...this.props} />
          </IdleTimerProvider>
        </StoreProvider>
      );
    }
  }
  Wrapper.wrappedComponent.propTypes = {
    store: PropTypes.object.isRequired,
  };
  return Wrapper;
}
