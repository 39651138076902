import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';
import nextId from 'react-id-generator';

export default class Tooltip extends Component {
  htmlId = `tooltip-${nextId()}`;

  render() {
    const { tooltip } = this.props;
    let tooltipHTML;

    if (React.isValidElement(tooltip)) {
      tooltipHTML = ReactDOMServer.renderToStaticMarkup(tooltip);
    } else {
      tooltipHTML = tooltip;
    }

    return (
      <span>
        <span
          className='text-muted small ml-2 icon-tooltip'
          data-for={this.htmlId}
          data-tip={tooltipHTML}
        >
          &#9432;
        </span>
        <ReactTooltip
          id={this.htmlId}
          className='tooltip-shadow'
          effect='solid'
          type='light'
          html
        />
      </span>
    );
  }
}

Tooltip.propTypes = {
  tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
