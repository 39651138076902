import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const MotorVehicleReport = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo =
    'Searches a state’s DMV or similar entity; confirms driver license and identifies driving incidents';

  const advantages = [
    'Most records are digitized for faster turnaround',
    'Single source of all driving-related information from that state',
    'Reports all data as provided by the DMV for the past 3-10 years, depending on state (+ Puerto Rico)',
  ];

  const considerations = [
    'Only searches state (+ Puerto Rico) of the candidate’s current driver’s license',
    'Some states and Puerto Rico have extra registration requirements',
  ];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>Motor Vehicle Report</ScreeningTitle>
        <ScreeningPrice priceByType='motor_vehicle_report' />
        <AddScreeningsAction
          screeningType='motor_vehicle_report'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default MotorVehicleReport;
