import React, { Component, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { IMaskInput } from 'react-imask';
import * as Sentry from '@sentry/browser';
import pick from 'lodash/pick';

import * as Form from '../../form';
import CheckrDirectWorkLocationSelector from './CheckrDirectWorkLocationSelector';
import ReCAPTCHA from '../../CheckrReCAPTCHA';
import CheckrDirectProgressBar from './CheckrDirectProgressBar';
import PasswordStrengthHelper from './PasswordStrengthHelper';
import { STATE_OPTIONS } from '../../helpers/Constants';
import ReactTooltip from 'react-tooltip';
import { verifyInfo } from './CheckrDirectConfig';
import styled from 'styled-components';
import {
  Box,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material/';
import { Help, Visibility, VisibilityOff } from '@mui/icons-material';
import { SIGNUP } from '../../../constants/identifiers';

const StyledTypography = styled(Typography)`
  @media only screen and (min-width: 600px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`;

const TextFieldMask = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, mask, definitions, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={definitions}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      onChange={() => {}}
    />
  );
});

function parseSearchParams(locationSearch) {
  try {
    return queryString.parse(locationSearch);
  } catch (error) {
    Sentry.captureException(error, {
      tags: { 'parse-search-params': 'checkr-direct-verify-a' },
    });
    return {};
  }
}

@inject('store')
@observer
export default class CheckrDirectVerifyA extends Component {
  constructor(props) {
    super(props);
    this.state = { passwordVisible: false, showTooltip: false };
    this.store = this.props.store;
    this.form = this.store.signupV3;
    this.form.assignParams();
    this.searchParams = parseSearchParams(this.props.location.search);
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    if (
      !this.searchParams.email ||
      !this.searchParams.fullName ||
      !this.searchParams.company
    ) {
      window.location.href = this.store.isGoodhireWebsite
        ? 'https://www.goodhire.com/get-started/about/'
        : 'https://checkr.com/signup';
    }

    const { startPLT } = this.searchParams;
    this.store.performanceObserver.setEndLoadTime({
      startPLTFromMarketing: startPLT,
    });
    if (startPLT) {
      this.clearPLTFromParams();
    }

    if (this.searchParams.email) {
      this.form.handleChange('email', this.searchParams.email);
    }
    this.form.trackSignupPageView();

    const query = Object.fromEntries(new URLSearchParams(this.searchParams));
    const updatedRouteParams = pick(
      query,
      this.form.constructor.assignableFields,
    );

    const localStorageData = JSON.parse(localStorage.getItem('signupV3'));
    const updateLocalStorageData = {
      ...localStorageData,
      ...updatedRouteParams,
    };

    localStorage.setItem('signupV3', JSON.stringify(updateLocalStorageData));
  }

  clearPLTFromParams = () => {
    const history = this.store.router.router.history;

    const params = new URLSearchParams(history.location.search);
    params.delete('startPLT');
    history.replace({
      search: params.toString(),
    });
  };

  serviceAgreementUrl = () => {
    return (
      this.store.application.custom_service_agreement_url ||
      'https://checkr.com/customer-agreement/'
    );
  };

  submitWithRecaptcha = e => {
    this.form.loading = true;
    this.form.trackSignupNextClicked();
    e.preventDefault();
    this.store.performanceObserver.setStartLoadTime();
    const errors = this.form.validateOnEvent();

    if (errors) {
      this.form.error.error = 'signupV3.errors';
      this.form.loading = false;
      this.form.trackSignupClientErrors();
      this.store.performanceObserver.setEndLoadTime();
      window.scrollTo(0, 0);
      return;
    } else {
      this.recaptchaRef.current
        .executeAsync()
        .then(recaptchaCode => {
          this.form.submit(recaptchaCode);
        })
        .catch(() => {
          window.scrollTo(0, 0);
          this.store.performanceObserver.setEndLoadTime();
          this.form.error = {
            error: 'Recaptcha encountered an error',
          };
        })
        .finally(() => {
          this.recaptchaRef.current?.reset();
        });
    }

    this.form.loading = false;
  };

  toggleTooltip() {
    const { showTooltip } = this.state;
    this.setState({
      showTooltip: !showTooltip,
    });
  }

  render() {
    return (
      <div>
        <div>
          <CheckrDirectProgressBar activeStep={0} />
        </div>
        <div className='row'>
          <div className='col-md-12 main'>
            {this.store.isGoodhireWebsite && (
              <CardHeader
                title={
                  <Typography
                    textAlign='center'
                    fontWeight='600'
                    fontSize='38px'
                    color='#001730'
                    fontFamily='ArgentCFRegular'
                    width='100%'
                    data-testid={SIGNUP.MISC.goodhireHeader}
                  >
                    Welcome to Checkr, GoodHire&apos;s parent company!
                  </Typography>
                }
                subheader={
                  <StyledTypography
                    textAlign='center'
                    paddingTop='10px'
                    fontWeight='400'
                    lineHeight='150%'
                    fontSize='16px'
                    color='#0A1626'
                    fontFamily='National2Regular'
                    data-testid={SIGNUP.MISC.goodhireSubheader}
                  >
                    We empower you to run secure and compliant background
                    checks. Let&rsquo;s get you on your way to running your
                    first check!
                  </StyledTypography>
                }
              />
            )}
            {!this.store.isGoodhireWebsite && (
              <CardHeader
                title={
                  <Typography
                    textAlign='center'
                    fontWeight='600'
                    fontSize='38px'
                    color='#001730'
                    fontFamily='ArgentCFRegular'
                    data-testid={SIGNUP.MISC.checkrHeader}
                  >
                    Welcome to Checkr!
                  </Typography>
                }
                subheader={
                  <StyledTypography
                    textAlign='center'
                    paddingTop='10px'
                    fontWeight='400'
                    lineHeight='150%'
                    fontSize='16px'
                    color='#576C82'
                    fontFamily='National2Regular'
                    data-testid={SIGNUP.MISC.checkrSubheader}
                  >
                    {this.store.isMicroSMB ? (
                      <>
                        Did you know you can start ordering background checks
                        right now? Let’s create your Checkr account so you can
                        start your first order today.
                      </>
                    ) : (
                      <div>
                        We need a little more information to create your free
                        account and verify your business.
                        <span
                          data-tip={verifyInfo}
                          data-for='cd-tooltip'
                          className='cd-tooltip-icon'
                        >
                          <i
                            className='fa fa-question-circle'
                            data-testid={SIGNUP.MISC.headerTooltip}
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                showTooltip: !this.state.showTooltip,
                              })
                            }
                          />
                          <ReactTooltip
                            place='right'
                            id='cd-tooltip'
                            role='tooltip'
                            className='text-left cd-tooltip tooltip-shadow'
                            clickable
                            effect='solid'
                            type='light'
                            html
                          />
                        </span>
                      </div>
                    )}
                  </StyledTypography>
                }
              />
            )}
            <Form.Form form={this.form}>
              {this.form.error?.company || this.form.error?.fullName ? (
                <div className='error-msg text-danger'>
                  Something when wrong. Please{' '}
                  <a
                    href={
                      this.store.isGoodhireWebsite
                        ? 'https://www.goodhire.com/get-started/about/'
                        : 'https://checkr.com/get-started'
                    }
                  >
                    re-enter your information.
                  </a>
                </div>
              ) : (
                <Form.Error error={this.form.error} store={this.store} />
              )}
              <div className='col-md-6 mx-auto'>
                <Box className='cd-signup-form mx-auto'>
                  <CardHeader
                    title={
                      <Typography
                        textAlign='left'
                        fontWeight='500'
                        fontSize='18px'
                        marginTop='-20px'
                        marginLeft='-10px'
                        color='#001730'
                        fontFamily='National2Medium'
                      >
                        Login Information
                      </Typography>
                    }
                  />
                  <TextField
                    style={{
                      width: '100%',
                      margin: '8px',
                    }}
                    type='text'
                    label='Business email'
                    value={this.form.email}
                    variant='outlined'
                    error={Boolean(this.form.error?.email)}
                    helperText={this.form.error?.email}
                    inputProps={{
                      maxLength: 256,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.email,
                    }}
                    onChange={e => {
                      this.form.handleChange('email', e.target.value);
                    }}
                  />
                  <TextField
                    style={{ margin: '8px' }}
                    type={this.state.passwordVisible ? 'text' : 'password'}
                    label='Create password'
                    variant='outlined'
                    error={Boolean(this.form.error?.password)}
                    inputProps={{
                      'data-testid': SIGNUP.INPUT.password,
                    }}
                    InputProps={{
                      style: { backgroundColor: 'white' },
                      maxLength: 35,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                passwordVisible: !this.state.passwordVisible,
                              })
                            }
                          >
                            {this.state.passwordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    FormHelperTextProps={{
                      style: {
                        backgroundColor: 'transparent',
                        paddingTop: '8px',
                        marginBottom: '16px',
                      },
                      'data-testid': SIGNUP.ERROR.password,
                    }}
                    helperText={
                      <PasswordStrengthHelper password={this.form.password} />
                    }
                    onChange={e => {
                      this.form.handleChange('password', e.target.value);
                    }}
                    fullWidth
                  />
                  <CardHeader
                    title={
                      <Typography
                        textAlign='left'
                        fontWeight='500'
                        fontSize='18px'
                        marginTop='-20px'
                        marginLeft='-10px'
                        color='#001730'
                        fontFamily='National2Medium'
                      >
                        Business Information
                      </Typography>
                    }
                  />
                  <TextField
                    style={{ width: '100%', margin: '6px' }}
                    type='text'
                    label='US Tax ID'
                    variant='outlined'
                    value={this.form.taxId || ''}
                    error={Boolean(this.form.error?.taxId)}
                    FormHelperTextProps={{
                      'data-testid': SIGNUP.ERROR.taxID,
                    }}
                    helperText={this.form.error?.taxId}
                    inputProps={{
                      maxLength: 10,
                      minLength: 9,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.taxID,
                      mask: '00-0000000',
                      definitions: { '#': /[1-9]/ },
                    }}
                    InputProps={{
                      inputComponent: TextFieldMask,
                      style: {
                        backgroundColor: 'white',
                      },
                      endAdornment: (
                        <Tooltip
                          enterTouchDelay={50}
                          leaveTouchDelay={5000}
                          title={
                            <>
                              We use your tax ID, or employer identification
                              number (EIN), to verify your business and its
                              valid permissible purpose.
                            </>
                          }
                        >
                          <IconButton>
                            <Help style={{ color: '#8799AB' }} />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    onChange={e => {
                      this.form.handleChange('taxId', e.target.value);
                    }}
                  />
                  <br />
                  <br />
                  <CardHeader
                    title={
                      <Typography
                        textAlign='left'
                        fontWeight='500'
                        fontSize='18px'
                        marginTop='-20px'
                        marginLeft='-10px'
                        color='#001730'
                        fontFamily='National2Medium'
                      >
                        Business address
                      </Typography>
                    }
                  />
                  <TextField
                    style={{ width: '100%', margin: '6px' }}
                    type='text'
                    label='Business address 1'
                    value={this.form.street || ''}
                    variant='outlined'
                    error={Boolean(this.form.error?.street)}
                    FormHelperTextProps={{
                      'data-testid': SIGNUP.ERROR.street,
                    }}
                    helperText={this.form.error?.street}
                    inputProps={{
                      maxLength: 35,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.street,
                    }}
                    onChange={e => {
                      this.form.handleChange('street', e.target.value);
                    }}
                  />
                  <br />
                  <TextField
                    style={{ width: '100%', margin: '6px' }}
                    type='text'
                    label='Business address 2'
                    value={this.form.street2 || ''}
                    variant='outlined'
                    inputProps={{
                      maxLength: 35,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.street2,
                    }}
                    onChange={e => {
                      this.form.handleChange('street2', e.target.value);
                    }}
                  />
                  <br />
                  <TextField
                    style={{ width: '100%', margin: '6px' }}
                    type='text'
                    label='City'
                    value={this.form.city || ''}
                    variant='outlined'
                    error={Boolean(this.form.error?.city)}
                    helperText={this.form.error?.city}
                    FormHelperTextProps={{
                      'data-testid': SIGNUP.ERROR.city,
                    }}
                    inputProps={{
                      maxLength: 35,
                      style: { backgroundColor: 'white' },
                      'data-testid': SIGNUP.INPUT.city,
                    }}
                    onChange={e => {
                      this.form.handleChange('city', e.target.value);
                    }}
                  />
                  <br />
                  <Grid
                    container
                    display='flex'
                    variant='outlined'
                    margin={'1'}
                    style={{ width: '100%', marginBottom: 32 }}
                  >
                    <Grid item xs>
                      <TextField
                        style={{ margin: '6px' }}
                        type='text'
                        label='ZIP code'
                        value={this.form.zipcode || ''}
                        variant='outlined'
                        error={Boolean(this.form.error?.zipcode)}
                        FormHelperTextProps={{
                          'data-testid': SIGNUP.ERROR.zip,
                        }}
                        helperText={this.form.error?.zipcode}
                        inputProps={{
                          maxLength: 5,
                          style: { backgroundColor: 'white' },
                          'data-testid': SIGNUP.INPUT.zip,
                          mask: '00000',
                          definitions: { '#': /[1-9]/ },
                        }}
                        InputProps={{
                          inputComponent: TextFieldMask,
                        }}
                        onChange={e => {
                          this.form.handleChange('zipcode', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        style={{ width: '100%', margin: '6px' }}
                        label='State'
                        value={this.form.stateName || ''}
                        variant='outlined'
                        error={Boolean(this.form.error?.stateName)}
                        FormHelperTextProps={{
                          'data-testid': SIGNUP.ERROR.state,
                        }}
                        helperText={this.form.error?.stateName}
                        name='stateName'
                        onChange={e => {
                          this.form.handleChange('stateName', e.target.value);
                        }}
                        inputProps={{
                          maxLength: 5,
                          style: { backgroundColor: 'white' },
                          'data-testid': SIGNUP.INPUT.state,
                        }}
                        InputProps={{
                          style: { backgroundColor: 'white' },
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                maxHeight: {
                                  // ITEM_HEIGHT: 48, ITEM_PADDING_TOP: 8
                                  xs: 224, // ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
                                  lg: 207, // ITEM_HEIGHT * 4.15 + ITEM_PADDING_TOP
                                },
                              },
                            },
                          },
                        }}
                        select
                      >
                        {STATE_OPTIONS.map(stateOption => (
                          <MenuItem
                            key={stateOption.text}
                            value={stateOption.value}
                          >
                            {stateOption.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <div className='col-md-12 tax-exempt'>
                      <Form.Checkbox
                        form={this.form}
                        className='mt-2 mb-2'
                        label={
                          <>
                            Check this box if your business is tax exempt.
                            (Sales tax exemption certificate required)
                          </>
                        }
                        name='taxExempt'
                        field='taxExempt'
                        value='true'
                      />
                    </div>
                  </Grid>

                  <CheckrDirectWorkLocationSelector form={this.form} />
                  <CardHeader
                    title={
                      <Typography
                        textAlign='left'
                        fontWeight='500'
                        fontSize='18px'
                        marginTop='-30px'
                        marginLeft='-10px'
                        color='#001730'
                        fontFamily='National2Medium'
                      >
                        Confirmation of purpose
                      </Typography>
                    }
                  />
                  <Box sx={{ fontSize: '14px', marginLeft: '10px' }}>
                    <Form.Checkbox
                      label={
                        <>
                          {`I certify that I will order and use the reports only for a lawful and
                          appropriate permissible purpose, as defined by the FCRA, and in accordance with all applicable
                          law and the `}
                          <a
                            href='https://checkr.com/customer-agreement'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            Checkr Customer Services Agreement.
                          </a>
                        </>
                      }
                      form={this.form}
                      name='purpose'
                      value='employment'
                      dataTestId={SIGNUP.MISC.purpose}
                    />
                  </Box>
                  <br />
                  <br />
                  <ReCAPTCHA recaptchaRef={this.recaptchaRef} />
                  <div className='d-flex justify-content-center'>
                    <Form.Submit
                      customClassName='cd-btn'
                      text='Next'
                      loading={this.form.loading}
                      submitWithRecaptcha={e => this.submitWithRecaptcha(e)}
                      dataTestId={SIGNUP.MISC.submit}
                      disabled={
                        !this.form.taxId ||
                        !this.form.numberOfComplianceLocations
                      }
                      // onClick={() => this.form.trackNextClicked}
                    />
                  </div>
                </Box>
              </div>
              <p className='text-muted text-center cd-footer mt-2'>
                By continuing, you agree to the Checkr, Inc.{' '}
                <a
                  href='https://checkr.com/privacy-policy/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href={this.serviceAgreementUrl()}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Services Agreement
                </a>
                .
              </p>
              <br />
            </Form.Form>
          </div>
        </div>
      </div>
    );
  }
}
