/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useCallback } from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const ProfessionalLicenseVerification = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo = 'Verifies a candidate’s professional license';

  const advantages = [
    'Determines eligibility for organizations with specialized or regulated roles',
    `License types include: Accounting, Coast Guard, Contractors, Dietitian, Healthcare, Insurance, IT (Technology),
    Mental health, Nursing, Pharmaceutical, Real estate, and Veterinary medicine`,
  ];

  const considerations = [
    'Many professional licenses can be checked instantly, while others may take up to 1-2 business days',
  ];

  const handleOnAddClick = useCallback(() => {
    const plvAdditionalProperties = {
      type: 'pro_license_verification',
      input_required: false,
      locations: [
        true,
        '["AL","AK","AZ","AR","CA","CO","CT","DE","DC","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY","United States"]',
      ],
    };
    onAddClick('professional_license_verification', plvAdditionalProperties);
  }, [onAddClick]);

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>Professional License Verification</ScreeningTitle>
        <ScreeningPrice priceByType='professional_license_verification' />
        <AddScreeningsAction
          screeningType='professional_license_verification'
          onAddClick={handleOnAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default ProfessionalLicenseVerification;
