import React, { useCallback } from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import CheckboxWithTooltip from '../../shared/CheckboxWithTooltip';
import { useAddScreeningsContext } from '../../shared/context';

const EducationVerification = () => {
  const {
    onAddClick,
    onRemoveClick,
    disabledScreenings,
    addedScreenings,
    additionalProperties,
  } = useAddScreeningsContext();

  const screeningInfo =
    'Verifies the highest degree obtained from high school or equivalent through doctorates';

  const advantages = [
    'National Student Clearinghouse used as primary data source',
    'Reports degrees earned and dates of enrollment',
    'Eliminates burden of verifying degrees',
  ];

  const considerations = [
    'Some schools may have to be verified manually with longer turnaround',
    'Verifies education, not identity',
  ];

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onAddClick('education_verification', {
        international_upgrade: event.target.checked,
      }),
    [onAddClick],
  );

  const checked = !!(
    additionalProperties.education_verification &&
    additionalProperties.education_verification.international_upgrade
  );

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
      height='92px'
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>Education Verification</ScreeningTitle>
        <ScreeningPrice priceByType='education_verification' />
        <AddScreeningsAction
          screeningType='education_verification'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
      {!disabledScreenings.includes('education_verification') && (
        <CheckboxWithTooltip
          onAddClick={handleCheckboxClick}
          checked={checked}
          screening='education_verification'
        />
      )}
    </ScreeningAccordion>
  );
};

export default EducationVerification;
