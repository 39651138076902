import React, { useCallback } from 'react';
import { Button as MuiButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled as MuiStyled } from '@mui/material/styles';

import colors from '../../../../../styles/_colors.scss';
import { ScreeningType } from '../../PackageUtils/packageTypes';
import { ADD_SCREENINGS } from '../../../../../constants/identifiers';

const buttonStyles = {
  borderRadius: '0',
  border: `2px solid ${colors.navy2}`,
  color: colors.navy2,
  textTransform: 'none' as const,
  fontFamily: 'National2Medium',
  fontSize: '16px',
  height: '40px',
  marginLeft: 'auto',
  alignSelf: 'center',
  '&:hover': {
    border: `2px solid ${colors.navy2}`,
  },
};

const Button = MuiStyled(MuiButton)({
  ...buttonStyles,
});

const DisabledButton = MuiStyled(MuiButton)({
  ...buttonStyles,
  border: `2px solid ${colors.slate5}`,
  color: colors.slate5,
  '&:hover': {
    border: `2px solid ${colors.slate5}`,
    color: colors.slate5,
    cursor: 'not-allowed',
    background: 'none',
  },
});

type Props = {
  screeningType: ScreeningType;
  onAddClick: (
    screeningType: ScreeningType,
    additionalProperties?: object,
  ) => void;
  onRemoveClick: (screeningType: ScreeningType) => void;
  disabledScreenings: ScreeningType[];
  addedScreenings: ScreeningType[];
  isDisabled?: boolean;
};

const AddScreeningsAction: React.FC<Props> = ({
  screeningType,
  onAddClick,
  onRemoveClick,
  disabledScreenings,
  addedScreenings,
  isDisabled,
}) => {
  const added = addedScreenings.includes(screeningType);
  const disabled = disabledScreenings.includes(screeningType);

  const handleOnAddClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onAddClick(screeningType);
    },
    [screeningType, onAddClick],
  );

  const handleOnRemoveClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onRemoveClick(screeningType);
    },
    [screeningType, onRemoveClick],
  );

  const disabledOnClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
    },
    [],
  );

  if (isDisabled || disabled) {
    return (
      <DisabledButton
        onClick={disabledOnClick}
        disableRipple
        variant='outlined'
        data-testid={`${ADD_SCREENINGS.screeningDisabledAdd}-${screeningType}`}
      >
        <AddIcon />
        Add
      </DisabledButton>
    );
  }

  if (added) {
    return (
      <Button
        variant='outlined'
        onClick={handleOnRemoveClick}
        data-testid={`${ADD_SCREENINGS.screeningRemove}-${screeningType}`}
      >
        <DeleteIcon />
        Remove
      </Button>
    );
  }

  return (
    <Button
      variant='outlined'
      onClick={handleOnAddClick}
      data-testid={`${ADD_SCREENINGS.screeningAdd}-${screeningType}`}
    >
      <AddIcon />
      Add
    </Button>
  );
};

export default AddScreeningsAction;
