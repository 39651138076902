export const customCardPaymentErrorMsgs = {
  'Your card was declined.':
    'Your card was declined. Verify your information and try again.',
  'Your card has insufficient funds.':
    'Your card has insufficient funds. Verify your information and try again.',
  'Your card has expired.':
    'Your card has expired. Verify your expiration date and try again.',
  "Your card's security code is incorrect.":
    'Your security code is invalid. Verify your code and try again.',
  'An error occurred while processing your card. Try again in a little bit.':
    'An error occurred while processing your card. Wait a moment and try again.',
};

export const fallbackCardPaymentErrorMsg =
  'An error occurred while processing your card. Verify your information and try again later.';
