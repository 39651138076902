import React from 'react';

import { Icon, ScreeningSectionTitle } from '../shared/ListItemElements';
import truck from '../shared/svg/truck.svg';
import ScreeningsSectionAccordion from '../shared/ScreeningsSectionAccordion';
import MotorVehicleReport from './MVRScreenings/MotorVehicleReport';
import { ADD_SCREENINGS } from '../../../../../constants/identifiers';

const Screenings = () => (
  <ul>
    <MotorVehicleReport />
  </ul>
);

const MotorVehicleRecordsCheckItem = () => {
  return (
    <ScreeningsSectionAccordion
      IconComponent={<Icon src={truck} className='screening-icon' />}
      TitleComponent={
        <ScreeningSectionTitle>
          Motor Vehicle Records Check
        </ScreeningSectionTitle>
      }
      ScreeningsComponent={<Screenings />}
      dataTestId={ADD_SCREENINGS.domesticMvrScreenings}
    />
  );
};

export default MotorVehicleRecordsCheckItem;
