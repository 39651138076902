export default [
  {
    country: 'US',
    state: 'AL',
    state_name: 'Alabama',
    geo_name: 'Alabama',
  },
  {
    country: 'US',
    state: 'AK',
    state_name: 'Alaska',
    geo_name: 'Alaska',
  },
  {
    country: 'US',
    state: 'AZ',
    state_name: 'Arizona',
    geo_name: 'Arizona',
  },
  {
    country: 'US',
    state: 'AR',
    state_name: 'Arkansas',
    geo_name: 'Arkansas',
  },
  {
    country: 'US',
    state: 'CA',
    state_name: 'California',
    geo_name: 'California',
  },
  {
    country: 'US',
    state: 'CO',
    state_name: 'Colorado',
    geo_name: 'Colorado',
  },
  {
    country: 'US',
    state: 'CT',
    state_name: 'Connecticut',
    geo_name: 'Connecticut',
  },
  {
    country: 'US',
    state: 'DE',
    state_name: 'Delaware',
    geo_name: 'Delaware',
  },
  {
    country: 'US',
    state: 'DC',
    state_name: 'District of Columbia',
    geo_name: 'District of Columbia',
  },
  {
    country: 'US',
    state: 'FL',
    state_name: 'Florida',
    geo_name: 'Florida',
  },
  {
    country: 'US',
    state: 'GA',
    state_name: 'Georgia',
    geo_name: 'Georgia',
  },
  {
    country: 'US',
    state: 'HI',
    state_name: 'Hawaii',
    geo_name: 'Hawaii',
  },
  {
    country: 'US',
    state: 'ID',
    state_name: 'Idaho',
    geo_name: 'Idaho',
  },
  {
    country: 'US',
    state: 'IL',
    state_name: 'Illinois',
    geo_name: 'Illinois',
  },
  {
    country: 'US',
    state: 'IN',
    state_name: 'Indiana',
    geo_name: 'Indiana',
  },
  {
    country: 'US',
    state: 'IA',
    state_name: 'Iowa',
    geo_name: 'Iowa',
  },
  {
    country: 'US',
    state: 'KS',
    state_name: 'Kansas',
    geo_name: 'Kansas',
  },
  {
    country: 'US',
    state: 'KY',
    state_name: 'Kentucky',
    geo_name: 'Kentucky',
  },
  {
    country: 'US',
    state: 'LA',
    state_name: 'Louisiana',
    geo_name: 'Louisiana',
  },
  {
    country: 'US',
    state: 'ME',
    state_name: 'Maine',
    geo_name: 'Maine',
  },
  {
    country: 'US',
    state: 'MD',
    state_name: 'Maryland',
    geo_name: 'Maryland',
  },
  {
    country: 'US',
    state: 'MA',
    state_name: 'Massachusetts',
    geo_name: 'Massachusetts',
  },
  {
    country: 'US',
    state: 'MI',
    state_name: 'Michigan',
    geo_name: 'Michigan',
  },
  {
    country: 'US',
    state: 'MN',
    state_name: 'Minnesota',
    geo_name: 'Minnesota',
  },
  {
    country: 'US',
    state: 'MS',
    state_name: 'Mississippi',
    geo_name: 'Mississippi',
  },
  {
    country: 'US',
    state: 'MO',
    state_name: 'Missouri',
    geo_name: 'Missouri',
  },
  {
    country: 'US',
    state: 'MT',
    state_name: 'Montana',
    geo_name: 'Montana',
  },
  {
    country: 'US',
    state: 'NE',
    state_name: 'Nebraska',
    geo_name: 'Nebraska',
  },
  {
    country: 'US',
    state: 'NV',
    state_name: 'Nevada',
    geo_name: 'Nevada',
  },
  {
    country: 'US',
    state: 'NH',
    state_name: 'New Hampshire',
    geo_name: 'New Hampshire',
  },
  {
    country: 'US',
    state: 'NJ',
    state_name: 'New Jersey',
    geo_name: 'New Jersey',
  },
  {
    country: 'US',
    state: 'NM',
    state_name: 'New Mexico',
    geo_name: 'New Mexico',
  },
  {
    country: 'US',
    state: 'NY',
    state_name: 'New York',
    geo_name: 'New York',
  },
  {
    country: 'US',
    state: 'NC',
    state_name: 'North Carolina',
    geo_name: 'North Carolina',
  },
  {
    country: 'US',
    state: 'ND',
    state_name: 'North Dakota',
    geo_name: 'North Dakota',
  },
  {
    country: 'US',
    state: 'OH',
    state_name: 'Ohio',
    geo_name: 'Ohio',
  },
  {
    country: 'US',
    state: 'OK',
    state_name: 'Oklahoma',
    geo_name: 'Oklahoma',
  },
  {
    country: 'US',
    state: 'OR',
    state_name: 'Oregon',
    geo_name: 'Oregon',
  },
  {
    country: 'US',
    state: 'PA',
    state_name: 'Pennsylvania',
    geo_name: 'Pennsylvania',
  },
  {
    country: 'US',
    state: 'RI',
    state_name: 'Rhode Island',
    geo_name: 'Rhode Island',
  },
  {
    country: 'US',
    state: 'SC',
    state_name: 'South Carolina',
    geo_name: 'South Carolina',
  },
  {
    country: 'US',
    state: 'SD',
    state_name: 'South Dakota',
    geo_name: 'South Dakota',
  },
  {
    country: 'US',
    state: 'TN',
    state_name: 'Tennessee',
    geo_name: 'Tennessee',
  },
  {
    country: 'US',
    state: 'TX',
    state_name: 'Texas',
    geo_name: 'Texas',
  },
  {
    country: 'US',
    state: 'UT',
    state_name: 'Utah',
    geo_name: 'Utah',
  },
  {
    country: 'US',
    state: 'VT',
    state_name: 'Vermont',
    geo_name: 'Vermont',
  },
  {
    country: 'US',
    state: 'VA',
    state_name: 'Virginia',
    geo_name: 'Virginia',
  },
  {
    country: 'US',
    state: 'WA',
    state_name: 'Washington',
    geo_name: 'Washington',
  },
  {
    country: 'US',
    state: 'WV',
    state_name: 'West Virginia',
    geo_name: 'West Virginia',
  },
  {
    country: 'US',
    state: 'WI',
    state_name: 'Wisconsin',
    geo_name: 'Wisconsin',
  },
  {
    country: 'US',
    state: 'WY',
    state_name: 'Wyoming',
    geo_name: 'Wyoming',
  },
  {
    country: 'US',
    state: 'OH',
    state_name: 'Ohio',
    city: 'Akron',
    geo_name: 'Ohio - Akron',
  },
  {
    country: 'US',
    state: 'NY',
    state_name: 'New York',
    city: 'Albany',
    geo_name: 'New York - Albany',
  },
  {
    country: 'US',
    state: 'NM',
    state_name: 'New Mexico',
    city: 'Albuquerque',
    geo_name: 'New Mexico - Albuquerque',
  },
  {
    country: 'US',
    state: 'PA',
    state_name: 'Pennsylvania',
    city: 'Allentown',
    geo_name: 'Pennsylvania - Allentown',
  },
  {
    country: 'US',
    state: 'AK',
    state_name: 'Alaska',
    city: 'Anchorage',
    geo_name: 'Alaska - Anchorage',
  },
  {
    country: 'US',
    state: 'MI',
    state_name: 'Michigan',
    city: 'Ann Arbor',
    geo_name: 'Michigan - Ann Arbor',
  },
  {
    country: 'US',
    state: 'MD',
    state_name: 'Maryland',
    city: 'Annapolis',
    geo_name: 'Maryland - Annapolis',
  },
  {
    country: 'US',
    state: 'GA',
    state_name: 'Georgia',
    city: 'Atlanta',
    geo_name: 'Georgia - Atlanta',
  },
  {
    country: 'US',
    state: 'ME',
    state_name: 'Maine',
    city: 'Auburn',
    geo_name: 'Maine - Auburn',
  },
  {
    country: 'US',
    state: 'GA',
    state_name: 'Georgia',
    city: 'Augusta',
    geo_name: 'Georgia - Augusta',
  },
  {
    country: 'US',
    state: 'ME',
    state_name: 'Maine',
    city: 'Augusta',
    geo_name: 'Maine - Augusta',
  },
  {
    country: 'US',
    state: 'CO',
    state_name: 'Colorado',
    city: 'Aurora',
    geo_name: 'Colorado - Aurora',
  },
  {
    country: 'US',
    state: 'IL',
    state_name: 'Illinois',
    city: 'Aurora',
    geo_name: 'Illinois - Aurora',
  },
  {
    country: 'US',
    state: 'TX',
    state_name: 'Texas',
    city: 'Austin',
    geo_name: 'Texas - Austin',
  },
  {
    country: 'US',
    state: 'MD',
    state_name: 'Maryland',
    city: 'Baltimore',
    geo_name: 'Maryland - Baltimore',
  },
  {
    country: 'US',
    state: 'ME',
    state_name: 'Maine',
    city: 'Bangor',
    geo_name: 'Maine - Bangor',
  },
  {
    country: 'US',
    state: 'LA',
    state_name: 'Louisiana',
    city: 'Baton Rouge',
    geo_name: 'Louisiana - Baton Rouge',
  },
  {
    country: 'US',
    state: 'NE',
    state_name: 'Nebraska',
    city: 'Bellevue',
    geo_name: 'Nebraska - Bellevue',
  },
  {
    country: 'US',
    state: 'WA',
    state_name: 'Washington',
    city: 'Bellevue',
    geo_name: 'Washington - Bellevue',
  },
  {
    country: 'US',
    state: 'MT',
    state_name: 'Montana',
    city: 'Billings',
    geo_name: 'Montana - Billings',
  },
  {
    country: 'US',
    state: 'MS',
    state_name: 'Mississippi',
    city: 'Biloxi',
    geo_name: 'Mississippi - Biloxi',
  },
  {
    country: 'US',
    state: 'AL',
    state_name: 'Alabama',
    city: 'Birmingham',
    geo_name: 'Alabama - Birmingham',
  },
  {
    country: 'US',
    state: 'ND',
    state_name: 'North Dakota',
    city: 'Bismarck',
    geo_name: 'North Dakota - Bismarck',
  },
  {
    country: 'US',
    state: 'MN',
    state_name: 'Minnesota',
    city: 'Bloomington',
    geo_name: 'Minnesota - Bloomington',
  },
  {
    country: 'US',
    state: 'ID',
    state_name: 'Idaho',
    city: 'Boise',
    geo_name: 'Idaho - Boise',
  },
  {
    country: 'US',
    state: 'MA',
    state_name: 'Massachusetts',
    city: 'Boston',
    geo_name: 'Massachusetts - Boston',
  },
  {
    country: 'US',
    state: 'KY',
    state_name: 'Kentucky',
    city: 'Bowling Green',
    geo_name: 'Kentucky - Bowling Green',
  },
  {
    country: 'US',
    state: 'MT',
    state_name: 'Montana',
    city: 'Bozeman',
    geo_name: 'Montana - Bozeman',
  },
  {
    country: 'US',
    state: 'CT',
    state_name: 'Connecticut',
    city: 'Bridgeport',
    geo_name: 'Connecticut - Bridgeport',
  },
  {
    country: 'US',
    state: 'NY',
    state_name: 'New York',
    city: 'Buffalo',
    geo_name: 'New York - Buffalo',
  },
  {
    country: 'US',
    state: 'VT',
    state_name: 'Vermont',
    city: 'Burlington',
    geo_name: 'Vermont - Burlington',
  },
  {
    country: 'US',
    state: 'MT',
    state_name: 'Montana',
    city: 'Butte',
    geo_name: 'Montana - Butte',
  },
  {
    country: 'US',
    state: 'MA',
    state_name: 'Massachusetts',
    city: 'Cambridge',
    geo_name: 'Massachusetts - Cambridge',
  },
  {
    country: 'US',
    state: 'IN',
    state_name: 'Indiana',
    city: 'Carmel',
    geo_name: 'Indiana - Carmel',
  },
  {
    country: 'US',
    state: 'NV',
    state_name: 'Nevada',
    city: 'Carson City',
    geo_name: 'Nevada - Carson City',
  },
  {
    country: 'US',
    state: 'WY',
    state_name: 'Wyoming',
    city: 'Casper',
    geo_name: 'Wyoming - Casper',
  },
  {
    country: 'US',
    state: 'IA',
    state_name: 'Iowa',
    city: 'Cedar Rapids',
    geo_name: 'Iowa - Cedar Rapids',
  },
  {
    country: 'US',
    state: 'AZ',
    state_name: 'Arizona',
    city: 'Chandler',
    geo_name: 'Arizona - Chandler',
  },
  {
    country: 'US',
    state: 'SC',
    state_name: 'South Carolina',
    city: 'Charleston',
    geo_name: 'South Carolina - Charleston',
  },
  {
    country: 'US',
    state: 'WV',
    state_name: 'West Virginia',
    city: 'Charleston',
    geo_name: 'West Virginia - Charleston',
  },
  {
    country: 'US',
    state: 'NC',
    state_name: 'North Carolina',
    city: 'Charlotte',
    geo_name: 'North Carolina - Charlotte',
  },
  {
    country: 'US',
    state: 'TN',
    state_name: 'Tennessee',
    city: 'Chattanooga',
    geo_name: 'Tennessee - Chattanooga',
  },
  {
    country: 'US',
    state: 'VA',
    state_name: 'Virginia',
    city: 'Chesapeake',
    geo_name: 'Virginia - Chesapeake',
  },
  {
    country: 'US',
    state: 'WY',
    state_name: 'Wyoming',
    city: 'Cheyenne',
    geo_name: 'Wyoming - Cheyenne',
  },
  {
    country: 'US',
    state: 'IL',
    state_name: 'Illinois',
    city: 'Chicago',
    geo_name: 'Illinois - Chicago',
  },
  {
    country: 'US',
    state: 'OH',
    state_name: 'Ohio',
    city: 'Cincinnati',
    geo_name: 'Ohio - Cincinnati',
  },
  {
    country: 'US',
    state: 'OH',
    state_name: 'Ohio',
    city: 'Cleveland',
    geo_name: 'Ohio - Cleveland',
  },
  {
    country: 'US',
    state: 'CO',
    state_name: 'Colorado',
    city: 'Colorado Springs',
    geo_name: 'Colorado - Colorado Springs',
  },
  {
    country: 'US',
    state: 'MO',
    state_name: 'Missouri',
    city: 'Columbia',
    geo_name: 'Missouri - Columbia',
  },
  {
    country: 'US',
    state: 'SC',
    state_name: 'South Carolina',
    city: 'Columbia',
    geo_name: 'South Carolina - Columbia',
  },
  {
    country: 'US',
    state: 'GA',
    state_name: 'Georgia',
    city: 'Columbus',
    geo_name: 'Georgia - Columbus',
  },
  {
    country: 'US',
    state: 'OH',
    state_name: 'Ohio',
    city: 'Columbus',
    geo_name: 'Ohio - Columbus',
  },
  {
    country: 'US',
    state: 'NH',
    state_name: 'New Hampshire',
    city: 'Concord',
    geo_name: 'New Hampshire - Concord',
  },
  {
    country: 'US',
    state: 'KY',
    state_name: 'Kentucky',
    city: 'Covington',
    geo_name: 'Kentucky - Covington',
  },
  {
    country: 'US',
    state: 'RI',
    state_name: 'Rhode Island',
    city: 'Cranston',
    geo_name: 'Rhode Island - Cranston',
  },
  {
    country: 'US',
    state: 'TX',
    state_name: 'Texas',
    city: 'Dallas',
    geo_name: 'Texas - Dallas',
  },
  {
    country: 'US',
    state: 'IA',
    state_name: 'Iowa',
    city: 'Davenport',
    geo_name: 'Iowa - Davenport',
  },
  {
    country: 'US',
    state: 'CO',
    state_name: 'Colorado',
    city: 'Denver',
    geo_name: 'Colorado - Denver',
  },
  {
    country: 'US',
    state: 'NH',
    state_name: 'New Hampshire',
    city: 'Derry',
    geo_name: 'New Hampshire - Derry',
  },
  {
    country: 'US',
    state: 'IA',
    state_name: 'Iowa',
    city: 'Des Moines',
    geo_name: 'Iowa - Des Moines',
  },
  {
    country: 'US',
    state: 'MI',
    state_name: 'Michigan',
    city: 'Detroit',
    geo_name: 'Michigan - Detroit',
  },
  {
    country: 'US',
    state: 'DE',
    state_name: 'Delaware',
    city: 'Dover',
    geo_name: 'Delaware - Dover',
  },
  {
    country: 'US',
    state: 'MN',
    state_name: 'Minnesota',
    city: 'Duluth',
    geo_name: 'Minnesota - Duluth',
  },
  {
    country: 'US',
    state: 'NC',
    state_name: 'North Carolina',
    city: 'Durham',
    geo_name: 'North Carolina - Durham',
  },
  {
    country: 'US',
    state: 'NJ',
    state_name: 'New Jersey',
    city: 'Edison',
    geo_name: 'New Jersey - Edison',
  },
  {
    country: 'US',
    state: 'NJ',
    state_name: 'New Jersey',
    city: 'Elizabeth',
    geo_name: 'New Jersey - Elizabeth',
  },
  {
    country: 'US',
    state: 'PA',
    state_name: 'Pennsylvania',
    city: 'Erie',
    geo_name: 'Pennsylvania - Erie',
  },
  {
    country: 'US',
    state: 'OR',
    state_name: 'Oregon',
    city: 'Eugene',
    geo_name: 'Oregon - Eugene',
  },
  {
    country: 'US',
    state: 'IN',
    state_name: 'Indiana',
    city: 'Evansville',
    geo_name: 'Indiana - Evansville',
  },
  {
    country: 'US',
    state: 'AK',
    state_name: 'Alaska',
    city: 'Fairbanks',
    geo_name: 'Alaska - Fairbanks',
  },
  {
    country: 'US',
    state: 'ND',
    state_name: 'North Dakota',
    city: 'Fargo',
    geo_name: 'North Dakota - Fargo',
  },
  {
    country: 'US',
    state: 'AR',
    state_name: 'Arkansas',
    city: 'Fayetteville',
    geo_name: 'Arkansas - Fayetteville',
  },
  {
    country: 'US',
    state: 'CO',
    state_name: 'Colorado',
    city: 'Fort Collins',
    geo_name: 'Colorado - Fort Collins',
  },
  {
    country: 'US',
    state: 'AR',
    state_name: 'Arkansas',
    city: 'Fort Smith',
    geo_name: 'Arkansas - Fort Smith',
  },
  {
    country: 'US',
    state: 'IN',
    state_name: 'Indiana',
    city: 'Fort Wayne',
    geo_name: 'Indiana - Fort Wayne',
  },
  {
    country: 'US',
    state: 'TX',
    state_name: 'Texas',
    city: 'Fort Worth',
    geo_name: 'Texas - Fort Worth',
  },
  {
    country: 'US',
    state: 'MD',
    state_name: 'Maryland',
    city: 'Frederick',
    geo_name: 'Maryland - Frederick',
  },
  {
    country: 'US',
    state: 'CA',
    state_name: 'California',
    city: 'Fresno',
    geo_name: 'California - Fresno',
  },
  {
    country: 'US',
    state: 'MD',
    state_name: 'Maryland',
    city: 'Gaithersburg',
    geo_name: 'Maryland - Gaithersburg',
  },
  {
    country: 'US',
    state: 'ND',
    state_name: 'North Dakota',
    city: 'Grand Forks',
    geo_name: 'North Dakota - Grand Forks',
  },
  {
    country: 'US',
    state: 'NE',
    state_name: 'Nebraska',
    city: 'Grand Island',
    geo_name: 'Nebraska - Grand Island',
  },
  {
    country: 'US',
    state: 'MI',
    state_name: 'Michigan',
    city: 'Grand Rapids',
    geo_name: 'Michigan - Grand Rapids',
  },
  {
    country: 'US',
    state: 'MT',
    state_name: 'Montana',
    city: 'Great Falls',
    geo_name: 'Montana - Great Falls',
  },
  {
    country: 'US',
    state: 'WI',
    state_name: 'Wisconsin',
    city: 'Green Bay',
    geo_name: 'Wisconsin - Green Bay',
  },
  {
    country: 'US',
    state: 'NC',
    state_name: 'North Carolina',
    city: 'Greensboro',
    geo_name: 'North Carolina - Greensboro',
  },
  {
    country: 'US',
    state: 'OR',
    state_name: 'Oregon',
    city: 'Gresham',
    geo_name: 'Oregon - Gresham',
  },
  {
    country: 'US',
    state: 'MS',
    state_name: 'Mississippi',
    city: 'Gulfport',
    geo_name: 'Mississippi - Gulfport',
  },
  {
    country: 'US',
    state: 'PA',
    state_name: 'Pennsylvania',
    city: 'Harrisburg',
    geo_name: 'Pennsylvania - Harrisburg',
  },
  {
    country: 'US',
    state: 'CT',
    state_name: 'Connecticut',
    city: 'Hartford',
    geo_name: 'Connecticut - Hartford',
  },
  {
    country: 'US',
    state: 'MS',
    state_name: 'Mississippi',
    city: 'Hattiesburg',
    geo_name: 'Mississippi - Hattiesburg',
  },
  {
    country: 'US',
    state: 'MT',
    state_name: 'Montana',
    city: 'Helena',
    geo_name: 'Montana - Helena',
  },
  {
    country: 'US',
    state: 'NV',
    state_name: 'Nevada',
    city: 'Henderson',
    geo_name: 'Nevada - Henderson',
  },
  {
    country: 'US',
    state: 'OR',
    state_name: 'Oregon',
    city: 'Hillsboro',
    geo_name: 'Oregon - Hillsboro',
  },
  {
    country: 'US',
    state: 'HI',
    state_name: 'Hawaii',
    city: 'Hilo',
    geo_name: 'Hawaii - Hilo',
  },
  {
    country: 'US',
    state: 'HI',
    state_name: 'Hawaii',
    city: 'Honolulu',
    geo_name: 'Hawaii - Honolulu',
  },
  {
    country: 'US',
    state: 'TX',
    state_name: 'Texas',
    city: 'Houston',
    geo_name: 'Texas - Houston',
  },
  {
    country: 'US',
    state: 'WV',
    state_name: 'West Virginia',
    city: 'Huntington',
    geo_name: 'West Virginia - Huntington',
  },
  {
    country: 'US',
    state: 'AL',
    state_name: 'Alabama',
    city: 'Huntsville',
    geo_name: 'Alabama - Huntsville',
  },
  {
    country: 'US',
    state: 'MO',
    state_name: 'Missouri',
    city: 'Independence',
    geo_name: 'Missouri - Independence',
  },
  {
    country: 'US',
    state: 'IN',
    state_name: 'Indiana',
    city: 'Indianapolis',
    geo_name: 'Indiana - Indianapolis',
  },
  {
    country: 'US',
    state: 'IA',
    state_name: 'Iowa',
    city: 'Iowa City',
    geo_name: 'Iowa - Iowa City',
  },
  {
    country: 'US',
    state: 'MS',
    state_name: 'Mississippi',
    city: 'Jackson',
    geo_name: 'Mississippi - Jackson',
  },
  {
    country: 'US',
    state: 'FL',
    state_name: 'Florida',
    city: 'Jacksonville',
    geo_name: 'Florida - Jacksonville',
  },
  {
    country: 'US',
    state: 'MO',
    state_name: 'Missouri',
    city: 'Jefferson City',
    geo_name: 'Missouri - Jefferson City',
  },
  {
    country: 'US',
    state: 'NJ',
    state_name: 'New Jersey',
    city: 'Jersey City',
    geo_name: 'New Jersey - Jersey City',
  },
  {
    country: 'US',
    state: 'IL',
    state_name: 'Illinois',
    city: 'Joliet',
    geo_name: 'Illinois - Joliet',
  },
  {
    country: 'US',
    state: 'AR',
    state_name: 'Arkansas',
    city: 'Jonesboro',
    geo_name: 'Arkansas - Jonesboro',
  },
  {
    country: 'US',
    state: 'AK',
    state_name: 'Alaska',
    city: 'Juneau',
    geo_name: 'Alaska - Juneau',
  },
  {
    country: 'US',
    state: 'HI',
    state_name: 'Hawaii',
    city: 'Kailua',
    geo_name: 'Hawaii - Kailua',
  },
  {
    country: 'US',
    state: 'HI',
    state_name: 'Hawaii',
    city: 'Kaneohe',
    geo_name: 'Hawaii - Kaneohe',
  },
  {
    country: 'US',
    state: 'KS',
    state_name: 'Kansas',
    city: 'Kansas City',
    geo_name: 'Kansas - Kansas City',
  },
  {
    country: 'US',
    state: 'MO',
    state_name: 'Missouri',
    city: 'Kansas City',
    geo_name: 'Missouri - Kansas City',
  },
  {
    country: 'US',
    state: 'HI',
    state_name: 'Hawaii',
    city: 'Kapolei',
    geo_name: 'Hawaii - Kapolei',
  },
  {
    country: 'US',
    state: 'NE',
    state_name: 'Nebraska',
    city: 'Kearney',
    geo_name: 'Nebraska - Kearney',
  },
  {
    country: 'US',
    state: 'TN',
    state_name: 'Tennessee',
    city: 'Knoxville',
    geo_name: 'Tennessee - Knoxville',
  },
  {
    country: 'US',
    state: 'LA',
    state_name: 'Louisiana',
    city: 'Lafayette',
    geo_name: 'Louisiana - Lafayette',
  },
  {
    country: 'US',
    state: 'LA',
    state_name: 'Louisiana',
    city: 'Lake Charles',
    geo_name: 'Louisiana - Lake Charles',
  },
  {
    country: 'US',
    state: 'CO',
    state_name: 'Colorado',
    city: 'Lakewood',
    geo_name: 'Colorado - Lakewood',
  },
  {
    country: 'US',
    state: 'MI',
    state_name: 'Michigan',
    city: 'Lansing',
    geo_name: 'Michigan - Lansing',
  },
  {
    country: 'US',
    state: 'NM',
    state_name: 'New Mexico',
    city: 'Las Cruces',
    geo_name: 'New Mexico - Las Cruces',
  },
  {
    country: 'US',
    state: 'NV',
    state_name: 'Nevada',
    city: 'Las Vegas',
    geo_name: 'Nevada - Las Vegas',
  },
  {
    country: 'US',
    state: 'ME',
    state_name: 'Maine',
    city: 'Lewiston',
    geo_name: 'Maine - Lewiston',
  },
  {
    country: 'US',
    state: 'KY',
    state_name: 'Kentucky',
    city: 'Lexington',
    geo_name: 'Kentucky - Lexington',
  },
  {
    country: 'US',
    state: 'NE',
    state_name: 'Nebraska',
    city: 'Lincoln',
    geo_name: 'Nebraska - Lincoln',
  },
  {
    country: 'US',
    state: 'AR',
    state_name: 'Arkansas',
    city: 'Little Rock',
    geo_name: 'Arkansas - Little Rock',
  },
  {
    country: 'US',
    state: 'CA',
    state_name: 'California',
    city: 'Los Angeles',
    geo_name: 'California - Los Angeles',
  },
  {
    country: 'US',
    state: 'KY',
    state_name: 'Kentucky',
    city: 'Louisville',
    geo_name: 'Kentucky - Louisville',
  },
  {
    country: 'US',
    state: 'MA',
    state_name: 'Massachusetts',
    city: 'Lowell',
    geo_name: 'Massachusetts - Lowell',
  },
  {
    country: 'US',
    state: 'GA',
    state_name: 'Georgia',
    city: 'Macon',
    geo_name: 'Georgia - Macon',
  },
  {
    country: 'US',
    state: 'WI',
    state_name: 'Wisconsin',
    city: 'Madison',
    geo_name: 'Wisconsin - Madison',
  },
  {
    country: 'US',
    state: 'NH',
    state_name: 'New Hampshire',
    city: 'Manchester',
    geo_name: 'New Hampshire - Manchester',
  },
  {
    country: 'US',
    state: 'TN',
    state_name: 'Tennessee',
    city: 'Memphis',
    geo_name: 'Tennessee - Memphis',
  },
  {
    country: 'US',
    state: 'ID',
    state_name: 'Idaho',
    city: 'Meridian',
    geo_name: 'Idaho - Meridian',
  },
  {
    country: 'US',
    state: 'AZ',
    state_name: 'Arizona',
    city: 'Mesa',
    geo_name: 'Arizona - Mesa',
  },
  {
    country: 'US',
    state: 'FL',
    state_name: 'Florida',
    city: 'Miami',
    geo_name: 'Florida - Miami',
  },
  {
    country: 'US',
    state: 'WI',
    state_name: 'Wisconsin',
    city: 'Milwaukee',
    geo_name: 'Wisconsin - Milwaukee',
  },
  {
    country: 'US',
    state: 'MN',
    state_name: 'Minnesota',
    city: 'Minneapolis',
    geo_name: 'Minnesota - Minneapolis',
  },
  {
    country: 'US',
    state: 'ND',
    state_name: 'North Dakota',
    city: 'Minot',
    geo_name: 'North Dakota - Minot',
  },
  {
    country: 'US',
    state: 'MT',
    state_name: 'Montana',
    city: 'Missoula',
    geo_name: 'Montana - Missoula',
  },
  {
    country: 'US',
    state: 'AL',
    state_name: 'Alabama',
    city: 'Mobile',
    geo_name: 'Alabama - Mobile',
  },
  {
    country: 'US',
    state: 'AL',
    state_name: 'Alabama',
    city: 'Montgomery',
    geo_name: 'Alabama - Montgomery',
  },
  {
    country: 'US',
    state: 'VT',
    state_name: 'Vermont',
    city: 'Montpelier',
    geo_name: 'Vermont - Montpelier',
  },
  {
    country: 'US',
    state: 'IL',
    state_name: 'Illinois',
    city: 'Naperville',
    geo_name: 'Illinois - Naperville',
  },
  {
    country: 'US',
    state: 'NH',
    state_name: 'New Hampshire',
    city: 'Nashua',
    geo_name: 'New Hampshire - Nashua',
  },
  {
    country: 'US',
    state: 'TN',
    state_name: 'Tennessee',
    city: 'Nashville',
    geo_name: 'Tennessee - Nashville',
  },
  {
    country: 'US',
    state: 'CT',
    state_name: 'Connecticut',
    city: 'New Haven',
    geo_name: 'Connecticut - New Haven',
  },
  {
    country: 'US',
    state: 'LA',
    state_name: 'Louisiana',
    city: 'New Orleans',
    geo_name: 'Louisiana - New Orleans',
  },
  {
    country: 'US',
    state: 'NY',
    state_name: 'New York',
    city: 'New York',
    geo_name: 'New York - New York',
  },
  {
    country: 'US',
    state: 'DE',
    state_name: 'Delaware',
    city: 'Newark',
    geo_name: 'Delaware - Newark',
  },
  {
    country: 'US',
    state: 'NJ',
    state_name: 'New Jersey',
    city: 'Newark',
    geo_name: 'New Jersey - Newark',
  },
  {
    country: 'US',
    state: 'VA',
    state_name: 'Virginia',
    city: 'Newport News',
    geo_name: 'Virginia - Newport News',
  },
  {
    country: 'US',
    state: 'VA',
    state_name: 'Virginia',
    city: 'Norfolk',
    geo_name: 'Virginia - Norfolk',
  },
  {
    country: 'US',
    state: 'OK',
    state_name: 'Oklahoma',
    city: 'Norman',
    geo_name: 'Oklahoma - Norman',
  },
  {
    country: 'US',
    state: 'CA',
    state_name: 'California',
    city: 'Oakland',
    geo_name: 'California - Oakland',
  },
  {
    country: 'US',
    state: 'OK',
    state_name: 'Oklahoma',
    city: 'Oklahoma City',
    geo_name: 'Oklahoma - Oklahoma City',
  },
  {
    country: 'US',
    state: 'WA',
    state_name: 'Washington',
    city: 'Olympia',
    geo_name: 'Washington - Olympia',
  },
  {
    country: 'US',
    state: 'NE',
    state_name: 'Nebraska',
    city: 'Omaha',
    geo_name: 'Nebraska - Omaha',
  },
  {
    country: 'US',
    state: 'UT',
    state_name: 'Utah',
    city: 'Orem',
    geo_name: 'Utah - Orem',
  },
  {
    country: 'US',
    state: 'FL',
    state_name: 'Florida',
    city: 'Orlando',
    geo_name: 'Florida - Orlando',
  },
  {
    country: 'US',
    state: 'KS',
    state_name: 'Kansas',
    city: 'Overland Park',
    geo_name: 'Kansas - Overland Park',
  },
  {
    country: 'US',
    state: 'KY',
    state_name: 'Kentucky',
    city: 'Owensboro',
    geo_name: 'Kentucky - Owensboro',
  },
  {
    country: 'US',
    state: 'NJ',
    state_name: 'New Jersey',
    city: 'Paterson',
    geo_name: 'New Jersey - Paterson',
  },
  {
    country: 'US',
    state: 'PA',
    state_name: 'Pennsylvania',
    city: 'Philadelphia',
    geo_name: 'Pennsylvania - Philadelphia',
  },
  {
    country: 'US',
    state: 'AZ',
    state_name: 'Arizona',
    city: 'Phoenix',
    geo_name: 'Arizona - Phoenix',
  },
  {
    country: 'US',
    state: 'PA',
    state_name: 'Pennsylvania',
    city: 'Pittsburgh',
    geo_name: 'Pennsylvania - Pittsburgh',
  },
  {
    country: 'US',
    state: 'ME',
    state_name: 'Maine',
    city: 'Portland',
    geo_name: 'Maine - Portland',
  },
  {
    country: 'US',
    state: 'OR',
    state_name: 'Oregon',
    city: 'Portland',
    geo_name: 'Oregon - Portland',
  },
  {
    country: 'US',
    state: 'RI',
    state_name: 'Rhode Island',
    city: 'Providence',
    geo_name: 'Rhode Island - Providence',
  },
  {
    country: 'US',
    state: 'UT',
    state_name: 'Utah',
    city: 'Provo',
    geo_name: 'Utah - Provo',
  },
  {
    country: 'US',
    state: 'NC',
    state_name: 'North Carolina',
    city: 'Raleigh',
    geo_name: 'North Carolina - Raleigh',
  },
  {
    country: 'US',
    state: 'SD',
    state_name: 'South Dakota',
    city: 'Rapid City',
    geo_name: 'South Dakota - Rapid City',
  },
  {
    country: 'US',
    state: 'PA',
    state_name: 'Pennsylvania',
    city: 'Reading',
    geo_name: 'Pennsylvania - Reading',
  },
  {
    country: 'US',
    state: 'NV',
    state_name: 'Nevada',
    city: 'Reno',
    geo_name: 'Nevada - Reno',
  },
  {
    country: 'US',
    state: 'VA',
    state_name: 'Virginia',
    city: 'Richmond',
    geo_name: 'Virginia - Richmond',
  },
  {
    country: 'US',
    state: 'NM',
    state_name: 'New Mexico',
    city: 'Rio Rancho',
    geo_name: 'New Mexico - Rio Rancho',
  },
  {
    country: 'US',
    state: 'MN',
    state_name: 'Minnesota',
    city: 'Rochester',
    geo_name: 'Minnesota - Rochester',
  },
  {
    country: 'US',
    state: 'NH',
    state_name: 'New Hampshire',
    city: 'Rochester',
    geo_name: 'New Hampshire - Rochester',
  },
  {
    country: 'US',
    state: 'NY',
    state_name: 'New York',
    city: 'Rochester',
    geo_name: 'New York - Rochester',
  },
  {
    country: 'US',
    state: 'IL',
    state_name: 'Illinois',
    city: 'Rockford',
    geo_name: 'Illinois - Rockford',
  },
  {
    country: 'US',
    state: 'MD',
    state_name: 'Maryland',
    city: 'Rockville',
    geo_name: 'Maryland - Rockville',
  },
  {
    country: 'US',
    state: 'NM',
    state_name: 'New Mexico',
    city: 'Roswell',
    geo_name: 'New Mexico - Roswell',
  },
  {
    country: 'US',
    state: 'VT',
    state_name: 'Vermont',
    city: 'Rutland',
    geo_name: 'Vermont - Rutland',
  },
  {
    country: 'US',
    state: 'CA',
    state_name: 'California',
    city: 'Sacramento',
    geo_name: 'California - Sacramento',
  },
  {
    country: 'US',
    state: 'MO',
    state_name: 'Missouri',
    city: 'Saint Louis',
    geo_name: 'Missouri - Saint Louis',
  },
  {
    country: 'US',
    state: 'MN',
    state_name: 'Minnesota',
    city: 'Saint Paul',
    geo_name: 'Minnesota - Saint Paul',
  },
  {
    country: 'US',
    state: 'FL',
    state_name: 'Florida',
    city: 'Saint Petersburg',
    geo_name: 'Florida - Saint Petersburg',
  },
  {
    country: 'US',
    state: 'OR',
    state_name: 'Oregon',
    city: 'Salem',
    geo_name: 'Oregon - Salem',
  },
  {
    country: 'US',
    state: 'UT',
    state_name: 'Utah',
    city: 'Salt Lake City',
    geo_name: 'Utah - Salt Lake City',
  },
  {
    country: 'US',
    state: 'TX',
    state_name: 'Texas',
    city: 'San Antonio',
    geo_name: 'Texas - San Antonio',
  },
  {
    country: 'US',
    state: 'CA',
    state_name: 'California',
    city: 'San Diego',
    geo_name: 'California - San Diego',
  },
  {
    country: 'US',
    state: 'CA',
    state_name: 'California',
    city: 'San Francisco',
    geo_name: 'California - San Francisco',
  },
  {
    country: 'US',
    state: 'CA',
    state_name: 'California',
    city: 'San Jose',
    geo_name: 'California - San Jose',
  },
  {
    country: 'US',
    state: 'NM',
    state_name: 'New Mexico',
    city: 'Santa Fe',
    geo_name: 'New Mexico - Santa Fe',
  },
  {
    country: 'US',
    state: 'GA',
    state_name: 'Georgia',
    city: 'Savannah',
    geo_name: 'Georgia - Savannah',
  },
  {
    country: 'US',
    state: 'AZ',
    state_name: 'Arizona',
    city: 'Scottsdale',
    geo_name: 'Arizona - Scottsdale',
  },
  {
    country: 'US',
    state: 'WA',
    state_name: 'Washington',
    city: 'Seattle',
    geo_name: 'Washington - Seattle',
  },
  {
    country: 'US',
    state: 'LA',
    state_name: 'Louisiana',
    city: 'Shreveport',
    geo_name: 'Louisiana - Shreveport',
  },
  {
    country: 'US',
    state: 'LA',
    state_name: 'Louisiana',
    city: 'Shreveport',
    geo_name: 'Louisiana - Shreveport',
  },
  {
    country: 'US',
    state: 'IA',
    state_name: 'Iowa',
    city: 'Sioux City',
    geo_name: 'Iowa - Sioux City',
  },
  {
    country: 'US',
    state: 'SD',
    state_name: 'South Dakota',
    city: 'Sioux Falls',
    geo_name: 'South Dakota - Sioux Falls',
  },
  {
    country: 'US',
    state: 'IN',
    state_name: 'Indiana',
    city: 'South Bend',
    geo_name: 'Indiana - South Bend',
  },
  {
    country: 'US',
    state: 'ME',
    state_name: 'Maine',
    city: 'South Portland',
    geo_name: 'Maine - South Portland',
  },
  {
    country: 'US',
    state: 'MS',
    state_name: 'Mississippi',
    city: 'Southaven',
    geo_name: 'Mississippi - Southaven',
  },
  {
    country: 'US',
    state: 'NV',
    state_name: 'Nevada',
    city: 'Sparks',
    geo_name: 'Nevada - Sparks',
  },
  {
    country: 'US',
    state: 'WA',
    state_name: 'Washington',
    city: 'Spokane',
    geo_name: 'Washington - Spokane',
  },
  {
    country: 'US',
    state: 'AR',
    state_name: 'Arkansas',
    city: 'Springdale',
    geo_name: 'Arkansas - Springdale',
  },
  {
    country: 'US',
    state: 'IL',
    state_name: 'Illinois',
    city: 'Springfield',
    geo_name: 'Illinois - Springfield',
  },
  {
    country: 'US',
    state: 'MA',
    state_name: 'Massachusetts',
    city: 'Springfield',
    geo_name: 'Massachusetts - Springfield',
  },
  {
    country: 'US',
    state: 'MO',
    state_name: 'Missouri',
    city: 'Springfield',
    geo_name: 'Missouri - Springfield',
  },
  {
    country: 'US',
    state: 'CT',
    state_name: 'Connecticut',
    city: 'Stamford',
    geo_name: 'Connecticut - Stamford',
  },
  {
    country: 'US',
    state: 'MI',
    state_name: 'Michigan',
    city: 'Sterling Heights',
    geo_name: 'Michigan - Sterling Heights',
  },
  {
    country: 'US',
    state: 'NY',
    state_name: 'New York',
    city: 'Syracuse',
    geo_name: 'New York - Syracuse',
  },
  {
    country: 'US',
    state: 'WA',
    state_name: 'Washington',
    city: 'Tacoma',
    geo_name: 'Washington - Tacoma',
  },
  {
    country: 'US',
    state: 'FL',
    state_name: 'Florida',
    city: 'Tallahassee',
    geo_name: 'Florida - Tallahassee',
  },
  {
    country: 'US',
    state: 'FL',
    state_name: 'Florida',
    city: 'Tampa',
    geo_name: 'Florida - Tampa',
  },
  {
    country: 'US',
    state: 'OH',
    state_name: 'Ohio',
    city: 'Toledo',
    geo_name: 'Ohio - Toledo',
  },
  {
    country: 'US',
    state: 'KS',
    state_name: 'Kansas',
    city: 'Topeka',
    geo_name: 'Kansas - Topeka',
  },
  {
    country: 'US',
    state: 'NJ',
    state_name: 'New Jersey',
    city: 'Trenton',
    geo_name: 'New Jersey - Trenton',
  },
  {
    country: 'US',
    state: 'AZ',
    state_name: 'Arizona',
    city: 'Tucson',
    geo_name: 'Arizona - Tucson',
  },
  {
    country: 'US',
    state: 'OK',
    state_name: 'Oklahoma',
    city: 'Tulsa',
    geo_name: 'Oklahoma - Tulsa',
  },
  {
    country: 'US',
    state: 'AL',
    state_name: 'Alabama',
    city: 'Tuscaloosa',
    geo_name: 'Alabama - Tuscaloosa',
  },
  {
    country: 'US',
    state: 'WA',
    state_name: 'Washington',
    city: 'Vancouver',
    geo_name: 'Washington - Vancouver',
  },
  {
    country: 'US',
    state: 'VA',
    state_name: 'Virginia',
    city: 'Virginia Beach',
    geo_name: 'Virginia - Virginia Beach',
  },
  {
    country: 'US',
    state: 'MI',
    state_name: 'Michigan',
    city: 'Warren',
    geo_name: 'Michigan - Warren',
  },
  {
    country: 'US',
    state: 'RI',
    state_name: 'Rhode Island',
    city: 'Warwick',
    geo_name: 'Rhode Island - Warwick',
  },
  {
    country: 'US',
    state: 'CT',
    state_name: 'Connecticut',
    city: 'Waterbury',
    geo_name: 'Connecticut - Waterbury',
  },
  {
    country: 'US',
    state: 'WV',
    state_name: 'West Virginia',
    city: 'Wheeling',
    geo_name: 'West Virginia - Wheeling',
  },
  {
    country: 'US',
    state: 'KS',
    state_name: 'Kansas',
    city: 'Wichita',
    geo_name: 'Kansas - Wichita',
  },
  {
    country: 'US',
    state: 'DE',
    state_name: 'Delaware',
    city: 'Wilmington',
    geo_name: 'Delaware - Wilmington',
  },
  {
    country: 'US',
    state: 'NC',
    state_name: 'North Carolina',
    city: 'Winston Salem',
    geo_name: 'North Carolina - Winston Salem',
  },
  {
    country: 'US',
    state: 'MA',
    state_name: 'Massachusetts',
    city: 'Worcester',
    geo_name: 'Massachusetts - Worcester',
  },
];
