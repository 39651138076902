import React from 'react';
import { Link, LinkProps } from '@mui/material';

const InvitesLink = (props: LinkProps) => {
  return (
    <Link
      sx={{
        fontSize: '12px',
        fontStyle: 'normal',
        fontFamily: 'National2Regular',
        fontWeight: '400',
        textDecorationColor: '#576C82 !important',
        color: '#576C82 !important',
        cursor: 'pointer',
      }}
      {...props}
    />
  );
};

export default InvitesLink;
