import React, { useCallback, useState } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { styled } from '@mui/system';
import { ScreeningPrice, ScreeningType } from '../../PackageUtils/packageTypes';
import { useAddScreeningsContext } from './context';
import {
  getEmploymentVerificationPrice,
  getPersonalReferenceVerificationPrice,
  getProfessionalReferenceVerificationPrice,
} from '../../PackageUtils/PackageUtils';

const StyledFormControl = styled(FormControl)({
  '&.MuiFormControl-root': {
    width: '130px',
    alignSelf: 'center',
  },
});

const getPrices = (
  screeningType: ScreeningType,
  screeningPrices: ScreeningPrice[],
) => {
  if (screeningType === 'employment_verification') {
    return [
      getEmploymentVerificationPrice(screeningPrices, '1'),
      getEmploymentVerificationPrice(screeningPrices, '2'),
      getEmploymentVerificationPrice(screeningPrices, '3'),
    ];
  } else if (screeningType === 'professional_reference_verification') {
    return [
      getProfessionalReferenceVerificationPrice(screeningPrices, '1'),
      getProfessionalReferenceVerificationPrice(screeningPrices, '2'),
      getProfessionalReferenceVerificationPrice(screeningPrices, '3'),
    ];
  } else if (screeningType === 'personal_reference_verification') {
    return [
      getPersonalReferenceVerificationPrice(screeningPrices, '1'),
      getPersonalReferenceVerificationPrice(screeningPrices, '2'),
      getPersonalReferenceVerificationPrice(screeningPrices, '3'),
    ];
  } else {
    return ['Loading price...', 'Loading price...', 'Loading price...'];
  }
};

type Props = {
  screeningType: ScreeningType;
  value?: string;
  onChange: (count: string) => void;
};

const QuantityDropdown = ({ screeningType, value = '', onChange }: Props) => {
  const [open, setOpen] = useState(false);
  const { screeningPrices } = useAddScreeningsContext();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      setOpen(state => !state);
    },
    [setOpen],
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  const prices = getPrices(screeningType, screeningPrices);

  return (
    <StyledFormControl size='small'>
      <Select
        value={value}
        open={open}
        onClick={handleClick}
        onChange={handleChange}
        data-testid={`${screeningType}-select-field`}
      >
        <MenuItem
          data-testid={`${screeningType}-value-1`}
          value='1'
        >{`1 (${prices[0]})`}</MenuItem>
        <MenuItem
          data-testid={`${screeningType}-value-2`}
          value='2'
        >{`2 (${prices[1]})`}</MenuItem>
        <MenuItem
          data-testid={`${screeningType}-value-3`}
          value='3'
        >{`3 (${prices[2]})`}</MenuItem>
      </Select>
    </StyledFormControl>
  );
};

export default QuantityDropdown;
