import { action, makeObservable, observable } from 'mobx';
import * as Sentry from '@sentry/browser';
import { datadogRum } from '@datadog/browser-rum';

import ValidationStore from '../ValidationStore';
import PasswordStrength from '../../services/PasswordStrength';
import AutoSave from './helpers/AutoSave';
import {
  CHECKR_DIRECT_SIGNUP_VALIDATION_RULES,
  SIGNUP_VALIDATION_RULES,
  SIGNUP_VALIDATION_MESSAGES,
  SIGNUP_V4_VALIDATION_RULES,
  SIGNUP_V4_VALIDATION_MESSAGES,
  CHECKR_DIRECT_VERIFY_VALIDATION_RULES,
  VERIFY_VALIDATION_RULES,
  VERIFY_VALIDATION_MESSAGES,
} from './helpers/ValidationHelper';
import complianceGeos from '../complianceGeos';
import { ANALYTICS_EVENTS } from '../../services/analytics';
import { createPromotionCode } from './helpers/PromotionCode';
import amplitudeExperiment from '../../services/amplitudeExperiment';

export default class SignupV3Store extends ValidationStore {
  @observable fullName;
  @observable firstName;
  @observable lastName;
  @observable email;
  @observable password;
  @observable confirmedPassword;
  @observable passwordStrength;
  @observable company;
  @observable phone;
  @observable nameDba;
  @observable street;
  @observable street2;
  @observable zipcode;
  @observable city;
  @observable stateName;
  @observable website;
  @observable taxId;
  @observable industry;
  @observable complianceCity;
  @observable complianceState;
  @observable numberOfComplianceLocations;
  @observable purpose;
  @observable taxExempt;
  @observable numEmployees;
  @observable yearlyBgcVolume;

  static messages = {
    ...SIGNUP_VALIDATION_MESSAGES,
    ...VERIFY_VALIDATION_MESSAGES,
  };

  static messagesV4 = {
    ...SIGNUP_V4_VALIDATION_MESSAGES,
  };

  static assignableFields = [
    'city',
    'company',
    'complianceCity',
    'complianceState',
    'email',
    'fullName',
    'firstName',
    'industry',
    'lastName',
    'nameDba',
    'numEmployees',
    'phone',
    'stateName',
    'street',
    'street2',
    'website',
    'zipcode',
    'yearlyBgcVolume',
  ];

  get rules() {
    if (this._state.isCheckrDirectA || this._state.isGoodhireWebsite) {
      return {
        ...CHECKR_DIRECT_SIGNUP_VALIDATION_RULES,
        ...CHECKR_DIRECT_VERIFY_VALIDATION_RULES,
      };
    }
    return { ...SIGNUP_VALIDATION_RULES, ...VERIFY_VALIDATION_RULES };
  }

  get rulesV4() {
    return { ...SIGNUP_V4_VALIDATION_RULES };
  }

  applyPropertyChangeSideEffects(name, value) {
    if (name === 'password') {
      this.passwordStrength.update(value);
    } else if (name === 'numberOfComplianceLocations') {
      this.complianceCity = null;
      this.complianceState = null;
    } else if (name === 'complianceState') {
      this.complianceCity = null;
    }
  }

  get availableComplianceCitiesForSelectedState() {
    if (
      this.complianceState &&
      this.availableComplianceStates[this.complianceState].cities.length > 0
    ) {
      return this.availableComplianceStates[this.complianceState].cities.concat(
        'Other',
      );
    } else {
      return [];
    }
  }

  get availableComplianceStates() {
    return complianceGeos.reduce((data, geo) => {
      data[geo.state] = data[geo.state] || {
        state: geo.state,
        state_name: geo.state_name,
        cities: [],
      };

      if (geo.city) {
        data[geo.state].cities.push(geo.city);
      }

      return data;
    }, {});
  }

  constructor(state) {
    super();
    this._state = state;
    this.passwordStrength = new PasswordStrength(this.password);
    makeObservable(this);

    new AutoSave( // eslint-disable-line no-new
      this,
      'signupV3',
      this.constructor.assignableFields.concat(
        'numberofComplianceLocations',
        'purpose',
      ),
    );
  }

  @action handleChange = (name, value) => {
    this[name] = value;
    this.applyPropertyChangeSideEffects(name, value);

    if (this.error) {
      this.debounceValidation(); // Only if was submitted should validate while typing
    }

    this.debounceValidity();
  };

  @action handleChangeV4 = (name, value) => {
    this[name] = value;

    if (this.error) {
      this.debounceValidationV4(); // Only if was submitted should validate while typing
    }

    this.debounceValidityV4();
  };

  trackSignupPageView() {
    this._state.trackAnalyticsEvent(
      'Self-Serve Customer Signup Signup Page Viewed',
      {
        'Page Type': this._state.isGoodhireWebsite ? 'goodhire' : 'checkr',
        'Self Serve Signup Version': 'Simplify Order Page',
      },
    );
  }

  trackSignupComplete() {
    const params = new URLSearchParams(window.location.search);
    const flow = params.get('flow');
    this._state.trackAnalyticsEvent(
      'Self-Serve Customer Signup Signup Page Completed',
      {
        'Page Type': this._state.isGoodhireWebsite ? 'goodhire' : 'checkr',
        'ab-09-test': 'ab-08-fox-v2-control',
        'Self Serve Signup Version': 'Simplify Order Page',
        City: this.complianceCity,
        State: this.complianceState,
      },
    );
  }

  trackSignupServerErrors() {
    this._state.trackAnalyticsEvent('Self-Serve Customer Signup Error Viewed', {
      'Error Name': this.error?._api?.error,
      'Page Type': this._state.isGoodhireWebsite ? 'goodhire' : 'checkr',
      'Page Name': 'signup',
    });
  }

  trackSignupClientErrors() {
    this._state.trackAnalyticsEvent('Self-Serve Customer Signup Error Viewed', {
      'Error Name': Object.values(this.error || {}).join(' '),
      'Page Type': this._state.isGoodhireWebsite ? 'goodhire' : 'checkr',
      'Page Name': 'signup',
    });
  }

  trackExistingEmailError() {
    this._state.trackAnalyticsEvent(ANALYTICS_EVENTS.ERROR_DISPLAYED_EMAIL, {
      Email: this.email,
    });
  }

  trackBlockedEmailError() {
    const emailDomain = this.email?.split('@')?.[1];
    let eventName;

    this._state.isSignup
      ? (eventName = 'Self-Serve Customer Signup Blocked Email Submitted')
      : (eventName = 'Partner Customer Onboarding Blocked Email Submitted');

    this._state.trackAnalyticsEvent(eventName, { 'Email Domain': emailDomain });
  }

  trackSignupNextClicked() {
    this._state.trackAnalyticsEvent(
      'Self-Serve Customer Signup Signup Page Next Page Clicked',
      {
        'Self Serve Signup Version': 'Simplify Order Page',
      },
    );
  }

  getNextStep() {
    let step;

    if (this._state.isBillable()) {
      if (this._state.isCheckrDirectA || this._state.isGoodhireWebsite) {
        step = 'payment';
      } else if (this._state.showPackageCustomization()) {
        // If user is part of the 1B variant group, show customize package step
        step = 'customize';
      } else {
        step = 'v3/payment';
      }
    } else {
      step = 'submission';
    }
    return step;
  }

  async submit(recaptchCode) {
    this.loading = true;
    this.error = null;
    let response;
    try {
      this.validate();
      response = await this._state.createAccount(recaptchCode, true);
      this._state.auth.setToken(response.user.token);
      await this._state.utms.track(response.id);
      Sentry.addBreadcrumb({ message: 'user signed up' });

      datadogRum.setUser({
        id: response?.id,
        name: response?.user?.full_name,
        email: response?.user?.email,
        companyName: response?.company?.name,
      });

      if (this._state.isSignup) {
        this.trackSignupComplete();
      }
    } catch (errors) {
      if (errors.error && errors.error.match(/email.*taken/i)) {
        if (!this._state.isSignup) {
          this.trackExistingEmailError();
        }
        errors.error = 'email.alreadyExists';
        this.error = errors;
        window.scrollTo(0, 0);
      } else {
        console.error(errors);
        this.error = errors;
      }
      if (errors.error && errors.error.match(/contains invalid characters./i)) {
        errors.error = 'account.inValidCharacter';
        this.error = errors;
      }
      if (this._state.isSignup) {
        this.trackSignupServerErrors();
      }

      if (errors && errors.error === 'Account Email is invalid') {
        this.trackBlockedEmailError();
      }
    }

    if (response) {
      const step = this.getNextStep();
      this._state.toStep(step);
    }

    this.loading = false;
  }

  storeSpecificValidationData() {
    return {
      availableComplianceCitiesForSelectedState:
        this.availableComplianceCitiesForSelectedState,
    };
  }
}
