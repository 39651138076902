import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import Logos from '../Logos';
import { SignupWrapper } from './SignupV4';
import Footer from '../Footer';
import Auth0 from '../../services/Auth0';

@inject('store')
export default class Auth0Callback extends Component {
  constructor(props) {
    super();
    this.store = props.store;
    this.state = {
      errorMessage: null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const token = this.store.auth.getToken();
    const currentUser = this.store.auth.user;
    if (token && currentUser) return;

    const { hash } = window.location;
    Auth0.parseHash({ hash }, (err, authResult) => {
      if (err || !authResult) {
        let errorMessage =
          'Could not find authentication credentials. ' +
          'Please try contacting your partner for help or signin again.';
        if (err) errorMessage += `: ${err.message}`;

        this.setState({ errorMessage });
        return;
      }

      this.store.auth.setToken(authResult.accessToken);
      this.store.signin.signin();
    });
  }

  render() {
    const { errorMessage } = this.state;
    const version4 = this.store.signUpFlowProperties.version === 'v4';

    return (
      <>
        {version4 && (
          <SignupWrapper>
            <CallbackContent errorMessage={errorMessage} />
          </SignupWrapper>
        )}
        {!version4 && (
          <div>
            <Logos />
            <CallbackContent errorMessage={errorMessage} />
            <Footer />
          </div>
        )}
      </>
    );
  }
}

const CallbackContent = ({ errorMessage }) => {
  return (
    <div className='callback-main-content'>
      {!!errorMessage && (
        <div>
          <p className='error-msg'>{errorMessage}</p>
        </div>
      )}
      {!errorMessage && (
        <div>
          <i className='fa fa-spinner fa-spin fa-fw fa-3x' />
          <p>Signing you into Checkr...</p>
        </div>
      )}
    </div>
  );
};

Auth0Callback.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
