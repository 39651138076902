import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';

@inject('store')
@observer
export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
  }

  render() {
    // If Sign up flow option is OFF, redirect to Sign in
    if (!this.store.application.sign_up_flow) {
      return (
        <div>
          <Redirect to={this.store.getStep('signin')} />
          Redirecting to <Link to={this.store.getStep('signin')}>Sign in</Link>
        </div>
      );
    }

    if (this.store.isSignup) {
      return <Redirect to={this.store.getStep('signup')} />;
    }
    // '/signup' is the path we use in the documentation and what our partners have
    // implemented as the entry way to the oauth flow, so this component and path must be maintained
    // as a redirect to '/welcome' to avoid having all of our partners change URLs
    return <Redirect to={this.store.getStep('welcome')} />;
  }
}

Signup.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
