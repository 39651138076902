import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const CountyCriminalSearch = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo =
    'Most complete look at a candidate’s criminal history; essential to a comprehensive background check';

  const advantages = [
    'Most felony and misdemeanor cases are filed in county courts',
    'May report records older than 7 years where allowed by law',
    'Can better ensure full coverage and case information',
  ];

  const considerations = [
    'Cost prohibitive to search all counties',
    'Longer turnaround for courts with non-digitized records',
    'May involve additional fees',
  ];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>
          County Criminal Records Search - 7-year address history
        </ScreeningTitle>
        <ScreeningPrice priceByType='county_criminal_search' />
        <AddScreeningsAction
          screeningType='county_criminal_search'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default CountyCriminalSearch;
