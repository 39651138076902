import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
import MaskedInput from 'react-text-mask';
import CheckrDirectProgressBar from './CheckrDirectProgressBar';
import {
  Box,
  Button,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material/';
import { Visibility, VisibilityOff, Circle } from '@mui/icons-material';

function parseSearchParams(locationSearch) {
  try {
    return queryString.parse(locationSearch);
  } catch (error) {
    Sentry.captureException(error, {
      tags: { 'parse-search-params': 'checkr-direct-signup-a' },
    });
    return {};
  }
}

@inject('store')
@observer
export default class CheckrDirectSignupA extends Component {
  constructor(props) {
    super(props);
    const { store } = this.props;
    this.store = store;
    this.form = this.store.signupV3;
    this.form.assignParams();
    this.searchParams = parseSearchParams(this.props.location.search);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div>
          <CheckrDirectProgressBar activeStep={0} />
        </div>
        <div className='row'>
          <div className='col-md-12 px-5 main'>
            <CardHeader
              title={
                <Typography
                  textAlign='center'
                  fontWeight='600'
                  fontSize='42px'
                  color='#001730'
                  fontFamily='ArgentCFRegular'
                >
                  Create a login for your account
                </Typography>
              }
            />

            <div className='col-md-6 mx-auto'>
              <Box className='cd-signup-form' textAlign='center'>
                <TextField
                  style={{
                    width: '100%',
                    margin: '8px',
                    backgroundColor: 'white',
                  }}
                  defaultValue={this.searchParams.fullName}
                  type='text'
                  label='Name'
                  variant='outlined'
                  error={Boolean(this.form.error?.fullName)}
                  helperText={this.form.error?.fullName}
                  inputProps={{ maxLength: 35 }}
                  onChange={e => {
                    this.form.handleChange('fullName', e.target.value);
                  }}
                />
                <TextField
                  style={{
                    width: '100%',
                    margin: '8px',
                    backgroundColor: 'white',
                  }}
                  defaultValue={this.searchParams?.email || ''}
                  type='text'
                  label='Business email'
                  variant='outlined'
                  error={Boolean(this.form.error?.email)}
                  helperText={this.form.error?.email}
                  inputProps={{ maxLength: 35 }}
                  onChange={e => {
                    this.form.handleChange('email', e.target.value);
                  }}
                />
                <MaskedInput
                  mask={[
                    '(',
                    /\d/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  onChange={e => {
                    this.form.handleChange('phone', e.target.value);
                  }}
                  render={(innerRef, props) => (
                    <TextField
                      inputRef={innerRef}
                      style={{
                        width: '100%',
                        margin: '8px',
                        backgroundColor: 'white',
                      }}
                      defaultValue={this.searchParams?.phone || ''}
                      type='text'
                      label='Phone number'
                      variant='outlined'
                      inputProps={{ maxLength: 35 }}
                      {...props}
                    />
                  )}
                />
                <TextField
                  style={{ margin: '8px' }}
                  type={this.state.passwordVisible ? 'text' : 'password'}
                  label='Create password'
                  variant='outlined'
                  error={Boolean(this.form.error?.password)}
                  InputProps={{
                    style: { backgroundColor: 'white' },
                    maxLength: 35,
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              passwordVisible: !this.state.passwordVisible,
                            })
                          }
                        >
                          {this.state.passwordVisible ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: 'transparent',
                      paddingTop: '8px',
                      marginBottom: '16px',
                    },
                  }}
                  helperText={
                    this.form.error?.password && (
                      <>
                        <Circle
                          style={{
                            fontSize: 'small',
                            width: '0.4em',
                            marginRight: '6px',
                          }}
                        />
                        Minimum 8 characters
                        <br />
                        <Circle
                          style={{
                            fontSize: 'small',
                            width: '0.4em',
                            marginRight: '6px',
                          }}
                        />
                        At least one special character (!@#$%^&*)
                        <br />
                        <Circle
                          style={{
                            fontSize: 'small',
                            width: '0.4em',
                            marginRight: '6px',
                          }}
                        />
                        At least one uppercase letter
                        <br />
                        <Circle
                          style={{
                            fontSize: 'small',
                            width: '0.4em',
                            marginRight: '6px',
                          }}
                        />
                        At least one lowercase letter
                        <br />
                        <Circle
                          style={{
                            fontSize: 'small',
                            width: '0.4em',
                            marginRight: '6px',
                          }}
                        />
                        At least one number (0-9)
                      </>
                    )
                  }
                  onChange={e => {
                    this.form.handleChange('password', e.target.value);
                  }}
                  fullWidth
                />
                <Button
                  variant='contained'
                  disabled={Boolean(
                    this.form.error &&
                      (this.form.error.fullName ||
                        this.form.error.email ||
                        this.form.error.password),
                  )}
                  onClick={() => {
                    const errors = this.form.validateOnEvent() || {};
                    if (!errors.fullName && !errors.email && !errors.password) {
                      this.form.error = undefined;
                      this.props.history.push(this.store.getStep('verify'));
                    }
                  }}
                  sx={{
                    backgroundColor: '#002853',
                    textTransform: 'none',
                    padding: '8px 60px',
                    borderRadius: '0px',
                    fontFamily: 'National2Regular',
                  }}
                >
                  Next
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
