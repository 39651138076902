/* eslint-disable camelcase */
import React from 'react';

import CriminalRecords from './CriminalRecords';
import VerificationScreenings from './VerificationScreenings';

const InternationalAddScreeningsList: React.FC = () => {
  return (
    <>
      <CriminalRecords />
      <VerificationScreenings />
    </>
  );
};

export default InternationalAddScreeningsList;
