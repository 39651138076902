import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material/';

import infoIcon from '../../../../../images/info-icon.svg';

type PackageCardDetailsTooltipProps = {
  testId: string;
  details: string[];
};

const PackageCardDetailsTooltip = ({
  testId,
  details,
}: PackageCardDetailsTooltipProps) => {
  return (
    <Tooltip
      enterTouchDelay={50}
      leaveTouchDelay={5000}
      sx={{
        cursor: 'auto !important',
      }}
      data-testid={`${testId}-tooltip`}
      title={
        <Box
          sx={{
            fontSize: { xs: '12px', sm: '12px', md: '14px' },
            color: '#5e5e64',
          }}
          onClick={e => e.stopPropagation()}
        >
          {details.map((d, i) => (
            <div key={`${testId}-tooltip-${i}`}>
              {i !== 0 && <br />}
              {d}
            </div>
          ))}
        </Box>
      }
      PopperProps={{
        popperOptions: {
          placement: 'bottom',
          modifiers: [
            {
              name: 'flip',
              enabled: false,
            },
          ],
        },
      }}
      onClick={e => e.stopPropagation()}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '15px 20px',
            boxShadow: '0px 0px 16px 0px #16161D2E',
            bgcolor: '#FFFFFF',
            '& .MuiTooltip-arrow': {
              color: '#FFFFFF',
            },
            color: '#334559',
            fontFamily: 'National2Regular',
          },
        },
      }}
      arrow
    >
      <IconButton sx={{ padding: '2px' }}>
        <img data-testid={`infoIcon-${infoIcon}`} src={infoIcon} />
      </IconButton>
    </Tooltip>
  );
};

export default PackageCardDetailsTooltip;
