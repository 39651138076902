import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  LEARN_MORE_TILE_CONTENT,
  CONTACT_FORM_URL,
} from '../../helpers/Constants';
import checkr from '../../../images/checkr.svg';
import click from '../../../images/click.png';
import data from '../../../images/data.png';
import rules2 from '../../../images/rules2.png';
import rules from '../../../images/rules.png';
import scale from '../../../images/scale.png';
import people from '../../../images/people.png';
import PricingV3 from './PricingV3';
import { ANALYTICS_EVENTS } from '../../../services/analytics';

export default class LearnModal extends Component {
  render() {
    const {
      isOpen,
      toggleModal,
      application,
      togglePackageGuide,
      packageGuideOpen,
      trackAnalyticsEvent,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        autoFocus={false}
        size='lg'
        style={{ maxWidth: '950px' }}
      >
        <ModalHeader toggle={toggleModal} className='modal-header'>
          <div className='h5 m-0'>
            {packageGuideOpen
              ? packageSectionTitle(application)
              : 'Learn more about Checkr'}
          </div>
        </ModalHeader>
        {packageGuideOpen ? (
          <PackageBody
            togglePackageGuide={togglePackageGuide}
            application={application}
          />
        ) : (
          <LearnBody
            togglePackageGuide={togglePackageGuide}
            application={application}
            trackAnalyticsEvent={trackAnalyticsEvent}
          />
        )}
      </Modal>
    );
  }
}

LearnModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  packageGuideOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  togglePackageGuide: PropTypes.func.isRequired,
  trackAnalyticsEvent: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
};

const LearnBody = ({
  togglePackageGuide,
  application,
  trackAnalyticsEvent,
}) => (
  <ModalBody>
    <div className='modal-body-text container'>
      <div className='row mb-3'>
        <p>
          Use these resources to learn more about Checkr and how to use our
          partner integrations. Speak with us directly by reaching out through
          our{' '}
          <a href={CONTACT_FORM_URL} target='_blank' rel='noopener noreferrer'>
            contact form
          </a>
          .
        </p>
      </div>
      <div className='row mb-3'>
        <LearnTile
          tileKey='aboutCheckr'
          img={checkr}
          trackAnalyticsEvent={trackAnalyticsEvent}
        />
        <LearnTile
          tileKey='packages'
          img={data}
          onClick={togglePackageGuide}
          title={packageSectionTitle(application)}
          trackAnalyticsEvent={trackAnalyticsEvent}
        />
        <LearnTile
          tileKey='integrations'
          img={rules2}
          trackAnalyticsEvent={trackAnalyticsEvent}
        />
        <LearnTile
          tileKey='gettingStarted'
          img={click}
          trackAnalyticsEvent={trackAnalyticsEvent}
        />
        <LearnTile
          tileKey='helpCenter'
          img={rules}
          trackAnalyticsEvent={trackAnalyticsEvent}
        />
        <LearnTile
          tileKey='fairChance'
          img={scale}
          trackAnalyticsEvent={trackAnalyticsEvent}
        />
      </div>
      <LearnFooter trackAnalyticsEvent={trackAnalyticsEvent} />
    </div>
  </ModalBody>
);

LearnBody.propTypes = {
  togglePackageGuide: PropTypes.func.isRequired,
  trackAnalyticsEvent: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
};

export const PackageBody = ({ togglePackageGuide, application }) => {
  let description = (
    <p className='mb-4'>
      {application.name} has already negotiated great pricing on the most
      popular background check packages and they will be automatically added to
      your account.
    </p>
  );

  if (application.partner_billing) {
    description = (
      <p className='mb-4'>
        Checkr will automatically add our most popular background check packages
        to your account. All pricing is part of your agreement with{' '}
        {application.name}.
        <br />
        <br />
        For more information or questions on package pricing, please contact{' '}
        {application.name}.
      </p>
    );
  }

  return (
    <ModalBody>
      <div className='modal-body-text'>
        <div className='packages-modal-content mb-5'>
          <div className='col-md-4 mr-3 position-relative'>
            <div>
              {description}
              <a
                href='#'
                onClick={togglePackageGuide}
                className='text-decoration-none small font-italic'
              >
                <i className='fa fa-chevron-left mr-2' />
                Back to learn more about Checkr
              </a>
            </div>
            <img src={people} className='packages-img' />
            <div className='rectangle' />
          </div>
          <div className='col-md-8'>
            <PricingV3 application={application} excludeHeader />
          </div>
        </div>
        <LearnFooter />
      </div>
    </ModalBody>
  );
};

PackageBody.propTypes = {
  togglePackageGuide: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
};

const LearnTile = ({ tileKey, img, onClick, title, trackAnalyticsEvent }) => {
  const trackLearnTile = tile => {
    let tileEvent;
    switch (tile) {
      case 'aboutCheckr':
        tileEvent = ANALYTICS_EVENTS.LEARN_MORE_CHECKR;
        break;
      case 'integrations':
        tileEvent = ANALYTICS_EVENTS.LEARN_MORE_PARTNER_INTEGRATIONS;
        break;
      case 'gettingStarted':
        tileEvent = ANALYTICS_EVENTS.LEARN_MORE_GETTING_STARTED;
        break;
      case 'helpCenter':
        tileEvent = ANALYTICS_EVENTS.LEARN_MORE_HELP;
        break;
      case 'fairChance':
        tileEvent = ANALYTICS_EVENTS.LEARN_MORE_FAIR_CHANCE;
    }

    return trackAnalyticsEvent(tileEvent);
  };

  const openNewTab = url => {
    trackLearnTile(tileKey);

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  if (!onClick) {
    onClick = () => openNewTab(LEARN_MORE_TILE_CONTENT[tileKey].url);
  }

  return (
    <div className='learn-tile' onClick={onClick}>
      <div className='tile-title'>
        {title || LEARN_MORE_TILE_CONTENT[tileKey].title}
      </div>
      <div
        data-testid={LEARN_MORE_TILE_CONTENT[tileKey].dataTestId}
        className='tile-text'
        dangerouslySetInnerHTML={{
          __html: LEARN_MORE_TILE_CONTENT[tileKey].text,
        }}
      />
      <div className='tile-img-container'>
        <img className='tile-img' src={img} />
      </div>
    </div>
  );
};

LearnTile.propTypes = {
  tileKey: PropTypes.string,
  img: PropTypes.string,
  onClick: PropTypes.func,
  trackAnalyticsEvent: PropTypes.func,
  title: PropTypes.string,
};

const LearnFooter = ({ trackAnalyticsEvent }) => {
  const trackContactForm = () => {
    trackAnalyticsEvent(ANALYTICS_EVENTS.LEARN_MORE_CONTACT, {});
  };

  return (
    <div className='row mb-3 justify-content-center'>
      <p className='col-lg-9 text-center'>
        If you have any questions about how our background checks work and how
        Checkr can help you better serve your community, please reach out to us
        using our{' '}
        <a
          href={CONTACT_FORM_URL}
          target='_blank'
          rel='noopener noreferrer'
          onClick={trackContactForm}
        >
          contact form
        </a>
        .
      </p>
    </div>
  );
};

LearnFooter.propTypes = {
  trackAnalyticsEvent: PropTypes.func,
};

const packageSectionTitle = application => {
  return application.partner_billing
    ? 'Package guide'
    : 'Package and pricing guide';
};
