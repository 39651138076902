import React from 'react';
import BusinessDescriptions from './BusinessDescriptions';
import PackageSubtext from './PackageSubtext';
import AdvantageSurveyList from './AdvantageSurveyList';
import {
  FairChanceBody,
  FAIRCHANCE_PARAGRAPHS,
  FAIRCHANCE_PARAGRAPHS_CUSTOM,
  FAIRCHANCE_PARAGRAPHS_CUSTOM_PLANNING_CENTER,
  FAIRCHANCE_PARAGRAPHS_CUSTOM_RIPPLING,
  FAIRCHANCE_PARAGRAPHS_CUSTOM_WELLSKY,
} from './FairChanceBody';
import {
  SIGNUP_BUSINESS_DESCRIPTIONS_B,
  SIGNUP_BUSINESS_DESCRIPTIONS_CUSTOM,
  SIGNUP_PACKAGE_SUBTEXT,
  SIGNUP_PACKAGE_SUBTEXT_CUSTOM,
  SURVEYS,
  CUSTOM_SURVEYS_1,
  WELCOME_HEADER,
  WELCOME_HEADER_CUSTOM,
  PACKAGE_HEADER,
  PACKAGE_HEADER_CUSTOM,
  FAIR_CHANCE_HEADER,
  FAIR_CHANCE_HEADER_CUSTOM,
  ADVANTAGE_HEADER,
  ADVANTAGE_HEADER_CUSTOM,
  ADVANTAGE_SUB_HEADER,
  ADVANTAGE_SUB_HEADER_CUSTOM,
  WELCOME_HEADER_CUSTOM_PLANNING_CENTER,
  SIGNUP_BUSINESS_DESCRIPTIONS_PLANNING_CENTER,
  PACKAGE_HEADER_PLANNING_CENTER,
  SIGNUP_PACKAGE_SUBTEXT_PLANNING_CENTER,
  FAIR_CHANCE_HEADER_PLANING_CENTER,
  ADVANTAGE_SUB_HEADER_PLANNING_CENTER_AND_RIPPLING,
  CUSTOM_SURVEYS_PLANNING_CENTER,
  WELCOME_HEADER_CUSTOM_WELLSKY,
  SIGNUP_BUSINESS_DESCRIPTIONS_WELLSKY,
  PACKAGE_HEADER_WELLSKY,
  SIGNUP_PACKAGE_SUBTEXT_WELLSKY,
  FAIR_CHANCE_HEADER_WELLSKY,
  ADVANTAGE_HEADER_WELLSKY,
  ADVANTAGE_SUB_HEADER_WELLSKY,
  CUSTOM_SURVEYS_WELLSKY,
  WELCOME_HEADER_CUSTOM_RIPPLING,
  SIGNUP_BUSINESS_DESCRIPTIONS_RIPPLING,
  PACKAGE_HEADER_RIPPLING,
  SIGNUP_PACKAGE_SUBTEXT_RIPPLING,
  FAIR_CHANCE_HEADER_RIPPLING,
  CUSTOM_SURVEYS_RIPPLING,
} from '../custom/PartnerCustomizationConstants';
import fairChanceImage from '../../images/fair-chance.jpg';
import fairChanceImagePlanningCenter from '../../images/fair-chance-planning-center.jpg';
import fairChanceImageWellsky from '../../images/fair-chance-wellsky.jpg';
import lightbulb from '../../images/lightbulb.png';

/* steps for new custom content
1. Pick a name for the content section ie 'welcomeHeader' or 'businessDescription'
2. Move the current content here to default content, keyed by the name you chose
- Content can be a string, a native html element or a rendered React component
- Keep data-testids and as much outer styling as possible in parents of CustomElement
3. Render <CustomElement elementKey={yourContentName} /> wherever you moved the content from
- Confirm the default content appears as expected
4. Make a new object in customConfig
5. In the new object, add an array of clientIds that should see specific content
- Include a staging client id for testing the custom content is visible in staging
6. In the new object, add the custom content keyed by the same key you defined in defaultContent
- Any keys not defined in a given customConfig object will fall through to the default content
7. For testing, we really only need 1 automated test for the default values and 1 for validating that we can customize.
- Any customizations beyond just the 2 above, we will want to manually validate that the customizations visually
*/

export const defaultContent = {
  // 96330651a308ee318f028a80 sees all default content
  welcomeHeader: WELCOME_HEADER,
  businessDescriptions: (
    <BusinessDescriptions content={SIGNUP_BUSINESS_DESCRIPTIONS_B} />
  ),
  packageHeader: PACKAGE_HEADER,
  packageSubtext: <PackageSubtext context={SIGNUP_PACKAGE_SUBTEXT} />,
  fairChanceHeader: FAIR_CHANCE_HEADER,
  fairChanceBody: <FairChanceBody content={FAIRCHANCE_PARAGRAPHS} />,
  advantageHeader: ADVANTAGE_HEADER,
  advantageSubHeader: ADVANTAGE_SUB_HEADER,
  advantageSurveyList: <AdvantageSurveyList content={SURVEYS} />,
  fairChanceImage: (
    <img
      src={fairChanceImage}
      style={{ maxWidth: '100%', maxHeight: '100%' }}
      data-testid='fairChanceImage'
    />
  ),
};

export const customConfig = [
  //test client id
  {
    clientIds: ['5f5449b1dc40b65ef645f6c9'],
    welcomeHeader: WELCOME_HEADER_CUSTOM,
    businessDescriptions: (
      <BusinessDescriptions content={SIGNUP_BUSINESS_DESCRIPTIONS_CUSTOM} />
    ),
    packageHeader: PACKAGE_HEADER_CUSTOM,
    packageSubtext: <PackageSubtext context={SIGNUP_PACKAGE_SUBTEXT_CUSTOM} />,
    fairChanceHeader: FAIR_CHANCE_HEADER_CUSTOM,
    fairChanceBody: <FairChanceBody content={FAIRCHANCE_PARAGRAPHS_CUSTOM} />,
    advantageHeader: ADVANTAGE_HEADER_CUSTOM,
    advantageSubHeader: ADVANTAGE_SUB_HEADER_CUSTOM,
    advantageSurveyList: <AdvantageSurveyList content={CUSTOM_SURVEYS_1} />,
    fairChanceImage: (
      <img
        src={lightbulb}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
        data-testid='fairChanceImage'
      />
    ),
  },
  //Planning Center production and staging client id
  {
    clientIds: ['a3d8c24e4a33c2d111f551b8', 'cb8b2b06aacbb2f4074e728e'],
    welcomeHeader: WELCOME_HEADER_CUSTOM_PLANNING_CENTER,
    businessDescriptions: (
      <BusinessDescriptions
        content={SIGNUP_BUSINESS_DESCRIPTIONS_PLANNING_CENTER}
      />
    ),
    packageHeader: PACKAGE_HEADER_PLANNING_CENTER,
    packageSubtext: (
      <PackageSubtext context={SIGNUP_PACKAGE_SUBTEXT_PLANNING_CENTER} />
    ),
    fairChanceHeader: FAIR_CHANCE_HEADER_PLANING_CENTER,
    fairChanceBody: (
      <FairChanceBody content={FAIRCHANCE_PARAGRAPHS_CUSTOM_PLANNING_CENTER} />
    ),
    advantageHeader: ADVANTAGE_HEADER,
    advantageSubHeader: ADVANTAGE_SUB_HEADER_PLANNING_CENTER_AND_RIPPLING,
    advantageSurveyList: (
      <AdvantageSurveyList content={CUSTOM_SURVEYS_PLANNING_CENTER} />
    ),
    fairChanceImage: (
      <img
        src={fairChanceImagePlanningCenter}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
        data-testid='fairChanceImage'
      />
    ),
  },
  //Wellsky client id
  {
    clientIds: ['3bde5de358d3812c548cc25c'],
    welcomeHeader: WELCOME_HEADER_CUSTOM_WELLSKY,
    businessDescriptions: (
      <BusinessDescriptions content={SIGNUP_BUSINESS_DESCRIPTIONS_WELLSKY} />
    ),
    packageHeader: PACKAGE_HEADER_WELLSKY,
    packageSubtext: <PackageSubtext context={SIGNUP_PACKAGE_SUBTEXT_WELLSKY} />,
    fairChanceHeader: FAIR_CHANCE_HEADER_WELLSKY,
    fairChanceBody: (
      <FairChanceBody content={FAIRCHANCE_PARAGRAPHS_CUSTOM_WELLSKY} />
    ),
    advantageHeader: ADVANTAGE_HEADER_WELLSKY,
    advantageSubHeader: ADVANTAGE_SUB_HEADER_WELLSKY,
    advantageSurveyList: (
      <AdvantageSurveyList content={CUSTOM_SURVEYS_WELLSKY} />
    ),
    fairChanceImage: (
      <img
        src={fairChanceImageWellsky}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
        data-testid='fairChanceImage'
      />
    ),
  },
  //Rippling production and staging client id
  {
    clientIds: ['caf35da2386a45643816d26f', '26fdb690748e32505b47a704'],
    welcomeHeader: WELCOME_HEADER_CUSTOM_RIPPLING,
    businessDescriptions: (
      <BusinessDescriptions content={SIGNUP_BUSINESS_DESCRIPTIONS_RIPPLING} />
    ),
    packageHeader: PACKAGE_HEADER_RIPPLING,
    packageSubtext: (
      <PackageSubtext context={SIGNUP_PACKAGE_SUBTEXT_RIPPLING} />
    ),
    fairChanceHeader: FAIR_CHANCE_HEADER_RIPPLING,
    fairChanceBody: (
      <FairChanceBody content={FAIRCHANCE_PARAGRAPHS_CUSTOM_RIPPLING} />
    ),
    advantageHeader: ADVANTAGE_HEADER,
    advantageSubHeader: ADVANTAGE_SUB_HEADER_PLANNING_CENTER_AND_RIPPLING,
    advantageSurveyList: (
      <AdvantageSurveyList content={CUSTOM_SURVEYS_RIPPLING} />
    ),
    fairChanceImage: (
      <img
        src={fairChanceImage}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
        data-testid='fairChanceImage'
      />
    ),
  },
];
