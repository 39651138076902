import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  ADD_ON_OPTIONS_CONFIG,
  PHASE2_ADD_ON_OPTIONS_CONFIG,
} from '../../data/packages';
import { InfoDiv, SelectedTitle, Price } from './orderSummaryStyles';
import { INVITES } from '../../../../../constants/identifiers';
import amplitudeExperiment from '../../../../../services/amplitudeExperiment';

const Label = styled.div`
  color: ${props => (props.currentStep < 2 ? '#8799AB' : '#117091')};
  font-size: ${props => (props.currentSelectedAddOns.length ? '12px' : '16px')};
  font-family: 'National2Regular';
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: ${props =>
      props.currentSelectedAddOns.length ? '12px' : '14px'};
  }

  @media (max-width: 600px) {
    font-size: ${props =>
      props.currentSelectedAddOns.length ? '12px' : '14px'};
  }
`;

const InfoContainer = styled.div`
  align-self: stretch;
  justify-content: space-between;
  align-items: baseline;
  display: flex;
  flex-direction: column;
`;

const ShowMore = styled.div`
  color: #0a57a4;
  font-size: 14px;
  font-family: 'National2Medium';
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 900px) {
    font-size: 12px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const ShowMorePrice = styled.div`
  color: #b2bdcc;
  font-size: 14px;
  font-family: 'National2Regular';
  font-weight: 400;
  cursor: pointer;

  @media (max-width: 900px) {
    font-size: 12px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const CustomizeWithAddOns = ({
  currentSelectedAddOns,
  currentStep,
  showAllAddOns,
  setShowAllAddOns,
}) => {
  const enableFullAddons =
    amplitudeExperiment.getVariantValue('ab-06-full-add-ons-experience') ===
    'treatment';

  const compact = currentSelectedAddOns.length > 5 && !showAllAddOns;
  const displayedAddOns =
    compact && enableFullAddons
      ? currentSelectedAddOns.slice(0, 4)
      : currentSelectedAddOns;

  const hiddenAddOnsPrice = currentSelectedAddOns
    .slice(4)
    .reduce((total, addOn) => {
      const addOnConfig = PHASE2_ADD_ON_OPTIONS_CONFIG[addOn];
      return total + parseFloat(addOnConfig.price.substring(1));
    }, 0);

  const hiddenAddOnsPriceString = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(hiddenAddOnsPrice);

  useEffect(() => {
    if (currentSelectedAddOns.length < 6) {
      setShowAllAddOns(false);
    }
  }, [currentSelectedAddOns, setShowAllAddOns]);

  return (
    <>
      <Label currentSelectedAddOns={displayedAddOns} currentStep={currentStep}>
        Customize with add-ons
      </Label>
      <InfoContainer>
        {displayedAddOns.length > 0 && (
          <>
            {displayedAddOns.map((addOn, i) => {
              const addOnConfig = enableFullAddons
                ? PHASE2_ADD_ON_OPTIONS_CONFIG[addOn]
                : ADD_ON_OPTIONS_CONFIG[addOn];
              return (
                <InfoDiv key={`add-on-container-${i}`}>
                  <SelectedTitle
                    data-testid={`${INVITES.ORDER_SUMMARY.addonName}-${i}`}
                    key={`add-on-name-${i}`}
                  >
                    {addOnConfig.displayName}
                  </SelectedTitle>
                  <Price
                    data-testid={`${INVITES.ORDER_SUMMARY.addonPrice}-${i}`}
                    key={`add-on-price-${i}`}
                  >
                    {addOnConfig.price}
                  </Price>
                </InfoDiv>
              );
            })}

            {enableFullAddons && compact && (
              <InfoDiv key='show-more'>
                <ShowMore
                  data-testid={INVITES.ORDER_SUMMARY.showMore}
                  onClick={event => {
                    event.stopPropagation();
                    setShowAllAddOns(true);
                  }}
                >
                  {currentSelectedAddOns.length - 4} more
                </ShowMore>
                <ShowMorePrice data-testid={INVITES.ORDER_SUMMARY.hiddenPrice}>
                  {hiddenAddOnsPriceString}
                </ShowMorePrice>
              </InfoDiv>
            )}
            {enableFullAddons && showAllAddOns && (
              <ShowMore
                data-testid={INVITES.ORDER_SUMMARY.showLess}
                onClick={event => {
                  event.stopPropagation();
                  setShowAllAddOns(false);
                }}
              >
                Show less
              </ShowMore>
            )}
          </>
        )}
      </InfoContainer>
    </>
  );
};

export default CustomizeWithAddOns;
