import { inject, observer } from 'mobx-react';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const CheckrReCAPTCHA = inject('store')(
  observer(({ store, recaptchaRef }) => {
    return (
      <ReCAPTCHA
        ref={recaptchaRef}
        size='invisible'
        sitekey={store.env.RECAPTCHA_SITE_KEY}
      />
    );
  }),
);

export default CheckrReCAPTCHA;
