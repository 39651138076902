import FieldKit from 'field-kit';
import TextField from './TextField';

export default class SocialSecurityNumberField extends TextField {
  static fieldName = 'ssn';

  getFormatter() {
    return new FieldKit.SocialSecurityNumberFormatter();
  }
}

SocialSecurityNumberField.propTypes = TextField.getBasePropTypes();

SocialSecurityNumberField.defaultProps = TextField.getBaseDefaultProps();
