import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Loading from '../../Loading';
import { Box, Button, Typography } from '@mui/material/';
import CheckrDirectHeader from './CheckrDirectHeader';
import ConnectHelper from '../../../stores/forms/helpers/ConnectHelper';
import { INVITES } from '../../../constants/identifiers';

const CONTENT = {
  default: {
    title: 'Order submitted!',
    message1: `We'll send your invitations after your business is verified, which typically takes less than one
      business day. Then, we'll send you an email when it's complete. We'll also reach out if the process
      is taking longer or we need more information.`,
    message2: (
      <>
        If your business is tax exempt, send a copy of your valid sales tax
        exemption certificate to{' '}
        <a href='mailto:tax@checkr.com'>tax@checkr.com</a>. Sales tax will be
        included on all orders until documentation is confirmed.
      </>
    ),
  },
  fromSignin: {
    title: 'Welcome back!',
    message1:
      'Visit your Checkr Dashboard to start taking advantage of all our great products and services.',
    message2: (
      <>
        Need a faster route? You can always get to your dashboard by going to{' '}
        <span className='aqua-3'>checkr.com</span>.
      </>
    ),
  },
  skipInvites: {
    title: 'Welcome to Checkr!',
    message1: `We're already working to verify your business, which typically takes less than one business day. 
      Then, we'll send you an email when it's complete. We'll also reach out if the process is taking longer or we 
      need more information.`,
    message2: (
      <>
        If your business is tax exempt, send a copy of your valid sales tax
        exemption certificate to{' '}
        <a href='mailto:tax@checkr.com'>tax@checkr.com</a>. Sales tax will be
        included on all orders until documentation is confirmed.
      </>
    ),
  },
};

@inject('store')
@observer
export default class CheckrDirectSubmissionA extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.form = this.store.submission;
    const queryParameters = new URLSearchParams(
      this.props.store.router.location.search,
    );
    this.skipInvites = queryParameters.get('skipInvites') === 'true';
    this._handleRedirect = this.handleRedirect.bind(this);
  }

  UNSAFE_componentWillMount() {
    const flagIsActive =
      this.store.pagePerfActions.remove_duplicate_authorize_call;
    const initialCallFailed = this.store.initialAuthorizationFailed;
    const comingFromSignin =
      window?.history?.state?.state?.from === 'signin' || undefined;

    if (flagIsActive) {
      if (initialCallFailed || comingFromSignin) {
        ConnectHelper.authorize(this.store).then(() => {
          this.store.performanceObserver.setEndLoadTime();
        });
      } else {
        this.store.performanceObserver.setEndLoadTime();
      }
    } else {
      ConnectHelper.authorize(this.store).then(() => {
        this.store.performanceObserver.setEndLoadTime();
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleRedirect(e) {
    e.preventDefault();
    this.form.handleRedirect();
  }

  dashboardRedirect = () => {
    window.location.href = this.store.env.CHECKR_DASHBOARD_URL;
  };

  render() {
    if (this.form.loading) {
      return <Loading />;
    }
    const fromSignin = this.store.router.location.state?.from === 'signin';
    /* Render contextual content depending on if user skipped the invites step,
    redirected from signin, or submitted an invitation */
    const content = this.skipInvites
      ? CONTENT.skipInvites
      : fromSignin
        ? CONTENT.fromSignin
        : CONTENT.default;

    return (
      <div>
        <div style={{ marginTop: '4%', marginLeft: '-1%' }}>
          <CheckrDirectHeader />
        </div>
        <Box textAlign='center' marginTop='72px'>
          <div className='icons-wrap'>
            <div
              className='icons-animation'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <svg
                className='explosion'
                width='90'
                height='102'
                viewBox='0 0 90 102'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g
                  transform='translate(1 1)'
                  stroke='#009DE0'
                  fill='none'
                  fillRule='evenodd'
                >
                  <circle strokeWidth='10' cx='44' cy='50' r='27' />
                  <path
                    className='check'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M33 50.578l8.911 9.542L55 39'
                  />
                  <path
                    d='M44 0v40m43.301-15l-34.64 20M87.3 75L52.66 55M44 100V60M.699 75l34.64-20M.7 25l34.64 20'
                    strokeWidth='2'
                    strokeLinecap='flat'
                  />
                </g>
              </svg>
            </div>
            <br />
            <p
              className='animation-title'
              data-testid={INVITES.MISC.SubmissionTitle}
              style={{ textAlign: 'center', fontSize: '36px' }}
            >
              {content.title}
            </p>

            <Typography
              className='animation-message'
              fontSize='16px'
              fontFamily='National2Regular'
              marginTop={4}
              marginBottom={4}
              data-testid={INVITES.MISC.SubmissionMessageContent}
            >
              {content.message1}
            </Typography>

            {content.message2 && (
              <Typography
                className='animation-message'
                fontSize='16px'
                fontFamily='National2Regular'
                marginBottom={4}
              >
                {content.message2}
              </Typography>
            )}

            <Button
              className='animation-message'
              variant='contained'
              id='btn-go-to-dashboard'
              onClick={this.dashboardRedirect}
              sx={{
                backgroundColor: '#002853',
                textTransform: 'none',
                borderRadius: '0px',
                padding: '8px 60px',
                fontFamily: 'National2Regular',
              }}
            >
              Go to dashboard
            </Button>
            {content.footnote && (
              <Typography
                className='animation-message'
                fontSize='14px'
                fontFamily='National2Regular'
                marginTop={4}
                data-testid={INVITES.MISC.SubmissionFootNote}
              >
                {content.footnote}
              </Typography>
            )}
          </div>
        </Box>
      </div>
    );
  }
}

CheckrDirectSubmissionA.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
