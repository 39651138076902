/* eslint-disable max-len */
import React from 'react';

const OrderSummaryCheck = ({ dataTestId }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='9'
      viewBox='0 0 12 9'
      fill='none'
      data-testid={dataTestId}
    >
      <path
        d='M0.875513 4.76946L0.875497 4.76988C0.868806 4.9444 0.929842 5.09755 1.05402 5.22172L3.83735 8.00505C3.9051 8.0728 3.98387 8.12408 4.07287 8.158C4.15928 8.19155 4.24939 8.20833 4.34241 8.20833C4.43548 8.20833 4.52578 8.19152 4.61255 8.15803C4.70121 8.12407 4.77977 8.07275 4.84746 8.00505L10.8975 1.95505C11.0205 1.83205 11.0841 1.68088 11.0841 1.508C11.0841 1.33665 11.0213 1.18664 10.9 1.06419C10.7857 0.937067 10.6348 0.875 10.4591 0.875C10.2835 0.875 10.1326 0.937025 10.0182 1.06406L4.34241 6.73989L1.93325 4.33073C1.82001 4.20488 1.67266 4.14167 1.50107 4.14167C1.3282 4.14167 1.17702 4.20527 1.05402 4.32828L1.05386 4.32812L1.04898 4.33362C0.94018 4.45602 0.882443 4.60316 0.875513 4.76946Z'
        fill='white'
        stroke='white'
        strokeWidth='0.25'
      />
    </svg>
  );
};

export default OrderSummaryCheck;
