/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Auth0 from '../../../services/Auth0';
import CheckrDirectHeader from './CheckrDirectHeader';
import { Box, Button, CardHeader, Typography } from '@mui/material/';

@inject('store')
@observer
export default class CheckrDirectSigninA extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.form = this.store.signin;
    this._onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.store.performanceObserver.setEndLoadTime();
  }

  onClick() {
    Auth0.authorize({ partner_client_id: this.store.application.client_id });
  }

  UNSAFE_componentWillMount() {
    // eslint-disable-line camelcase
    this.store.auth.setClientId(this.store.application.client_id);
    if (
      this.store.router.location.search &&
      new URLSearchParams(this.store.router.location.search).get('state')
    ) {
      this.store.auth.setStateParam(
        new URLSearchParams(this.store.router.location.search).get('state'),
      );
    }
  }

  render() {
    return (
      <div>
        <CheckrDirectHeader />
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          textAlign='center'
        >
          <CardHeader
            style={{ marginTop: '30px', marginBottom: '30px' }}
            title={
              <Typography
                fontWeight='600'
                lineHeight='115%'
                marginBottom='10px'
                fontSize='42px'
                color='#001730'
                fontFamily='ArgentCFRegular'
              >
                Sign in to your Checkr Account
              </Typography>
            }
            subheader={
              <Typography
                fontWeight='400'
                lineHeight='150%'
                fontSize='16px'
                color='#0A1626'
                fontFamily='National2Regular'
              >
                This will allow you to continue the set up process with Checkr.
                Once complete, you will be
                <br />
                integrated with Checkr and can start ordering background checks.
              </Typography>
            }
          />
          <Button
            style={{
              textTransform: 'none',
              color: 'white',
              backgroundColor: '#002853',
              padding: '10px 60px',
              marginBottom: '10px',
            }}
            onClick={this._onClick}
            id='signin-button'
          >
            Sign in with Checkr
          </Button>
          <div>
            Need a Checkr account?&nbsp;
            <a href='https://checkr.com/signup'>Sign up</a>
          </div>
        </Box>
      </div>
    );
  }
}

CheckrDirectSigninA.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
