import React from 'react';
import PropTypes from 'prop-types';
import logo from '../images/checkr.svg';
import Footer from './Footer';

export default function NotFound(props) {
  return (
    <div className='text-center' data-testid='not-found'>
      <br />
      <br />
      <br />
      <img src={logo} height='50px' />
      <br />
      <br />
      <br />
      {props.children ? (
        props.children
      ) : (
        <h5>{props.msg || 'Oops, there has been an error.'}</h5>
      )}
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

NotFound.propTypes = {
  msg: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
