import React from 'react';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

import colors from '../../../../../styles/_colors.scss';
import AdvantagesAndConsiderations from './AdvantagesAndConsiderations';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  boxShadow: 'none',
  borderRadius: 0,
}));

type SummaryProps = AccordionSummaryProps & { height?: string };

const AccordionSummary = styled((props: SummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ height = '72px' }) => ({
  flexDirection: 'row-reverse',
  padding: '0 22px 0 20px',
  height,
  minHeight: 'unset',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',
    transition: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordion-root': {
    cursor: 'pointer',
  },
}));

type Props = {
  screeningInfo?: string;
  advantages?: string[];
  considerations?: string[];
  height?: string;
  children?: React.ReactNode;
};

const ScreeningAccordion: React.FC<Props> = ({
  screeningInfo,
  advantages,
  considerations,
  height,
  children,
}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: colors.aqua3 }} />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        height={height}
      >
        <div
          style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        >
          {children}
        </div>
      </AccordionSummary>
      <AdvantagesAndConsiderations
        screeningInfo={screeningInfo}
        advantages={advantages}
        considerations={considerations}
      />
    </Accordion>
  );
};

export default ScreeningAccordion;
