import { createClient } from '../../../../services/http';

export const postAccountPackage = async (authToken, accountId, reqBody) => {
  return createClient(authToken).post(
    `/accounts/${accountId}/packages`,
    reqBody,
  );
};

export const deleteAccountPackage = async (authToken, accountId, packageId) => {
  return createClient(authToken).delete(
    `/accounts/${accountId}/packages/${packageId}`,
  );
};
