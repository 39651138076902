import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const InternationalIdentityDocumentValidation = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo =
    'Validates the authenticity of a candidate’s passport, national ID, or driver license';

  const advantages = [
    'Multi-level process that includes cross-checking information against government databases',
    'Ensures presented document isn’t stolen, lost, expired or invalid',
    'Covers more than 200 countries and regions',
  ];

  const considerations = [
    'Validates documentation, not identity',
    'Doesn’t satisfy United Kingdom (England and Wales) identity verification requirement',
  ];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>
          International Identity Document Validation
        </ScreeningTitle>
        <ScreeningPrice
          priceByType='international_identity_document_validation'
          priceVaries
        />
        <AddScreeningsAction
          screeningType='international_identity_document_validation'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default InternationalIdentityDocumentValidation;
