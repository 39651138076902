import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { InvitesLink } from './shared';
import { useInvitesAnalyticsTrackers } from '../hooks/useInvitesAnalyticsTrackers';
import { INVITES } from '../../../../constants/identifiers';
import { SkipOrderFlow } from '.';

type ChoosePackageSectionFooterProps = {
  selectedRegion: string;
  setLearnMoreClicked: (arg: boolean) => void;
};

const ChoosePackageSectionFooter = ({
  selectedRegion,
  setLearnMoreClicked,
}: ChoosePackageSectionFooterProps) => {
  const { trackSkipInvitesClicked } = useInvitesAnalyticsTrackers();
  const [modalOpen, setModalOpen] = useState(false);

  if (selectedRegion !== 'US') {
    return (
      <Typography
        marginTop='12px'
        marginLeft='12px'
        marginBottom='10px'
        fontSize='16px'
        color='#0A1626'
        fontFamily='National2Regular'
        textAlign='center'
      >
        An international package should only be ordered for a candidate living
        and working outside of the United States. Additionally, please note that
        pricing, candidate requirements and turnaround times will vary by
        country.
      </Typography>
    );
  }

  return (
    <Box className='d-flex justify-content-center flex-column'>
      <Typography
        fontFamily='National2Regular'
        fontSize='14px'
        marginTop={4}
        textAlign='center'
      >
        More package and screening options are available in your{' '}
        <InvitesLink
          data-testid={INVITES.MISC.showMorePackagesLink}
          onClick={() => {
            trackSkipInvitesClicked('Under Package Selection');
            setModalOpen(true);
          }}
        >
          Checkr dashboard
        </InvitesLink>
        .
      </Typography>
      <SkipOrderFlow
        handleCloseModal={() => setModalOpen(false)}
        modalOpen={modalOpen}
      />
      <Typography
        fontFamily='National2Regular'
        fontSize='14px'
        marginTop={2}
        textAlign='center'
      >
        Additional third-party fees may apply.{' '}
        <InvitesLink
          data-testid={INVITES.MISC.learnMore}
          onClick={() => setLearnMoreClicked(true)}
          href='https://help.checkr.com/hc/en-us/articles/115001342747-FAQs-about-Checkr-charges-fees'
          target='_blank'
        >
          Learn more
        </InvitesLink>
      </Typography>
    </Box>
  );
};

export default ChoosePackageSectionFooter;
