import React from 'react';
import styled from 'styled-components';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import { styled as muiStyled } from '@mui/system';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';

import colors from '../../../../../styles/_colors.scss';
import { StyledLink } from './ListItemElements';

const StyledLabel = muiStyled((props: FormControlLabelProps) => (
  <FormControlLabel {...props} />
))({
  '&.MuiFormControlLabel-root': {
    marginLeft: '0px',
    marginRight: '0px',
  },
  '& .MuiTypography-root': {
    color: 'black',
    fontFamily: 'National2Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    marginLeft: '10px',
    marginRight: '5px',
  },
});

const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  align-self: end;
  font: inherit;
  color: ${colors.slate5};
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid ${colors.slate5};
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  cursor: pointer;
  display: grid;
  place-content: center;

  &:before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: ${colors.slate5};
  }

  &:checked:before {
    transform: scale(1);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledCheckbox = ({
  checked,
  onAddClick,
  screening,
}: {
  onAddClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  screening: string;
}) => (
  <Checkbox
    id={screening}
    checked={checked}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onAddClick(event)}
  />
);

const StyledTooltip = (props: React.ComponentProps<typeof Tooltip>) => {
  const { className, ...otherProps } = props;
  return <Tooltip {...otherProps} classes={{ popper: className }} />;
};

const Styled = styled(StyledTooltip)({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
    color: 'black',
    fontFamily: 'Helvetica Neue',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
});

export const CheckboxWrapper = styled.div`
  display: flex;
  height: 30px;
`;

const title = (
  <div>
    <p>
      This option applies to candidates who will perform their work inside the
      US, but have employment history outside the country.{' '}
    </p>
    <br />
    <p>
      Visit{' '}
      <StyledLink
        href='https://help.checkr.com/hc/en-us/articles/360056108714-International-Screenings'
        target='_blank'
        rel='noreferrer'
        data-testid='learn-more-link-international-screenings'
      >
        International Screenings
      </StyledLink>{' '}
      to learn more.
    </p>
  </div>
);

type Props = {
  onAddClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  screening: string;
};

const CheckboxWithTooltip = ({ onAddClick, checked, screening }: Props) => {
  return (
    <CheckboxWrapper
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
      }}
    >
      <StyledLabel
        control={
          <StyledCheckbox
            checked={checked}
            onAddClick={onAddClick}
            screening={screening}
          />
        }
        label='Include out-of-country history'
        data-testid={`${screening}-checkbox`}
      />
      <div>
        <Styled title={title}>
          <HelpOutlineOutlinedIcon
            onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
              event.stopPropagation()
            }
            style={{ height: '16px', width: '16px', marginBottom: '4px' }}
          />
        </Styled>
      </div>
    </CheckboxWrapper>
  );
};

export default CheckboxWithTooltip;
