import { analytics } from '@dashboard-experience/utils';
import { AMPLITUDE_KEY } from '../components/helpers/Constants';
import * as Sentry from '@sentry/browser';

const services = [
  {
    name: 'amplitude',
    apiKey: AMPLITUDE_KEY,
    logLevel: 0,
  },
];

let analyticsCheck = {};

// Wrap in try/catch to prevent some tests failures
try {
  if (AMPLITUDE_KEY) {
    analyticsCheck = analytics(services);
  }
} catch (err) {
  Sentry.captureEvent(err, Error);
}

const ANALYTICS_EVENTS = {
  ACCOUNT_CONNECTED: 'Partner Customer Onboarding Account Connected',
  ACCOUNT_SUBMITTED: 'Partner Customer Onboarding Account Submitted',
  CONNECT_PAGE_VIEWED:
    'Partner Customer Onboarding Connect Account Page Viewed',
  CUSTOMIZE_PAGE_VIEWED:
    'Partner Customer Onboarding Customize Your First Package Page Viewed',
  CUSTOMIZE_PACKAGE_COMPLETED:
    'Partner Customer Onboarding Customize Your First Package Completed',
  CUSTOMIZE_PACKAGE_SKIPPED:
    'Partner Customer Onboarding Customize Your First Package Skipped',
  CUSTOMIZE_PACKAGE_VIEW_ALL:
    'Partner Customer Onboarding Customize Your First Package - View All Packages',
  CREATE_ACCOUNT_POPUP_VIEWED:
    'Partner Customer Onboarding Create Account Popup Viewed',
  DBA_FIELD_SELECTED: 'Partner Customer Onboarding DBA Field Selected',
  ERROR_DISPLAYED_CONTACT:
    'Partner Customer Onboarding Error Displayed - Contact/Business Information',
  ERROR_DISPLAYED_PAYMENT:
    'Partner Customer Onboarding Error Displayed - Payment Method',
  ERROR_DISPLAYED_EMAIL:
    'Partner Customer Onboarding Error Displayed - Existing Email',
  INDUSTRY_FIELD_SELECTED:
    'Partner Customer Onboarding Industry Field Selected',
  LEARN_MORE_CHECKR: 'Partner Customer Onboarding Learn More - About Checkr',
  LEARN_MORE_CONTACT: 'Partner Customer Onboarding Learn More - Contact Form',
  LEARN_MORE_FAIR_CHANCE:
    'Partner Customer Onboarding Learn More - Fair Chance',
  LEARN_MORE_GETTING_STARTED:
    'Partner Customer Onboarding Learn More - Getting Started',
  LEARN_MORE_HELP: 'Partner Customer Onboarding Learn More - Help Center',
  LEARN_MORE_PACKAGE:
    'Partner Customer Onboarding Learn More - Package and Pricing Guide',
  LEARN_MORE_PARTNER_INTEGRATIONS:
    'Partner Customer Onboarding Learn More - Partner Integrations',
  LEARN_MORE_VIEWED: 'Partner Customer Onboarding Learn More Viewed',
  PAYMENT_PAGE_VIEWED: 'Partner Customer Onboarding Payment Method Page Viewed',
  PAYMENT_SUBMITTED: 'Partner Customer Onboarding Payment Submitted',
  SIGN_IN: 'Partner Customer Onboarding Sign In',
  SIGN_UP_PAGE_VIEWED:
    'Partner Customer Onboarding Contact/Business Information Page Viewed',
  SUBMIT_PAGE_VIEWED: 'Partner Customer Onboarding Submit Account Page Viewed',
  WELCOME_PAGE_VIEWED: 'Partner Customer Onboarding Homepage Viewed',
  WORK_LOCATION_SELECTED: 'Partner Customer Onboarding Work Location Selected',
};

const ANALYTICS_PROPERTIES = {
  ADD_ONS_PRICE: 'Add Ons Price',
  ADD_ONS_SELECTED: 'Add Ons Selected',
  BUTTON_CLICK: 'Button Click',
  CREATE_ACCOUNT_POPUP: 'Create Account Popup',
  ERROR_MESSAGE: 'Error Message',
  FIELD_NAME: 'Field Name',
  INDUSTRY_SELECTED: 'Industry Selected',
  INDUSTRY_SELECTED_UPON_SUBMISSION: 'Industry Selected Upon Submission',
  LEARN_MORE_VIEWED: 'Learn More Selected',
  MULTIPLE_WORK_LOCATIONS: 'Multiple Work Locations',
  NUM_ADD_ONS_SELECTED: 'Number of Add Ons Selected',
  PACKAGE_SELECTED: 'Package Selected',
  PACKAGE_PRICE: 'Package Price',
  PAGE_NAME: 'Page Name',
  PAYMENT_METHOD: 'Payment Method',
  TOTAL_PRICE: 'Total Price',
};

export { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES };

export default analyticsCheck;
