/* eslint-disable camelcase */
import React, { useCallback } from 'react';
import { useStore } from '../../../hooks/useStore';
import PropTypes from 'prop-types';
import { CardActionArea, CardContent, IconButton } from '@mui/material/';
import { CircleOutlined, RadioButtonChecked } from '@mui/icons-material';
import { PARTNER_PACKAGES } from '../../../constants/identifiers';
import PackageCardAddOns from './PackageCardAddOns';
import PackageCardAddOnsList from './PackageCardAddOnsList';
import MoreOptions from './MoreOptions';
import { buildPostBodyWithAddOns } from './PackageUtils/PackageUtils';
import { mapPackage } from './PackageUtils/MapPackage';
import { usePackagePrice } from './PackageUtils/PricingQueries';
import { displayPricing } from './priceHelpers';
import PackageCard from './PackageCard';
import PackageCardHeader from './PackageCardHeader';
import PackageCardScreening from './PackageCardScreening';
import PackageCardIntlDisclaimer from './PackageCardIntlDisclaimer';

const CustomizablePackageCard = ({
  partnerPackage,
  selectedPackage,
  handleSelectPackage,
  partnerBilling,
  handleOpenModal,
  editedPackages,
  dispatch,
  screeningPrices,
}) => {
  const { name, slug, screenings, international_only } = partnerPackage;
  const store = useStore();
  const isSelected = slug === selectedPackage;
  const primaryColor =
    document.documentElement.style.getPropertyValue('--primary-color');
  const activeSelectionColor =
    primaryColor === '#043F93' ? '#117091' : primaryColor;

  const editedPackage = editedPackages[slug];
  const addedScreeningTypes = editedPackage?.addedScreeningTypes || [];
  const additionalProperties = editedPackage?.additionalProperties || {};

  const buildBody = useCallback(
    (basePackage, addedScreeningTypes, additionalProperties) => {
      const pkg = mapPackage(basePackage);
      return buildPostBodyWithAddOns({
        basePackage: pkg,
        addedScreeningTypes,
        additionalProperties,
        packageFields: {
          slug: pkg?.slug,
          name: pkg?.name,
        },
      });
    },
    [],
  );

  const { data, isLoading } = usePackagePrice(
    [
      'pending-addon-on-prices',
      name,
      addedScreeningTypes,
      additionalProperties,
    ],
    {
      ...buildBody(partnerPackage, addedScreeningTypes, additionalProperties),
    },
    !!editedPackage,
  );

  const price = data?.data.fixed_price;
  const disabled = !!store.signUpFlowProperties.customizedPackages;

  return (
    <PackageCard testId={slug} isSelected={isSelected}>
      <CardActionArea
        data-testid={`${PARTNER_PACKAGES.cardArea}-${slug}`}
        disabled={disabled}
        disableRipple
        onClick={() => handleSelectPackage(slug)}
        component={'div'}
        sx={{
          opacity: disabled ? '50%' : '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <IconButton
          data-testid={`${PARTNER_PACKAGES.packageRadioButton}-${slug}`}
          style={{
            position: 'absolute',
            top: '6px',
            right: '6px',
          }}
        >
          {isSelected ? (
            <RadioButtonChecked sx={{ color: '#FFF' }} />
          ) : (
            <CircleOutlined />
          )}
        </IconButton>

        <PackageCardHeader
          title={name}
          testId={slug}
          isSelected={isSelected}
          showPrice={!partnerBilling}
          price={displayPricing(partnerPackage, price)}
          priceLoading={isLoading}
        />
        <CardContent
          style={{
            minHeight: '136px',
            marginTop: '-16px',
          }}
          data-testid={`${PARTNER_PACKAGES.cardContent}-${slug}`}
        >
          {screenings.map(screening => (
            <PackageCardScreening
              key={`${slug}-${screening.type}`}
              slug={slug}
              screening={screening}
              isSelected={isSelected}
            />
          ))}
        </CardContent>
        <CardContent
          style={{
            marginTop: '-16px',
            paddingTop: '0px',
          }}
        >
          <PackageCardAddOnsList
            slug={slug}
            addedScreenings={editedPackage?.addedScreeningTypes}
            additionalProperties={editedPackage?.additionalProperties}
            isSelected={isSelected}
            internationalOnly={international_only}
          />
        </CardContent>
        <CardContent
          style={{
            marginTop: '-16px',
          }}
        >
          {!international_only && (
            <PackageCardAddOns
              addedScreenings={editedPackage?.addedScreeningTypes}
              isSelected={isSelected}
              dispatch={dispatch}
              slug={slug}
              defaultScreenings={screenings}
              screeningPrices={screeningPrices}
            />
          )}
        </CardContent>
        {international_only && (
          <PackageCardIntlDisclaimer isSelected={isSelected} />
        )}
        <MoreOptions
          show
          onClick={handleOpenModal}
          style={{ color: !isSelected ? activeSelectionColor : '#FFF' }}
          slug={slug}
        />
      </CardActionArea>
    </PackageCard>
  );
};

CustomizablePackageCard.propTypes = {
  partnerPackage: PropTypes.object.isRequired,
  selectedPackage: PropTypes.string,
  handleSelectPackage: PropTypes.func,
  partnerBilling: PropTypes.bool.isRequired,
};

CustomizablePackageCard.defaultProps = {
  selectedPackage: '',
  handleSelectPackage: () => {},
};

export default CustomizablePackageCard;
