import React from 'react';
import { Box, Typography } from '@mui/material/';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

const AdvantageSurveyItems = ({ percentage, text, subText = '' }) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant='h3'
          className='aqua-3'
          fontFamily='ArgentCFRegular'
          sx={{
            typography: {
              xs: { fontSize: '28px' },
              lg: { fontSize: '42px' },
            },
          }}
        >
          {percentage}
        </Typography>
        <Typography
          variant='h5'
          className='primary-text-signup-v4'
          fontFamily='National2Medium'
          fontWeight='500'
          marginLeft='18px'
          sx={{
            typography: {
              xs: { fontSize: '16px' },
              lg: { fontSize: '22px' },
            },
          }}
        >
          {text}
        </Typography>
      </Box>
      {subText && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant='h3'
            className='aqua-3'
            fontFamily='ArgentCFRegular'
            sx={{
              visibility: 'hidden',
              typography: {
                xs: { fontSize: '28px' },
                lg: { fontSize: '42px' },
              },
            }}
          >
            {percentage}
          </Typography>
          <Typography
            variant='body1'
            className='primary-text-signup-v4'
            fontFamily='National2Regular'
            fontWeight='400'
            sx={{
              typography: {
                xs: { fontSize: '14px' },
                lg: { fontSize: '16px' },
              },
            }}
            marginLeft='18px'
          >
            {subText}
          </Typography>
        </Box>
      )}
    </>
  );
};

const AdvantageSurveyList = ({ content }) => {
  return content.map(survey => (
    <Grid item sm={1} key={survey.text}>
      <AdvantageSurveyItems {...survey} />
    </Grid>
  ));
};

export default AdvantageSurveyList;
