import FieldKit from 'field-kit';
import TextField from './TextField';

export default class PhoneField extends TextField {
  static fieldName = 'phone';

  getFormatter() {
    return new FieldKit.PhoneFormatter();
  }
}

PhoneField.propTypes = TextField.getBasePropTypes();

PhoneField.defaultProps = TextField.getBaseDefaultProps();
