import { LEGACY_PARTNER_WELCOME } from '../../constants/identifiers';

/* eslint-disable max-len */
export const AMPLITUDE_KEY = window?.__env?.REACT_APP_AMPLITUDE_KEY;

const STATE_OPTIONS = [
  { text: 'Alabama', value: 'AL' },
  { text: 'Alaska', value: 'AK' },
  { text: 'Arizona', value: 'AZ' },
  { text: 'Arkansas', value: 'AR' },
  { text: 'California', value: 'CA' },
  { text: 'Colorado', value: 'CO' },
  { text: 'Connecticut', value: 'CT' },
  { text: 'DC', value: 'DC' },
  { text: 'Delaware', value: 'DE' },
  { text: 'Florida', value: 'FL' },
  { text: 'Georgia', value: 'GA' },
  { text: 'Guam', value: 'GU' },
  { text: 'Hawaii', value: 'HI' },
  { text: 'Idaho', value: 'ID' },
  { text: 'Illinois', value: 'IL' },
  { text: 'Indiana', value: 'IN' },
  { text: 'Iowa', value: 'IA' },
  { text: 'Kansas', value: 'KS' },
  { text: 'Kentucky', value: 'KY' },
  { text: 'Louisiana', value: 'LA' },
  { text: 'Maine', value: 'ME' },
  { text: 'Maryland', value: 'MD' },
  { text: 'Massachusetts', value: 'MA' },
  { text: 'Michigan', value: 'MI' },
  { text: 'Minnesota', value: 'MN' },
  { text: 'Mississippi', value: 'MS' },
  { text: 'Missouri', value: 'MO' },
  { text: 'Montana', value: 'MT' },
  { text: 'Nebraska', value: 'NE' },
  { text: 'Nevada', value: 'NV' },
  { text: 'New Hampshire', value: 'NH' },
  { text: 'New Jersey', value: 'NJ' },
  { text: 'New Mexico', value: 'NM' },
  { text: 'New York', value: 'NY' },
  { text: 'North Carolina', value: 'NC' },
  { text: 'North Dakota', value: 'ND' },
  { text: 'Ohio', value: 'OH' },
  { text: 'Oklahoma', value: 'OK' },
  { text: 'Oregon', value: 'OR' },
  { text: 'Pennsylvania', value: 'PA' },
  { text: 'Puerto Rico', value: 'PR' },
  { text: 'Rhode Island', value: 'RI' },
  { text: 'South Carolina', value: 'SC' },
  { text: 'South Dakota', value: 'SD' },
  { text: 'Tennessee', value: 'TN' },
  { text: 'Texas', value: 'TX' },
  { text: 'Utah', value: 'UT' },
  { text: 'Vermont', value: 'VT' },
  { text: 'Virginia', value: 'VA' },
  { text: 'Washington', value: 'WA' },
  { text: 'West Virginia', value: 'WV' },
  { text: 'Wisconsin', value: 'WI' },
  { text: 'Wyoming', value: 'WY' },
];

const INDUSTRY_OPTIONS = [
  { value: 5412, text: 'Accounting' },
  { value: 11, text: 'Agriculture' },
  { value: 23, text: 'Construction' },
  { value: 928, text: 'Defense' },
  { value: 61, text: 'Education' },
  { value: 221, text: 'Energy/Utility' },
  { value: 5413, text: 'Engineering' },
  { value: 52, text: 'Financial' },
  { value: 72, text: 'Food' },
  { value: 921, text: 'Government' },
  { value: 62, text: 'Healthcare' },
  { value: 524, text: 'Insurance' },
  { value: 5411, text: 'Legal' },
  { value: 721, text: 'Hospitality' },
  { value: 31, text: 'Manufacturing' },
  { value: 541613, text: 'Marketing/HR' },
  { value: 5418, text: 'Media' },
  { value: 92, text: 'Non-Profit' },
  { value: 5419, text: 'Professional Services' },
  { value: 531, text: 'Real Estate' },
  { value: 44, text: 'Retail' },
  { value: 561612, text: 'Security' },
  { value: 561320, text: 'Staffing' },
  { value: 541511, text: 'Technology' },
  { value: 48, text: 'Transportation' },
  { value: 5615, text: 'Travel' },
];

const LEARN_MORE_TILE_CONTENT = {
  aboutCheckr: {
    dataTestId: LEGACY_PARTNER_WELCOME.tileAboutCheckraboutCheckr,
    title: 'About Checkr',
    text: "We're building a better future for everyone by making hiring better, fairer, and more efficient. Learn more about our company and solutions.",
    url: 'https://checkr.com/company',
  },
  packages: {
    dataTestId: LEGACY_PARTNER_WELCOME.tilePackages,
    title: 'Package and pricing guide',
    text: 'Check out our guide for the most popular packages and other services that can be added to your account.',
  },
  integrations: {
    dataTestId: LEGACY_PARTNER_WELCOME.tileIntegrations,
    title: 'Partner integrations',
    text: 'Learn more about how to enable the integration',
    url: 'https://help.checkr.com/hc/en-us/sections/360000320587-Partner-Integrations',
  },
  gettingStarted: {
    dataTestId: LEGACY_PARTNER_WELCOME.tileGettingStarted,
    title: 'Getting started',
    text: `
    Everything you need to get started.
    <ul class="modal-list-style">
      <li>Learn to run a report</li>
      <li>Manage candidate statuses</li>
      <li>Adjudicate/review reports</li>
    </ul>`,
    url: 'https://help.checkr.com/hc/en-us/sections/203794077-Getting-Started',
  },
  helpCenter: {
    dataTestId: LEGACY_PARTNER_WELCOME.tileHelpCenter,
    title: 'Help center',
    text: 'Browse our features, user guides, screening types, compliance resources, and get answers to our most common questions.',
    url: 'https://help.checkr.com/hc/en-us/categories/202502548-Customers',
  },
  fairChance: {
    dataTestId: LEGACY_PARTNER_WELCOME.tileFairChance,
    title: 'Fair chance',
    text: 'Fair chance hiring is built on the premise that everyone, regardless of their background, has the right to be fairly assessed for a role they are qualified for.',
    url: 'https://fair.checkr.com/',
  },
};

const BASE_PACKAGE_NAMES = {
  checkrdirect_basic_plus_criminal: 'basic',
  checkrdirect_essential_criminal: 'essential',
  checkrdirect_professional_criminal: 'professional',
  checkrdirect_international_basic_plus: 'international_basic',
  checkrdirect_international_professional: 'international_professional',
  checkrdirect_international_professional_plus:
    'international_professional_plus',
};

const SCREENING_NAMES = {
  arrest_search: 'Arrest Search',
  clearinghouse_search: 'Clearinghouse Search',
  international_criminal_search: 'International Criminal Search',
  child_abuse_search: 'Child Abuse Search',
  county_civil_search: 'County Civil Records Check',
  county_criminal_search: 'County Criminal Records Check',
  credit_report: 'Employment Credit Check',
  drug_screening: 'Drug Screening',
  education_verification: 'Education Verification',
  employment_verification: 'Employment Verification',
  eviction_search: 'Eviction Search',
  facis_search_1: 'FACIS Level 1',
  facis_search_3: 'FACIS Level 3',
  facis_search: 'FACIS (Fraud Abuse Control Information System) Check',
  federal_civil_search: 'Federal Civil Records Check',
  federal_criminal_search: 'Federal Criminal Records Check',
  global_watchlist_search: 'Global Watchlist Records Check',
  international_adverse_media_search: 'International Adverse Media Search',
  international_canada_bc_enhanced_criminal_search:
    'International Canada BC Enhanced Criminal Search',
  international_criminal_search_v2: 'International Criminal Search',
  international_education_verification: 'International Education Verification',
  international_employment_verification:
    'International Employment Verification',
  international_global_watchlist_search: 'Global Watchlist Search',
  international_identity_document_validation:
    'International Identity Document Validation',
  international_motor_vehicle_report: 'International Motor Vehicle Report',
  motor_vehicle_report: 'Motor Vehicle Records (MVRs)',
  municipal_criminal_search: 'Municipal Criminal Records Check',
  national_criminal_search: 'National Criminal Database Check',
  occupational_health_screening: 'Occupational Drug Screening',
  out_of_country_criminal_history: 'Out-of-Country Criminal History',
  personal_reference_verification: 'Personal Reference Check',
  pro_license_verification: 'Professional License Verification',
  professional_license_verification: 'Professional License Verification',
  professional_reference_verification: 'Professional Reference Check',
  sex_offender_search: 'Sex Offender Registry Check',
  ssn_trace: 'Social Security Number (SSN) Trace',
  state_criminal_search: 'State Criminal Records Check',
};

const SCREENING_TOOLTIPS = {
  county_criminal_search:
    'Includes a county criminal search for all counties where a registered address is found within the indicated number of years.  The county-level verification fee is included, but additional county court fees may apply. ',
  education_verification:
    'Confirm the candidate’s provided education or certification history.',
  employment_verification: 'Confirm the candidate’s provided work history.',
  national_criminal_search:
    'Search 2,800+ databases and over 900 million records to cast a wide net and find potential offenses. These records often lack identifying information and/or the final disposition of what actually happened—for example, whether the case was dismissed.',
  targeted_county_criminal_search:
    'If the national criminal search finds a record, the report automatically adds a county criminal search. This search includes only specific counties based on the national criminal search. A $15 county-level verification fee and additional county court fees may apply. ',
};

const SCREENINGS_WITH_SUBTYPES = [
  'county_civil_search',
  'county_criminal_search',
  'federal_criminal_search',
  'state_criminal_search',
];

const SCREENING_SUBTYPE_NAME_MAP = {
  current: 'Current',
  current_no_email: 'Current - Skip Email Verification',
  last3: 'Last 3 Employers',
  last3_no_email: 'Last 3 Employers - Skip Email Verification',
  '7years': 'Last 7 Years',
  '10years': 'Last 10 Years',
  '15years': 'Last 15 Years',
  '99years': 'Last 99 Years',
  '5panel': '5 Panel',
  '5panelhair': '5 Panel Hair',
  '5panelinstant': '5 Panel Instant',
  '10panel': '10 Panel',
  '10panelinstant': '10 Panel Instant',
  dot: 'DOT',
  standard: 'Standard',
  instant: 'Instant',
  1: '1M',
};

const CONTACT_FORM_URL =
  'https://help.checkr.com/hc/en-us/requests/new?ticket_form_id=360004180714';

const SIGNUP_INPUT_FIELDS = {
  firstName: 'First name',
  lastName: 'Last name',
  company: 'Business name',
  email: 'Business email',
  phone: 'Phone number',
  yearlyBgcVolume: 'Yearly background check estimate',
};

const CREDIT_CARD_INPUT_FIELDS = [
  'name',
  'number',
  'zipcode',
  'expiration',
  'cvc',
];

const BANK_ACCOUNT_INPUT_FIELDS = [
  'accountHolderType',
  'name',
  'routingNumber',
  'accountNumber',
  'confirmedAccountNumber',
];

const SIGNUP_VOLUME_OPTIONS = {
  5: '1-10',
  20: '11-25',
  35: '26-50',
  150: '51-299',
  750: '300-1000',
  1001: '1000+',
};

const PHASE_1B = '1b';

export {
  BANK_ACCOUNT_INPUT_FIELDS,
  CONTACT_FORM_URL,
  CREDIT_CARD_INPUT_FIELDS,
  INDUSTRY_OPTIONS,
  LEARN_MORE_TILE_CONTENT,
  SCREENING_NAMES,
  BASE_PACKAGE_NAMES,
  SCREENINGS_WITH_SUBTYPES,
  STATE_OPTIONS,
  SCREENING_SUBTYPE_NAME_MAP,
  SCREENING_TOOLTIPS,
  SIGNUP_INPUT_FIELDS,
  SIGNUP_VOLUME_OPTIONS,
  PHASE_1B,
};
