import React from 'react';
import { INVITES } from '../../../../constants/identifiers';

const PackageErrorMsg = () => {
  return (
    <small
      data-testid={INVITES.ERROR.package}
      className='form-control-feedback has-danger'
    >
      Package is required
    </small>
  );
};

export default PackageErrorMsg;
