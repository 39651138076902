import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import { PROGRESS_BAR } from '../constants/identifiers';

const Checkmark = () => (
  <CheckIcon
    style={{
      fill: '#FFFFFF',
      stroke: '#FFFFFF',
      strokeWidth: '2',
      fontSize: 'small',
    }}
    data-testid={PROGRESS_BAR.stepCheck}
  />
);

const ProgressBar = ({ currentStep, stepItems = [], style = {} }) => {
  const primaryColor =
    document.documentElement.style.getPropertyValue('--primary-color');
  return (
    <div
      style={{ ...style }}
      className={`progress-bar-container ${
        primaryColor === '#043F93' ? 'default' : ''
      }`}
    >
      <div className='progress-bar'>
        {stepItems.map((text, index) => (
          <div
            className='progress-bar__step'
            style={index + 1 > currentStep ? { opacity: '50%' } : {}}
            key={index}
            data-testid={
              index + 1 > currentStep
                ? PROGRESS_BAR.inactive
                : PROGRESS_BAR.active
            }
          >
            <div className='progress-bar__dot'>
              {index + 1 >= currentStep ? (
                <div data-testid={PROGRESS_BAR.stepNumber}>{index + 1}</div>
              ) : (
                <Checkmark />
              )}
            </div>
            <div
              className='progress-bar__text'
              data-testid={PROGRESS_BAR.stepText}
            >
              {text}
            </div>
            {index !== 0 && <div className='progress-bar__line' />}
          </div>
        ))}
      </div>
    </div>
  );
};

export const PartnerSignupProgressBar = props => {
  return (
    <ProgressBar {...props} stepItems={['add details', 'enter payment info']} />
  );
};

export const NewPartnerSignupProgressBar = props => {
  const { type } = props;
  const steps = {
    variant: ['create account', 'customize package', 'add payment info'],
    default: ['create account', 'add payment info'],
  };

  return <ProgressBar {...props} stepItems={steps[type]} />;
};

export default ProgressBar;

ProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  stepItems: PropTypes.array.isRequired,
  style: PropTypes.object,
};
