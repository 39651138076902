import React from 'react';
import styled from 'styled-components';
import { PARTNER_PACKAGES } from '../../../constants/identifiers';

const StyledSpan = styled.span`
  text-decoration: underline;
  padding: 0 0 20px 16px;
  margin: auto 0 0 0;
`;

type Props = {
  show?: boolean;
  onClick: (slug: string) => void;
  style?: React.CSSProperties;
  slug: string;
};

const MoreOptions = ({ show = true, onClick, style, slug }: Props) => {
  if (!show) return null;
  return (
    <StyledSpan
      style={style}
      data-testid={`${PARTNER_PACKAGES.moreOptions}-${slug}`}
      onClick={() => onClick(slug)}
    >
      More options
    </StyledSpan>
  );
};

export default MoreOptions;
