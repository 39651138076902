import React from 'react';

import { Icon, ScreeningSectionTitle } from '../shared/ListItemElements';
import stethoscope from '../shared/svg/stethoscope.svg';
import ScreeningsSectionAccordion from '../shared/ScreeningsSectionAccordion';
import FACIS1 from './FACISScreenings/FACIS1';
import FACIS3 from './FACISScreenings/FACIS3';
import { ADD_SCREENINGS } from '../../../../../constants/identifiers';

const Screenings = () => (
  <ul>
    <FACIS1 />
    <FACIS3 />
  </ul>
);

const FACIS = () => {
  return (
    <ScreeningsSectionAccordion
      IconComponent={<Icon src={stethoscope} className='screening-icon' />}
      TitleComponent={
        <ScreeningSectionTitle>
          Healthcare Sanctions (FACIS)
        </ScreeningSectionTitle>
      }
      ScreeningsComponent={<Screenings />}
      dataTestId={ADD_SCREENINGS.domesticFacisScreenings}
    />
  );
};

export default FACIS;
