/* eslint-disable camelcase */
import React, { useCallback } from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';
import QuantityDropdown from '../../shared/QuantityDropdown';
import { getProfessionalReferenceVerificationPrice } from '../../../PackageUtils/PackageUtils';

const ProfessionalReferenceVerification = () => {
  const {
    onAddClick,
    onRemoveClick,
    disabledScreenings,
    addedScreenings,
    additionalProperties,
    screeningPrices,
  } = useAddScreeningsContext();

  const screeningInfo = 'Contacts references known to the candidate';

  const advantages = ['Vouches for the character of a candidate'];

  const considerations = [
    'References may not respond to the information request or reveal full truths about candidate',
    'Checks personal references, not identity',
  ];

  const required_quantity =
    additionalProperties.professional_reference_verification &&
    additionalProperties.professional_reference_verification
      .required_quantity &&
    additionalProperties.professional_reference_verification
      .required_quantity[1];

  const handleDropdownChange = useCallback(
    (count: string) =>
      onAddClick('professional_reference_verification', {
        required_quantity: [true, count],
      }),
    [onAddClick],
  );

  const handleOnAddClick = useCallback(
    () =>
      onAddClick('professional_reference_verification', {
        required_quantity: [true, '1'],
      }),
    [onAddClick],
  );

  const price = getProfessionalReferenceVerificationPrice(screeningPrices, '1');

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>Professional Reference Verification</ScreeningTitle>
        {addedScreenings.includes('professional_reference_verification') ? (
          <QuantityDropdown
            value={required_quantity}
            onChange={handleDropdownChange}
            screeningType='professional_reference_verification'
          />
        ) : (
          <ScreeningPrice priceByType='professional_reference_verification'>
            {price}
          </ScreeningPrice>
        )}
        <AddScreeningsAction
          screeningType='professional_reference_verification'
          onAddClick={handleOnAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default ProfessionalReferenceVerification;
