import axiosRetry from 'axios-retry';
import { createClient } from '../../../services/http';

export function createPromotionCode(state, promotionCode, accountId) {
  const client = createClient(state.auth.token);
  const clientId =
    state.isCheckrDirectA || state.isGoodhireWebsite
      ? state.application.client_id
      : state.router.params.client_id;
  const request = {
    method: 'post',
    url: `/accounts/${accountId || state.auth.user.account.id}/promotion_codes`,
    data: {
      application_client_id: clientId,
      promotion_code: promotionCode,
    },
  };

  axiosRetry(client, { retries: 3, retryCondition: () => true });

  return new Promise((resolve, reject) => {
    client(request)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error?.response?.data?.error || error.message);
      });
  });
}

export function validatePromotionCode(state, promotionCode) {
  const clientId =
    state.isCheckrDirectA || state.isGoodhireWebsite
      ? state.application.client_id
      : state.router.params.client_id;

  const request = {
    method: 'post',
    url: `/accounts/${state.auth.user.account.id}/promotion_code_validations`,
    data: {
      application_client_id: clientId,
      promotion_code: promotionCode,
    },
  };

  return new Promise((resolve, reject) => {
    state
      .fetch(request)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error?.response?.data?.error || error.message);
      });
  });
}
