import Validator from 'validatorjs';
import mapKeys from 'lodash/mapKeys';
import defaults from 'lodash/defaults';
import pick from 'lodash/pick';
import PasswordStrength from '../services/PasswordStrength';
import debounce from 'lodash.debounce';
import { observable, computed, makeObservable } from 'mobx';

const MAX_DEBOUNCE_TIME = 250;

class ValidationStore {
  @observable isValid = false;
  @observable error;
  @observable loading = false;

  static messages = {};
  static messagesV4 = {};

  static attributes = {};

  constructor() {
    makeObservable(this);
  }

  static friendlyErrors(errors, keys) {
    return mapKeys(errors, function (value, key) {
      return keys[key] || key;
    });
  }

  @computed get rules() {
    return {};
  }

  friendlyErrors(errors) {
    return this.constructor.friendlyErrors(errors, this.constructor.attributes);
  }

  storeSpecificValidationData() {
    return {};
  }

  validationData() {
    const data = this.storeSpecificValidationData();

    mapKeys(this.rules, (v, k) => {
      data[k] = this[k];
    });
    return data;
  }

  validationDataV4() {
    const data = this.storeSpecificValidationData();

    mapKeys(this.rulesV4, (v, k) => {
      data[k] = this[k];
    });
    return data;
  }

  validateOnEvent() {
    try {
      this.validate();
    } catch (errors) {
      this.error = errors;
      return errors;
    }
  }

  validateOnEventV4() {
    try {
      this.validateV4();
    } catch (errors) {
      this.error = errors;
      return errors;
    }
  }

  checkValidity = () => {
    const validator = new Validator(
      this.validationData(),
      this.rules,
      this.constructor.messages,
    );
    validator.setAttributeNames(this.constructor.attributes);

    this.isValid = validator.passes();
  };

  checkValidityV4 = () => {
    const validator = new Validator(
      this.validationDataV4(),
      this.rulesV4,
      this.constructor.messagesV4,
    );
    validator.setAttributeNames(this.constructor.attributes);

    this.isValid = validator.passes();
  };

  debounceValidation = debounce(this.validateOnEvent, MAX_DEBOUNCE_TIME);
  debounceValidity = debounce(this.checkValidity, MAX_DEBOUNCE_TIME);
  debounceValidationV4 = debounce(this.validateOnEventV4, MAX_DEBOUNCE_TIME);
  debounceValidityV4 = debounce(this.checkValidityV4, MAX_DEBOUNCE_TIME);

  validate = () => {
    const validator = new Validator(
      this.validationData(),
      this.rules,
      this.constructor.messages,
    );
    validator.setAttributeNames(this.constructor.attributes);
    if (validator.fails()) {
      const errors = validator.errors.all();
      throw this.friendlyErrors(errors);
    } else {
      this.error = null;
    }
    return validator;
  };

  validateV4 = () => {
    const validator = new Validator(
      this.validationDataV4(),
      this.rulesV4,
      this.constructor.messagesV4,
    );
    validator.setAttributeNames(this.constructor.attributes);
    if (validator.fails()) {
      const errors = validator.errors.all();
      throw this.friendlyErrors(errors);
    } else {
      this.error = null;
    }
    return validator;
  };

  assignParams() {
    const state = this._state || this.state;
    const query = Object.fromEntries(
      new URLSearchParams(state.router.location.search),
    );

    this.checkValidity();

    defaults(this, pick(query, this.constructor.assignableFields));
  }
}

Validator.registerImplicit(
  'compliance_city',
  function () {
    return (
      this.validator.input.availableComplianceCitiesForSelectedState.length ===
        0 ||
      this.validator.input.availableComplianceCitiesForSelectedState.includes(
        this.validator.input.complianceCity,
      )
    );
  },
  'City must be selected',
);

Validator.register(
  'password_confirmation',
  function () {
    return (
      this.validator.input.password &&
      this.validator.input.password === this.validator.input.confirmedPassword
    );
  },
  'Re-entered password is different from the original password',
);

Validator.register(
  'password_strength',
  function (value) {
    return PasswordStrength.isAcceptable(value);
  },
  'Password is not strong enough',
);

Validator.register(
  'account_number_confirmation',
  function () {
    return (
      this.validator.input.accountNumber &&
      this.validator.input.accountNumber ===
        this.validator.input.confirmedAccountNumber
    );
  },
  'Re-entered account number is different from the original account number',
);

export default ValidationStore;
