/* eslint-disable camelcase */
import React from 'react';
import { useParams } from 'react-router-dom';
import { defaultContent, customConfig } from './customElementConfig';

const CustomElement = ({ elementKey }) => {
  const { client_id } = useParams();
  const partnerContent =
    customConfig.find(content => content.clientIds.includes(client_id)) || {};
  const node = partnerContent[elementKey] || defaultContent[elementKey];
  return <>{node}</>;
};

export default CustomElement;
