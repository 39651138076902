import * as Form from '../../form';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
export default class TestPaymentForm extends Component {
  render() {
    const { form } = this.props;

    return (
      <Form.Form form={form}>
        <br />
        <div className='text-center'>
          <p>Test applications don&apos;t require payment information.</p>
        </div>
        <br />

        <Form.Submit text='Continue' loading={form.loading} />
      </Form.Form>
    );
  }
}

TestPaymentForm.propTypes = {
  form: PropTypes.object.isRequired,
};
