import React from 'react';
import styled from 'styled-components';

const BoldFont = styled.span`
  font-family: National2Bold !important;
`;

const PackageSubtext = ({ context }) => {
  return <span dangerouslySetInnerHTML={{ __html: context }} />;
};

export default PackageSubtext;
