/* eslint-disable camelcase */
import { makeObservable, observable } from 'mobx';
import { createClient } from '../services/http';

export default class ApplicationStore {
  @observable utm_campaign;
  @observable utm_medium;
  @observable utm_content;
  @observable utm_source;
  @observable utm_term;

  constructor(state) {
    this.state = state;
    makeObservable(this);
  }

  setUtmsFromSearch = search => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('utm_campaign'))
      this.utm_campaign = searchParams.get('utm_campaign');
    if (searchParams.has('utm_medium'))
      this.utm_medium = searchParams.get('utm_medium');
    if (searchParams.has('utm_content'))
      this.utm_content = searchParams.get('utm_content');
    if (searchParams.has('utm_source'))
      this.utm_source = searchParams.get('utm_source');
    if (searchParams.has('utm_term'))
      this.utm_term = searchParams.get('utm_term');
  };

  track = async id => {
    const accountId = id || this.state.auth?.user?.account?.id;
    const data = {
      utm_campaign: this.utm_campaign,
      utm_medium: this.utm_medium,
      utm_content: this.utm_content,
      utm_source: this.utm_source,
      utm_term: this.utm_term,
    };

    try {
      const http = createClient(this.state.auth.token);
      return await http.patch(`/accounts/${accountId}/oauth_progress`, data);
    } catch (e) {
      console.error(e);
    }
  };
}
