import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const FederalCriminalSearch = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo =
    'A more comprehensive screening for candidates who have lived outside of the US';

  const advantages = [
    'For candidates who will perform work inside the US, but have address history outside the US',
    'Helps maintain compliance, mitigate risk, and make informed hiring decisions',
  ];

  const considerations = [
    'Longer turnaround compared to US screenings',
    'May require candidate to provide additional documents',
  ];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>Out-of-Country Criminal History</ScreeningTitle>
        <ScreeningPrice
          priceByType='international_criminal_search_v2'
          priceVaries
        />
        <AddScreeningsAction
          screeningType='international_criminal_search_v2'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default FederalCriminalSearch;
