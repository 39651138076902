import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import MaskedInput from 'react-text-mask';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, TextField } from '@mui/material/';
import {
  customCardPaymentErrorMsgs,
  fallbackCardPaymentErrorMsg,
} from './data/cardPaymentErrors';
import { PAYMENT } from '../../../constants/identifiers';

@observer
export default class CheckrDirectCreditCardPaymentForm extends Component {
  render() {
    const { form } = this.props;

    return (
      <div className='form-credit-card'>
        {!!form.paymentError && !!form.error && (
          <div className='payment-error'>
            <ErrorIcon fontSize='12px' />
            <span
              className='payment-error-msg'
              data-testid={PAYMENT.ERROR.cardPaymentError}
            >
              {customCardPaymentErrorMsgs[form.error?.error] ||
                fallbackCardPaymentErrorMsg}
            </span>
          </div>
        )}
        <Box>
          <TextField
            style={{ width: '100%', margin: '6px' }}
            type='text'
            label='Card holder name'
            variant='outlined'
            defaultValue={form?.name || ''}
            inputProps={{
              maxLength: 35,
              style: { backgroundColor: 'white' },
              'data-testid': PAYMENT.INPUT.ccName,
            }}
            error={Boolean(form.error?.name)}
            helperText={form.error?.name}
            FormHelperTextProps={{
              style: { backgroundColor: 'transparent', paddingTop: '4px' },
              'data-testid': PAYMENT.ERROR.ccNameFieldError,
            }}
            onChange={e => {
              form.handleChange('name', e.target.value);
            }}
          />
          <MaskedInput
            mask={[
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            onChange={e => {
              form.handleChange(
                'number',
                Number(e.target.value.replace(/\s|_/g, '')),
              );
            }}
            render={(innerRef, props) => (
              <TextField
                inputRef={innerRef}
                inputProps={{
                  style: { backgroundColor: 'white' },
                  'data-testid': PAYMENT.INPUT.ccNumber,
                }}
                style={{ width: '100%', margin: '6px' }}
                backgroundcolor='white'
                type='text'
                label='Card number'
                variant='outlined'
                error={Boolean(form.error?.number)}
                helperText={form.error?.number}
                FormHelperTextProps={{
                  style: { backgroundColor: 'transparent', paddingTop: '4px' },
                  'data-testid': PAYMENT.ERROR.ccNumberFieldError,
                }}
                {...props}
              />
            )}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '6px',
            }}
          >
            <TextField
              style={{ width: '49%' }}
              type='text'
              label='ZIP code'
              variant='outlined'
              inputProps={{
                maxLength: 5,
                style: { backgroundColor: 'white' },
                'data-testid': PAYMENT.INPUT.ccZip,
              }}
              error={Boolean(form.error?.zipcode)}
              helperText={form.error?.zipcode}
              FormHelperTextProps={{
                style: { backgroundColor: 'transparent', paddingTop: '4px' },
                'data-testid': PAYMENT.ERROR.ccZipFieldError,
              }}
              onChange={e => {
                form.handleChange('zipcode', e.target.value);
              }}
            />

            <MaskedInput
              mask={[/\d/, /\d/, '/', /\d/, /\d/]}
              onChange={e => {
                const [month, year] = e.target.value
                  .replace(/_/g, '')
                  .split('/');
                form.handleChange('expiration', {
                  month: month?.length === 2 ? Number(month) : null,
                  year: year?.length === 2 ? Number(`20${year}`) : null,
                });
              }}
              render={(innerRef, props) => (
                <TextField
                  inputRef={innerRef}
                  style={{ width: '49%' }}
                  type='text'
                  label='MM/YY'
                  variant='outlined'
                  inputProps={{
                    style: { backgroundColor: 'white' },
                    'data-testid': PAYMENT.INPUT.ccExpiration,
                  }}
                  error={Boolean(form.error?.expiration)}
                  helperText={form.error?.expiration}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: 'transparent',
                      paddingTop: '4px',
                    },
                    'data-testid': PAYMENT.ERROR.ccExpirationFieldError,
                  }}
                  {...props}
                />
              )}
            />
          </Box>
          <MaskedInput
            mask={[/\d/, /\d/, /\d/, /\d/]}
            guide={false}
            onChange={e => {
              form.handleChange('cvc', e.target.value);
            }}
            render={(ref, props) => (
              <TextField
                style={{ width: '100%', margin: '6px' }}
                type='password'
                label='Security code'
                variant='outlined'
                inputProps={{
                  'data-testid': PAYMENT.INPUT.ccCvc,
                  style: { backgroundColor: 'white' },
                }}
                inputRef={ref}
                error={Boolean(form.error?.cvc)}
                helperText={form.error?.cvc}
                FormHelperTextProps={{
                  style: { backgroundColor: 'transparent', paddingTop: '4px' },
                  'data-testid': PAYMENT.ERROR.ccCvcFieldError,
                }}
                {...props}
              />
            )}
          />
        </Box>
      </div>
    );
  }
}

CheckrDirectCreditCardPaymentForm.propTypes = {
  form: PropTypes.object.isRequired,
};
