import * as Form from '../../form';
import PromotionCodeInput from './PromotionCodeInput';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { PAYMENT } from '../../../constants/identifiers';

@observer
export default class BankAccountPaymentForm extends Component {
  render() {
    const { store, form } = this.props;

    return (
      <Form.Form form={form} className='form-bank-account'>
        <Form.Error error={form.error} store={store} />
        <div data-testid={PAYMENT.MISC.formBankAccount} className='row'>
          <div className='col-12'>
            <div className='form-group'>
              <Form.Label text='Account Type' />
              <Form.Radio
                form={form}
                name='accountHolderType'
                value='company'
                dataTestId={PAYMENT.INPUT.corporate}
                labelSubText='Corporate'
              />
              <Form.Radio
                form={form}
                name='accountHolderType'
                value='individual'
                dataTestId={PAYMENT.INPUT.personal}
                labelSubText='Personal'
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <Form.Input
              form={form}
              label='Account Holder'
              errorDataTestId={PAYMENT.ERROR.bankNameFieldError}
              dataTestId={PAYMENT.INPUT.bankName}
              name='name'
              placeholder='John Doe'
              autoFocus
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Input
              form={form}
              label='Routing Number'
              errorDataTestId={PAYMENT.ERROR.routingNumberFieldError}
              dataTestId={PAYMENT.INPUT.routingNumber}
              name='routingNumber'
              placeholder='123456789'
            />
          </div>
          <div className='col-md-6'>
            <Form.Input
              form={form}
              label='Account Number'
              errorDataTestId={PAYMENT.ERROR.accountFieldError}
              dataTestId={PAYMENT.INPUT.account}
              placeholder='000123456789'
              name='accountNumber'
            />
          </div>
        </div>
        {store.promoCodeActive && (
          <PromotionCodeInput form={form} dataTestId='promo-code' />
        )}
        <br />
        <Form.Submit
          text='Continue'
          dataTestId={PAYMENT.MISC.nextBtn}
          loading={form.loading}
        />
      </Form.Form>
    );
  }
}

BankAccountPaymentForm.propTypes = {
  form: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};
