/* eslint-disable camelcase */
import { makeObservable, observable } from 'mobx';
import { datadogRum } from '@datadog/browser-rum';

export default class PerformanceObserverStore {
  @observable startLoadTime;

  constructor(state) {
    this.state = state;
    makeObservable(this);
  }

  clearStartLoadTime = () => {
    this.startLoadTime = null;
  };

  setStartLoadTime = () => {
    this.startLoadTime = Date.now();
  };

  setEndLoadTime = ({ startPLTFromMarketing = null } = {}) => {
    if (startPLTFromMarketing || this.startLoadTime) {
      const customEndTime = Date.now();

      // starting time can be passed from marketing landing page or measured in-app
      const startTime = startPLTFromMarketing || this.startLoadTime;

      const loadTime = customEndTime - startTime;

      datadogRum.addTiming('signup_page_load_time', loadTime);

      this.clearStartLoadTime();
    } else {
      // initial load uses the default timing issued by RUM
      datadogRum.addTiming('signup_page_load_time');
    }
  };
}
