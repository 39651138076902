import React, { useContext } from 'react';

/**
 * This is an attempt to migrate away from mobx by creating a provider/context to inject state into
 * functional components. This is basically what the new version of mobx-react-lite does anyway
 */
export const ApplicationContext = React.createContext(null);

export const ApplicationProvider = ApplicationContext.Provider;

export function useApplication() {
  const application = useContext(ApplicationContext);

  return application;
}
