import React, { Component } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';
import * as Form from '../../form';

import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../../services/analytics';
import colors from '../../../styles/_colors.scss';
import timedProcess from '../../../images/timed-process.png';
import SignupWrapper from './SignupWrapper';
import { SIGNUP } from '../../../constants/identifiers';
import { INDUSTRY_OPTIONS } from '../../helpers/Constants';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  .connect-page-content {
    width: 800px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-self: center;
    font-size: 18px;
    font-family: National2Regular;
  }

  h1 {
    font-weight: 600;
    font-family: ArgentCFRegular;
    color: ${colors.navy4};
  }

  .visit-dashboard-text {
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }

  span {
    font-weight: 700;
    font-family: National2Bold;
  }

  button {
    margin: 40px auto 23px;
  }
`;

const LoadingSpinner = () => (
  <span>
    <i className='fa fa-spinner fa-spin fa-fw' /> Loading...
  </span>
);

@inject('store')
@observer
export default class Connect extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.form = this.store.connect;
    this._handleRedirect = this.handleRedirect.bind(this);
  }

  componentDidMount() {
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.SUBMIT_PAGE_VIEWED);
  }

  trackIndustrySelected() {
    const industry = this.store.signupV3?.industry;
    const industryText =
      INDUSTRY_OPTIONS.find(o => o.value === parseInt(industry))?.text ||
      'Not Selected';

    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.ACCOUNT_SUBMITTED, {
      [ANALYTICS_PROPERTIES.INDUSTRY_SELECTED_UPON_SUBMISSION]: industryText,
    });
  }

  handleRedirect(e) {
    e.preventDefault();
    this.form.submit();
    this.trackIndustrySelected();
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.ACCOUNT_SUBMITTED, {
      [ANALYTICS_PROPERTIES.BUTTON_CLICK]: 'Submit Account Clicked',
    });
  }

  render() {
    return (
      <SignupWrapper>
        {this.store.isSignup && (
          <Redirect to={this.store.getStep('submission')} />
        )}
        <Wrapper component='Wrapper' className='connect-page-wrapper'>
          <div className='connect-page-content'>
            <div>
              <img style={{ height: '180px' }} src={timedProcess} />
            </div>
            <Box
              component='h1'
              sx={{
                typography: {
                  xs: { fontSize: '28px' },
                  lg: { fontSize: '42px' },
                },
              }}
            >
              You&apos;re almost done!
            </Box>
            <Box
              component='p'
              sx={{
                color: '#0A1626',
                typography: {
                  xs: { fontSize: '16px' },
                  lg: { fontSize: '18px' },
                },
              }}
            >
              Select <span>Finish sign up</span> and we’ll get to work verifying
              your business. Then we’ll email you once we’re done. You should be
              able to place your first order within <span>1-2</span> business
              days.
              <br />
              <br />
              If your business is tax exempt, send a copy of your valid sales
              tax exemption certificate to{' '}
              <span>
                <u>tax@checkr.com</u>
              </span>
              . Sales tax will be included on all orders until documentation is
              confirmed.
            </Box>
            <Form.Error error={this.form.error} store={this.store} />
            <Box
              component='button'
              className='btn btn-success btn-signup-v4'
              disabled={this.form.loading}
              data-testid={SIGNUP.MISC.connect}
              onClick={this._handleRedirect}
              sx={{
                width: {
                  lg: '175px',
                },
                mt: '20px',
              }}
            >
              {this.form.loading ? <LoadingSpinner /> : 'Finish sign up'}
            </Box>
            <Box
              component='p'
              sx={{
                color: '#0A1626',
                typography: {
                  xs: { fontSize: '16px' },
                  lg: { fontSize: '18px' },
                },
              }}
              className='visit-dashboard-text'
            >
              You can also visit your <span>Checkr dashboard</span> at any time
              to build and customize more packages.
            </Box>
          </div>
        </Wrapper>
      </SignupWrapper>
    );
  }
}

Connect.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
