export const PAYMENT = {
  INPUT: {
    account: 'bank-account-number-input',
    accountConfirmed: 'bank-confirmed-account-number-input',
    bankName: 'bank-name-input',
    ccCvc: 'cc-cvc-input',
    ccExpiration: 'cc-expiration-input',
    ccName: 'cc-name-input',
    ccNumber: 'cc-number-input',
    ccZip: 'cc-zipcode-input',
    corporate: 'bank-account-holder-type-company-input',
    personal: 'bank-account-holder-type-individual-input',
    routingNumber: 'bank-routing-number-input',
  },

  ERROR: {
    accountConfirmedFieldError: 'field-error-bank-confirmed-account-number',
    accountFieldError: 'field-error-bank-account-number',
    bankNameFieldError: 'field-error-bank-name',
    bankPaymentError: 'bank-payment-error-msg',
    cardPaymentError: 'card-payment-error-msg',
    ccCvcFieldError: 'field-error-cc-cvc',
    ccExpirationFieldError: 'field-error-cc-expiration',
    ccNameFieldError: 'field-error-cc-name',
    ccNumberFieldError: 'field-error-cc-number',
    ccZipFieldError: 'field-error-cc-zipcode',
    paymentError: 'payment-error',
    routingNumberFieldError: 'field-error-bank-routing-number',
  },

  MISC: {
    bank: 'testid-nav-link-bank',
    ccCvcVisibilityButton: 'cc-cvc-visibility-button',
    credit: 'testid-nav-link-cc',
    formBankAccount: 'form-bank-account',
    formCreditCard: 'form-credit-card',
    nextBtn: 'next',
    headerTooltip: 'payment-header-tooltip',
  },
};

export const SIGNUP = {
  INPUT: {
    city: 'city-input',
    company: 'company-input',
    complianceCity: 'compliance-city',
    complianceState: 'compliance-state',
    confirmPassword: 'confirm-password-input',
    email: 'email-input',
    fullName: 'fullName-input',
    industry: 'industry-input',
    nameDba: 'nameDba-input',
    password: 'password-input',
    phone: 'phone-input',
    state: 'stateName-input',
    street: 'street-input',
    street2: 'street2-input',
    taxExempt: 'tax-exempt-input',
    taxID: 'taxId-input',
    website: 'website-input',
    zip: 'zipcode-input',
  },

  ERROR: {
    city: 'error-input-city',
    company: 'error-input-company',
    confirmPassword: 'error-input-confirm-password',
    email: 'error-input-email',
    fullName: 'error-input-fullName',
    industry: 'error-input-industry',
    nameDba: 'error-input-nameDba',
    password: 'error-input-password',
    phone: 'error-input-phone',
    state: 'error-input-stateName',
    street: 'error-input-street',
    street2: 'error-input-street2',
    taxID: 'error-input-taxId',
    website: 'error-input-website',
    zip: 'error-input-zipcode',
  },

  MISC: {
    checkrHeader: 'checkr-header',
    checkrSubheader: 'checkr-subheader',
    goodhireHeader: 'goodhire-header',
    goodhireSubheader: 'goodhire-subheader',
    partnerHeader: 'testid-partner-header',
    partnerLogoHeader: 'partner-logo-header',
    passwordRequirements: 'password-requirements',
    passwordStrengthHelper: 'password-strength-helper',
    purpose: 'purpose',
    signUp: 'signup-link',
    submit: 'submit',
    connect: 'connect-page-submit-btn',
    headerTooltip: 'signup-header-tooltip',
  },
};

export const INVITES = {
  INPUT: {
    candidate: 'candidate-input',
    city: 'city-input',
    country: 'country-input',
    removeCandidate: 'remove-candidate-input',
    state: 'state-input',
  },

  ERROR: {
    candidate: 'error-candidate',
    invites: 'error-checkr-signup-invites',
    package: 'error-package',
    signup: 'error-signup',
    stateFieldError: 'field-error-state',
  },

  MISC: {
    allBasic: 'all_basic',
    allBasicIntl: 'all_basic_intl',
    allEssential: 'all_essential',
    allProfessional: 'all_professional',
    candidateEmailAddButton: 'add-candidate-email',
    comprehensiveCounty: 'comprehensive_county',
    educationAddOn: 'education-add-on',
    educationVerification: 'education_verification',
    educationVerificationIntl: 'education_verification_intl',
    emailsInvalidMessage: 'emails-invalid-message',
    employmentVerification: 'employment_verification',
    employmentVerificationIntl: 'employment_verification_intl',
    federalAddOn: 'federal-add-on',
    federalFeaturedAddOn: 'federal-featured-add-on',
    employmentFeaturedAddOn: 'employment-featured-add-on',
    mvrFeaturedAddOn: 'mvr-featured-add-on',
    globalWatchlist: 'global_watchlist',
    globalWatchlistIntl: 'global_watchlist_intl',
    header: 'header',
    identityDocument: 'identity_document',
    internationalCriminal: 'international_criminal',
    internationalButton: 'international-button',
    internationalRadioButton: 'international-radio-button',
    internationalText: 'international-text',
    learnMore: 'learn-more',
    loadingBlock: 'loading-block',
    maxCandidatesMessage: 'max-candidates-message',
    minimumEmailsMessage: 'minimum-emails-message',
    mvrAddOn: 'mvr-add-on',
    nationalCriminal: 'national_criminal',
    package: 'package',
    packageCard: 'package-card',
    packagePrice: 'package-price',
    sexOffender: 'sex_offender',
    ssnTrace: 'ssn_trace',
    submitButton: 'submit',
    nextButton: 'next',
    backButton: 'back',
    targetedCounty: 'targeted_county',
    usaButton: 'usa-button',
    usaRadioButton: 'usa-radio-button',
    viewAllPackagesLink: 'view-all-packages',
    doThisLater: 'do-this-later',
    doThisLaterModal: 'do-this-later-modal',
    doThisLaterModalClose: 'do-this-later-modal-closed',
    doThisLaterModalCancel: 'do-this-later-modal-cancel',
    skippedInvites: 'skipped-invites',
    SubmissionTitle: 'submission-title',
    SubmissionMessageContent: 'submission-message-content',
    SubmissionFootNote: 'submission-foot-note',
    showAllPackagesLink: 'show_all_packages_button',
    showMorePackagesLink: 'show_more_packages_link',
    skipThisStepLink: 'skip-this-step-link',
    skipThisStepModalClose: 'skip-this-step-modal-close',
    skipThisStepModalOrderBtn: 'skip-this-step-modal-order-btn',
    skipOrderFlowModal: 'skip-order-flow-modal',
    skipOrderFlowModalCancel: 'skip-order-flow-modal-cancel',
    skippedOrderFlow: 'skipped-order-flow',
    screeningAccordion1: 'screening-accordion-1',
    screeningAccordion2: 'screening-accordion-2',
    screeningAccordion3: 'screening-accordion-3',
    screeningAccordion4: 'screening-accordion-4',
    addBtn: 'add-button',
    removeBtn: 'remove-button',
  },

  ORDER_SUMMARY: {
    selectPackage: 'select-package',
    selectedPackageName: 'selected-package-name',
    selectedPackagePrice: 'selected-package-price',
    customizeWithAddOns: 'customize-with-add-ons',
    inviteCandidates: 'invite-candidates',
    inviteCandidatesDescription: 'invite-candidates-description',
    subtotalPrice: 'subtotal-price',
    itemizedPrice: 'itemized-price',
    addonName: 'addon-name',
    addonPrice: 'addon-price',
    checkMark: 'check-mark',
    stepNumber: 'step-number',
    showMore: 'show-more',
    showLess: 'show-less',
    hiddenPrice: 'hidden-price',
  },
};

export const WELCOME = {
  bizDescription: 'bizDescription',
  fieldError: 'field-error',
  input: 'input',
  signIn: 'sign-in-btn',
  signUp: 'sign-up',
  welcomeHeader: 'welcomeHeader',
  yearlyVolume: 'yearlyBgcVolume-option',
  fairChanceHeader: 'fairChanceHeader',
  fairChanceBody: 'fairChanceBody',
  packageHeader: 'packageHeader',
  packageSubtext: 'packageSubtext',
  advantageHeader: 'advantageHeader',
  advantageSubHeader: 'advantageSubHeader',
  advantageSurveyList: 'advantageSurveyList',
  fairChanceImage: 'fairChanceImage',
};

export const CSSIdentifiers = {
  errorMsg: '.error-msg',
  loadingSpinner: 'loading-spinner',
};

export const PARTNER_PACKAGES = {
  card: 'card',
  cardArea: 'card-area',
  cardContent: 'cardContent',
  cardHeader: 'cardHeader',
  intlDisclaimer: 'intl-disclaimer',
  restOfPackageGrid: 'rest-of-package-grid',
  subheadPrice: 'subheader-price',
  topPackageGrid: 'top-package-grid',
  viewAllPackagesLink: 'view-all-packages-link',
  packageRadioButton: 'pkg-radio-button',
  moreOptions: 'more-options',
  noPackagesMessage: 'no-packages-message',
  screeningTooltip: 'screening-tooltip',
  commonAddonsTooltip: 'common-addons-tooltip',
  startingAtLoadingBlock: 'starting-at-loading-block',
};

export const PARTNER_CUSTOMIZE = {
  continue: 'customize-continue',
};

export const ADD_SCREENINGS = {
  modalAdd: 'modal-add',
  modalCancel: 'modal-cancel',
  modalClose: 'close-modal',
  screeningAdd: 'screening-add',
  screeningDisabledAdd: 'screening-disabled-add',
  screeningRemove: 'screening-remove',
  domesticMvrScreenings: 'domestic-mvr-screenings',
  domesticFacisScreenings: 'domestic-facis-screenings',
  domesticCivilRecords: 'domestic-civil-records',
  domesticCriminalRecords: 'domestic-criminal-records',
  domesticVerificationScreenings: 'domestic-verification-screenings',
  internationalCriminalRecords: 'international-criminal-records',
  internationalVerificationScreenings: 'international-verification-screenings',
  addScreeningModal: 'add-screening-modal',
  screeningPrice: 'screening-price',
};

export const SCREENING_TYPES = {
  arrestSearch: 'arrest_search',
  clearinghouseSearch: 'clearinghouse_search',
  internationalCriminalSearch: 'international_criminal_search',
  childAbuseSearch: 'child_abuse_search',
  countyCivilSearch: 'county_civil_search',
  countyCriminalSearch: 'county_criminal_search',
  creditReport: 'credit_report',
  drugScreening: 'drug_screening',
  educationVerification: 'education_verification',
  employmentVerification: 'employment_verification',
  evictionSearch: 'eviction_search',
  facisSearch1: 'facis_search_1',
  facisSearch3: 'facis_search_3',
  facisSearch: 'facis_search',
  federalCivilSearch: 'federal_civil_search',
  federalCriminalSearch: 'federal_criminal_search',
  globalWatchlistSearch: 'global_watchlist_search',
  internationalAdverseMediaSearch: 'international_adverse_media_search',
  internationalCanadaBcEnhancedCriminalSearch:
    'international_canada_bc_enhanced_criminal_search',
  internationalCriminalSearchV2: 'international_criminal_search_v2',
  internationalEducationVerification: 'international_education_verification',
  internationalEmploymentVerification: 'international_employment_verification',
  internationalGlobalWatchlistSearch: 'international_global_watchlist_search',
  internationalIdentityDocumentValidation:
    'international_identity_document_validation',
  internationalMotorVehicleReport: 'international_motor_vehicle_report',
  motorVehicleReport: 'motor_vehicle_report',
  municipalCriminalSearch: 'municipal_criminal_search',
  nationalCriminalSearch: 'national_criminal_search',
  occupationalHealthScreening: 'occupational_health_screening',
  outOfCountryCriminalHistory: 'out_of_country_criminal_history',
  personalReferenceVerification: 'personal_reference_verification',
  proLicenseVerification: 'pro_license_verification',
  professionalLicenseVerification: 'professional_license_verification',
  professionalReferenceVerification: 'professional_reference_verification',
  sexOffenderSearch: 'sex_offender_search',
  ssnTrace: 'ssn_trace',
  stateCriminalSearch: 'state_criminal_search',
};

export const PROGRESS_BAR = {
  active: 'progress-bar-step-active',
  inactive: 'progress-bar-step-inactive',
  stepCheck: 'progress-bar-step-checkmark',
  stepNumber: 'progress-bar-step-number',
  stepText: 'progress-bar-step-text',
};

export const PROMO_CODES = {
  applyPromoCode: 'apply-promo-code',
  applyPromoCodeSuccessMsg: 'apply-promo-code-success-message',
  promoCodeInput: 'promotionCode-input',
  promoCodeInputFieldError: 'field-error-promotionCode',
};

export const ERRORS = {
  emailTaken: 'error-email-taken',
  formErrors: 'form-errors',
  signupError: 'signup-errors',
};

export const DYNAMIC_PROGRESS_BAR = {
  container: 'dynamic-progress-bar-container',
  active: 'dynamic-progress-bar-step-active',
  inactive: 'dynamic-progress-bar-step-inactive',
  stepCheck: 'dynamic-progress-bar-step-checkmark',
  stepNumber: 'dynamic-progress-bar-step-number',
  stepText: 'dynamic-progress-bar-step-text',
};

export const LEGACY_PROGRESS_BAR = {
  active: 'legacy-progress-bar-step-active',
  disabled: 'legacy-progress-bar-step-disabled',
  complete: 'legacy-progress-bar-step-complete',
};

export const LEGACY_PARTNER_PACKAGES = {
  packageCardList: 'package-cards-list',
  packageCard: 'package-card',
};

export const LEGACY_PARTNER_WELCOME = {
  learnMore: 'learn-more',
  welcomeSetup: 'welcome-setup',
  tileAboutCheckr: 'tile-about-checkr',
  tilePackages: 'tile-packages',
  tileIntegrations: 'tile-integrations',
  tileGettingStarted: 'tile-getting-started',
  tileHelpCenter: 'tile-help-center',
  tileFairChance: 'tile-fair-chance',
};
