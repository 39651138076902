import { useStore } from '../../../../hooks/useStore';

export function useInvitesAnalyticsTrackers() {
  const store = useStore();

  const trackSkipInvitesClicked = buttonLocation => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Explore Checkr Dashboard Selected',
      { 'Button Selected': buttonLocation },
    );
  };

  const trackSkipInvitesConfirmed = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Skip Step Page Completed',
      { 'Choice Selected': 'Explore Dashboard' },
    );
  };

  const trackSkipInvitesCancelled = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Skip Step Page Completed',
      { 'Choice Selected': 'Order Background Checks' },
    );
  };

  const trackSkipOrderBGCFlowClicked = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Skip Order BGC Flow Clicked',
      {},
    );
  };

  const trackSkipOrderBGCFlowCompleted = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Skip Order BGC Flow Completed',
      {},
    );
  };

  const trackInvitesSubmitted = (
    addOns,
    calculatedPrices,
    formValues,
    selectedRegion,
    learnMoreClicked,
  ) => {
    const numCandidates = formValues.candidates?.length || 0;

    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC Page Completed',
      {
        'Candidate Work Location Selected': selectedRegion,
        City: formValues.city,
        State: formValues.state,
        Country: formValues.country || 'US',
        'Learn More Selected': learnMoreClicked,
        'Number Of Candidate Email Entered': numCandidates,
        'Package Selected': [formValues.package.replace('checkrdirect_', '')],
        'Reach Out To Customer Support Selected': false,
        'Add-On Selected': addOns.length > 0 ? 'Yes' : 'No',
        'Add-on Screenings': addOns,
        'Package Price': calculatedPrices.basePriceFloat.toFixed(2),
        'Add-on Price': calculatedPrices.addOnsPriceFloat.toFixed(2),
        'Total Order Price': (
          calculatedPrices.totalPackagePriceFloat * numCandidates
        ).toFixed(2),
      },
    );
  };

  const trackInvitesSubmitError = () => {
    store.trackAnalyticsEvent('Self-Serve Customer Signup Error Viewed', {
      'Page Name': 'invites',
      'Error Name': 'Server Error',
    });
  };

  const trackInvitesFormError = errors => {
    store.trackAnalyticsEvent('Self-Serve Customer Signup Error Viewed', {
      'Page Name': 'invites',
      'Error Name': Object.values(errors)
        ?.map(err => err.message)
        .join(' '),
    });
  };

  const trackOrderBGCCountryToggled = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC Country Toggle',
      {},
    );
  };

  const trackSelectPackageStepViewed = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Select a Package Step Viewed',
      {},
    );
  };

  const trackCustomizeAddOnsStepViewed = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Customize Add Ons Step Viewed',
      {},
    );
  };

  const trackInviteCandidatesStepViewed = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Invite Candidates Step Viewed',
      {},
    );
  };

  const trackOrderSummaryClicked = pageClicked => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Order Summary Clicked',
      { 'Page Click': pageClicked },
    );
  };

  const trackAddOnCategoryExpanded = addOn => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Add-On Category Expanded',
      { 'Add-On Category': addOn },
    );
  };

  const trackAddOnSelected = addOn => {
    store.trackAnalyticsEvent('Self-Serve Customer Signup Add-On Selected', {
      'Add-on Screenings': addOn,
    });
  };

  const trackAddOnRemoved = addOn => {
    store.trackAnalyticsEvent('Self-Serve Customer Signup Add-On Removed', {
      'Add-on Screenings': addOn,
    });
  };

  const trackFeaturedAddOnSelected = addOn => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Featured Add-On Selected',
      {
        'Add-on Screenings': addOn,
      },
    );
  };

  const trackFeaturedAddOnRemoved = addOn => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Featured Add-On Removed',
      {
        'Add-on Screenings': addOn,
      },
    );
  };

  const trackCustomerSignupLearnMoreClicked = intent => {
    store.trackAnalyticsEvent('Self-Serve Customer Signup Learn More Clicked', {
      'Link Click': intent,
    });
  };

  const trackPackageCardSelected = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Package Card Clicked',
      { 'Self Serve Signup Version': 'Simplify Order Page' },
    );
  };

  const trackSelectAPackageCompleted = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Select a Package Step Completed',
      { 'Self Serve Signup Version': 'Simplify Order Page' },
    );
  };

  const trackSelectAPackageNextClicked = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Select a Package Step Next Page Clicked',
      { 'Self Serve Signup Version': 'Simplify Order Page' },
    );
  };

  const trackSelectAddonsNextClicked = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Customize Add Ons Step Next Page Clicked',
      { 'Self Serve Signup Version': 'Simplify Order Page' },
    );
  };

  const trackAddonsStepCompleted = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Customize Add Ons Step Completed',
      { 'Self Serve Signup Version': 'Simplify Order Page' },
    );
  };

  const trackInviteCandidatesNextClicked = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Invite Candidates Step Next Page Clicked',
      { 'Self Serve Signup Version': 'Simplify Order Page' },
    );
  };

  const trackInviteCandidateCompleted = () => {
    store.trackAnalyticsEvent(
      'Self-Serve Customer Signup Order BGC - Invite Candidates Step Completed',
      { 'Self Serve Signup Version': 'Simplify Order Page' },
    );
  };

  return {
    trackSkipInvitesClicked,
    trackSkipInvitesConfirmed,
    trackSkipInvitesCancelled,
    trackSkipOrderBGCFlowClicked,
    trackSkipOrderBGCFlowCompleted,
    trackInvitesSubmitted,
    trackInvitesSubmitError,
    trackInvitesFormError,
    trackOrderBGCCountryToggled,
    trackSelectPackageStepViewed,
    trackCustomizeAddOnsStepViewed,
    trackInviteCandidatesStepViewed,
    trackOrderSummaryClicked,
    trackAddOnCategoryExpanded,
    trackAddOnSelected,
    trackAddOnRemoved,
    trackFeaturedAddOnRemoved,
    trackFeaturedAddOnSelected,
    trackCustomerSignupLearnMoreClicked,
    trackPackageCardSelected,
    trackSelectAPackageCompleted,
    trackSelectAPackageNextClicked,
    trackSelectAddonsNextClicked,
    trackAddonsStepCompleted,
    trackInviteCandidatesNextClicked,
    trackInviteCandidateCompleted,
  };
}
