/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

//
// Used as a way to inject react router into store so we can interact with it via mobx
// Kind of a hack until this is stable: https://github.com/ReactTraining/react-router-addons-controlled
//
export default function connectRouter(WrapperComponent) {
  @inject('store')
  @observer
  class Wrapper extends Component {
    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
    };

    constructor(props, context) {
      super(props, context);
      this.context = context;
    }

    componentDidMount() {
      const { location, store } = this.props;
      store.utms.setUtmsFromSearch(location.search);
    }

    UNSAFE_componentWillMount() {
      /**
       * a hack on top of a hack. the "Router" object no longer exists in react-router, so we are creating something
       * similar that matches our uses.
       *
       * We should look into reworking how we use the router & store together. It is possible mixing the router with the
       * store was the wrong approach.
       */
      const { location, match, history } = this.props;
      this.props.store.router.updateFromContext({ location, match, history });
      this.props.store.router.updateFromProps({
        ...this.props,
        params: match.params,
        pathname: location.pathname,
        pattern: match.path,
      });
    }

    UNSAFE_componentWillUpdate() {
      const { location, match } = this.nextProps || {};
      this.props.store.router.updateFromProps({
        ...this.props,
        params: match?.params,
        pathname: location?.pathname,
        pattern: match?.path,
      });
    }

    render() {
      return <WrapperComponent {...this.props} />;
    }
  }
  Wrapper.wrappedComponent.propTypes = {
    store: PropTypes.object.isRequired,
  };
  return withRouter(Wrapper);
}
