import React, { SyntheticEvent } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { ChevronDown } from '@carbon/icons-react';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion {...props} />
))(() => ({
  borderRadius: '8px !important',
  boxShadow: '0px 4px 32px -16px rgba(26, 32, 38, 0.10)',
  border: '1px solid rgba(228, 231, 237, 1)',
  '&:hover': {
    boxShadow:
      '0px 4px 16px -2px rgba(87, 108, 130, 0.10), -4px 20px 32px -24px rgba(135, 153, 171, 0.50)',
  },
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: '0px',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  padding: '24px 24px 20px 24px',
  '@media (max-width: 599px)': {
    padding: '20px',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'baseline',
    height: '32px',
    width: '32px',
    minWidth: '32px',
    minHeight: '32px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#8793A11A',
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    gap: '20px',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
    },
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
}));

const Title = styled('div')(() => ({
  fontSize: '18px',
  fontWeight: '500',
  fontFamily: 'National2Medium',
  fontStyle: 'normal',
  lineHeight: '150%',
  color: '#334559',
}));

const SubTitle = styled('div')(() => ({
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'National2Regular',
  fontStyle: 'normal',
  lineHeight: '150%',
  color: '#576C82',
}));

const IconContainer = styled('div')(() => ({
  minHeight: '40px',
  minWidth: '40px',
  height: '40px',
  width: '40px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  border: '1px solid #C4F3F8',
  background: '#E4FAFC',
  boxShadow: '0px 4px 8px -4px rgba(13, 162, 182, 0.25)',
}));

const ChildrenContainer = styled('div')(() => ({
  padding: '0 24px 20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

type Props = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  expanded: boolean;
  onChange:
    | ((event: SyntheticEvent<Element, Event>, expanded: boolean) => void)
    | undefined;
  dataTestId: string;
  children: React.ReactNode;
};

const ScreeningAccordion = ({
  title,
  subtitle,
  icon,
  expanded,
  onChange,
  dataTestId,
  children,
}: Props) => {
  return (
    <Accordion expanded={expanded} onChange={onChange} data-testid={dataTestId}>
      <AccordionSummary
        expandIcon={
          <ChevronDown size={20} style={{ fill: 'rgba(51, 69, 89, 1)' }} />
        }
        aria-controls='panel1-content'
        id='panel1-header'
        data-testid={`${dataTestId}-${expanded ? 'expanded' : 'collapsed'}`}
      >
        <IconContainer>{icon}</IconContainer>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </div>
      </AccordionSummary>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Accordion>
  );
};

export default ScreeningAccordion;
