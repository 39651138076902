import FieldKit from 'field-kit';
import TextField from './TextField';

const DIGITS_PATTERN = /^\d{0,4}$/;

class CvcFormatter extends FieldKit.Formatter {
  isChangeValid(change, error) {
    if (DIGITS_PATTERN.test(change.proposed.text)) {
      return super.isChangeValid(change, error);
    } else {
      return false;
    }
  }
}

export default class CvcTextField extends TextField {
  static fieldName = 'cvc';

  getFormatter() {
    return new CvcFormatter();
  }
}

CvcTextField.propTypes = TextField.getBasePropTypes();

CvcTextField.defaultProps = TextField.getBaseDefaultProps();
