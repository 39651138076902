import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material/';
import styled from 'styled-components';
import SelectPackage from './OrderSummary/SelectedPackage';
import InviteCandidates from './OrderSummary/InviteCandidates';
import CustomizeWithAddOns from './OrderSummary/CustomizeWithAddOns';
import SubTotal from './OrderSummary/SubTotal';
import { INVITES } from '../../../../constants/identifiers';
import { InvitesLink } from './shared';
import { useInvitesAnalyticsTrackers } from '../hooks/useInvitesAnalyticsTrackers';
import OrderSummaryCheck from './svgs/OrderSummaryCheck';

const Frame = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-right: 16px;
  padding: 32px;
  background: white;
  box-shadow: 0px 4px 16px -2px rgba(87, 108, 130, 0.1);
  border-radius: 8px;
  overflow: hidden;
  border: 1px #e1e6eb solid;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
`;

const Title = styled.div`
  align-self: stretch;
  color: #334559;
  font-size: 22px;
  font-family: 'National2Medium';
  font-weight: 500;
  line-height: 28.6px;
  word-wrap: break-word;

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Item = styled.div`
  align-self: stretch;
  padding: 12px 16px;
  background: ${props => {
    switch (true) {
      case props.stepPosition < 0:
        return '#FFFFFF';
      case props.stepPosition === 0:
        return 'var(--aqua-100, #e8fafc)';
      case props.stepPosition > 0:
        return '#F6F6F7';
      default:
        return '#F6F6F7';
    }
  }};
  border-radius: 4px;
  border: 1px ${props => (props.stepPosition === 0 ? '#C0E9F0' : '#E4E7ED')}
    solid;
  display: inline-flex;
  align-items: center;
  gap: 16px;
  margin-bottom: ${props => (props.stepNumber === 3 ? '8px' : '')};
  cursor: ${props => (props.isClickable ? 'pointer' : 'default')};
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  background: ${props => (props.stepPosition <= 0 ? '#009BB5' : '#B2BDCC')};
  margin-top: ${props => props.isStepComponentShown && '10px'};
  align-self: ${props => (props.isStepComponentShown ? 'baseline' : 'center')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Number = styled.div`
  text-align: center;
  color: white;
  font-size: 13px;
  font-family: 'National2Regular';
  font-weight: 500;

  @media (max-width: 600px) {
    font-size: 11px;
  }
`;

const ContentDiv = styled.div`
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
`;

const OrderSummary = ({
  calculatedPrices,
  candidates,
  currentStep,
  packageAddOns,
  selectedPackage,
  setCurrentStep,
  selectedRegion,
  handleLearnMoreClicked,
}) => {
  const [showAllAddOns, setShowAllAddOns] = useState(false);
  const { trackOrderSummaryClicked } = useInvitesAnalyticsTrackers();

  const currentSelectedAddOns = Object.keys(packageAddOns).filter(
    key => packageAddOns[key] === true,
  );

  const numberOfCandidates = () =>
    candidates.filter(candidate => candidate.value !== '').length;

  const calculateSubTotal = () => {
    const packageTotal = calculatedPrices(
      selectedPackage?.price,
      selectedPackage?.slug,
    ).totalPackagePriceFloat;

    const totalCandidates = numberOfCandidates() || 1;
    const totalOrderPrice = packageTotal * totalCandidates;

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(totalOrderPrice);
  };

  const calculateTotalPackagePrice = () =>
    calculatedPrices(selectedPackage?.price, selectedPackage?.slug)
      .totalPackagePrice;

  const isPreviousStep = step => step - currentStep >= 0;

  const CircleInput = ({ step }) =>
    isPreviousStep(step) ? (
      <Number data-testid={`${INVITES.ORDER_SUMMARY.stepNumber}-${step}`}>
        {step}
      </Number>
    ) : (
      <OrderSummaryCheck
        dataTestId={`${INVITES.ORDER_SUMMARY.checkMark}-${step}`}
      />
    );

  const handleOnClick = (step, stepName, isClickable) => {
    if (isClickable) trackOrderSummaryClicked(stepName);
    if (!isPreviousStep(step)) {
      setCurrentStep(step);
    }
  };

  const StepContainer = ({
    stepNumber,
    stepComponent,
    stepName,
    dataTestId,
    isStepComponentShown,
  }) => {
    const stepPosition = stepNumber - currentStep;
    const nonUSStepNumber =
      selectedRegion === 'nonUS' && stepNumber === 3 ? 2 : stepNumber;
    return (
      <Item
        stepPosition={stepPosition}
        stepNumber={stepNumber}
        isClickable={!isPreviousStep(stepNumber)}
        onClick={() =>
          handleOnClick(stepNumber, stepName, !isPreviousStep(stepNumber))
        }
        data-testid={dataTestId}
        hidden={selectedRegion === 'nonUS' && stepNumber === 2}
      >
        <Circle
          isStepComponentShown={isStepComponentShown}
          stepPosition={stepPosition}
        >
          <CircleInput step={nonUSStepNumber} />
        </Circle>
        <ContentDiv>{stepComponent}</ContentDiv>
      </Item>
    );
  };

  return (
    <>
      <Grid
        item
        md={12}
        lg={3}
        sx={{
          width: '100%',
          padding: {
            xs: '0 20px',
            sm: '0 20px',
            md: '0 32px',
            lg: '0px',
            xl: '0px',
          },
        }}
      >
        <Frame>
          <Title>Order summary</Title>
          <Container>
            <StepContainer
              stepNumber={1}
              stepComponent={
                <SelectPackage selectedPackage={selectedPackage} />
              }
              stepName='Select package'
              dataTestId={`${INVITES.ORDER_SUMMARY.selectPackage}`}
            />
            <StepContainer
              stepNumber={2}
              stepComponent={
                <CustomizeWithAddOns
                  currentSelectedAddOns={currentSelectedAddOns}
                  currentStep={currentStep}
                  showAllAddOns={showAllAddOns}
                  setShowAllAddOns={setShowAllAddOns}
                />
              }
              stepName='Customize Add-Ons'
              dataTestId={`${INVITES.ORDER_SUMMARY.customizeWithAddOns}`}
              isStepComponentShown={currentSelectedAddOns.length > 0}
            />
            <StepContainer
              stepNumber={3}
              stepComponent={
                <InviteCandidates
                  numberOfCandidates={numberOfCandidates()}
                  currentStep={currentStep}
                />
              }
              stepName='Invite Candidates'
              dataTestId={`${INVITES.ORDER_SUMMARY.inviteCandidates}`}
              isStepComponentShown={numberOfCandidates().length > 0}
            />
            {selectedPackage && (
              <SubTotal
                calculatedSubTotal={calculateSubTotal()}
                numberOfCandidates={numberOfCandidates()}
                totalPackagePrice={calculateTotalPackagePrice()}
              />
            )}
          </Container>
        </Frame>
        {currentStep >= 2 && (
          <Typography
            color='#576C82'
            fontFamily='National2Regular'
            fontSize='12px'
            lineHeight='16px'
            fontWeight={400}
            marginTop='24px'
            marginBottom='40px'
            paddingLeft='16px'
          >
            * Prices exclude{' '}
            <InvitesLink
              data-testid={INVITES.MISC.learnMore}
              onClick={handleLearnMoreClicked}
              href='https://checkr.com/pricing/passthrough-fees'
              target='_blank'
            >
              passthrough fees.
            </InvitesLink>
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default OrderSummary;
