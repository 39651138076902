/* eslint-disable max-len */
import { ScreeningType } from './packageTypes';

export type ScreeningPayloads = {
  [key in ScreeningType]?: object;
};

export const screeningPayloadsByType: ScreeningPayloads = {
  arrest_search: {
    type: 'arrest_search',
  },
  clearinghouse_search: {
    type: 'clearinghouse_search',
  },
  international_criminal_search: {
    type: 'international_criminal_search',
  },
  county_criminal_search: {
    type: 'county_criminal_search',
    subtype: '7years',
    requires_education_history: false,
    requires_employment_history: false,
  },
  state_criminal_search: {
    type: 'state_criminal_search',
    subtype: '7years',
    requires_education_history: false,
    requires_employment_history: false,
  },
  federal_criminal_search: {
    type: 'federal_criminal_search',
    subtype: '10years',
  },
  international_adverse_media_search: {
    type: 'international_adverse_media_search',
    out_of_country_history: true,
  },
  international_criminal_search_v2: {
    type: 'international_criminal_search_v2',
    out_of_country_history: true,
  },
  international_motor_vehicle_report: {
    type: 'international_motor_vehicle_report',
    include_driving_history: false,
  },
  county_civil_search: {
    type: 'county_civil_search',
    subtype: '7years',
  },
  federal_civil_search: {
    type: 'federal_civil_search',
  },
  motor_vehicle_report: {
    type: 'motor_vehicle_report',
  },
  facis_search: {
    type: 'facis_search',
    subtype: 1,
  },
  employment_verification: {
    type: 'employment_verification',
    lookback_years: 7,
    requires_full_lookback: false,
    maximum_allowed_employers: null,
    requires_employment_history: false,
    international_upgrade: false,
    salary: false,
    manager_contact: true,
    contract_type: true,
    date_matching: 6,
    position_matching: 'strict',
    document_collection: true,
    dot_compliance: false,
  },
  education_verification: {
    type: 'education_verification',
    requires_education_history: false,
    international_upgrade: false,
    requires_completed_degrees: false,
    document_collection: true,
  },
  personal_reference_verification: {
    type: 'personal_reference_verification',
    required_quantity: [true, 1],
  },
  professional_reference_verification: {
    type: 'professional_reference_verification',
    required_quantity: [true, 1],
  },
  professional_license_verification: {
    type: 'pro_license_verification',
    input_required: [false],
    locations: [
      true,
      '["AL","AK","AZ","AR","CA","CO","CT","DE","DC","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY","United States"]',
    ],
  },
  pro_license_verification: {
    type: 'pro_license_verification',
    input_required: [false],
    locations: [
      true,
      '["AL","AK","AZ","AR","CA","CO","CT","DE","DC","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY","United States"]',
    ],
  },
  international_education_verification: {
    type: 'international_education_verification',
  },
  international_employment_verification: {
    type: 'international_employment_verification',
  },
  international_global_watchlist_search: {
    type: 'international_global_watchlist_search',
  },
  international_identity_document_validation: {
    type: 'international_identity_document_validation',
  },
  drug_screening: {
    type: 'drug_screening',
    subtype: ['DS_10_PANEL'],
    test_reason: [true, 'PRE_EMPLOYMENT'],
    externally_ordered: false,
    requires_observed_collection: false,
  },
  occupational_health_screening: {
    type: 'occupational_health_screening',
    subtype: [],
  },
  credit_report: {
    type: 'credit_report',
  },
  global_watchlist_search: {
    type: 'global_watchlist_search',
    subtype: null,
    preferences: null,
  },
};

export const screeningTypes = Object.keys(
  screeningPayloadsByType,
) as ScreeningType[];
export const ssnDependentScreeningTypes: ScreeningType[] = [
  'county_criminal_search',
  'state_criminal_search',
  'federal_criminal_search',
  'international_criminal_search_v2',
  'county_civil_search',
  'federal_civil_search',
];

export default Object.values(screeningPayloadsByType);

// these addon requests are needed to gather all the pricing data.
// from this doc: https://checkr.atlassian.net/wiki/spaces/RD/pages/1984790618/Package+Add-ons+Calculating+Prices
export const itemizedAddonRequest1 = {
  name: 'add ons 1',
  slug: 'add_ons_1',
  screenings: [
    {
      type: 'county_criminal_search',
      subtype: '7years',
      requires_education_history: false,
      requires_employment_history: false,
    },
    {
      type: 'state_criminal_search',
      subtype: '7years',
      requires_education_history: false,
      requires_employment_history: false,
    },
    {
      type: 'federal_criminal_search',
      subtype: '10years',
    },
    {
      type: 'international_criminal_search_v2',
      out_of_country_history: true,
    },
    {
      type: 'international_adverse_media_search',
      out_of_country_history: true,
    },
    {
      type: 'county_civil_search',
      subtype: '7years',
    },
    {
      type: 'federal_civil_search',
    },
    {
      type: 'personal_reference_verification',
      required_quantity: [true, 1],
    },
    {
      type: 'professional_reference_verification',
      required_quantity: [true, 1],
    },
    {
      type: 'education_verification',
      international_upgrade: false,
      requires_education_history: false,
    },
    {
      type: 'employment_verification',
      maximum_allowed_employers: 1,
      lookback_years: 7,
      position_matching: 'strict',
      date_matching: 186,
      manager_contact: false,
      salary: false,
      contract_type: false,
      international_upgrade: false,
      requires_employment_history: false,
      requires_full_lookback: false,
    },
    {
      type: 'motor_vehicle_report',
    },
    {
      type: 'facis_search',
      subtype: '1',
    },
    {
      type: 'pro_license_verification',
      input_required: [false],
      license_categories: [true, '["Accounting"]'],
      locations: [
        true,
        '["AL","AK","AZ","AR","CA","CO","CT","DE","DC","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY","United States"]',
      ],
    },
    { type: 'global_watchlist_search', subtype: null, preferences: null },
  ],
};

export const itemizedAddonRequest2 = {
  name: 'add ons 2',
  slug: 'add_ons_2',
  screenings: [
    {
      type: 'employment_verification',
      maximum_allowed_employers: 2,
      lookback_years: 7,
      position_matching: 'strict',
      date_matching: 186,
      manager_contact: true,
      salary: false,
      contract_type: true,
      international_upgrade: false,
      requires_employment_history: false,
      requires_full_lookback: false,
    },
    {
      type: 'facis_search',
      subtype: '3',
    },
    {
      type: 'personal_reference_verification',
      required_quantity: [true, 2],
    },
    {
      type: 'professional_reference_verification',
      required_quantity: [true, 2],
    },
  ],
};

export const itemizedAddonRequest3 = {
  name: 'add ons 3',
  slug: 'add_ons_3',
  screenings: [
    {
      type: 'employment_verification',
      maximum_allowed_employers: 3,
      lookback_years: 7,
      position_matching: 'strict',
      date_matching: 186,
      manager_contact: true,
      salary: false,
      contract_type: true,
      international_upgrade: false,
      requires_employment_history: false,
      requires_full_lookback: false,
    },
    {
      type: 'personal_reference_verification',
      required_quantity: [true, 3],
    },
    {
      type: 'professional_reference_verification',
      required_quantity: [true, 3],
    },
  ],
};

export const itemizedAddonRequest4 = {
  name: 'add ons 4',
  slug: 'add_ons_4',
  screenings: [
    {
      type: 'credit_report',
    },
  ],
};
