/* eslint-disable max-len */
import React from 'react';

export default function TruckIcon(props) {
  return (
    <svg
      {...props}
      width='40'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='scales--tipped'>
        <path
          id='Vector'
          d='M20 13C20 14.3261 20.5268 15.5979 21.4645 16.5355C22.4021 17.4732 23.6739 18 25 18C26.3261 18 27.5979 17.4732 28.5355 16.5355C29.4732 15.5979 30 14.3261 30 13C30 12.8447 29.9639 12.6916 29.8945 12.5527L25.8945 4.5527C25.7868 4.33736 25.6051 4.16809 25.3826 4.07598C25.1602 3.98387 24.912 3.97511 24.6836 4.0513L18.3528 6.1613C18.0021 5.71545 17.5335 5.37689 17 5.1841V2H15V5.1841C14.4264 5.38698 13.9282 5.75975 13.5717 6.25281C13.2152 6.74586 13.0173 7.3358 13.0044 7.9441L6.6836 10.0511C6.43204 10.135 6.2241 10.3153 6.1055 10.5525L2.1055 18.5525C2.03605 18.6914 1.99993 18.8447 2 19C2 20.3261 2.52678 21.5979 3.46447 22.5355C4.40215 23.4732 5.67392 24 7 24C8.32608 24 9.59785 23.4732 10.5355 22.5355C11.4732 21.5979 12 20.3261 12 19C12 18.8447 11.9639 18.6916 11.8945 18.5527L8.41 11.584L13.647 9.8384C13.9976 10.2844 14.4664 10.6229 15 10.8154V28H6V30H26V28H17V10.8159C17.5736 10.613 18.0718 10.2402 18.4283 9.74717C18.7848 9.25412 18.9827 8.66419 18.9956 8.0559L23.0256 6.7121L20.1056 12.5529C20.0362 12.6917 20 12.8448 20 13ZM7 22C6.38172 21.9979 5.77924 21.8046 5.27521 21.4465C4.77117 21.0884 4.39028 20.5831 4.1848 20H9.8148C9.60934 20.5831 9.22851 21.0883 8.72456 21.4464C8.2206 21.8044 7.61821 21.9978 7 22ZM9.3821 18H4.6179L7 13.2363L9.3821 18ZM16 9C15.8022 9 15.6089 8.94135 15.4444 8.83147C15.28 8.72159 15.1518 8.56541 15.0761 8.38268C15.0004 8.19996 14.9806 7.99889 15.0192 7.80491C15.0578 7.61093 15.153 7.43275 15.2929 7.29289C15.4327 7.15304 15.6109 7.0578 15.8049 7.01921C15.9989 6.98063 16.2 7.00043 16.3827 7.07612C16.5654 7.15181 16.7216 7.27998 16.8315 7.44443C16.9414 7.60888 17 7.80222 17 8C16.9998 8.26514 16.8943 8.51936 16.7068 8.70684C16.5194 8.89433 16.2651 8.99976 16 9ZM25 16C24.3817 15.9979 23.7793 15.8045 23.2752 15.4464C22.7712 15.0884 22.3903 14.5831 22.1848 14H27.8148C27.6093 14.5831 27.2285 15.0883 26.7246 15.4464C26.2206 15.8044 25.6182 15.9978 25 16ZM25 7.2363L27.3821 12H22.6179L25 7.2363Z'
          fill='#576C82'
        />
      </g>
    </svg>
  );
}
