import * as Form from '../../form';
import creditCardNetworksLogo from '../../../images/credit-card-networks.png';
import PromotionCodeInput from './PromotionCodeInput';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PAYMENT } from '../../../constants/identifiers';

import { observer } from 'mobx-react';

@observer
export default class CreditCardPaymentForm extends Component {
  render() {
    const { store, form } = this.props;

    return (
      <Form.Form form={form} className='form-credit-card'>
        <div data-testid={PAYMENT.MISC.formCreditCard} className='mb-3'>
          <div className='d-flex justify-content-between mb-2'>
            <div>
              <strong>Secure Card Entry</strong>
              <i className='fa fa-lock ml-2' />
            </div>
            <div>
              <img
                className='img-credit-card-networks-logo'
                src={creditCardNetworksLogo}
              />
            </div>
          </div>
          <div>This is a secure 128-bit SSL encrypted form</div>
        </div>
        <Form.Error error={form.error} store={store} />
        <div className='row'>
          <div className='col-12'>
            <Form.Input
              form={form}
              label='Card Holder'
              errorDataTestId={PAYMENT.ERROR.ccNameFieldError}
              dataTestId={PAYMENT.INPUT.ccName}
              name='name'
              placeholder='John Doe'
              field='name'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='credit-card-number-wrapper'>
              <Form.Input
                form={form}
                label='Card Number'
                errorDataTestId={PAYMENT.ERROR.ccNumberFieldError}
                dataTestId={PAYMENT.INPUT.ccNumber}
                name='number'
                placeholder='•••• •••• •••• ••••'
                field='card'
              >
                <i className='fa fa-credit-card' />
              </Form.Input>
            </div>
          </div>
          <div className='col-md-6'>
            <Form.Input
              form={form}
              label='Zip Code'
              errorDataTestId={PAYMENT.ERROR.ccZipFieldError}
              dataTestId={PAYMENT.INPUT.ccZip}
              name='zipcode'
              field='zipcode'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Input
              form={form}
              label='Expiration Date'
              errorDataTestId={PAYMENT.ERROR.ccExpirationFieldError}
              dataTestId={PAYMENT.INPUT.ccExpiration}
              name='expiration'
              placeholder='MM/YY'
              field='expiry'
            />
          </div>
          <div className='col-md-6'>
            <Form.Input
              form={form}
              label='Security Code'
              errorDataTestId={PAYMENT.ERROR.ccCvcFieldError}
              dataTestId={PAYMENT.INPUT.ccCvc}
              tooltip='The 3-digit code on the back of your card'
              name='cvc'
              field='cvc'
            />
          </div>
        </div>
        {store.promoCodeActive && <PromotionCodeInput form={form} />}
        <br />
        <Form.Submit
          text='Continue'
          dataTestId={PAYMENT.MISC.nextBtn}
          loading={form.loading}
        />
      </Form.Form>
    );
  }
}

CreditCardPaymentForm.propTypes = {
  form: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};
