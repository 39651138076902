import { omit } from 'lodash';

const SIGNUP_VALIDATION_RULES = {
  fullName: 'required',
  company: [
    'required',
    'min:2',
    'max:80',
    "regex:/^[a-zA-Z0-9!#$%&',()*+-\\/=?^_\\s`{|}~@:]*$/",
  ],
  complianceCity: 'compliance_city',
  complianceState: 'required_if:numberOfComplianceLocations,single',
  email: 'required|email',
  numberOfComplianceLocations: 'required',
  phone: ['required', 'regex:/^[a-zA-Z0-9()\\-\\s]*$/'],
  nameDba: ["regex:/^[a-zA-Z0-9!#$%&',()*+-\\/=?^_\\s`{|}~@:]*$/"],
  password: ['required', 'password_strength'],
  confirmedPassword: ['required', 'password_confirmation'],
  numEmployees: ['regex:/^[0-9()\\-\\s]*$/'],
};

const SIGNUP_V4_VALIDATION_RULES = {
  firstName: 'required',
  lastName: 'required',
  company: [
    'required',
    'min:2',
    'max:80',
    "regex:/^[a-zA-Z0-9!#$%&',()*+-\\/=?^_\\s`{|}~@:]*$/",
  ],
  email: 'required|email',
  phone: ['required', 'regex:/^[0-9]{3}-[0-9]{3}-[0-9]{4}$'],
  yearlyBgcVolume: 'required',
};

const CHECKR_DIRECT_SIGNUP_VALIDATION_RULES = omit(SIGNUP_VALIDATION_RULES, [
  'nameDba',
  'confirmedPassword',
  'phone',
  'company',
  'numEmployees',
]);

const SIGNUP_VALIDATION_MESSAGES = {
  required: ':attribute cannot be blank.',
  'required_if.complianceState': 'State is required',
  email: 'Email is invalid',
  'min.company': 'Company name must be at least 2 characters long.',
  'max.company': 'Company name must be at most 80 characters long.',
  'required.fullName': 'Name cannot be blank.',
  'required.company': 'Legal Business Name cannot be blank.',
  'required.confirmedPassword': 'Confirmed Password cannot be blank',
  'required.phone': 'Must be a phone number. 503-555-1212',
  'regex.company':
    'Company name must be made of blocks of lowercase letters and/or numbers' +
    ' separated by hyphens or underscore',
  'regex.nameDba':
    'Doing Business As must be made of blocks of lowercase letters and/or numbers separated by' +
    ' hyphens or underscore',
  'regex.phone': 'Please provide a valid phone number.',
};

const SIGNUP_V4_VALIDATION_MESSAGES = {
  required: ':attribute cannot be blank.',
  email: 'Email must be valid.',
  'required.firstName': 'First name cannot be blank',
  'required.lastName': 'Last name cannot be blank',
  'min.company': 'Company name must be at least 2 characters long.',
  'max.company': 'Company name must be at most 80 characters long.',
  'required.company': 'Legal Business Name cannot be blank.',
  'required.email': 'Business email cannot be blank',
  'required.phone': 'Must be a phone number. 503-555-1212',
  'required.yearlyBgcVolume': 'Field cannot be blank',
};

const CHECKR_DIRECT_VERIFY_VALIDATION_RULES = {
  taxId: ['required', 'regex:/^[0-9]{2}-?[0-9]{7}\\s?$/'],
  street: ['required', "regex:/^[a-zA-Z0-9!#$%&'*+-\\/=?^_\\s`{|}~]*$/"],
  zipcode: 'required',
  city: ['required', "regex:/^[a-zA-Z\\s.']*$/"],
  stateName: 'required',
  purpose: 'required',
  fullName: 'required',
  company: 'required',
};

const VERIFY_VALIDATION_RULES = {
  taxId: ['required', 'regex:/^[0-9]{2}-?[0-9]{7}\\s?$/'],
  street: ['required', "regex:/^[a-zA-Z0-9!#$%&'*+-\\/=?^_\\s`{|}~]*$/"],
  street2: ["regex:/^[a-zA-Z0-9!#$%&'*+-\\/=?^_\\s`{|}~]*$/"],
  zipcode: ['required', 'regex:/^[0-9]{5}$/'],
  city: ['required', "regex:/^[a-zA-Z\\s.']*$/"],
  stateName: 'required',
  purpose: 'required',
};

const VERIFY_VALIDATION_MESSAGES = {
  required: ':attribute cannot be blank.',
  regex: ':attribute has invalid characters',
  'required.email': 'Business email cannot be blank',
  'regex.email': 'Email is invalid',
  'required.street': 'Street cannot be blank',
  'regex.street': 'Street 1 is invalid',
  'regex.street2': 'Street 2 is invalid',
  'required.city': 'City cannot be blank',
  'regex.city': 'City is invalid',
  'required.zipcode': 'Zipcode cannot be blank',
  'regex.zipcode': 'Zipcode is invalid',
  'required.stateName': 'State cannot be blank',
  'required.taxId': 'Tax ID / EIN cannot be blank',
  'regex.taxId': 'Tax ID / EIN should be formatted XX-XXXXXXX',
  'required.purpose': 'We need to confirm the purpose for background checks',
  'required.fullName': 'Full name cannot be blank',
  'required.company': 'Business name cannot be blank',
};

export {
  CHECKR_DIRECT_SIGNUP_VALIDATION_RULES,
  SIGNUP_VALIDATION_RULES,
  SIGNUP_VALIDATION_MESSAGES,
  SIGNUP_V4_VALIDATION_RULES,
  SIGNUP_V4_VALIDATION_MESSAGES,
  CHECKR_DIRECT_VERIFY_VALIDATION_RULES,
  VERIFY_VALIDATION_RULES,
  VERIFY_VALIDATION_MESSAGES,
};
