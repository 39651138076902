/* eslint-disable camelcase */
import { ScreeningType } from './PackageUtils/packageTypes';
import { cloneDeep } from 'lodash';

export enum actionTypes {
  SET_BASE_PACKAGES = 'SET_BASE_PACKAGES',
  ADD_SCREENING = 'ADD_SCREENING',
  REMOVE_SCREENING = 'REMOVE_SCREENING',
  SET_SELECTED_PACKAGE = 'SET_SELECTED_PACKAGE',
  ADD_PENDING_SCREENING = 'ADD_PENDING_SCREENING',
  REMOVE_PENDING_SCREENING = 'REMOVE_PENDING_SCREENING',
  CONFIRM_PENDING_ADD_ONS = 'CONFIRM_PENDING_ADD_ONS',
  CLEAR_PENDING_ADD_ONS = 'CLEAR_PENDING_ADD_ONS',
  INIT_PENDING_ADD_ONS = 'INIT_PENDING_ADD_ONS',
  RESET_EDITED_PACKAGES = 'RESET_EDITED_PACKAGES',
}

export type Package = {
  name: string;
  screenings: Array<{
    type: string;
    subtype: null | string;
    preferences?: null | object;
  }>;
  slug: string;
  international_only: boolean;
  id?: string;
  price?: number;
  drug_screening_price?: number | null;
  [key: string]: unknown;
};

type SetBasePackageAction = {
  type: actionTypes.SET_BASE_PACKAGES;
  payload: Package[];
};

type AddScreeningAction = {
  type: actionTypes.ADD_SCREENING;
  payload: {
    package_slug: string;
    screening: ScreeningType;
    additionalProperties?: object;
  };
};

type AddPendingScreeningAction = {
  type: actionTypes.ADD_PENDING_SCREENING;
  payload: {
    package_slug: string;
    screening: ScreeningType;
    additionalProperties?: object;
  };
};

type RemovePendingScreeningAction = {
  type: actionTypes.REMOVE_PENDING_SCREENING;
  payload: {
    package_slug: string;
    screening: string;
  };
};

type ConfirmPendingAddOnsAction = {
  type: actionTypes.CONFIRM_PENDING_ADD_ONS;
};

type ClearPendingAddOnsAction = {
  type: actionTypes.CLEAR_PENDING_ADD_ONS;
};

type InitPendingAddOnsAction = {
  type: actionTypes.INIT_PENDING_ADD_ONS;
};

type ResetEditedPackagesAction = {
  type: actionTypes.RESET_EDITED_PACKAGES;
};

type RemoveScreeningAction = {
  type: actionTypes.REMOVE_SCREENING;
  payload: {
    package_slug: string;
    screening: string;
  };
};

type SetSelectedPackageAction = {
  type: actionTypes.SET_SELECTED_PACKAGE;
  payload: string;
};

type EditedScreeningsType = {
  [key: string]: {
    addedScreeningTypes: ScreeningType[];
    additionalProperties: { [key: string]: object };
  };
};

export type BasePackagesType = {
  [key: string]: Package;
};

export type State = {
  editedPackages: EditedScreeningsType;
  basePackages: BasePackagesType;
  selectedPackage: string;
  pendingAddOns: EditedScreeningsType;
};

export type Actions =
  | SetBasePackageAction
  | AddScreeningAction
  | RemoveScreeningAction
  | SetSelectedPackageAction
  | AddPendingScreeningAction
  | RemovePendingScreeningAction
  | ConfirmPendingAddOnsAction
  | ClearPendingAddOnsAction
  | InitPendingAddOnsAction
  | ResetEditedPackagesAction;

export const phase1BInitialState: State = {
  editedPackages: {},
  basePackages: {},
  selectedPackage: '',
  pendingAddOns: {},
};

export const signUpInitialState: State = {
  editedPackages: {},
  basePackages: {
    basic: {
      name: 'Basic Plus Criminal',
      slug: 'checkrdirect_basic_plus_criminal',
      screenings: [
        { type: 'national_criminal_search', subtype: 'standard' },
        { type: 'sex_offender_search', subtype: null },
        { type: 'ssn_trace', subtype: null },
        { type: 'global_watchlist_search', subtype: null },
      ],
      international_only: false,
    },
    essential: {
      name: 'Essential Criminal',
      slug: 'checkrdirect_essential_criminal',
      screenings: [
        {
          type: 'county_criminal_search',
          subtype: 'standard',
          preferences: {
            requires_education_history: false,
            requires_employment_history: false,
          },
        },
        { type: 'national_criminal_search', subtype: 'standard' },
        { type: 'sex_offender_search', subtype: null },
        { type: 'ssn_trace', subtype: null },
        { type: 'global_watchlist_search', subtype: null },
      ],
      international_only: false,
    },
    professional: {
      name: 'Professional Criminal',
      slug: 'checkrdirect_professional_criminal',
      screenings: [
        {
          type: 'county_criminal_search',
          subtype: '7years',
          preferences: {
            requires_education_history: false,
            requires_employment_history: false,
          },
        },
        { type: 'national_criminal_search', subtype: 'standard' },
        { type: 'sex_offender_search', subtype: null },
        { type: 'ssn_trace', subtype: null },
        { type: 'global_watchlist_search', subtype: null },
        {
          type: 'education_verification',
          subtype: null,
          preferences: {
            requires_history: false,
            international_upgrade: false,
            requires_completed_degrees: false,
            document_collection: true,
          },
        },
        {
          type: 'employment_verification',
          subtype: 'current',
          preferences: {
            requires_history: false,
            maximum_allowed_employers: 1,
            maximum_lookback_years: 3,
            requires_full_lookback: false,
            position_matching: 'strict',
            manager_contact: true,
            salary: false,
            contract_type: true,
            date_matching: 186,
            international_upgrade: false,
            document_collection: true,
          },
        },
      ],
      international_only: false,
    },
    international_basic: {
      name: 'International Basic+',
      slug: 'checkrdirect_international_basic_plus',
      screenings: [
        { type: 'international_criminal_search_v2', subtype: null },
        { type: 'international_adverse_media_search', subtype: null },
        { type: 'international_global_watchlist_search', subtype: null },
      ],
      international_only: true,
    },
    international_professional: {
      name: 'International Professional',
      slug: 'checkrdirect_international_professional',
      screenings: [
        { type: 'international_criminal_search_v2', subtype: null },
        { type: 'international_adverse_media_search', subtype: null },
        { type: 'international_global_watchlist_search', subtype: null },
        { type: 'international_education_verification', subtype: null },
        { type: 'international_employment_verification', subtype: null },
      ],
      international_only: true,
    },
    international_professional_plus: {
      name: 'International Professional+',
      slug: 'checkrdirect_international_professional_plus',
      screenings: [
        { type: 'international_criminal_search_v2', subtype: null },
        { type: 'international_adverse_media_search', subtype: null },
        { type: 'international_global_watchlist_search', subtype: null },
        { type: 'international_education_verification', subtype: null },
        { type: 'international_employment_verification', subtype: null },
        { type: 'international_identity_document_validation', subtype: null },
      ],
      international_only: true,
    },
  },
  selectedPackage: '',
  pendingAddOns: {},
};

const reducePackagesArrayToObject = (packages: Package[]) => {
  return packages.reduce(
    (result, obj) => {
      result[obj.slug] = obj;
      return result;
    },
    {} as { [key: string]: Package },
  );
};

export const reducer = (state: State, action: Actions) => {
  const removeScreening = (
    statePackage: EditedScreeningsType,
    action: RemoveScreeningAction | RemovePendingScreeningAction,
    packageType: string,
  ) => {
    let updated: EditedScreeningsType = {};
    updated = { ...statePackage };

    const pkgToRemoveScreening = updated[action.payload.package_slug];
    const arrWithoutSelectedScreening =
      pkgToRemoveScreening.addedScreeningTypes.filter(
        screeningType => screeningType !== action.payload.screening,
      );

    pkgToRemoveScreening.addedScreeningTypes = arrWithoutSelectedScreening;

    delete pkgToRemoveScreening.additionalProperties[action.payload.screening];

    if (action.payload.screening === 'international_criminal_search_v2') {
      const arrWithAmsRemoved = pkgToRemoveScreening.addedScreeningTypes.filter(
        screeningType => screeningType !== 'international_adverse_media_search',
      );

      pkgToRemoveScreening.addedScreeningTypes = arrWithAmsRemoved;
    }

    return {
      ...state,
      [packageType]: updated,
    };
  };

  const addScreening = (
    statePackage: EditedScreeningsType,
    action: AddScreeningAction | AddPendingScreeningAction,
    packageType: string,
  ) => {
    let updated: EditedScreeningsType = {};
    updated = cloneDeep(statePackage);

    if (!(action.payload.package_slug in updated)) {
      updated[action.payload.package_slug] = {
        addedScreeningTypes: [],
        additionalProperties: {},
      };
    }

    const pkgToAddScreening = updated[action.payload.package_slug];
    let arrWithAddedScreening = pkgToAddScreening.addedScreeningTypes.concat(
      action.payload.screening,
    );

    if (action.payload.screening === 'international_criminal_search_v2') {
      arrWithAddedScreening = arrWithAddedScreening.concat(
        'international_adverse_media_search',
      );
    }

    pkgToAddScreening.addedScreeningTypes = Array.from(
      new Set(arrWithAddedScreening),
    );

    if (action.payload.additionalProperties) {
      pkgToAddScreening.additionalProperties = {
        ...pkgToAddScreening.additionalProperties,
        [action.payload.screening]: {
          ...pkgToAddScreening.additionalProperties[action.payload.screening],
          ...action.payload.additionalProperties,
        },
      };
    }

    return {
      ...state,
      [packageType]: updated,
    };
  };

  switch (action.type) {
    case actionTypes.SET_BASE_PACKAGES: {
      const { payload } = action;
      const basePackages: BasePackagesType =
        reducePackagesArrayToObject(payload);

      return {
        ...state,
        basePackages,
      };
    }
    case actionTypes.ADD_SCREENING:
      return addScreening(state.editedPackages, action, 'editedPackages');

    case actionTypes.ADD_PENDING_SCREENING:
      return addScreening(state.pendingAddOns, action, 'pendingAddOns');

    case actionTypes.REMOVE_SCREENING:
      return removeScreening(state.editedPackages, action, 'editedPackages');

    case actionTypes.REMOVE_PENDING_SCREENING:
      return removeScreening(state.pendingAddOns, action, 'pendingAddOns');

    case actionTypes.SET_SELECTED_PACKAGE: {
      const { payload } = action;

      return {
        ...state,
        selectedPackage: payload,
      };
    }

    case actionTypes.CONFIRM_PENDING_ADD_ONS: {
      const { pendingAddOns } = state;

      return {
        ...state,
        editedPackages: cloneDeep(pendingAddOns),
        pendingAddOns: {},
      };
    }

    case actionTypes.CLEAR_PENDING_ADD_ONS: {
      return {
        ...state,
        pendingAddOns: {},
      };
    }

    case actionTypes.INIT_PENDING_ADD_ONS: {
      const { editedPackages } = state;

      return {
        ...state,
        pendingAddOns: cloneDeep(editedPackages),
      };
    }

    case actionTypes.RESET_EDITED_PACKAGES: {
      return {
        ...state,
        editedPackages: {},
      };
    }

    default:
      return state;
  }
};
