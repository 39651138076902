import React from 'react';
import styled from 'styled-components';

import colors from '../../../../../styles/_colors.scss';
import { Icon } from '../shared/ListItemElements';
import courtWeights from '../shared/svg/court-weights.svg';
import ScreeningsSectionAccordion from '../shared/ScreeningsSectionAccordion';
import InternationalCriminalSearch from './CriminalRecordScreenings/InternationalCriminalSearch';
import GlobalWatchlistSearch from './CriminalRecordScreenings/GlobalWatchlistSearch';
import { ADD_SCREENINGS } from '../../../../../constants/identifiers';

const Screenings = () => (
  <ul>
    <GlobalWatchlistSearch />
    <InternationalCriminalSearch />
  </ul>
);

const Title = styled.span`
  align-self: center;
  color: ${colors.navy3};
  font-family: National2Regular;
  font-size: 20px;
  font-weight: 500;
`;

const CriminalRecordsCheckItem = () => {
  return (
    <ScreeningsSectionAccordion
      IconComponent={<Icon src={courtWeights} className='screening-icon' />}
      TitleComponent={<Title>Criminal Records Searches</Title>}
      ScreeningsComponent={<Screenings />}
      dataTestId={ADD_SCREENINGS.internationalCriminalRecords}
    />
  );
};

export default CriminalRecordsCheckItem;
