import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material/';
import { InfoOutlined } from '@mui/icons-material';
import { PARTNER_PACKAGES } from '../../../constants/identifiers';

interface TooltipProps {
  isSelected: boolean;
  showMvr: boolean;
  showFedCrim: boolean;
  showEducationVerification: boolean;
}

interface DividerProps {
  show: boolean;
}

const Divider = ({ show }: DividerProps) => {
  return (
    <>
      {show && (
        <>
          <br />
          <br />
        </>
      )}
    </>
  );
};

const CommonAddonsTooltip = ({
  isSelected,
  showMvr,
  showFedCrim,
  showEducationVerification,
}: TooltipProps) => {
  return (
    <Tooltip
      sx={{
        cursor: 'auto !important',
      }}
      data-testid={PARTNER_PACKAGES.commonAddonsTooltip}
      title={
        <Box
          sx={{
            fontSize: '14px',
            color: '#5E5E64',
          }}
          onClick={e => e.stopPropagation()}
        >
          <div>
            {showMvr && (
              <>
                <b>MVR:</b> Searches driving history (including driving-related
                records that might not show up on a criminal search) in a
                particular state. Often used by companies hiring for roles that
                will require driving.
              </>
            )}
            {showFedCrim && (
              <>
                <Divider show={showMvr} />
                <b>Federal criminal search:</b> Searches for criminal records in
                federal jurisdictions. Often used to be more thorough in
                industries where crimes like fraud or embezzlement are
                job-relevant.
              </>
            )}
            {showEducationVerification && (
              <>
                <Divider show={showFedCrim || showMvr} />
                <b>Education verification (highest-level):</b> Confirms the
                candidate’s provided education or certification history to help
                ensure they have the required degree, training, or license to
                operate in a role.
              </>
            )}
          </div>
        </Box>
      }
      onClick={e => e.stopPropagation()}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '15px 20px',
            boxShadow: '0px 0px 16px 0px #16161D2E',
            bgcolor: '#FFFFFF',
            '& .MuiTooltip-arrow': {
              color: '#FFFFFF',
            },
          },
        },
      }}
      arrow
    >
      <IconButton style={{ padding: '2px' }}>
        <InfoOutlined
          style={{
            fontSize: '0.9rem',
            marginTop: '-2px',
            color: isSelected ? '#F9F6F2' : '#334559',
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default CommonAddonsTooltip;
