import FieldKit from 'field-kit';
import TextField from './TextField';

const DIGITS_PATTERN = /^\d{0,5}$/;

class ZipcodeFormatter extends FieldKit.Formatter {
  isChangeValid(change, error) {
    if (DIGITS_PATTERN.test(change.proposed.text)) {
      return super.isChangeValid(change, error);
    } else {
      return false;
    }
  }
}

export default class ZipcodeField extends TextField {
  static fieldName = 'zipcode';
  getFormatter() {
    return new ZipcodeFormatter();
  }
}

ZipcodeField.propTypes = TextField.getBasePropTypes();

ZipcodeField.defaultProps = TextField.getBaseDefaultProps();
