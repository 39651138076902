import React from 'react';
import styled from 'styled-components';
import { InfoDiv, SelectedTitle } from './orderSummaryStyles';
import { INVITES } from '../../../../../constants/identifiers';

const Label = styled.div`
  color: ${props => (props.currentStep < 3 ? '#8799AB' : '#117091')};
  font-size: ${props => (props.numberOfCandidates > 0 ? '12px' : '16px')};
  font-family: 'National2Regular';
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: ${props => (props.numberOfCandidates > 0 ? '12px' : '14px')};
  }

  @media (max-width: 600px) {
    font-size: ${props => (props.numberOfCandidates > 0 ? '12px' : '14px')};
  }
`;

const InviteCandidates = ({ numberOfCandidates, currentStep }) => {
  return (
    <>
      <Label numberOfCandidates={numberOfCandidates} currentStep={currentStep}>
        Invite candidates
      </Label>
      <InfoDiv>
        {numberOfCandidates > 0 && (
          <>
            <SelectedTitle
              data-testid={`${INVITES.ORDER_SUMMARY.inviteCandidatesDescription}`}
            >
              {numberOfCandidates} candidate{numberOfCandidates > 1 ? 's' : ''}
            </SelectedTitle>
          </>
        )}
      </InfoDiv>
    </>
  );
};

export default InviteCandidates;
