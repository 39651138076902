import React from 'react';

import { Icon, ScreeningSectionTitle } from '../shared/ListItemElements';
import courtWeights from '../shared/svg/court-weights.svg';
import ScreeningsSectionAccordion from '../shared/ScreeningsSectionAccordion';
import CountyCriminalSearch from './CriminalScreenings/CountyCriminalSearch';
import StatewideCriminalSearch from './CriminalScreenings/StatewideCriminalSearch';
import FederalCriminalSearch from './CriminalScreenings/FederalCriminalSearch';
import OutOfCountrySearch from './CriminalScreenings/OutOfCountrySearch';
import GlobalWatchlistSearch from './CriminalScreenings/GlobalWatchlistSeach';
import { ADD_SCREENINGS } from '../../../../../constants/identifiers';

const Screenings = () => (
  <ul>
    <CountyCriminalSearch />
    <StatewideCriminalSearch />
    <FederalCriminalSearch />
    <GlobalWatchlistSearch />
    <OutOfCountrySearch />
  </ul>
);

const CriminalRecords = () => {
  return (
    <ScreeningsSectionAccordion
      IconComponent={<Icon src={courtWeights} className='screening-icon' />}
      TitleComponent={
        <ScreeningSectionTitle>Criminal Records Searches</ScreeningSectionTitle>
      }
      ScreeningsComponent={<Screenings />}
      dataTestId={ADD_SCREENINGS.domesticCriminalRecords}
    />
  );
};

export default CriminalRecords;
