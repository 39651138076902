import { observable, action, makeObservable } from 'mobx';

export default class RouterStore {
  @observable router;
  @observable location = {};
  @observable pathname;
  @observable pattern;

  constructor(state) {
    this.state = state;
    makeObservable(this);
  }

  transitionTo(to) {
    return this.router.history.push(to);
  }

  @action updateFromContext(context) {
    this.router = context;
  }

  @action updateFromProps(props) {
    const { params, location, pathname, pattern } = props;
    this.params = params;
    this.location = location;
    this.pathname = pathname;
    this.pattern = pattern;
  }

  toStep(step) {
    this.transitionTo({
      pathname: `/authorize/${this.params.client_id}/${step}`,
      search: this.location.search,
    });
  }
}
