import React, { Component } from 'react';
import { CSSIdentifiers } from '../constants/identifiers';

export default class Loading extends Component {
  render() {
    return (
      <div className='text-muted text-center'>
        <br />
        <br />
        <br />
        <i
          className='fa fa-spinner fa-spin fa-3x fa-fw'
          data-testid={CSSIdentifiers.loadingSpinner}
        />
      </div>
    );
  }
}
