import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';
import Footer from '../../Footer';
import Logos from '../../Logos';
import Title from '../../Title';
import Steps from '../../Steps';
import * as Form from '../../form';
import PasswordStrengthHelp from '../../PasswordStrengthHelp';
import WorkLocationSelector from '../../WorkLocationSelector';
import StepUnloadWarningModal from '../../StepUnloadWarningModal';
import PasswordMismatchAlert from '../../PasswordMismatchAlert';
import ReCAPTCHA from '../../CheckrReCAPTCHA';
import { INDUSTRY_OPTIONS, STATE_OPTIONS } from '../../helpers/Constants';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../../services/analytics';
import { SIGNUP } from '../../../constants/identifiers';

@inject('store')
@observer
export default class SignupV3 extends Component {
  constructor(props) {
    super(props);
    const { store } = this.props;
    this.store = store;
    this.form = this.store.signupV3;
    this.form.assignParams();
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    if (this.store.application.sign_up_flow) {
      this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.SIGN_UP_PAGE_VIEWED);
    }
  }

  serviceAgreementUrl() {
    return (
      this.store.application.custom_service_agreement_url ||
      'https://checkr.com/customer-agreement/'
    );
  }

  trackSignupSignIn = () => {
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.SIGN_IN, {
      [ANALYTICS_PROPERTIES.PAGE_NAME]: 'Sign In from Signup',
    });
  };

  trackErrorsDisplayed(errors) {
    errors = errors.map(error => error[0]);
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.ERROR_DISPLAYED_CONTACT, {
      [ANALYTICS_PROPERTIES.ERROR_MESSAGE]: errors,
    });
  }

  trackMultipleWorkLocations(form) {
    const eventValue =
      form.numberOfComplianceLocations === 'multiple' ? 'Yes' : 'No';
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.WORK_LOCATION_SELECTED, {
      [ANALYTICS_PROPERTIES.MULTIPLE_WORK_LOCATIONS]: eventValue,
    });
  }

  trackIndustrySelected(form) {
    const industry = form?.industry;
    const industryText =
      INDUSTRY_OPTIONS.find(o => o.value === parseInt(industry))?.text ||
      'Not Selected';

    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.INDUSTRY_FIELD_SELECTED, {
      [ANALYTICS_PROPERTIES.INDUSTRY_SELECTED]: industryText,
    });
  }

  contactInformation() {
    return (
      <div>
        <Form.Label text='Contact information' />
        <Form.Input
          form={this.form}
          label='Full name'
          errorDataTestId={SIGNUP.ERROR.fullName}
          dataTestId={SIGNUP.INPUT.fullName}
          name='fullName'
          placeholder='John Doe'
          tooltip='By completing this sign-up process, <br />
                            you will be the administrator for this <br />
                            Checkr account. If you are not the account <br />
                            admin or owner, please consult with <br />
                            your team before proceeding.'
          autoFocus
        />
        <Form.Input
          form={this.form}
          label='Email address'
          errorDataTestId={SIGNUP.ERROR.email}
          dataTestId={SIGNUP.INPUT.email}
          name='email'
          placeholder='johndoe@acme.com'
          type='email'
          tooltip='The email address you provide here <br />
                            will be used for your Checkr login. <br />
                            If you already have a Checkr account, <br />
                            please click Sign In at the top.'
        />
        <div>
          <Form.Input
            form={this.form}
            label='Create a password / passphrase'
            errorDataTestId={SIGNUP.ERROR.password}
            dataTestId={SIGNUP.INPUT.password}
            name='password'
            type='password'
            tooltip='Create a password or passphrase <br />
                              for your account, using the tips <br />
                              for strong passwords below.'
          />
          <Form.Input
            form={this.form}
            label='Re-enter your password / passphrase'
            errorDataTestId={SIGNUP.ERROR.confirmPassword}
            dataTestId={SIGNUP.INPUT.confirmPassword}
            name='confirmedPassword'
            type='password'
            tooltip='Your re-entered password / <br />
                      passphrase must match your original.'
          />
          <PasswordStrengthHelp
            passwordStrength={this.form.passwordStrength}
            isActive={!!this.form.password}
          >
            <PasswordMismatchAlert
              password={this.form.password}
              passwordConfirm={this.form.confirmedPassword}
            />
          </PasswordStrengthHelp>
        </div>
      </div>
    );
  }

  businessInformation() {
    return (
      <div>
        <Form.Label text='Business information' />
        <Form.Input
          form={this.form}
          label='Legal business name'
          errorDataTestId={SIGNUP.ERROR.company}
          dataTestId={SIGNUP.INPUT.company}
          name='company'
          placeholder='Acme Corporation'
          tooltip='The official name of your business <br />
                              that was used to register or <br />
                              incorporate in your state.'
        />
        <div className='row'>
          <div className='col-md-6'>
            <Form.Input
              form={this.form}
              field='phone'
              label='Phone number'
              errorDataTestId={SIGNUP.ERROR.phone}
              dataTestId={SIGNUP.INPUT.phone}
              name='phone'
              placeholder='1 (555) 555-5555'
              tooltip='The company’s primary billing <br />
                                phone number. The number to call <br />
                                for all billing inquiries.'
            />
          </div>
          <div className='col-md-6'>
            <Form.Input
              form={this.form}
              label='Doing business as'
              errorDataTestId={SIGNUP.ERROR.nameDba}
              dataTestId={SIGNUP.INPUT.nameDba}
              labelSubText='(Optional)'
              name='nameDba'
              tooltip='Only fill out D/B/A if your company <br />
                                operates under a name that’s different <br />
                                than your legal business name. <br />
                                We may reach out for documents to verify.'
            />
          </div>
        </div>
        <Form.Label
          text='Company Address'
          tooltip='Your company address must <br />
                            match the address associated <br />
                            with your Tax ID / EIN.'
        />
        <div className='row'>
          <div className='col-md-6'>
            <Form.Input
              form={this.form}
              label='Street'
              errorDataTestId={SIGNUP.ERROR.street}
              dataTestId={SIGNUP.INPUT.street}
              name='street'
              placeholder='Street name'
            />
          </div>
          <div className='col-md-6'>
            <Form.Input
              field='zipcode'
              form={this.form}
              errorDataTestId={SIGNUP.ERROR.zip}
              dataTestId={SIGNUP.INPUT.zip}
              label='Zip Code'
              name='zipcode'
              placeholder='12345'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Input
              form={this.form}
              label='City'
              errorDataTestId={SIGNUP.ERROR.city}
              dataTestId={SIGNUP.INPUT.city}
              name='city'
              placeholder='City name'
            />
          </div>
          <div className='col-md-6'>
            <Form.Select
              form={this.form}
              label='State'
              errorDataTestId={SIGNUP.ERROR.state}
              dataTestId={SIGNUP.INPUT.state}
              name='stateName'
              options={STATE_OPTIONS}
            />
          </div>
        </div>
        <Form.Input
          form={this.form}
          label='Company website'
          errorDataTestId={SIGNUP.ERROR.website}
          dataTestId={SIGNUP.INPUT.website}
          name='website'
          placeholder='acmecorp.com'
          tooltip='Checkr will accept any valid <br />
                                URL format, including both name.com <br />
                                and https://www.name.com.'
        />
        <div className='row'>
          <div className='col-md-6'>
            <Form.Input
              form={this.form}
              label='Tax ID / EIN'
              errorDataTestId={SIGNUP.ERROR.taxID}
              dataTestId={SIGNUP.INPUT.taxID}
              name='taxId'
              field='tin'
              placeholder='XX-XXXXXXX'
              tooltip='The unique nine-digit number <br />
                                assigned by the IRS that’s used to <br />
                                identify your company as a business entity.'
            />
          </div>
          <div className='col-md-6'>
            <Form.Select
              blankOptionText='Choose an industry...'
              form={this.form}
              label='Industry'
              errorDataTestId={SIGNUP.ERROR.industry}
              dataTestId={SIGNUP.INPUT.industry}
              labelSubText='(Optional)'
              name='industry'
              options={INDUSTRY_OPTIONS}
              tooltip='Select the industry that <br />
                                  best matches your business'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 tax-exempt'>
            <Form.Checkbox
              form={this.form}
              className='mt-2 mb-2'
              label={
                <>
                  {
                    'My business is tax exempt. By selecting this, you are \
                    confirming that you have a valid sales and use tax exemption certificate. \
                    If this is the case, please send a copy of your exemption certificate to '
                  }
                  <a href='mailto:tax@checkr.com'>tax@checkr.com</a>
                </>
              }
              dataTestId={SIGNUP.INPUT.taxExempt}
              name='taxExempt'
              field='taxExempt'
              value='true'
            />

            <p>
              Sales tax exemptions are uncommon, so if you are unsure whether
              your organization is tax exempt, please ignore. You can reach our
              team at tax@checkr.com if you have any questions regarding the tax
              exempt designation.
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    // If Sign up flow option is OFF, redirect to Sign in
    if (!this.store.application.sign_up_flow) {
      return (
        <div>
          <Redirect to={this.store.getStep('signin')} />
          Redirecting to <Link to={this.store.getStep('signin')}>Sign in</Link>
        </div>
      );
    }

    const formStep = 1;

    return (
      <div>
        {this.store.isSignup && <Redirect to={this.store.getStep('signup')} />}
        <StepUnloadWarningModal />
        <div className='container'>
          <div className='row justify-content-lg-center'>
            <div className='col-lg-7 col-md-12'>
              <div className='row container-outer py-4'>
                <Logos />
                <Title label='Connect to Checkr' baseClass='mb-0' />
                <Steps step={formStep} isBillable={this.store.isBillable()} />
                <div className='col-lg-12 text-center mb-4'>
                  <p>
                    Have an existing Checkr account?&nbsp;
                    <Link
                      className='font-weight-bold'
                      onClick={this.trackSignupSignIn}
                      to={this.store.getStep('signin')}
                    >
                      Sign in
                    </Link>
                  </p>
                  <div className='row justify-content-md-center'>
                    <p className='col-md-9 text-center'>
                      Please provide the following contact and business
                      information so Checkr can verify you are running
                      background checks for legitimate business purposes.
                    </p>
                  </div>
                </div>
                <div className='col-md-12 container-inner'>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 px-5 main'>
                        <Form.Form form={this.form}>
                          <Form.Error
                            form={this.form}
                            error={this.form.error}
                            store={this.store}
                          />
                          {this.contactInformation()}
                          <hr />
                          {this.businessInformation()}
                          <hr />
                          <WorkLocationSelector form={this.form} />
                          <br />
                          <div className='row my-3'>
                            <div className='col-12'>
                              <Form.Checkbox
                                dataTestId={SIGNUP.MISC.purpose}
                                labelSubText='I confirm that I am running background checks for employment
                                              purposes, including full and part time positions, promotions,
                                              volunteers and independent contractor relationships.'
                                form={this.form}
                                name='purpose'
                                value='employment'
                              />
                            </div>
                          </div>

                          <br />
                          <ReCAPTCHA recaptchaRef={this.recaptchaRef} />
                          <Form.Submit
                            text='Continue'
                            dataTestId={SIGNUP.MISC.submit}
                            loading={this.form.loading}
                            submitWithRecaptcha={e =>
                              this.submitWithRecaptcha(e)
                            }
                          />
                          <p className='text-muted text-center mt-2'>
                            By continuing, you agree to the Checkr, Inc.{' '}
                            <a
                              href='https://checkr.com/privacy-policy/'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              Privacy Policy
                            </a>{' '}
                            and{' '}
                            <a
                              href={this.serviceAgreementUrl()}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              Services Agreement
                            </a>
                            .
                          </p>
                        </Form.Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  submitWithRecaptcha(e) {
    this.form.loading = true;
    e.preventDefault();
    const errors = this.form.validateOnEvent();

    if (errors) {
      this.trackErrorsDisplayed(Object.values(errors));
      this.form.error.error = 'signupV3.errors';
      this.form.loading = false;
      window.scrollTo(0, 0);
      return;
    } else {
      this.recaptchaRef.current
        .executeAsync()
        .then(recaptchaCode => {
          this.form.submit(recaptchaCode);
        })
        .then(this.trackMultipleWorkLocations(this.form))
        .then(this.trackIndustrySelected(this.form))
        .catch(() => {
          this.form.error = {
            error: 'Recaptcha encountered an error',
          };
        })
        .finally(() => {
          this.recaptchaRef.current?.reset();
        });
    }

    this.form.loading = false;
  }
}

SignupV3.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
