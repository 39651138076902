import React from 'react';
import PropTypes from 'prop-types';
import { LEGACY_PROGRESS_BAR } from '../constants/identifiers';

export default function Step({ baseClass, currentStep, labelStep, labelName }) {
  const className =
    currentStep === labelStep
      ? 'active'
      : currentStep > labelStep
        ? 'complete'
        : 'disabled';

  return (
    <div
      data-testid={LEGACY_PROGRESS_BAR[className]}
      className={`${baseClass} ${className}`}
    >
      {labelName}
    </div>
  );
}

Step.propTypes = {
  baseClass: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  labelStep: PropTypes.number.isRequired,
  labelName: PropTypes.string.isRequired,
};
