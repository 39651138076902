/* eslint-disable max-len */
import { makeObservable, observable, toJS } from 'mobx';
import axios from 'axios';

import AuthStore from './AuthStore';
import RouterStore from './RouterStore';

import SigninStore from './forms/SigninStore';
import SignupV3Store from './forms/SignupV3Store';
import CreditCardPaymentStore from './forms/CreditCardPaymentStore';
import ConnectStore from './forms/ConnectStore';
import SuccessStore from './forms/SuccessStore';
import BankAccountPaymentStore from './forms/BankAccountPaymentStore';
import SubmissionStore from './forms/SubmissionStore';
import UtmStore from './UtmStore';
import { queryClient } from '../components/App';

import { fetchFlagr, loadPagePerfFlag, FLAG_KEYS } from '../services/flagr';
import analyticsCheck from '../services/analytics';
import amplitudeExperiment from '../services/amplitudeExperiment';
import PerformanceObserverStore from './PerformanceObserverStore';

class AppState {
  @observable loading;
  @observable error;
  @observable application;
  @observable flagr;
  @observable promoCodeActive;

  constructor(env, config = {}) {
    this.env = env;
    this.config = config;

    this.loading = false;
    this.error = null;
    this.application = null;
    this.isCheckrDirectA = false;
    this.isGoodhireWebsite = false;
    this.flagr = null;
    this.pagePerfActions = {};
    this.isSignup = false;
    this.isMicroSMB = false;
    this.signUpFlowProperties = {};
    this.promoCodeActive = false;
    this.promotionDisabled = false;
    this.initialAuthorizationFailed = false;

    this.auth = new AuthStore(this);
    this.router = new RouterStore(this);

    this.signin = new SigninStore(this);
    this.signupV3 = new SignupV3Store(this);
    this.bankAccountPayment = new BankAccountPaymentStore(this);
    this.creditCardPayment = new CreditCardPaymentStore(this);
    this.connect = new ConnectStore(this);
    this.success = new SuccessStore(this);
    this.submission = new SubmissionStore(this);
    this.utms = new UtmStore(this);
    this.performanceObserver = new PerformanceObserverStore(this);
    makeObservable(this);
  }

  createAccount(recaptchCode) {
    const queryParams =
      (this.router.location.search &&
        Object.fromEntries(new URLSearchParams(this.router.location.search))) ||
      {};

    if (queryParams.expected_checks_per_year) {
      queryParams.numEmployees = queryParams.expected_checks_per_year;
      delete queryParams.expected_checks_per_year;
    }

    if (queryParams.volume) {
      const valueMap = {
        5: '1-10',
        20: '11-25',
        35: '26-50',
        150: '51-299',
        750: '300-1000',
        1001: '1000+',
      };
      queryParams.numEmployees = valueMap[queryParams.volume];
      delete queryParams.volume;
    }

    return this.executeRequest({
      data: this.accountPayload(recaptchCode, queryParams),
      method: 'post',
      url: '/v1/accounts',
    }).then(response => {
      return response;
    });
  }

  getAccount() {
    return this.executeRequest({
      data: {},
      method: 'get',
      url: '/v1/account',
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        this.loading = false;
        console.error(error);
      });
  }

  getUser() {
    return this.executeRequest({
      data: {},
      method: 'get',
      url: '/user',
    })
      .then(response => {
        this.auth.setCurrentUser(response);
        return response;
      })
      .catch(error => {
        this.loading = false;
        console.error(error);
      });
  }

  getPromoCodeActive() {
    const clientId = this.application.client_id;

    this.executeRequest({
      method: 'get',
      url: `/billing/promotion_codes/${clientId}/active`,
    }).then(response => {
      this.promoCodeActive = response;
    });
  }

  accountPayload(recaptchCode, queryParams) {
    let clientId;

    if (this.isCheckrDirectA) {
      clientId = this.env.CHECKR_DIRECT_A_CLIENT_ID;
    } else if (this.isGoodhireWebsite) {
      clientId = this.env.GOODHIRE_WEBSITE_CLIENT_ID;
    } else {
      clientId = this.router.router.match.params.client_id;
    }

    const payload = {
      client_id: clientId,
      company: {
        city: this.signupV3.city,
        dba_name: this.signupV3.nameDba,
        industry: this.signupV3.industry,
        num_employees:
          queryParams.numEmployees || this.signupV3.yearlyBgcVolume,
        phone: this.signupV3.phone,
        state: this.signupV3.stateName,
        street: this.signupV3.street,
        tax_id: String(this.signupV3.taxId).replace(/-|\s/g, ''),
        website: this.signupV3.website,
        zipcode: this.signupV3.zipcode,
      },
      compliance_contact_email: this.signupV3.email,
      default_compliance_city: this.signupV3.complianceCity,
      default_compliance_state: this.signupV3.complianceState,
      name: this.signupV3.company,
      purpose: this.signupV3.purpose,
      recaptcha_code: recaptchCode,
      redirect_uri: queryParams.redirect_uri,
      state: queryParams.state,
      user: {
        email: this.signupV3.email,
        full_name: this.signupV3.fullName,
        password: this.signupV3.password,
      },
      tax_exempt: this.signupV3.taxExempt === 'true', // forms/Checkbox only returns a string
    };

    if (this.isCheckrDirectA || this.isGoodhireWebsite) {
      payload.amplitude_experiment_bucket = 'ab-08-fox-v2-control';
    }

    return payload;
  }

  executeRequest(request) {
    return new Promise((resolve, reject) => {
      this.fetch(request)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject({
            _api: error?.response?.data || error?.response?.body,
            error:
              error?.response?.data?.error ||
              error?.response?.data?.errors?.join(', ') ||
              error?.message,
          });
        });
    });
  }

  executeAMSRequest(request) {
    return new Promise((resolve, reject) => {
      this.fetchAMS(request)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject({
            _api: error?.response?.data || error?.response?.body,
            error: error?.response?.data?.error || error.message,
          });
        });
    });
  }

  loadFlagr(keys) {
    const isSelfService = this.isCheckrDirectA || this.isGoodhireWebsite;
    const additionalContext = {
      application_client_id: toJS(this.application).client_id,
      self_service: isSelfService,
      partner_account_ids: [toJS(this.application.account_resource_id)],
    };

    fetchFlagr(keys, additionalContext).then(data => {
      this.flagr = data;
    });
  }

  async loadPackages(applicationId) {
    let packagesResp;

    try {
      packagesResp = await queryClient.fetchQuery(['packages'], () =>
        axios({
          url: `${this.env.CHECKR_API}/applications/client_id/${applicationId}/packages`,
        }),
      );
    } catch (packagesError) {
      console.warn('Packages request failed', packagesError);
    }

    this.application.packages = packagesResp?.data;
    this.packages = packagesResp?.data;
  }

  async loadApplication(applicationId) {
    let applicationResp;

    try {
      applicationResp = await queryClient.fetchQuery(['application'], () =>
        axios({
          url: `${this.env.ACCOUNTS_API}/applications/client_id/${applicationId}`,
        }),
      );
    } catch (error) {
      this.error = error;
      this.loading = false;
      throw error;
    }

    this.application = applicationResp.data;
  }

  async initializeAmplitudeExperiment() {
    const userProperties = this.getUserProperties();

    try {
      await amplitudeExperiment.initializeAndStart(userProperties);
    } catch (amplitudeExperimentError) {
      console.warn(
        'Could not initialize amplitude experiment',
        amplitudeExperimentError,
      );
    }
  }

  async initializePagePerf() {
    try {
      const res = await loadPagePerfFlag();
      this.pagePerfActions = res;
    } catch (error) {}
  }

  async loadDependencies(applicationId) {
    this.loading = true;
    this.setCheckrDirectOrGoodhire(applicationId);

    await this.initializePagePerf();

    if (
      (this.isCheckrDirectA || this.isGoodhireWebsite) &&
      this.pagePerfActions.remove_network_calls
    ) {
      this.setApplication(applicationId);
    } else {
      await this.loadApplication(applicationId);
      await this.loadPackages(applicationId);
    }

    await this.initializeAmplitudeExperiment();

    this.setApplicationTheme();
    this.error = null;
    this.loading = false;
  }

  isBillable() {
    return !this.application.partner_billing;
  }

  isLive() {
    return this.application.live;
  }

  showPackageCustomization() {
    return false;
  }

  fetch(config) {
    config.url = `${this.env.CHECKR_API}${config.url}`;
    if (this.auth.token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${this.auth.token}`;
    }
    return axios(config);
  }

  fetchAS(config) {
    config.url = `${this.env.ACCOUNTS_API}${config.url}`;
    if (this.auth.token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${this.auth.token}`;
    }
    return axios(config);
  }

  fetchAMS(config) {
    config.url = `${this.env.CHECKR_AMS_BASE_URI}${config.url}`;
    if (this.auth.token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${this.auth.token}`;
    }
    return axios(config);
  }

  setApplication(applicationId) {
    // manually sets the application object for ss signup to avoid network calls and improve page performance
    this.application = {
      redirect_uri: 'https://checkr.com/signup/thank-you',
      live: true,
      client_id: applicationId,
    };
  }

  setApplicationTheme() {
    if (this.application.primary_color) {
      document.documentElement.style.setProperty(
        '--primary-color',
        this.application.primary_color,
      );
    }
  }

  toStep(step) {
    // flagr is broken for ss signup - this needs to be addressed separately
    const { variantKey } =
      this.flagr?.flags?.[FLAG_KEYS.SIGN_UP_STANDARDIZATION] || {};

    if (this.env.REACT_APP_USE_APPLICATION_STEPS || variantKey === 'on') {
      const currentStep = this.application.signup_steps.find(step =>
        this.router.location.pathname.includes(step.path),
      );
      const nextStep = this.application.signup_steps.find(
        step => step.position === currentStep.position + 1,
      );
      this.router.transitionTo(nextStep.path);
      return;
    }
    this.router.transitionTo(this.getStep(step));
  }

  getStep(step) {
    let path;
    let searchParams;
    if (step === 'submission_DoThisLater') {
      step = 'submission';
      searchParams = this.router.location.search + '&skipInvites=true';
    } else {
      searchParams = this.router.location.search;
    }

    if (this.isCheckrDirectA) {
      path = `/checkr/${step}`;
    } else if (this.isGoodhireWebsite) {
      path = `/checkr-goodhire/${step}`;
    } else {
      path = `/authorize/${this.router.router.match.params.client_id}/${step}`;
    }

    return {
      pathname: path,
      search: searchParams,
    };
  }

  redirect(url) {
    window.location = url;
  }

  setCheckrDirectOrGoodhire(applicationId) {
    if (applicationId === this.env.CHECKR_DIRECT_A_CLIENT_ID) {
      this.isCheckrDirectA = true;
      this.isSignup = true;
      this.checkrDirectWebsitePromo = this.promotionDisabled
        ? null
        : this.env.CHECKR_DIRECT_A_PROMO_CODE;

      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has('microSMB')) {
        this.isMicroSMB = true;
      }
    }
    if (applicationId === this.env.GOODHIRE_WEBSITE_CLIENT_ID) {
      this.isGoodhireWebsite = true;
      this.isSignup = true;
    }
  }

  getUserProperties() {
    const dummyUser = {
      account: {
        id: this.auth.getAccountId(),
      },
    };

    // full user object comes from an api response that may not be populated at the time of the tracking call
    // provide the dummy user if that response is not yet available
    const user = this?.auth?.user || dummyUser;
    const queryParams = new URLSearchParams(location.search);
    const userProperties = {
      account_resource_id: this.auth.getAccountId(),
      account_uri_name: user?.account?.uri_name,
      application_client_id: this?.application?.client_id,
      multiple_partners: Boolean(user?.account?.partner_account_ids?.length),
      partner_uri_name: this?.application?.partner_account_uri_name,
      traffic: queryParams.get('utm_source'),
      customer_size: queryParams.get('volume'),
      business_entry_point: this?.isGoodhireWebsite ? 'goodhire' : 'checkr',
      is_micro_smb: this?.isMicroSMB,
      partner_billing: this?.application?.partner_billing,
      sign_up_flow: this?.application?.sign_up_flow,
      ...(this?.signUpFlowProperties.version
        ? { signup_flow_version: this?.signUpFlowProperties.version }
        : {}),
    };

    return userProperties;
  }

  trackAnalyticsEvent(analyticsEvent, analyticsProperties = {}, callback) {
    // always populate account id from auth jwt
    // the analytics wrapper maps this to a property called account_resource_id
    // https://gitlab.checkrhq.net/dashboard-experience/utils/-/blob/develop/src/helpers/analytics/analytics.ts#L95
    const dummyUser = {
      account: {
        id: this.auth.getAccountId(),
      },
    };

    // full user object comes from an api response that may not be populated at the time of the tracking call
    // provide the dummy user if that response is not yet available
    const user = this?.auth?.user || dummyUser;
    const queryParams = new URLSearchParams(location.search);
    const userProperties = {
      account_resource_id: this.auth.getAccountId(),
      account_uri_name: user?.account?.uri_name,
      application_client_id: this?.application?.client_id,
      multiple_partners: Boolean(user?.account?.partner_account_ids?.length),
      partner_uri_name: this?.application?.partner_account_uri_name,
      traffic: queryParams.get('utm_source'),
      customer_size: queryParams.get('volume'),
      business_entry_point: this?.isGoodhireWebsite ? 'goodhire' : 'checkr',
      is_micro_smb: this?.isMicroSMB,
      partner_billing: this?.application?.partner_billing,
      sign_up_flow: this?.application?.sign_up_flow,
      ...(this?.signUpFlowProperties.version
        ? { signup_flow_version: this?.signUpFlowProperties.version }
        : {}),
    };

    analyticsCheck.track?.(
      user,
      analyticsEvent,
      analyticsProperties,
      userProperties,
      callback,
    );
  }
}

export default AppState;
