import React from 'react';
import PropTypes from 'prop-types';

export default function Title({ baseClass, label }) {
  return (
    <div className='col-lg-12'>
      <h4 className={`text-center ${baseClass}`}>{label}</h4>
    </div>
  );
}

Title.propTypes = {
  baseClass: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
