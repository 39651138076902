import React, { useState } from 'react';
import { useInvitesAnalyticsTrackers } from '../hooks/useInvitesAnalyticsTrackers';
import { Typography, Box } from '@mui/material';
import { SkipOrderFlow } from '.';
import { SkipThisStepLink } from './shared';
import { INVITES } from '../../../../constants/identifiers';

const PageHeader = () => {
  const { trackSkipOrderBGCFlowClicked } = useInvitesAnalyticsTrackers();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box mx='auto' paddingLeft='20px' paddingRight='20px'>
      <Typography
        textAlign='center'
        fontWeight='600'
        fontSize='42px'
        sx={{ fontSize: { xs: '32px', sm: '32px', md: '42px' } }}
        color='#001730'
        fontFamily='ArgentCFRegular'
      >
        Order your first background check
      </Typography>
      <Typography
        textAlign='center'
        paddingTop='10px'
        fontWeight='400'
        lineHeight='150%'
        color='#576c82'
        fontFamily='National2Regular'
        component='div'
        sx={{ fontSize: { xs: '16px', sm: '16px', md: '18px' } }}
      >
        If you’re not ready to order, you can{' '}
        <SkipThisStepLink
          onClick={() => {
            trackSkipOrderBGCFlowClicked();
            setModalOpen(true);
          }}
          data-testid={INVITES.MISC.skipThisStepLink}
        >
          skip this step
        </SkipThisStepLink>
        .
      </Typography>
      <SkipOrderFlow
        handleCloseModal={() => setModalOpen(false)}
        modalOpen={modalOpen}
      />
    </Box>
  );
};

export default PageHeader;
