import { observable, action, makeObservable } from 'mobx';
import decode from 'jwt-decode';

export const CHECKR_TOKEN = 'checkr_token';
const CLIENT_ID = 'client_id';
const STATE_PARAM = 'state_param';

export default class AuthStore {
  @observable loading;
  @observable error;

  @observable token;
  @observable clientId;
  @observable stateParam;
  @observable user;
  @observable oauth;
  @observable storage;

  constructor(state) {
    this.state = state;
    this.storage =
      state && state.config && state.config.storage
        ? state.config.storage
        : window.localStorage;
    this.token = this.getToken();
    this.clientId = this.getClientId();
    this.stateParam = this.getStateParam();
    makeObservable(this);
  }

  getToken = () => {
    return this.storage.getItem(CHECKR_TOKEN);
  };

  getAccountId = () => {
    const token = this.getToken();
    let decoded;
    try {
      decoded = decode(token);
    } catch (e) {
      decoded = null;
    }

    if (decoded) {
      const account = decoded['https://checkr.com/authorizations'] || {};
      return account.account_id;
    }

    return null;
  };

  @action setToken = token => {
    this.token = token;
    return this.storage.setItem(CHECKR_TOKEN, token);
  };

  @action removeToken = () => {
    this.token = null;
    return this.storage.removeItem(CHECKR_TOKEN);
  };

  getClientId() {
    return this.storage.getItem(CLIENT_ID);
  }

  @action setClientId = clientId => {
    this.clientId = clientId;
    return this.storage.setItem(CLIENT_ID, clientId);
  };

  getStateParam() {
    return this.storage.getItem(STATE_PARAM);
  }

  @action setStateParam = stateParam => {
    this.stateParam = stateParam;
    return this.storage.setItem(STATE_PARAM, stateParam);
  };

  load = () => {
    this.getCurrentUser();
  };

  setCurrentUser(user) {
    this.user = user;
  }

  getCurrentUser = () => {
    this.loading = true;
    const request = {
      method: 'get',
      url: '/user',
    };

    return this.state
      .fetch(request)
      .then(response => {
        this.error = null;
        this.loading = false;
        this.user = response.data;
        return response.data;
      })
      .catch(error => {
        this.loading = false;
        this.error = error?.response?.data || error.message;
        return error?.response?.data || error.message;
      });
  };

  getOauthProgress = () => {
    this.loading = true;
    const request = {
      method: 'get',
      url: `/accounts/${this.user.account.id}/oauth_progress`,
    };

    return this.state
      .fetch(request)
      .then(response => {
        this.error = null;
        this.loading = false;
        this.oauth = response.data;
      })
      .catch(error => {
        console.error(error);
        this.loading = false;
        this.error = error?.response?.data || error.message;
      });
  };
}
