/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { SetStateAction } from 'react';
import ScreeningAccordion from '../ScreeningAccordion';
import Screening from '../Screening';
import searches from '../svgs/1-searches.svg';
import { PHASE2_ADD_ON_OPTIONS_CONFIG } from '../../data/packages';

type Props = {
  expanded: string;
  handleChange: (
    panel: SetStateAction<string>,
    category: string,
  ) => (event: unknown, isExpanded: boolean) => void;
  slug: string;
};

const Screenings = ({ expanded, handleChange, slug }: Props) => {
  const {
    federal_criminal_search,
    state_criminal_search,
    county_criminal_search,
    federal_civil_search,
    county_civil_search,
  } = PHASE2_ADD_ON_OPTIONS_CONFIG;
  return (
    <ScreeningAccordion
      icon={<img src={searches} alt='icon' height={24} width={24} />}
      title='Need a better view of criminal or civil records?'
      subtitle='Check for criminal and civil records at the federal, state, and/or county level to get the results that are important to you'
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1', 'Criminal and Civil Records')}
      dataTestId='screening-accordion-1'
    >
      <Screening
        name={federal_criminal_search.displayName}
        description={federal_criminal_search.description}
        price={federal_criminal_search.price}
        screening='federal_criminal_search'
        slug={slug}
      />
      <Screening
        name={state_criminal_search.displayName}
        description={state_criminal_search.description}
        price={state_criminal_search.price}
        screening='state_criminal_search'
        slug={slug}
      />
      {slug === 'checkrdirect_basic_plus_criminal' && (
        <Screening
          name={county_criminal_search.displayName}
          description={county_criminal_search.description}
          price={county_criminal_search.price}
          screening='county_criminal_search'
          slug={slug}
        />
      )}
      <Screening
        name={federal_civil_search.displayName}
        description={federal_civil_search.description}
        price={federal_civil_search.price}
        screening='federal_civil_search'
        slug={slug}
      />
      <Screening
        name={county_civil_search.displayName}
        description={county_civil_search.description}
        price={county_civil_search.price}
        screening='county_civil_search'
        slug={slug}
      />
    </ScreeningAccordion>
  );
};

export default Screenings;
