/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { CardContent } from '@mui/material/';
import { PARTNER_PACKAGES } from '../../../constants/identifiers';
import { displayPricing } from './priceHelpers';
import PackageCard from './PackageCard';
import PackageCardHeader from './PackageCardHeader';
import PackageCardScreening from './PackageCardScreening';
import PackageCardIntlDisclaimer from './PackageCardIntlDisclaimer';

const StaticPackageCard = ({ partnerPackage, partnerBilling }) => {
  const { name, slug, screenings, international_only } = partnerPackage;

  return (
    <PackageCard testId={slug}>
      <PackageCardHeader
        title={name}
        testId={slug}
        showPrice={!partnerBilling}
        price={displayPricing(partnerPackage)}
        priceLoading={false}
      />
      <CardContent
        style={{
          minHeight: '136px',
          marginTop: '-16px',
        }}
        data-testid={`${PARTNER_PACKAGES.cardContent}-${slug}`}
      >
        {screenings.map(screening => (
          <PackageCardScreening key={slug} slug={slug} screening={screening} />
        ))}
      </CardContent>
      {international_only && <PackageCardIntlDisclaimer />}
    </PackageCard>
  );
};

StaticPackageCard.propTypes = {
  partnerPackage: PropTypes.object.isRequired,
  partnerBilling: PropTypes.bool.isRequired,
};

export default StaticPackageCard;
