import React, { Component } from 'react';

const CURRENT_YEAR = new Date().getFullYear();

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer className='footer'>
          &copy; {CURRENT_YEAR} Checkr
          <span>|</span>
          What is Checkr?{' '}
          <a
            href='https://checkr.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            Learn More
          </a>
        </footer>
      </div>
    );
  }
}
