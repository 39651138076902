import React from 'react';
import styled from 'styled-components';

const FAIRCHANCE_PARAGRAPHS = [
  <>
    Fair chance hiring is the practice of hiring people with criminal records.
    It helps companies find a pool of qualified, diverse talent with a wide
    range of experiences that builds diversity and ultimately leads to stronger
    business outcomes.
  </>,
  <>
    Built directly into our platform, the fair chance dashboard helps you see
    first-hand how your company’s fair chance hiring data stacks up against
    other companies.
  </>,
  <>
    Checkr has also committed to the{' '}
    <span className='bold-font'>Pledge 1%</span> corporate philanthropy
    movement, pledging to donate one percent of our employees’ time, one percent
    of our product, one percent of our equity, and one percent of our profits
    every year to the mission of enabling fair chance.
  </>,
];

const FAIRCHANCE_PARAGRAPHS_CUSTOM_PLANNING_CENTER = [
  <>
    <span className='bold-font'>Faster turnaround times</span>
    <br />
    98% of reports using Checkr’s Basic+ package complete within one hour
  </>,
  <>
    <span className='bold-font'>User-friendly experience</span>
    <br />
    Volunteers can easily complete their background check invitation and view
    updates on any device
  </>,
  <>
    <span className='bold-font'>Continuous safety</span>
    <br />
    Easily run ongoing background screenings for current volunteers and ensure
    peace of mind for your community
  </>,
];

const FAIRCHANCE_PARAGRAPHS_CUSTOM_WELLSKY = [
  <>
    <span className='bold-font'>Protect patient safety</span>
    <br />
    Confirm that the professionals trusted with your patients’ care are eligible
    for medical employment
  </>,
  <>
    <span className='bold-font'>Prevent costly violations</span>
    <br />
    Comply with healthcare industry regulations to mitigate risk for your
    organization
  </>,
  <>
    <span className='bold-font'>Improve patient outcomes</span>
    <br />
    Provide consistent, high-quality care by hiring fully-qualified candidates
  </>,
];

const FAIRCHANCE_PARAGRAPHS_CUSTOM_RIPPLING = [
  <>
    Traditional background check companies rely on manual operations to process
    report data, which slows down time-to-offer, could introduce bias, and makes
    it difficult to scale. We’ve rebuilt the background check process with
    technology at its core, so you can increase productivity for your team and
    get candidates to work sooner.
  </>,
  <>
    We’re on a mission to help you create a seamless hiring experience that
    empowers recruiters and candidates alike with greater transparency and
    control. Checkr’s global screenings platform extends the modern technology
    of our domestic product across the world. With screenings available in over
    200+ countries and territories, you can manage your domestic and
    international checks with a single source of truth.
  </>,
  <>
    Is your organization expanding and looking to streamline your background
    check process? Or perhaps you are already running background checks on all
    employees and looking to modernize your approach. Either way, Checkr has you
    covered.
  </>,
];

const FAIRCHANCE_PARAGRAPHS_CUSTOM = [
  <>This is a custom</>,
  <>more custom text part 2</>,
  <>more custom text part 3</>,
];

const FairChanceBody = ({ content }) => {
  const ParagraphWrapper = styled.p`
    font-family: National2Regular;
    margin-top: 15px;
    .bold-font {
      font-family: National2Bold;
      color: black;
    }
  `;

  return (
    <>
      {content?.map((paragraph, idx) => (
        <ParagraphWrapper
          className='secondary-text-signup-v4'
          key={`fairchance-paragraph-${idx}`}
          sx={{
            typography: {
              xs: { fontSize: '16px' },
              lg: { fontSize: '18px' },
            },
          }}
        >
          {paragraph}
        </ParagraphWrapper>
      ))}
    </>
  );
};

export {
  FairChanceBody,
  FAIRCHANCE_PARAGRAPHS,
  FAIRCHANCE_PARAGRAPHS_CUSTOM,
  FAIRCHANCE_PARAGRAPHS_CUSTOM_PLANNING_CENTER,
  FAIRCHANCE_PARAGRAPHS_CUSTOM_WELLSKY,
  FAIRCHANCE_PARAGRAPHS_CUSTOM_RIPPLING,
};
