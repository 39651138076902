import React from 'react';

import { Icon, ScreeningSectionTitle } from '../shared/ListItemElements';
import courtWeights from '../shared/svg/court-weights.svg';
import ScreeningsSectionAccordion from '../shared/ScreeningsSectionAccordion';
import CountyCivilSearch from './CivilScreenings/CountyCivilSearch';
import FederalCivilSearch from './CivilScreenings/FederalCivilSearch';
import { ADD_SCREENINGS } from '../../../../../constants/identifiers';

const Screenings = () => (
  <ul>
    <CountyCivilSearch />
    <FederalCivilSearch />
  </ul>
);

const CivilRecords = () => {
  return (
    <ScreeningsSectionAccordion
      IconComponent={<Icon src={courtWeights} className='screening-icon' />}
      TitleComponent={
        <ScreeningSectionTitle>Civil Records Searches</ScreeningSectionTitle>
      }
      ScreeningsComponent={<Screenings />}
      dataTestId={ADD_SCREENINGS.domesticCivilRecords}
    />
  );
};

export default CivilRecords;
