import React from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  ButtonProps as MuiButtonProps,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Close from '@mui/icons-material/Close';

import colors from '../../../../../styles/_colors.scss';
import { ADD_SCREENINGS } from '../../../../../constants/identifiers';

const childrenStyles = {
  overflow: 'auto',
  scrollbarWidth: 'none' /* Firefox */,
  msOverflowStyle: 'none' /* Internet Explorer and Edge */,
  '&::-webkit-scrollbar': {
    display: 'none' /* For Google Chrome, Safari and Opera */,
  },
  flexGrow: '1',
};

const buttonStyles = {
  borderRadius: '0',
  border: `2px solid ${colors.navy2}`,
  color: colors.navy2,
  textTransform: 'none' as const,
  fontFamily: 'National2Medium',
  fontSize: '16px',
  height: '40px',
  marginLeft: 'auto',
  alignSelf: 'center',
  '&:hover': {
    border: `2px solid ${colors.navy2}`,
  },
};

interface ButtonProps extends MuiButtonProps {
  disabled?: boolean;
}

const AddButton = styled(({ disabled, ...props }: ButtonProps) => (
  <Button disabled={disabled} {...props} />
))({
  ...buttonStyles,
  backgroundColor: colors.navy2,
  color: colors.white,
  '&:hover': {
    border: `2px solid ${colors.navy2}`,
    backgroundColor: colors.navy2,
  },
  marginRight: 'auto',
  marginLeft: 'unset',
  '&.Mui-disabled': {
    opacity: '0.4',
    color: colors.white,
  },
});

const CancelButton = styled(Button)({
  ...buttonStyles,
  marginRight: '32px',
});

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAddClick?: () => void;
  onCancelClick?: () => void;
  onClose?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
};

const AddScreeningsModal = ({
  open,
  setOpen,
  onClose = () => setOpen(false),
  onAddClick,
  onCancelClick,
  disabled,
  children,
}: Props) => {
  const match800px = useMediaQuery('(min-height:800px)');
  const match640px = useMediaQuery('(min-height:640px)');

  let height;
  if (match800px) {
    height = '80%';
  } else if (match640px) {
    height = '640px';
  } else {
    height = '100%';
  }

  const modalStyle = {
    height,
    fontFamily: 'National2Regular',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '780px',
    borderRadius: '6px',
    border: 'none',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    padding: '64px 40px 40px 40px',
    display: 'flex',
    flexDirection: 'column',
  };

  if (!open) return null;
  return (
    <Modal
      open={open}
      onClose={onClose}
      data-testid={ADD_SCREENINGS.addScreeningModal}
    >
      <Box sx={modalStyle}>
        <IconButton
          style={{ position: 'absolute', top: '8px', right: '8px' }}
          onClick={onCancelClick}
          data-testid={ADD_SCREENINGS.modalClose}
        >
          <Close />
        </IconButton>
        <Typography
          id='modal-modal-title'
          variant='h6'
          component='h4'
          style={{
            fontWeight: 500,
            fontSize: '30px',
            color: colors.navy4,
            fontFamily: 'National2Medium',
          }}
        >
          Customize with add-ons
        </Typography>
        <Typography
          id='modal-modal-description'
          style={{
            margin: '20px 0',
            fontFamily: 'National2Regular',
            fontWeight: 400,
            fontSize: '18px',
            color: colors.navy4,
          }}
        >
          Add more screenings to customize your package and meet any additional
          requirements for a specific role, location, or industry. (optional)
        </Typography>
        <Box sx={childrenStyles}>{children}</Box>
        <Box
          sx={{
            display: 'flex',
            marginTop: 'auto',
            padding: '40px 0 0 0',
            justifyContent: 'center',
          }}
        >
          <CancelButton
            onClick={onCancelClick}
            data-testid={ADD_SCREENINGS.modalCancel}
          >
            Cancel
          </CancelButton>
          <AddButton
            disabled={disabled}
            onClick={onAddClick}
            data-testid={ADD_SCREENINGS.modalAdd}
          >
            Add
          </AddButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddScreeningsModal;
