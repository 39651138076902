import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { FLAG_KEYS } from '../../services/flagr';
import CheckrSignupEmbed from '../../components/views/EmbedSignup/CheckrSignupEmbed';
import { ABSwitcher } from '../wrappers';
import { observer } from 'mobx-react';
import Signup from '../views/SignupV3/Signup';

const EmbedSwitcher = observer(({ componentName, ...props }) => {
  const { store } = props;
  const flagr = toJS(store.flagr);
  const flagrKey =
    flagr?.flags?.[FLAG_KEYS.USE_EMBED_FOR_PARTNER_SIGNUP]?.variantKey;
  const shouldShowEmbed = flagrKey === 'on';

  return shouldShowEmbed ? (
    <CheckrSignupEmbed {...props} />
  ) : (
    <ABSwitcher componentName={componentName} {...props} />
  );
});

EmbedSwitcher.propTypes = {
  componentName: PropTypes.string.isRequired,
};

export default EmbedSwitcher;
