import React from 'react';
import { Button, Typography, Box, FormHelperText } from '@mui/material/';
import { Clear, InfoOutlined, ErrorOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { INVITES } from '../../../../constants/identifiers';
import { MAX_CANDIDATES } from './constants';
import styled from 'styled-components';

const CandidateInPutContainer = styled(Box)`
  display: flex;
  position: relative;
  max-width: 422px;
  align-items: center;

  &:hover #remove-candidate {
    display: inline-flex;
  }
`;

const CandidateInput = styled.input`
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #334559;
  height: 48px;
  background: #fff;
  border: 1px solid;
  border-color: ${props => (props.hasError ? '#CD2F1D' : '#e1e6eb')};

  &:focus {
    outline: none;
    border: ${props =>
      props.hasError ? ' 2px solid #CD2F1D' : '1px solid #009bb5'} !important;
  }
  ::placeholder {
    color: #b2bdcc;
  }

  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const AddCandidateText = styled(Typography)`
  font-size: 15px;
  font-weight: 600;
  text-transform: none;
  font-family: National2Regular;

  @media (max-width: 900px) {
    font-size: 13px;
  }

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

const ErrorMessage = ({ candidateErrors, serverError, fields }) => {
  let errorMessage = '';
  let dataTestId = '';

  if (
    candidateErrors.filter(Boolean).length === fields.length &&
    candidateErrors.every(err => err?.value?.type === 'required')
  ) {
    errorMessage = 'Enter a candidate email';
    dataTestId = INVITES.MISC.minimumEmailsMessage;
  } else if (serverError?.message) {
    errorMessage = `Error: ${serverError.message}`;
    dataTestId = INVITES.ERROR.signup;
  }

  return (
    errorMessage.length > 0 && (
      <div>
        <Typography
          textAlign='center'
          marginTop='16px'
          sx={{ fontSize: { xs: '14px', sm: '14px', md: '16px' } }}
          color='#CD2F1D'
          data-testid={dataTestId}
        >
          <ErrorOutlined
            style={{
              fontSize: '1rem',
              marginTop: '-2px',
            }}
          />{' '}
          {errorMessage}
        </Typography>
      </div>
    )
  );
};

const CandidateEmailGrid = () => {
  const {
    formState: {
      errors: { candidates: candidateErrors = [], serverError = {} },
    },
    register,
    trigger,
    control,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'candidates', // must match defaultValues field
  });

  return (
    <>
      <div>
        {fields.map((field, index) => (
          <Box key={field.id} sx={{ marginBottom: '16px', height: '65px' }}>
            <CandidateInPutContainer key={`candidate-field-${index}`}>
              <CandidateInput
                className='invites-input'
                hasError={candidateErrors[index]}
                data-testid={`${INVITES.INPUT.candidate}-${index}`}
                placeholder='candidate@email.com'
                {...register(`candidates.${index}.value`)}
              />
              {fields.length > 1 && (
                <Button
                  id='remove-candidate'
                  data-testid={`${INVITES.INPUT.removeCandidate}-${index}`}
                  onClick={() => remove(index)}
                  sx={{
                    position: 'absolute',
                    right: '5px',
                    minWidth: '40px',
                    display: 'none',
                  }}
                >
                  <Clear
                    style={{
                      color: 'rgba(26, 32, 38, 0.65)',
                      fontSize: '18px',
                      fontWeight: '400',
                    }}
                  />
                </Button>
              )}
            </CandidateInPutContainer>
            {candidateErrors[index] && (
              <FormHelperText
                data-testid={`${INVITES.ERROR.candidate}-${index}`} // or this {INVITES.MISC.emailsInvalidMessage}?
                sx={{ color: '#CD2F1D', marginTop: 0, paddingLeft: '16px' }}
              >
                {candidateErrors[index]?.value?.message}
              </FormHelperText>
            )}
          </Box>
        ))}
        {fields.length < MAX_CANDIDATES && (
          <Button
            data-testid={INVITES.MISC.candidateEmailAddButton}
            type='button'
            variant='outlined'
            disableRipple
            startIcon={<AddIcon style={{ width: '16px', height: '16px' }} />}
            onClick={() => {
              /*
                if user never interacted with first input field before adding
                new one trigger validation for the first input field
              */
              if (fields.length < 2) trigger(`candidates.0.value`);
              append({ value: '' });
            }}
            sx={{
              color: '#1A2026',
              marginTop: '10px',
              background: '#fff',
              borderColor: '#e1e6eb',
              boxShadow: `0px 2px 4px -2px rgba(72, 82, 94, 0.14)`,
              '&:hover': { borderColor: '#e1e6eb', backgroundColor: '#f7f9fa' },
              '&:active': { backgroundColor: '#f2f5f7' },
            }}
          >
            <AddCandidateText>Add another</AddCandidateText>
          </Button>
        )}
      </div>
      {!candidateErrors.length && fields.length === MAX_CANDIDATES && (
        <div>
          <Typography
            textAlign='center'
            marginTop='16px'
            fontSize='16px'
            color='#334559'
            data-testid={INVITES.MISC.maxCandidatesMessage}
          >
            <InfoOutlined
              style={{
                fontSize: '1rem',
                marginTop: '-2px',
                color: '#334559',
              }}
            />{' '}
            Maximum candidates reached. Order more background checks in the
            dashboard.
          </Typography>
          <br />
        </div>
      )}
      {(Boolean(candidateErrors.length) ||
        Boolean(Object.keys(serverError).length)) && (
        <ErrorMessage
          candidateErrors={candidateErrors}
          serverError={serverError}
          fields={fields}
        />
      )}
    </>
  );
};

export default CandidateEmailGrid;
