/* global __env */

import auth0 from 'auth0-js';

const auth0Singleton = new auth0.WebAuth({
  clientID: __env.AUTH0_CLIENT_ID,
  domain: __env.AUTH0_DOMAIN,
  redirectUri: __env.AUTH0_REDIRECT_URI,
  responseType: 'token',
  scope: 'openid',
  audience: __env.AUTH0_AUDIENCE,
  __tryLocalStorageFirst: true, // Attempts to use local storage before using cookie storage
});

export default auth0Singleton;
