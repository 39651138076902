import React from 'react';
import { INVITES } from '../../../../constants/identifiers';

type ServerErrorMsgProps = {
  message: string;
};
const ServerErrorMsg = ({ message }: ServerErrorMsgProps) => {
  return (
    <div className='error-msg text-danger' data-testid={INVITES.ERROR.signup}>
      <b>Error:</b> {message}
    </div>
  );
};

export default ServerErrorMsg;
