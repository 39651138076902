/**
 * @name usePluralize
 * @description usePluralize hook takes a count and returns a function that takes
 *  word and optional plural params and returns either default word + 's' or
 *  optionally passed plural param if count > 1
 *  Usage:
 *   function App() {
 *     const packages = [{},{}];
 *     const pluralize = usePluralize(packages.length);
 *     return <div>{`${pluralize('This', 'These)} International Checkr Background Check ${pluralize('Package')}`}</div>;
 *   }
 * @params {number} count
 * @returns { (word:string, plural?:string) => string }
 */
export const usePluralize =
  (count = 1) =>
  (word, plural = word + 's') => {
    return [1, -1].includes(Number(count)) ? word : plural;
  };
