import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import find from 'lodash/find';
import values from 'lodash/values';
import upperFirst from 'lodash/upperFirst';
import * as fields from '../fields';
import Label from './Label';
import nextId from 'react-id-generator';
import { paramCase } from 'param-case';
import TooltipForMobile from './TooltipForMobile';
import { ANALYTICS_EVENTS } from '../../services/analytics';

@observer
export default class Input extends Component {
  htmlId = `input-${nextId()}`;

  handleChange = e => {
    const key = this.props.name;
    const value = e.target ? e.target.value : e.field.value();

    if (this.props.onChange) {
      this.props.onChange(key, value);
    }
    if (this.props.form && this.props.form.handleChange) {
      this.props.form.handleChange(key, value);
    }
  };

  trackFieldFocused(fieldName) {
    const state = this.props.form._state || this.props.form.state;
    const trackableFields = {
      'Doing business as': ANALYTICS_EVENTS.DBA_FIELD_SELECTED,
    };

    if (fieldName in trackableFields) {
      state.trackAnalyticsEvent(trackableFields[fieldName]);
    }
  }

  render() {
    const {
      form,
      name,
      label,
      labelSubText,
      inlineTooltip,
      tooltip,
      type,
      autoFocus,
      customClassName,
      dataTestId,
      errorDataTestId,
    } = this.props;
    let { field } = this.props;

    const hasError = form && form.error && form.error[name];
    const inputClassName = `form-control ${customClassName || ''} ${
      hasError ? 'form-control-danger' : ''
    } input-${paramCase(name)}`;
    const groupClassName = `form-group ${hasError ? 'has-danger' : ''}`;

    if (typeof field === 'string') {
      field = find(values(fields), f => f.fieldName === field);
    }

    const FieldOrInput = field || 'input';
    const input = (
      <div
        className={`${inlineTooltip ? 'cd-input-container' : ''}`}
        onFocus={() => this.trackFieldFocused(label)}
      >
        <FieldOrInput
          id={this.htmlId}
          dataTestId={FieldOrInput === field && dataTestId}
          data-testid={dataTestId}
          type={type || 'text'}
          className={inputClassName}
          name={name}
          disabled={this.props.disabled}
          value={(form && form[name]) || ''}
          placeholder={this.props.placeholder || ''}
          onChange={this.handleChange}
          autoFocus={autoFocus}
        />
        {inlineTooltip && <TooltipForMobile inlineInput tooltip={tooltip} />}
      </div>
    );

    let error = null;
    if (hasError) {
      error = (
        <small data-testid={errorDataTestId} className='form-control-feedback'>
          {upperFirst(form.error[name][0])}
        </small>
      );
    }
    return (
      <div className={groupClassName}>
        {label && (
          <Label
            text={label}
            subText={labelSubText}
            tooltip={!inlineTooltip && tooltip}
            htmlFor={this.htmlId}
          />
        )}
        {this.props.children}
        {input}
        {error}
      </div>
    );
  }
}

Input.propTypes = {
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.object,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelSubText: PropTypes.string,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  customClassName: PropTypes.string,
  inlineTooltip: PropTypes.bool,
  dataTestId: PropTypes.string,
};
