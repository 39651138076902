import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export default class TooltipForMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    const { showTooltip } = this.state;
    this.setState({
      showTooltip: !showTooltip,
    });
  }

  render() {
    const { tooltip } = this.props;
    const { showTooltip } = this.state;

    return (
      <span>
        <span
          data-tip={tooltip}
          data-for='cd-tooltip'
          className='cd-tooltip-icon input'
        >
          <i className='fa fa-question-circle' onClick={this.toggleTooltip} />
          <ReactTooltip
            place='bottom'
            id='cd-tooltip'
            className='text-left cd-tooltip tooltip-shadow'
            clickable
            effect='solid'
            type='dark'
            html
          />
        </span>
        {showTooltip && (
          <span
            className='cd-tooltip-mobile'
            dangerouslySetInnerHTML={{ __html: tooltip }}
          />
        )}
      </span>
    );
  }
}

TooltipForMobile.propTypes = {
  tooltip: PropTypes.string.isRequired,
};
