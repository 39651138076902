import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../styles/_colors.scss';

export const List = styled.div`
  font-size: 12px !important;
  line-height: 14px !important;
  color: ${colors.slate6} !important;
  text-align: left;
  padding-top: 3px;
`;

const BulletContainer = styled.div`
  line-height: 14px !important;
  min-height: 20px;
`;

const Bullet = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${colors.aqua3};
  margin-right: 8px;
  margin-top: 8px;
`;

export const Flex = styled.div`
  display: flex;
  padding-top: 0.25rem;
`;

export const Box = styled.div`
  width: 50%;
  min-height: 100px;
  background: ${colors.slate1};
  border-radius: 8px;
  padding: 16px;
  align-self: stretch;

  ul {
    padding-inline-start: 0;
  }
`;

const InvisibleBox = styled(Box)`
  background: white;
`;

export const OnlyAdvantagesBox = styled.div`
  width: 100%;
  min-height: 100px;
  background: ${colors.slate1};
  border-radius: 8px;
  padding: 16px;
  align-self: stretch;
`;

export const BoxDivider = styled.div`
  width: 20px;
`;

export const ScreeningInfo = styled.p`
  color: ${colors.slate6} !important;
  text-align: left;
  padding-bottom: 0.25rem;
`;

export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  width: 66px;
  height: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.navy3};
  margin-bottom: 8px;
`;

export const BulletPoint = ({ text }: { text: string }) => {
  return (
    <Flex>
      <BulletContainer>
        <Bullet />
      </BulletContainer>
      <List>{text}</List>
    </Flex>
  );
};

type ContentGuardProps = {
  children: React.ReactNode;
  length: number;
};

const ContentGuard = ({ children, length }: ContentGuardProps) => {
  if (length) return <>{children}</>;
  return <InvisibleBox />;
};

type Props = {
  screeningInfo?: string;
  advantages?: Array<string>;
  considerations?: Array<string>;
};

const AdvantagesAndConsiderations: React.FC<Props> = ({
  screeningInfo = '',
  advantages = [],
  considerations = [],
}) => (
  <div style={{ padding: '0 22px 10px 44px' }}>
    <ScreeningInfo>{screeningInfo}</ScreeningInfo>
    <Flex>
      <ContentGuard length={advantages.length}>
        <Box>
          <BoxHeader>Advantages</BoxHeader>
          <ul>
            {advantages.map((advantage: string) => (
              <BulletPoint key={advantage} text={advantage} />
            ))}
          </ul>
        </Box>
      </ContentGuard>
      <BoxDivider />
      <ContentGuard length={considerations.length}>
        <Box>
          <BoxHeader>Considerations</BoxHeader>
          <ul>
            {considerations.map((consideration: string) => (
              <BulletPoint key={consideration} text={consideration} />
            ))}
          </ul>
        </Box>
      </ContentGuard>
    </Flex>
  </div>
);

export default AdvantagesAndConsiderations;
