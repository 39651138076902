/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { SetStateAction } from 'react';
import ScreeningAccordion from '../ScreeningAccordion';
import Screening from '../Screening';
import industry from '../svgs/4-industry.svg';
import { PHASE2_ADD_ON_OPTIONS_CONFIG } from '../../data/packages';

type Props = {
  expanded: string;
  handleChange: (
    panel: SetStateAction<string>,
    category: string,
  ) => (event: unknown, isExpanded: boolean) => void;
  slug: string;
};

const Screenings = ({ expanded, handleChange, slug }: Props) => {
  const { facis_search } = PHASE2_ADD_ON_OPTIONS_CONFIG;
  return (
    <ScreeningAccordion
      icon={<img src={industry} alt='icon' height={24} width={24} />}
      title='Are you hiring in a highly regulated industry? '
      subtitle='Check industry-specific registries to comply with regulations in areas such as banking and healthcare'
      expanded={expanded === 'panel4'}
      onChange={handleChange('panel4', 'Regulations')}
      dataTestId='screening-accordion-4'
    >
      <Screening
        name={facis_search.displayName}
        description={facis_search.description}
        price={facis_search.price}
        screening='facis_search'
        slug={slug}
      />
    </ScreeningAccordion>
  );
};

export default Screenings;
