import React from 'react';
import { Card } from '@mui/material/';
import { PARTNER_PACKAGES } from '../../../constants/identifiers';

const PackageCard = ({ testId, isSelected, children }) => {
  const primaryColor =
    document.documentElement.style.getPropertyValue('--primary-color');
  const activeSelectionColor =
    primaryColor === '#043F93' ? '#117091' : primaryColor;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '2px solid',
        borderColor: 'transparent',
        height: '100%',
      }}
      key={testId}
      style={{
        backgroundColor: isSelected ? activeSelectionColor : '#FFF',
      }}
      data-testid={`${PARTNER_PACKAGES.card}-${testId}`}
    >
      {children}
    </Card>
  );
};

export default PackageCard;
