import PromotionCodeInput from './PromotionCodeInput';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import {
  customBankPaymentErrorMsgs,
  fallbackBankPaymentErrorMsg,
} from '../CheckrDirect/data/bankPaymentErrors';

import { observer } from 'mobx-react';
import { PAYMENT } from '../../../constants/identifiers';
@observer
export default class BankAccountPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  handleFormChange(e) {
    this.props.form.handleChange(e.target.name, e.target.value, 'V4');
  }

  render() {
    const { store, form } = this.props;

    return (
      <div
        className='form-bank-account'
        data-testid={PAYMENT.MISC.formBankAccount}
      >
        <Grid xs={12} className='d-flex justify-content-center' item>
          <Box
            className='d-flex flex-column gap-0'
            sx={{ width: { xs: '100%', lg: '450px' } }}
          >
            {!!form.paymentError && (
              <div
                data-testid={PAYMENT.ERROR.paymentError}
                className='payment-error'
              >
                <ErrorIcon fontSize='12px' />
                <span
                  className='payment-error-msg'
                  data-testid={PAYMENT.ERROR.bankPaymentError}
                >
                  {customBankPaymentErrorMsgs[form.error?.error] ||
                    fallbackBankPaymentErrorMsg}
                </span>
              </div>
            )}
            <Typography
              textAlign='left'
              fontWeight='500'
              fontSize='16px'
              color='#001730'
              fontFamily='National2Medium'
              sx={{ my: '8px' }}
            >
              Account Type
            </Typography>
            <FormControl>
              <RadioGroup className='d-flex' value={form.accountHolderType} row>
                <FormControlLabel
                  form={form}
                  value='company'
                  control={
                    <Radio
                      inputProps={{
                        'data-testid': PAYMENT.INPUT.corporate,
                      }}
                    />
                  }
                  label={
                    <Typography color='#001730' fontFamily='National2Regular'>
                      Corporate
                    </Typography>
                  }
                  name='accountHolderType'
                  onChange={this.handleFormChange}
                />
                <FormControlLabel
                  form={form}
                  value='individual'
                  control={
                    <Radio
                      inputProps={{
                        'data-testid': PAYMENT.INPUT.personal,
                      }}
                    />
                  }
                  label={
                    <Typography color='#001730' fontFamily='National2Regular'>
                      Personal
                    </Typography>
                  }
                  name='accountHolderType'
                  sx={{ marginLeft: '29px' }}
                  onChange={this.handleFormChange}
                />
              </RadioGroup>
            </FormControl>
            <Typography
              textAlign='left'
              fontWeight='500'
              fontSize='16px'
              color='#001730'
              fontFamily='National2Medium'
              sx={{ my: '8px' }}
            >
              Account information
            </Typography>
            <TextField
              className='input-signup-v4'
              label='Name on account'
              value={form.name || ''}
              name='name'
              error={Boolean(form.error?.name)}
              helperText={form.error?.name}
              inputProps={{
                maxLength: 35,
                'data-testid': PAYMENT.INPUT.bankName,
              }}
              InputLabelProps={{ shrink: true }}
              FormHelperTextProps={{
                'data-testid': PAYMENT.ERROR.bankNameFieldError,
              }}
              onChange={this.handleFormChange}
            />
            <TextField
              className='input-signup-v4'
              label='Routing number'
              name='routingNumber'
              value={form.routingNumber || ''}
              error={Boolean(form.error?.routingNumber)}
              helperText={form.error?.routingNumber}
              inputProps={{
                maxLength: 9,
                'data-testid': PAYMENT.INPUT.routingNumber,
              }}
              InputLabelProps={{ shrink: true }}
              FormHelperTextProps={{
                'data-testid': PAYMENT.ERROR.routingNumberFieldError,
              }}
              onChange={this.handleFormChange}
            />
            <TextField
              className='input-signup-v4'
              label='Account number'
              name='accountNumber'
              value={form.accountNumber || ''}
              error={Boolean(form.error?.accountNumber)}
              helperText={form.error?.accountNumber}
              inputProps={{
                maxLength: 12,
                'data-testid': PAYMENT.INPUT.account,
              }}
              InputLabelProps={{ shrink: true }}
              FormHelperTextProps={{
                'data-testid': PAYMENT.INPUT.accountFieldError,
              }}
              onChange={this.handleFormChange}
            />
            <TextField
              className='input-signup-v4'
              label='Confirm account number'
              name='confirmedAccountNumber'
              value={form.confirmedAccountNumber || ''}
              error={Boolean(form.error?.confirmedAccountNumber)}
              helperText={form.error?.confirmedAccountNumber}
              inputProps={{
                maxLength: 12,
                'data-testid': PAYMENT.INPUT.accountConfirmed,
              }}
              InputLabelProps={{ shrink: true }}
              FormHelperTextProps={{
                'data-testid': PAYMENT.ERROR.accountConfirmedFieldError,
              }}
              onChange={this.handleFormChange}
            />
            {store.promoCodeActive && <PromotionCodeInput form={form} />}
          </Box>
        </Grid>
      </div>
    );
  }
}

BankAccountPaymentForm.propTypes = {
  form: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};
