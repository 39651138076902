import React from 'react';

import { ScreeningPrice, ScreeningTitle } from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import ScreeningAccordion from '../../shared/ScreeningAccordion';
import { useAddScreeningsContext } from '../../shared/context';

const FederalCivilSearch = () => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    useAddScreeningsContext();

  const screeningInfo =
    'Searches the US District Court level for civil cases filed in federal court';

  const advantages = [
    'Helps provide a comprehensive screening for financial and managerial roles',
    'Centralized source for all federal records',
  ];

  const considerations = ['Limited to 10 years of history'];

  return (
    <ScreeningAccordion
      screeningInfo={screeningInfo}
      advantages={advantages}
      considerations={considerations}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ScreeningTitle>Federal Civil Records Search</ScreeningTitle>
        <ScreeningPrice priceByType='federal_civil_search' />
        <AddScreeningsAction
          screeningType='federal_civil_search'
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          disabledScreenings={disabledScreenings}
          addedScreenings={addedScreenings}
        />
      </div>
    </ScreeningAccordion>
  );
};

export default FederalCivilSearch;
