import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

import colors from '../../../../../styles/_colors.scss';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderTop: `2px solid ${colors.slate2}`,
  boxShadow: 'none',
  borderRadius: 0,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon style={{ color: colors.aqua3 }} />}
    {...props}
  />
))(() => ({
  minHeight: 'unset',
  height: '60px',
}));

type Props = {
  IconComponent: React.ReactNode;
  TitleComponent: React.ReactNode;
  ScreeningsComponent: React.ReactNode;
  dataTestId?: string;
};

const ScreeningsSectionAccordion: React.FC<Props> = ({
  IconComponent,
  TitleComponent,
  ScreeningsComponent,
  dataTestId,
}) => (
  <Accordion>
    <AccordionSummary
      aria-controls='panel1a-content'
      id='panel1a-header'
      data-testid={dataTestId}
    >
      {IconComponent}
      {TitleComponent}
    </AccordionSummary>
    {ScreeningsComponent}
  </Accordion>
);

export default ScreeningsSectionAccordion;
