import { observable, action, makeObservable } from 'mobx';
import { get } from 'lodash';
import * as Sentry from '@sentry/browser';
import ValidationStore from '../ValidationStore';
import { authorize } from './helpers/ConnectHelper';
import {
  createPromotionCode,
  validatePromotionCode,
} from './helpers/PromotionCode';
import { FLAG_KEYS } from '../../services/flagr';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../services/analytics';
import { errorValuesForTracking } from './helpers/TrackErrorValues';

export const computeErrorKeyForStripeParam = stripeParamName => {
  const stripeToCheckrMapping = {
    exp_year: 'expiration',
    exp_month: 'expiration',
    cvc: 'cvc',
    name: 'name',
    number: 'number',
    address_zip: 'zipcode',
  };

  // if the error is for a visible form attribute, then show it next to it, else
  // show it at the global form level.
  return stripeToCheckrMapping[stripeParamName] || 'error';
};

export default class CreditCardPaymentStore extends ValidationStore {
  @observable name;
  @observable number;
  @observable zipcode;
  @observable cvc;
  @observable expiration = null;
  @observable token;
  @observable promotionCode;
  @observable promotionCodeApplied = false;
  @observable promoLoading = false;
  @observable paymentError = false;

  static messages = {
    required: ':attribute cannot be blank.',
    'required.cvc': 'Security code cannot be blank.',
    'required.expMonth': 'Expiration should be formatted MM/YY.',
    'required.expYear': 'Expiration should be formatted MM/YY.',
    'required.number': 'Card number cannot be blank.',
    'required.name': 'Name cannot be blank.',
    'required.zipcode': 'Zip code cannot be blank.',
    'required.expiration': 'Expiration cannot be blank.',
  };

  static attributes = {
    expMonth: 'expiration',
    expYear: 'expiration',
    promotion_code: 'promotionCode',
  };

  get rules() {
    return {
      name: 'required',
      number: 'required',
      zipcode: 'required',
      cvc: 'required',
      expMonth: 'required',
      expYear: 'required',
    };
  }

  get expMonth() {
    return this.expiration ? this.expiration.month : null;
  }

  get expYear() {
    return this.expiration ? this.expiration.year : null;
  }

  constructor(state) {
    super();
    this.state = state;
    makeObservable(this);
  }

  @action handleChange = (name, value) => {
    this[name] = value;

    if (this.error) {
      this.debounceValidation(); // Only if was submitted should validate while typing
    }

    this.debounceValidity();
  };

  async trackPaymentSubmitted() {
    this.state.trackAnalyticsEvent(ANALYTICS_EVENTS.PAYMENT_SUBMITTED, {
      [ANALYTICS_PROPERTIES.PAYMENT_METHOD]: 'Credit/Debit',
    });
  }

  trackSignupPaymentSubmitted() {
    this.state.trackAnalyticsEvent(
      'Self-Serve Customer Signup Payment Information Completed',
      {},
    );
  }

  trackErrors(errors) {
    const errorMessages = errorValuesForTracking(errors);

    this.state.trackAnalyticsEvent(ANALYTICS_EVENTS.ERROR_DISPLAYED_PAYMENT, {
      [ANALYTICS_PROPERTIES.ERROR_MESSAGE]: errorMessages,
    });
  }

  trackSignupErrors() {
    this.state.trackAnalyticsEvent('Self-Serve Customer Signup Error Viewed', {
      'Error Name': this.error
        ? Object.values(this.error).join(',')
        : 'Server Error',
      'Page Name': 'payment',
    });
  }

  trackPaymentInfoNextClicked() {
    this.state.trackAnalyticsEvent(
      'Self-Serve Customer Signup Payment Information Next Page Clicked',
      {
        'Self Serve Signup Version': 'Simplify Order Page',
      },
    );
  }

  async submit() {
    this.trackPaymentInfoNextClicked();
    this.loading = true;
    if (this.state.isSignup) {
      this.state.performanceObserver.setStartLoadTime();
    }

    if (!this.state.isLive() || !this.state.isBillable()) {
      await this.state.utms.track();
      if (this.state.isCheckrDirectA || this.state.isGoodhireWebsite) {
        Sentry.addBreadcrumb(
          'Not live account. CC Payment submitted redirecting to invite',
        );
        this.state.toStep('invites');
        await authorize(this.state);
      } else {
        Sentry.addBreadcrumb(
          'Not live account. CC Payment submitted redirecting to submission',
        );
        this.state.toStep('submission');
      }
      return;
    }

    try {
      this.validate();
      this.token = await this.createStripeToken();
      await this.createCard();
      await this.state.utms.track();

      if (this.state.isSignup) {
        this.trackSignupPaymentSubmitted();
      } else {
        await this.trackPaymentSubmitted();
      }

      if (this.promotionCode) {
        await createPromotionCode(this.state, this.promotionCode);
      }

      this.error = null;
      this.paymentError = false;
      if (this.state.isCheckrDirectA || this.state.isGoodhireWebsite) {
        try {
          await authorize(this.state);

          Sentry.addBreadcrumb(
            'Checkr Signup Payment submitted redirecting to invite',
          );
          this.loading = false;
          this.state.toStep('invites');
        } catch (error) {
          // If the authorization step fails, queueing invites will fail. Redirect to submission.
          Sentry.addBreadcrumb(
            'Checkr Signup Authorize failed redirecting to submission',
          );
          this.loading = false;
          this.state.toStep('submission');
        }
      } else {
        Sentry.addBreadcrumb('Payment submitted redirecting to submission');
        this.loading = false;
        this.state.toStep('submission');
      }
    } catch (error) {
      if (this.state.isSignup) {
        this.trackSignupErrors(error);
        this.state.performanceObserver.setEndLoadTime();

        if (error.isAuthorizationCall) {
          this.state.initialAuthorizationFailed = true;
        }
      } else {
        this.trackErrors(error);
      }

      this.loading = false;
      this.error = this.friendlyErrors(error);
      if ('_api' in this.error) {
        this.paymentError = true;
      }
      // Only capture Sentry exceptions for 5xx server errors
      if (error?.response?.status >= 500) {
        Sentry.captureException(error, {
          tags: { 'add-payment-error': 'credit-card' },
        });
      }
    }
  }

  setCardHolderName = () => {
    this.name = get(this.state, 'auth.user.full_name');
  };

  async validatePromotionCode() {
    this.promoLoading = true;
    this.error = null;
    this.promotionCodeApplied = false;

    try {
      await validatePromotionCode(this.state, this.promotionCode);
      this.promoLoading = false;
      this.promotionCodeApplied = true;
    } catch (errors) {
      if (this.state.isSignup) {
        this.trackSignupErrors(errors);
      } else {
        this.trackErrors(errors);
      }

      this.promoLoading = false;
      this.error = this.friendlyErrors(errors);
    }
  }

  createStripeToken = () => {
    const card = {
      name: this.name,
      number: this.number,
      cvc: this.cvc,
      exp_month: this.expiration ? this.expiration.month : null,
      exp_year: this.expiration ? this.expiration.year : null,
      address_zip: this.zipcode,
    };

    return new Promise((resolve, reject) => {
      window.Stripe.card.createToken(card, (status, response) => {
        if (status === 200) {
          resolve(response);
        } else {
          const errorKey = computeErrorKeyForStripeParam(response.error.param);

          const errors = {
            _stripe: response.error,
            [errorKey]: [response.error.message],
          };
          reject(errors);
        }
      });
    });
  };

  createCard = () => {
    const clientId =
      this.state.isCheckrDirectA || this.state.isGoodhireWebsite
        ? this.state.application.client_id
        : this.state.router.params.client_id;

    const request = {
      method: 'post',
      url: `/billing/accounts/${this.state.auth.user.account.id}/credit_cards`,
      data: {
        client_id: clientId,
        stripe_token: this.token.id,
        name: this.token.card.name,
        last4: this.token.card.last4,
        exp_month: this.token.card.exp_month,
        exp_year: this.token.card.exp_year,
      },
    };

    return new Promise((resolve, reject) => {
      this.state
        .fetch(request)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          this.error = [error?.response?.data?.error] || [error.message];

          reject({
            _api: error?.response?.data?.error,
            _status: error?.response?.status,
            error: error?.response?.data?.error || error.message,
          });
        });
    });
  };
}
