/* eslint-disable max-len */
import React, { SetStateAction } from 'react';
import ScreeningAccordion from '../ScreeningAccordion';
import Screening from '../Screening';
import mvr from '../svgs/2-mvr.svg';
import { PHASE2_ADD_ON_OPTIONS_CONFIG } from '../../data/packages';

type Props = {
  expanded: string;
  handleChange: (
    panel: SetStateAction<string>,
    category: string,
  ) => (event: unknown, isExpanded: boolean) => void;
  slug: string;
};

const Screenings = ({ expanded, handleChange, slug }: Props) => {
  const { displayName, description, price } =
    PHASE2_ADD_ON_OPTIONS_CONFIG.motor_vehicle_report;
  return (
    <ScreeningAccordion
      icon={<img src={mvr} alt='icon' height={20} width={24} />}
      title='Does the role require driving?'
      subtitle='Meet government and industry regulations for operating a motor vehicle'
      expanded={expanded === 'panel2'}
      onChange={handleChange('panel2', 'Driving')}
      dataTestId='screening-accordion-2'
    >
      <Screening
        name={displayName}
        description={description}
        price={price}
        screening='motor_vehicle_report'
        slug={slug}
      />
    </ScreeningAccordion>
  );
};

export default Screenings;
