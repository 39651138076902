import PropTypes from 'prop-types';
import FieldKit from 'field-kit';
import TextField from './TextField';

export default class ExpiryDateField extends TextField {
  static fieldName = 'expiry';

  getFormatter() {
    return new FieldKit.ExpiryDateFormatter();
  }
}

ExpiryDateField.propTypes = TextField.getBasePropTypes();
ExpiryDateField.propTypes.value = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.string,
]);

ExpiryDateField.defaultProps = TextField.getBaseDefaultProps();
