import amplitudeExperiment from '../../../../services/amplitudeExperiment';
import {
  ADD_ON_OPTIONS_CONFIG,
  PHASE2_ADD_ON_OPTIONS_CONFIG,
} from '../data/packages';
import { AvailableAddOns, CalculatePriceHelperResult } from '../types';

export const isSelected = (value: string, slug: string): boolean => {
  return value === slug;
};

export const calculatePriceHelper = (
  basePrice: string,
  addOns: AvailableAddOns[],
): CalculatePriceHelperResult => {
  const enableFullAddons =
    amplitudeExperiment.getVariantValue('ab-06-full-add-ons-experience') ===
    'treatment';

  const basePriceFloat = parseFloat(basePrice.substring(1));
  let addOnsPriceFloat = 0;
  addOns?.forEach(addOn => {
    const addOnOption = enableFullAddons
      ? PHASE2_ADD_ON_OPTIONS_CONFIG[addOn]
      : ADD_ON_OPTIONS_CONFIG[addOn];
    addOnsPriceFloat += parseFloat(addOnOption?.price.substring(1)) ?? 0;
  });

  const totalPackagePriceFloat = basePriceFloat + addOnsPriceFloat;
  const totalPackagePrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(totalPackagePriceFloat);

  return {
    totalPackagePrice,
    addOnsPriceFloat,
    basePriceFloat,
    totalPackagePriceFloat,
  };
};
