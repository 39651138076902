import React from 'react';
import { INVITES } from '../../../../constants/identifiers';

type InvitesSubmitButtonProps = {
  showLoading: boolean;
};

type DisabledProps = {
  disabled: boolean;
};

const InvitesSubmitButton = (
  { showLoading }: InvitesSubmitButtonProps,
  { disabled }: DisabledProps,
) => {
  return (
    <button
      type='submit'
      data-testid={INVITES.MISC.submitButton}
      className={'cd-btn btn-success'}
      disabled={showLoading || disabled}
    >
      {showLoading ? (
        <>
          <i className='fa fa-spinner fa-spin fa-fw' />
          Loading...
        </>
      ) : (
        'Continue'
      )}
    </button>
  );
};

export default InvitesSubmitButton;
