import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material/';
import { INVITES } from '../../../../constants/identifiers';
import colors from '../../../../styles/_colors.scss';
import styled from 'styled-components';

type SelectLocationToggleProps = {
  selectedRegion: 'US' | 'nonUS';
  setSelectedRegion: (val: string) => void;
  trackLocationChange?: () => void;
};

const StyledToggleButton = styled(ToggleButton)`
  font-family: National2Regular;
  text-transform: none;
  height: 38px;
  ${({ selected }) => `
    color: ${selected ? colors.slate7 : colors.slate6};
    background-color: ${selected ? colors.white : '#efeff0'} !important;
    border: ${selected ? '1px solid rgba(0, 0, 0, 0.12)' : 0} !important;
    border-radius: ${selected ? '4px' : 0} !important;
    font-weight: ${selected ? 'bold' : 400};
    `};
`;

const SelectLocationToggle = ({
  selectedRegion,
  setSelectedRegion,
  trackLocationChange = () => {},
}: SelectLocationToggleProps) => {
  return (
    <ToggleButtonGroup
      value={selectedRegion}
      exclusive
      sx={{
        height: '40px',
        padding: '1px',
        background: '#efeff0',
        width: '100%',
      }}
    >
      <StyledToggleButton
        value='US'
        disableRipple
        data-testid={INVITES.MISC.usaButton}
        onClick={() => {
          trackLocationChange();
          setSelectedRegion('US');
        }}
        sx={{
          width: {
            xs: 'auto',
            sm: '260px',
          },
          px: {
            xs: '20px',
            sm: '0px',
          },
          fontSize: {
            xs: '12px',
            sm: '14px',
          },
        }}
      >
        United States
      </StyledToggleButton>
      <StyledToggleButton
        value='nonUS'
        disableRipple
        data-testid={INVITES.MISC.internationalButton}
        onClick={() => {
          trackLocationChange();
          setSelectedRegion('nonUS');
        }}
        sx={{
          width: {
            xs: 'auto',
            sm: '260px',
          },
          px: {
            xs: '20px',
            sm: '0px',
          },
          fontSize: {
            xs: '12px',
            sm: '14px',
          },
        }}
      >
        Outside of the US
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};

export default SelectLocationToggle;
