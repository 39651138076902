import { useQuery } from 'react-query';
import { createClient } from '../services/http';
import { useStore } from './useStore';

export function useUser(useQueryOptions) {
  const store = useStore();
  const auth = store.auth;
  const { data: user } = useQuery(
    ['user'],
    async () => {
      const http = createClient(auth?.token);
      const { data } = await http.get('/user');
      return data;
    },
    useQueryOptions,
  );

  return user;
}
