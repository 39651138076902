import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import nextId from 'react-id-generator';
import { paramCase } from 'param-case';
import Tooltip from './Tooltip';

@observer
export default class Radio extends Component {
  htmlId = `radio-${nextId()}`;

  handleChange = e => {
    const key = this.props.name;
    const value = e.target ? e.target.value : e.field.value();

    if (this.props.onChange) {
      this.props.onChange(key, value);
    }

    if (this.props.form && this.props.form.handleChange) {
      this.props.form.handleChange(key, value);
    }
  };

  render() {
    const { form, label, labelSubText, name, tooltip, value, dataTestId } =
      this.props;
    const hasError = form && form.error && form.error[name];
    const inputClassName = `form-check-input input-${paramCase(name)}`;
    const groupClassName = `form-check custom-input ${
      hasError ? 'has-danger' : ''
    }`;
    const isChecked = form[name] === value;

    return (
      <div className={groupClassName}>
        <label className='form-control-label ml-4'>
          <input
            className={inputClassName}
            checked={isChecked}
            id={this.htmlId}
            name={name}
            onChange={this.handleChange}
            type='radio'
            value={value}
          />
          <span data-testid={dataTestId} className='radiobtn' />
          <strong>{label}</strong> {labelSubText}
          {tooltip ? <Tooltip tooltip={tooltip} /> : null}
        </label>
      </div>
    );
  }
}

Radio.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelSubText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
  value: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
};
