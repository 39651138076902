import React from 'react';

import { Icon, ScreeningSectionTitle } from '../shared/ListItemElements';
import certificate from '../shared/svg/certificate.svg';
import ScreeningsSectionAccordion from '../shared/ScreeningsSectionAccordion';

import EducationVerification from './VerificationScreenings/EducationVerification';
import EmploymentVerification from './VerificationScreenings/EmploymentVerification';
import ProfessionalLicenseVerification from './VerificationScreenings/ProfessionalLicenseVerification';
import PersonalReferenceVerification from './VerificationScreenings/PersonalReferenceVerification';
import ProfessionalReferenceVerification from './VerificationScreenings/ProfessionalReferenceVerification';
import { ADD_SCREENINGS } from '../../../../../constants/identifiers';

const Screenings = () => (
  <ul>
    <EmploymentVerification />
    <EducationVerification />
    <ProfessionalLicenseVerification />
    <PersonalReferenceVerification />
    <ProfessionalReferenceVerification />
  </ul>
);

const VerificationScreenings = () => {
  return (
    <ScreeningsSectionAccordion
      IconComponent={<Icon src={certificate} className='screening-icon' />}
      TitleComponent={
        <ScreeningSectionTitle>Verifications</ScreeningSectionTitle>
      }
      ScreeningsComponent={<Screenings />}
      dataTestId={ADD_SCREENINGS.domesticVerificationScreenings}
    />
  );
};

export default VerificationScreenings;
