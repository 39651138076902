/* global Stripe */
/* global __env */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/browser';
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  CaptureConsole as CaptureConsoleIntegration,
} from '@sentry/integrations';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import App from './components/App';
import AppState from './stores/AppState';
import { discardErrorMessages, scrubSensitiveData } from './dataDogUtils';

import { datadogRum } from '@datadog/browser-rum';

import smoothscroll from 'smoothscroll-polyfill';

require('./styles/main.scss');

smoothscroll.polyfill();

Stripe.setPublishableKey(__env.STRIPE_PUBLISHABLE_KEY);

if (__env && __env.SENTRY_TOKEN) {
  Sentry.init({
    dsn: __env.SENTRY_TOKEN,
    integrations: [
      new ExtraErrorDataIntegration({
        depth: 4,
      }),
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['error'],
      }),
    ],
    maxBreadcrumbs: 50,
    allowUrls: [
      /partners\.checkr-sandbox\.com/,
      /partners\.checkrhq-staging\.net/,
      /partners\.checkr-staging\.com/,
      /partners\.checkr\.com/,
    ],
    ignoreErrors: [
      // This error comes from Google Translate for Chrome on iPhones and is not related to any of our code
      /TypeError: undefined is not an object \(evaluating '[a-zA-Z]\.[a-zA-Z]'\)/,
    ],
    environment: __env.REACT_APP_ENV,
  });
}

if (__env && __env.DD_RUM_CLIENT_TOKEN && __env.DD_RUM_APPLICATION_ID) {
  datadogRum.init({
    clientToken: __env.DD_RUM_CLIENT_TOKEN,
    applicationId: __env.DD_RUM_APPLICATION_ID,
    site: 'datadoghq.com',
    env: __env.REACT_APP_ENV,
    service: 'partner-onboarding',
    version: __env.DD_VERSION,
    trackUserInteractions: true, // includes frustrations
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    beforeSend: event => {
      scrubSensitiveData(event);
      // If we should discard the event, return false so it is not sent to DataDog.
      if (discardErrorMessages(event)) {
        return false;
      }
      // Otherwise, return the (scrubbed) event so it is sent to DataDog.
      return event;
    },
  });
}

const appState = (window._appState = new AppState(__env));

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <AppContainer>
    <App store={appState} />
  </AppContainer>,
  document.getElementById('root'),
);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default;
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(
      <AppContainer>
        <NextApp store={appState} />
      </AppContainer>,
      document.getElementById('root'),
    );
  });
}
