import React from 'react';
import { Box, Typography } from '@mui/material/';
import { CheckCircle } from '@mui/icons-material';

const BusinessDescription = ({ heading, description }) => {
  return (
    <>
      <Box
        className='d-flex align-content-center flex-wrap'
        sx={{ mt: { xs: '1rem', lg: '1.5rem' } }}
      >
        <Box className='d-flex align-content-center flex-wrap mr-3'>
          <CheckCircle className='aqua-3' />
        </Box>
        <Typography
          variant='h5'
          className='primary-text-signup-v4'
          display='inline'
          fontFamily='National2Medium'
          sx={{
            typography: {
              xs: { fontSize: '16px', fontWeight: '700' },
              lg: { fontSize: '22px', fontWeight: '500' },
            },
          }}
        >
          {heading}
        </Typography>
      </Box>
      <Box
        className='d-flex align-content-center mt-1'
        sx={{ mb: { xs: '1rem', lg: '0rem' } }}
      >
        <Box className='d-flex align-content-center flex-wrap mr-3'>
          <CheckCircle sx={{ visibility: 'hidden' }} />
        </Box>
        <Typography
          variant='body1'
          className='primary-text-signup-v4'
          fontFamily='National2Regular'
          fontWeight='400'
          sx={{
            typography: {
              xs: { fontSize: '16px' },
              lg: { fontSize: '18px' },
            },
          }}
        >
          {description}
        </Typography>
      </Box>
    </>
  );
};

const BusinessDescriptions = ({ content }) => {
  return Object.entries(content).map(([heading, description]) => (
    <BusinessDescription
      heading={heading}
      description={description}
      key={heading}
    />
  ));
};

export default BusinessDescriptions;
