import React from 'react';
import CheckrLogo from '../CheckrDirect/icons/CheckrLogo.svg';
import { useApplication } from '../../../hooks/useApplication';
import { Box } from '@mui/material';
import { SIGNUP } from '../../../constants/identifiers';

const PartnerHeader = () => {
  const application = useApplication();
  const partnerLogo = application?.logo_url;

  return (
    <Box
      className='logo-container'
      sx={{
        padding: { xs: '13px 30px', lg: '60px 80px' },
        borderBottom: {
          xs: '1px solid #E5E7ED',
          lg: 'none',
        },
      }}
      data-testid={SIGNUP.MISC.partnerHeader}
    >
      <Box
        component='img'
        src={CheckrLogo}
        alt='Checkr'
        data-testid={SIGNUP.MISC.checkrHeader}
        sx={{
          maxWidth: { xs: '86px', lg: '135px' },
          maxHeight: { xs: '20px !important', lg: '32px !important' },
        }}
      />
      <svg
        width='50'
        height='32'
        viewBox='0 0 1 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='1' height='32' fill='#979797' />
      </svg>
      <Box
        component='img'
        className='partner-logo'
        src={partnerLogo}
        alt='partner logo'
        data-testid={SIGNUP.MISC.partnerLogoHeader}
        sx={{
          maxWidth: { xs: '86px', lg: '135px' },
          maxHeight: { xs: '20px !important', lg: '32px !important' },
        }}
      />
    </Box>
  );
};

export default PartnerHeader;
