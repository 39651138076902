export const errorValuesForTracking = errors => {
  const errorValues = [];

  for (const error in errors) {
    switch (error) {
      case '_status':
      case '_api':
        errorValues.push('Internal billing error');
        break;
      case 'error':
        errorValues.push(errors[error]);
        break;
      case 'promotion_code':
        errors[error][0].includes('invalid')
          ? errorValues.push('Promotion code invalid')
          : errorValues.push(errors[error][0]);
        break;
      case '_stripe':
        break;
      default:
        errorValues.push(errors[error][0]);
    }
  }

  return [...new Set(errorValues)];
};
