import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export default class Deauthorize extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
  }

  render() {
    return <div>DEAUTHORIZE</div>;
  }
}

Deauthorize.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
