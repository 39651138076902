import React from 'react';
import startCase from 'lodash/startCase';
import { Typography } from '@mui/material/';
import { Check } from '@mui/icons-material';
import {
  SCREENING_NAMES,
  SCREENINGS_WITH_SUBTYPES,
} from '../../helpers/Constants';
import ScreeningToolTip from './ScreeningToolTip';

const checkIconStyle = {
  fontSize: 'medium',
  width: '1em',
  color: '#117091',
  fontWeight: '400',
  marginRight: '6px',
  marginTop: '-2px',
};

const displayScreening = (screening, slug, isSelected) => {
  let screeningName =
    SCREENING_NAMES[screening.type] || startCase(screening.type);
  if (screening.subtype && SCREENINGS_WITH_SUBTYPES.includes(screening.type)) {
    screeningName += ` (${screening.subtype.replace(
      /(\d+)([a-zA-Z]+)/g,
      '$1 $2',
    )})`;
  }

  return (
    <div style={{ marginTop: '-3px' }} key={`desc-${slug}-${screening.type}`}>
      <Typography
        variant='body2'
        color={isSelected ? '#FFF' : 'text.secondary'}
        sx={{
          typography: {
            xs: { fontSize: '12px' },
            lg: { fontSize: '14px' },
          },
        }}
        display='inline'
      >
        <Check
          style={{
            ...checkIconStyle,
            color: isSelected ? '#FFF' : '#117091',
          }}
        />
        {screeningName}
        <ScreeningToolTip
          isSelected={isSelected}
          screening={screening.type}
          slug={slug}
        />
      </Typography>
    </div>
  );
};

const PackageCardScreening = ({ slug, screening, isSelected }) => (
  <>
    {displayScreening(screening, slug, isSelected)}
    {screening.type === 'national_criminal_search' &&
      displayScreening(
        { type: 'targeted_county_criminal_search' },
        slug,
        isSelected,
      )}
  </>
);

export default PackageCardScreening;
