import ValidationStore from '../ValidationStore';
import { authorizeAndRedirectToApplication } from './helpers/ConnectHelper';
import { makeObservable } from 'mobx';

export default class SubmissionStore extends ValidationStore {
  constructor(state) {
    super();
    this._state = state;
    makeObservable(this);
  }

  // TODO: https://checkr.atlassian.net/browse/ESUP-1682
  // trackWelcomeToCheckrPageCompleted(callback) {
  //   this._state.trackAnalyticsEvent(
  //     'Self-Serve Customer Signup Seamless Checkout Welcome to Checkr Page Completed',
  //     {
  //       'Go To Dashboard Selected': true,
  //     },
  //     callback,
  //   );
  // }

  // trackGoToDashboardClicked(callback) {
  //   this._state.trackAnalyticsEvent(
  //     'Self-Serve Customer Signup Order Submitted Page Completed',
  //     {
  //       'Go To Dashboard Selected': true,
  //     },
  //     callback,
  //   );
  // }

  async handleRedirect() {
    try {
      this.loading = true;
      await this._state.utms.track();
      await authorizeAndRedirectToApplication(this._state);
    } catch (errors) {
      this.error = errors;
      this.loading = false;
    }
  }
}
