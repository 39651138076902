import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';
import Footer from '../../Footer';
import checkMark from '../../../images/success.svg';
import exclamation from '../../../images/exclamation.png';
import Logos from '../../Logos';
import Title from '../../Title';
import Steps from '../../Steps';
import * as Form from '../../form';

import { CONTACT_FORM_URL, INDUSTRY_OPTIONS } from '../../helpers/Constants';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../../services/analytics';
import { SIGNUP } from '../../../constants/identifiers';

@inject('store')
@observer
export default class Submission extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.form = this.store.submission;
    this._handleRedirect = this.handleRedirect.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.SUBMIT_PAGE_VIEWED);
  }

  trackIndustrySelected() {
    const industry = this.store.signupV3?.industry;
    const industryText =
      INDUSTRY_OPTIONS.find(o => o.value === parseInt(industry))?.text ||
      'Not Selected';

    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.ACCOUNT_SUBMITTED, {
      [ANALYTICS_PROPERTIES.INDUSTRY_SELECTED_UPON_SUBMISSION]: industryText,
    });
  }

  trackAccountSubmitted() {
    this.store.trackAnalyticsEvent(ANALYTICS_EVENTS.ACCOUNT_SUBMITTED, {
      [ANALYTICS_PROPERTIES.BUTTON_CLICK]: 'Submit Account Clicked',
    });
  }

  handleRedirect(e) {
    e.preventDefault();
    this.trackIndustrySelected();
    this.trackAccountSubmitted();
    this.form.handleRedirect();
  }

  render() {
    const formStep = this.store.isBillable() ? 3 : 2;

    return (
      <div>
        {this.store.isSignup && (
          <Redirect to={this.store.getStep('submission')} />
        )}
        <div className='container'>
          <div className='row justify-content-lg-center'>
            <div className='col-lg-7 col-md-12'>
              <div className='row submission-container-outer py-4'>
                <Logos />
                <Title label='Connect to Checkr' baseClass='mb-0' />
                <Steps step={formStep} isBillable={this.store.isBillable()} />
                <div className='col-lg-12 px-5 pt-3 main'>
                  <div className='checklist'>
                    <div className='mb-4'>
                      <img className='checklist-icon' src={checkMark} />
                      <strong>
                        Contact and business information collected
                      </strong>
                    </div>
                    {this.store.isBillable() && (
                      <div className='mb-4'>
                        <img className='checklist-icon' src={checkMark} />
                        <strong>Payment information submitted</strong>
                      </div>
                    )}
                    <div className='mb-4'>
                      <div className='d-flex align-items-center'>
                        <img className='checklist-icon' src={exclamation} />
                        <strong>Account still needs to be authorized</strong>
                      </div>
                      <p className='checklist-description'>
                        Click the button below to submit your account for
                        authorization. Checkr will verify your information and
                        notify you in 1-2 business days via email when your
                        account is ready to run background checks.
                      </p>
                      <div className='row justify-content-md-center'>
                        <button
                          className='btn btn-lg btn-success submit-button-margin'
                          onClick={this._handleRedirect}
                          disabled={this.form.loading}
                          data-testid={SIGNUP.MISC.connect}
                        >
                          Submit account
                        </button>
                      </div>
                      <br />
                      <Form.Error error={this.form.error} store={this.store} />
                    </div>
                  </div>
                  <div className='row'>
                    <hr className='col-4 my-2' />
                  </div>
                  <div className='row justify-content-md-center'>
                    <div className='col-md-12 text-center my-3 px-5'>
                      <p className='mb-4'>
                        In the meantime, please{' '}
                        <a
                          href={CONTACT_FORM_URL}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          contact us
                        </a>{' '}
                        for any questions you may have about your Checkr
                        account. Once you click submit, you will be returned to
                        the {this.store.application.name} website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Submission.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
};
