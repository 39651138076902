import FieldKit from 'field-kit';
import TextField from './TextField';

export default class CardTextField extends TextField {
  static fieldName = 'card';

  getFormatter() {
    return new FieldKit.AdaptiveCardFormatter();
  }
}

CardTextField.propTypes = TextField.getBasePropTypes();

CardTextField.defaultProps = TextField.getBaseDefaultProps();
