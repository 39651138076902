import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Form extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.submit(this.props.version);
  };

  render() {
    const { className } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete='off'
        className={className}
      >
        {this.props.children}
      </form>
    );
  }
}

Form.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  form: PropTypes.object.isRequired,
  version: PropTypes.string,
};
