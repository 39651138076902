import FieldKit from 'field-kit';
import TextField from './TextField';

const DIGITS_PATTERN = /^\d*$/;

export default class TaxNumberField extends TextField {
  static fieldName = 'tin';
  getFormatter() {
    return new TaxNumberFormatter();
  }
}

class TaxNumberFormatter extends FieldKit.DelimitedTextFormatter {
  constructor() {
    super('-');
    this.maximumLength = 9 + 1;
  }

  hasDelimiterAtIndex(index) {
    return index === 2;
  }

  isChangeValid(change, error) {
    if (DIGITS_PATTERN.test(change.inserted.text)) {
      return super.isChangeValid(change, error);
    } else {
      return false;
    }
  }
}

TaxNumberField.propTypes = TextField.getBasePropTypes();

TaxNumberField.defaultProps = TextField.getBaseDefaultProps();
