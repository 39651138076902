/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Check } from '@mui/icons-material';

import {
  Box,
  CardActionArea,
  CardHeader,
  CardContent,
  Grid,
  Button,
  Typography,
} from '@mui/material/';
import { PackageCard, PackageCardDetailsTooltip } from './packages';
import basicIcon from '../../../../images/basic-icon.svg';
import essentialIcon from '../../../../images/essential-icon.svg';
import professionalIcon from '../../../../images/professional-icon.svg';

import { isSelected } from '../utils';
import { INVITES } from '../../../../constants/identifiers';

import { PackageConfig } from '../types';
import { GenericObject } from '@dashboard-experience/utils';
import { CheckCircleIcon } from './shared';
import { useInvitesAnalyticsTrackers } from '../hooks/useInvitesAnalyticsTrackers';

type PackageCardGridProps = {
  packages: PackageConfig[];
  onPackageChange: (slug: string) => void;
  selectedPackage: string;
  selectedRegion: string;
  hidden: boolean;
};

export const Icon = styled.img`
  width: 48px;
  height: 48px;
`;

const CustomCardHeader = ({
  name,
  subHeader,
  isPackageSelected,
  slug,
  styleConfig,
  icon,
  selectedRegion,
}: {
  name: string;
  slug: string;
  subHeader: string;
  icon: string;
  isPackageSelected: boolean;
  styleConfig: GenericObject;
  selectedRegion: string;
}) => {
  const styles = isPackageSelected
    ? styleConfig.selected
    : styleConfig.unselected;

  const iconSrc =
    icon === 'basic'
      ? basicIcon
      : icon === 'essential'
        ? essentialIcon
        : professionalIcon;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0rem 1.25rem',
        backgroundColor: styles.backgroundColor,
        minHeight: selectedRegion === 'US' ? '92px' : '120px',
      }}
      data-testid={`${INVITES.MISC.packageCard}-${slug}-header`}
    >
      <Icon src={iconSrc} />
      <CardHeader
        titleTypographyProps={{
          fontFamily: 'ArgentCFDemiBold',
          fontSize:
            selectedRegion !== 'US'
              ? { xs: '14px', sm: '14px', md: '16px' }
              : { xs: '18px', sm: '18px', md: '20px' },
          fontWeight: '600',
          letterSpacing: '0.4px',
          color: isPackageSelected ? '#FFF' : '#001730',
          transition: '0.8s',
        }}
        title={name}
        subheader={
          <>
            <Typography
              color={
                isPackageSelected
                  ? 'rgba(255, 255, 255, 0.78)'
                  : 'rgba(26, 32, 38, 0.78)'
              }
              display='inline'
              fontFamily='National2Regular'
              sx={{ fontSize: { xs: '12px', sm: '12px', md: '14px' } }}
              fontWeight='400'
              lineHeight='18px'
            >
              {subHeader}
            </Typography>
          </>
        }
      />
    </div>
  );
};

export const StyledCardActionArea = styled(CardActionArea)`
  :hover {
    background: transparent;
  }
`;

const PackageCardGrid = ({
  packages,
  onPackageChange,
  selectedPackage,
  selectedRegion,
  hidden,
}: PackageCardGridProps) => {
  const [hasSelectedPackage, setHasSelectedPackage] = useState(false);
  const { trackPackageCardSelected } = useInvitesAnalyticsTrackers();

  useEffect(() => {
    if (selectedPackage) {
      setHasSelectedPackage(true);
    }
  }, [hasSelectedPackage, selectedPackage]);

  return (
    <Grid
      container
      spacing={{ xs: 5, sm: 5, md: 5, lg: 2 }}
      direction='row'
      marginBottom='2rem'
      hidden={hidden}
    >
      {packages.map(
        (
          {
            name,
            slug,
            label,
            isMostPopular,
            price,
            description,
            styleConfig,
            subHeader,
            icon,
          },
          i,
        ) => {
          const isPackageSelected = isSelected(selectedPackage, slug);

          return (
            <Grid
              key={`${slug}-grid`}
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              sx={{ marginTop: 'auto' }}
            >
              <PackageCard
                testId={`${INVITES.MISC.packageCard}-${slug}`}
                isSelected={isPackageSelected}
                handleClick={() => {
                  trackPackageCardSelected();
                  if (selectedPackage !== slug) onPackageChange(slug);
                }}
                isMostPopular={isMostPopular}
                hasSelectedPackage={hasSelectedPackage}
              >
                <Typography
                  data-testid={`${INVITES.MISC.package}-${i}`}
                  padding='0.5625rem 1rem'
                  fontFamily='National2NarrowMedium'
                  fontWeight='500'
                  textAlign='center'
                  lineHeight='100%'
                  letterSpacing='0.78px'
                  textTransform='uppercase'
                  color='#022331'
                  sx={{
                    fontSize: { xs: '11px', sm: '11px', md: '13px' },
                    backgroundColor: isMostPopular ? '#32D0E2' : 'transparent',
                    display: isMostPopular ? 'block' : 'none',
                  }}
                >
                  {label}
                </Typography>
                <div>
                  <CustomCardHeader
                    styleConfig={styleConfig}
                    isPackageSelected={isPackageSelected}
                    slug={slug}
                    name={name}
                    subHeader={subHeader}
                    icon={icon}
                    selectedRegion={selectedRegion}
                  />
                </div>
                <CardContent
                  sx={{
                    padding: '20px',
                    marginBottom: '4px',
                    height: '224px',
                    maxHeight: '224px',
                    fontFamily: 'National2Regular',
                  }}
                >
                  <Typography
                    data-testid={`${INVITES.MISC.packagePrice}-${slug}`}
                    fontWeight='700'
                    sx={{ fontSize: { xs: '18px', sm: '18px', md: '28px' } }}
                    marginLeft='4px'
                    color='#002853'
                    display='inline'
                    letterSpacing='0.28px'
                    marginBottom='20px'
                    fontFamily='National2Bold'
                  >
                    {price}{' '}
                    <Box
                      component='span'
                      sx={{
                        color: '#576c82',
                        fontSize: { xs: '12px', sm: '12px', md: '14px' },
                        fontWeight: '400',
                        fontFamily: 'National2Regular',
                      }}
                    >
                      <span>/ report*</span>
                    </Box>
                  </Typography>
                  <div style={{ marginTop: '6px' }}>
                    {description.map(d => {
                      return (
                        <div
                          data-testid={d.testId}
                          key={d.testId}
                          style={{ paddingTop: '0.75rem' }}
                        >
                          <Typography
                            variant='body2'
                            color='#334559'
                            fontFamily='National2Regular'
                            sx={{
                              fontSize: { xs: '11px', sm: '11px', md: '13px' },
                            }}
                            display='inline'
                            marginTop='-3px'
                          >
                            <CheckCircleIcon
                              isSelected={d?.isHighlighted}
                              itemProp={d.name}
                              isMostPopular={isMostPopular}
                            />
                            {d.isSummary ? (
                              <>
                                <span
                                  style={{
                                    fontFamily: 'National2Medium',
                                  }}
                                >
                                  {d.name}
                                </span>
                              </>
                            ) : (
                              <>{d.name}</>
                            )}
                            {d.details && d.details.length > 0 && (
                              <PackageCardDetailsTooltip
                                testId={d.testId}
                                details={d.details}
                              />
                            )}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
                {/*Card action area for footer*/}
                <Button
                  sx={{
                    borderRadius: '0',
                    backgroundColor: isPackageSelected
                      ? styleConfig.selected.button.backgroundColor
                      : styleConfig.unselected.button.backgroundColor,
                    width: '100%',
                    color: isPackageSelected
                      ? styleConfig.selected.button.color
                      : styleConfig.unselected.button.color,
                    '&:hover': {
                      backgroundColor: isPackageSelected
                        ? styleConfig.selected.button.backgroundColor
                        : styleConfig.hover.button.backgroundColor,
                      color: isPackageSelected
                        ? styleConfig.selected.button.color
                        : styleConfig.hover.button.color,
                    },
                    fontFamily: 'National2Medium',
                    borderTop: `1px solid #e4e7ed`,
                    fontSize: { xs: '14px', sm: '14px', md: '16px' },
                    height: '48px',
                    textTransform: 'none',
                  }}
                  disableRipple
                  data-testid={`${INVITES.MISC.packageCard}-${slug}-button`}
                  onClick={() => {
                    onPackageChange(slug);
                  }}
                >
                  {isPackageSelected ? (
                    <>
                      <Check sx={{ marginRight: '10px', width: '17px' }} />{' '}
                      <span>Selected</span>
                    </>
                  ) : (
                    <span>Select</span>
                  )}
                </Button>
              </PackageCard>
            </Grid>
          );
        },
      )}
    </Grid>
  );
};

export default PackageCardGrid;
