/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import useHover from '../../../../hooks/useHover';

const TooltipBody = styled.div`
  position: absolute;
  width: 284px;
  height: 209px;
  padding: 24px;
  background: white;
  border-radius: 2px;
  bottom: 30px;
  left: -134px;

  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e64;
  box-shadow: 0px 0px 16px 0px #16161d2e;
`;

const TriangleDiv = styled.div`
  position: absolute;
  left: calc(50% - 20px);
  bottom: -10px;

  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid white;
`;

export default function WorkLocationTooltip() {
  const [hoverRef, isHovering] = useHover();
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        marginLeft: '5px',
      }}
    >
      <TooltipBody hidden={!isHovering}>
        Checkr uses work location to determine the appropriate forms and
        disclosures to display to candidates, and to determine any applicable
        compliance reporting requirements for the resultant background check.
        Use work location to accurately reflect the job’s location.
        <TriangleDiv />
      </TooltipBody>
      <svg
        ref={hoverRef}
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.99992 0.333313C5.68138 0.333313 4.39245 0.724306 3.29612 1.45685C2.19979 2.18939 1.34531 3.23058 0.840725 4.44876C0.336141 5.66693 0.204118 7.00737 0.461353 8.30058C0.718588 9.59379 1.35353 10.7817 2.28588 11.714C3.21823 12.6464 4.40611 13.2813 5.69932 13.5385C6.99252 13.7958 8.33297 13.6638 9.55114 13.1592C10.7693 12.6546 11.8105 11.8001 12.543 10.7038C13.2756 9.60745 13.6666 8.31852 13.6666 6.99998C13.6666 5.23187 12.9642 3.53618 11.714 2.28593C10.4637 1.03569 8.76803 0.333313 6.99992 0.333313ZM6.99992 11.2857C6.85865 11.2857 6.72055 11.2438 6.60308 11.1653C6.48562 11.0868 6.39407 10.9753 6.34001 10.8447C6.28594 10.7142 6.2718 10.5706 6.29936 10.4321C6.32692 10.2935 6.39495 10.1662 6.49484 10.0663C6.59474 9.96643 6.72201 9.89841 6.86057 9.87084C6.99913 9.84328 7.14275 9.85743 7.27326 9.91149C7.40378 9.96555 7.51534 10.0571 7.59383 10.1746C7.67231 10.292 7.71421 10.4301 7.71421 10.5714C7.71421 10.7608 7.63895 10.9425 7.505 11.0765C7.37104 11.2104 7.18936 11.2857 6.99992 11.2857ZM7.54278 7.59522V8.78569H6.47135V6.52379H7.47611C7.76337 6.50514 8.03278 6.37787 8.22962 6.16783C8.42647 5.95778 8.53602 5.6807 8.53602 5.39284C8.53602 5.10497 8.42647 4.82789 8.22962 4.61784C8.03278 4.4078 7.76337 4.28053 7.47611 4.26188H6.76182C6.46207 4.26188 6.17452 4.38063 5.96212 4.59214C5.74971 4.80366 5.62975 5.0907 5.62849 5.39045V5.69522H4.56659V5.39045C4.56785 4.80654 4.80069 4.24697 5.21403 3.83453C5.62736 3.42209 6.18743 3.19045 6.77135 3.19045H7.47611C8.04353 3.20843 8.58216 3.44458 8.97982 3.84974C9.37748 4.25491 9.60353 4.79785 9.61089 5.36551C9.61826 5.93317 9.40637 6.4818 9.01935 6.89714C8.63233 7.31248 8.10002 7.56253 7.53325 7.59522H7.54278Z'
          fill='#043F93'
        />
      </svg>
    </div>
  );
}
